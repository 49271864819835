export interface Contato {
  email: string;
  nome: string;
  tipo: EnumTipoContato;
}

export enum EnumTipoContato {
  Comercial = 0,
  Financeiro = 1,
  Fiscal = 2,
}

