import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { ProductSpecificDataService } from './product-specific-data.service';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { ApiSubnivelService } from 'src/app/services/api/dados-especificos/api-subnivel.service';
import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { PdmDescriptionBuilderService } from './pdm-description-builder.service';
import { startWith, map, switchMap, catchError, tap, defaultIfEmpty } from 'rxjs/operators';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';

// Capitalize os valores de PDM vindos de Titulo de Publicação
function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); }

@Injectable()
export class ProductSpecificDataSubnivelService {

  departamentoControl: FormControl;
  departamentoId$: Observable<string>;

  categoriaControl: FormControl;
  categoriaId$: Observable<string>;

  subnivelControl: FormControl;
  subnivelId$: Observable<string>;

  filteredCategorias$: BehaviorSubject<Categoria[]>;
  filteredSubniveis$: BehaviorSubject<Subnivel[]>;

  options$: Observable<CampoDinamico[]>;
  options: CampoDinamico[];

  constructor(
    public specificDataService: ProductSpecificDataService,
    public apiDepartamento: ApiDepartamentoService,
    public apiCategoria: ApiCategoriaService,
    public apiSubnivel: ApiSubnivelService,
    public apiCampoDinamico: ApiCampoDinamicoService,
    public pdmDescriptionBuilder: PdmDescriptionBuilderService,
  ) {
    /**
     * O SHARE REPLAY funciona como um cache, a request é feita apenas uma vez,
     * mesmo que seja criado uma nova subscription para o observable
     */

    this.departamentoControl = this.specificDataService.form.get('departamentoId') as FormControl;
    this.departamentoId$ = this.departamentoControl.valueChanges.pipe(startWith(this.departamentoControl.value));

    this.categoriaControl = this.specificDataService.form.get('categoriaId') as FormControl;
    this.categoriaId$ = this.categoriaControl.valueChanges.pipe(startWith(this.categoriaControl.value));

    this.subnivelControl = this.specificDataService.form.get('subnivelId') as FormControl;
    this.subnivelId$ = this.subnivelControl.valueChanges.pipe(
      startWith(this.subnivelControl.value)
    );

    this.filteredCategorias$ = new BehaviorSubject([]);
    combineLatest([
      this.specificDataService.categorias$,
      this.departamentoId$
    ])
      .pipe(map(([categorias, departamentoId]) => categorias.filter(c => c.departamentoId === departamentoId)))
      .subscribe(res => this.filteredCategorias$.next(res));

    this.filteredSubniveis$ = new BehaviorSubject([]);
    combineLatest([
      this.specificDataService.subniveis$,
      this.filteredCategorias$,
      this.categoriaId$,
    ])
      .pipe(
        map(
          ([subniveis, categorias, categoriaId]) => subniveis.filter(
            s => categoriaId ? s.categoriaId === categoriaId : false
          )
        )
      )
      .subscribe(res => this.filteredSubniveis$.next(res));

    this.options$ = this.subnivelId$.pipe(
      switchMap(id => id ? this.apiCampoDinamico.getBySubnivel(id).pipe(catchError(() => null)) : of(null)),
      tap(res => this.options = res)
    );

    // reseta categoria quando departamento muda
    this.departamentoControl.valueChanges.subscribe(departamentoId => {
      this.categoriaControl.reset(null, { emitEvent: true });
    });

    // reseta subnivel quando categoria muda
    this.categoriaControl.valueChanges.subscribe(categoriaId => {
      this.subnivelControl.reset(null, { emitEvent: true });
    });

    const subnivel$ = combineLatest([
      this.filteredSubniveis$,
      this.subnivelId$
    ]).pipe(
      map(([subniveis, id]) => {
        const subnivel = subniveis && subniveis.find(s => s.id == id);
        return subnivel;
      })
    );

    combineLatest([
      subnivel$,
      this.options$,
      this.specificDataService.descricaoExtendidaPersonalizada.valueChanges.pipe(
        startWith(this.specificDataService.descricaoExtendidaPersonalizada.value
        ))
    ]).pipe(
      switchMap(([subnivel, options]) => subnivel && subnivel.regraDescricaoEstendida ?
        this.pdmDescriptionBuilder.getStringForPDMRule(
          subnivel.regraDescricaoEstendida,
          options,
        ) :
        of('SEM REGRA')
      )
    ).subscribe(description => {
      if (description && this.specificDataService.canUsePdmForDescricaoExtendida) {
        this.specificDataService.form.get('descricaoExtendida').setValue(description);
      }
    });

    combineLatest([
      subnivel$,
      this.options$,
      this.specificDataService.tituloDePublicacaoPersonalizada.valueChanges.pipe(
        startWith(this.specificDataService.tituloDePublicacaoPersonalizada.value
        ))
    ]).pipe(
      switchMap(([subnivel, options]) => subnivel && subnivel.regraTituloDePublicacao ?
        this.pdmDescriptionBuilder.getStringForPDMRule(
          subnivel.regraTituloDePublicacao,
          options,
        ) :
        of('SEM REGRA')
      )
    ).subscribe(description => {
      if (description && this.specificDataService.canUsePdmForTituloDePublicacao) {
        this.specificDataService.form.get('tituloDePublicacao').setValue(titleCase(description));
      }
    });

    combineLatest([
      subnivel$,
      this.options$,
      this.specificDataService.descricaoEcommercePersonalizada.valueChanges.pipe(
        startWith(this.specificDataService.descricaoEcommercePersonalizada.value
        ))
    ]).pipe(
      switchMap(([subnivel, options]) => subnivel && subnivel.regraDescricaoEcommerce ?
        this.pdmDescriptionBuilder.getStringForPDMRule(
          subnivel.regraDescricaoEcommerce,
          options,
        ) :
        of('')
      )
    ).subscribe(description => {
      if (description && this.specificDataService.canUsePdmForDescricaoEcommerce) {
        this.specificDataService.form.get('dadosDoEcommerce').get('nomeDoProduto').setValue(titleCase(description));
      }
    });

    combineLatest([
      subnivel$,
      this.options$,
      this.specificDataService.descricaoLongaPersonalizada.valueChanges.pipe(
        startWith(this.specificDataService.descricaoLongaPersonalizada.value
        ))
    ]).pipe(
      switchMap(([subnivel, options]) => subnivel && subnivel.regraDescricaoLonga ?
        this.pdmDescriptionBuilder.getStringForPDMRule(
          subnivel.regraDescricaoLonga,
          options,
        ) :
        of('SEM REGRA')
      ),
    ).subscribe(description => {
      if (description && this.specificDataService.canUsePdmForDescricaoLonga) {
        this.specificDataService.form.get('descricaoLonga').setValue(description);
      }
    });
  }

  pdmSortimentoGrade(sortimentoGrade: string = "sortimento") {
    const subnivelId$ = this.subnivelControl.valueChanges.pipe(
      startWith(this.subnivelControl.value)
    );

    const subnivel$ = combineLatest([
      this.filteredSubniveis$,
      subnivelId$
    ]).pipe(
      map(([subniveis, id]) => {
        const subnivel = subniveis && subniveis.find(s => s.id == id);
        return subnivel;
      })
    );
 
    let options$: BehaviorSubject<CampoDinamico[]> = new BehaviorSubject([]);
    options$.next(this.options);

    let subnivel;
    subnivel$.subscribe(sub => subnivel = sub);

    let options;
    options$.subscribe(opt => {options = opt});
    
    
    let selectedForm;
    if (sortimentoGrade == "sortimento") {
      selectedForm = this.specificDataService.sortimentoListForm.value;
    } else {
      selectedForm = this.specificDataService.gradeListForm.value;
    }

    const arrayRegras = [
      { regra: 'regraDescricaoEstendida', campo: 'descricaoEstendida' },
      { regra: 'regraTituloDePublicacao', campo: 'tituloDePublicacao'},
      { regra: 'regraDescricaoEcommerce', campo: 'descricaoEcommerce'},
      { regra: 'regraDescricaoLonga', campo: 'descricaoLonga' }];

    selectedForm.map((itemForm, index) => {
      arrayRegras.map(regra => {
        var inputSelected;
        if (sortimentoGrade == "sortimento"){
          if (regra.campo == "descricaoEcommerce"){
            inputSelected = this.specificDataService.sortimentoListForm.get(`${index}`).get('dadosDoEcommerce').get('nomeDoProduto');
          }
          else{
            inputSelected = this.specificDataService.sortimentoListForm.get(`${index}`).get(`${regra.campo}Sortimento`);
          }
        }
        else{
          if (regra.campo == "descricaoEcommerce"){
            inputSelected = this.specificDataService.gradeListForm.get(`${index}`).get('dadosDoEcommerce').get('nomeDoProduto');
          }
          else{
            inputSelected = this.specificDataService.gradeListForm.get(`${index}`).get(`${regra.campo}Grade`);
          }
        }

        const canUpdateInput = sortimentoGrade == "sortimento" ? 
        this.specificDataService.sortimentoListForm.get(`${index}`).get(`${regra.campo}SortimentoPersonalizada`) : 
        this.specificDataService.gradeListForm.get(`${index}`).get(`${regra.campo}GradePersonalizada`);

        if (subnivel[regra.regra]) {
          let observable = this.pdmDescriptionBuilder.getStringForPDMRule(
            subnivel[regra.regra],
            options,
            sortimentoGrade,
            index
          ).pipe(map(item => item), defaultIfEmpty("SEM REGRA"));
  
          observable.subscribe(description => {
            if (description) {
              if(regra.campo == 'tituloDePublicacao' || regra.campo == 'descricaoEcommerce'){
                description = titleCase(description)
              }
              if (canUpdateInput.value) {
                inputSelected.setValue(description);
              }
            }
          });
        } else {
          if (canUpdateInput.value) {
            if (regra.campo == 'descricaoEcommerce'){
              inputSelected.setValue("")
            }
            else {
              inputSelected.setValue("SEM REGRA");
            }
          }
        }
      })
    });
  }
  resetInputsForRulesPDM(input: string, index: number = 0, sortimento: string = 'sortimento') {
    const inputSelected = sortimento == "sortimento" ? 
      this.specificDataService.sortimentoListForm.get(`${index}`).get(`${input}`) : 
      this.specificDataService.gradeListForm.get(`${index}`).get(`${input}`);
    
      const canUpdateInput = sortimento == "sortimento" ? 
      this.specificDataService.sortimentoListForm.get(`${index}`).get(`${input}Personalizada`) : 
      this.specificDataService.gradeListForm.get(`${index}`).get(`${input}Personalizada`);


    if (!inputSelected.value || inputSelected.value.length <= 0) {
      canUpdateInput.setValue(true);
      this.pdmSortimentoGrade(sortimento);
    } else {
      canUpdateInput.setValue(false);
    }
  }

  resetInputsForRulesDadosEcommercePDM(index: number = 0, sortimento: string = 'sortimento') {
    const inputSelected = sortimento == "sortimento" ? 
      this.specificDataService.sortimentoListForm.get(`${index}`).get('dadosDoEcommerce').get('nomeDoProduto') : 
      this.specificDataService.gradeListForm.get(`${index}`).get('dadosDoEcommerce').get('nomeDoProduto');

      const canUpdateInput = sortimento == "sortimento" ? 
      this.specificDataService.sortimentoListForm.get(`${index}`).get('descricaoEcommerceSortimentoPersonalizada') : 
      this.specificDataService.gradeListForm.get(`${index}`).get('descricaoEcommerceGradePersonalizada');

    if (!inputSelected.value || inputSelected.value.length <= 0) {
      canUpdateInput.setValue(true);
      this.pdmSortimentoGrade(sortimento);
    } else {
      canUpdateInput.setValue(false);
    }
  }
}
