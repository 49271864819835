import { Directive, Optional, Input, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { startWith, switchMap, debounceTime, mapTo, map, filter, catchError, distinctUntilChanged } from 'rxjs/operators';
import { ApiDadosBasicosService } from 'src/app/services/api/dados-basicos/api-dados-basicos.service';
import { ProductService } from './services/product.service';
import { of, combineLatest, EMPTY } from 'rxjs';

@Directive({
  selector: '[eanStatus], [dunStatus]',
  exportAs: 'eanStatus'
})
export class EanStatusDirective implements AfterViewInit {
  status$ = of(null);

  constructor(
    @Optional() private ngControl: NgControl,
    private api: ApiDadosBasicosService,
    // private productService: ProductService
  ) { }

  ngAfterViewInit() {
    if (this.ngControl) {
      this.status$ = combineLatest([
        this.ngControl.valueChanges.pipe(startWith(this.ngControl.value)),
        this.ngControl.statusChanges.pipe(startWith(this.ngControl.status))
      ]).pipe(
        debounceTime(500),
        filter(([value, status]) => status == 'VALID' && !!value),
        map( ([value, status]) => value),
        distinctUntilChanged(),
        switchMap( ean => this.api.validateGS1(ean).pipe( catchError( () =>  EMPTY ) ) ),
      );
    }
  }
}
