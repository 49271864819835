import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'c-empty-pattern',
  templateUrl: './empty-pattern.component.html',
  styleUrls: ['./empty-pattern.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyPatternComponent implements OnInit {

  @Input() icon: string;
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

}
