import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-tab-item-title',
  templateUrl: './tab-item-title.component.html',
  styleUrls: ['./tab-item-title.component.scss']
})
export class TabItemTitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
