import { EnumCampoDinamicoType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-type.enum';

export const disableRules = new Map([
  [EnumCampoDinamicoType.Text, [
    'dynamicFieldValidator.min',
    'dynamicFieldValidator.minValue',
    'dynamicFieldValidator.max',
    'dynamicFieldValidator.maxValue',
    'options',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.Number, [
    'dynamicFieldValidator.min',
    'dynamicFieldValidator.minValue',
    'dynamicFieldValidator.max',
    'dynamicFieldValidator.maxValue',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'options',
    'mask',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.SimpleSelect, [
    'prefix',
    'sufix',
    'mask',
    'dynamicFieldValidator.min',
    'dynamicFieldValidator.minValue',
    'dynamicFieldValidator.max',
    'dynamicFieldValidator.maxValue',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.Radio, [
    'prefix',
    'sufix',
    'mask',
    'dynamicFieldValidator.min',
    'dynamicFieldValidator.minValue',
    'dynamicFieldValidator.max',
    'dynamicFieldValidator.maxValue',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.MultipleSelect, [
    'prefix',
    'sufix',
    'mask',
    'dynamicFieldValidator.min',
    'dynamicFieldValidator.minValue',
    'dynamicFieldValidator.max',
    'dynamicFieldValidator.maxValue',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.CheckBox, [
    'prefix',
    'sufix',
    'mask',
    'dynamicFieldValidator.min',
    'dynamicFieldValidator.minValue',
    'dynamicFieldValidator.max',
    'dynamicFieldValidator.maxValue',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.Composition, [
    'prefix',
    'sufix',
    'mask',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'min',
    'max',
  ]],
  [EnumCampoDinamicoType.Range, [
    'prefix',
    'sufix',
    'mask',
    'dynamicFieldValidator.required',
    'dynamicFieldValidator.minLength',
    'dynamicFieldValidator.minLengthValue',
    'dynamicFieldValidator.maxLength',
    'dynamicFieldValidator.maxLengthValue',
    'dynamicFieldValidator.email',
    'dynamicFieldValidator.pattern',
    'dynamicFieldValidator.patternValue',
    'options'
  ]],
]);
