import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'c-products-navbar',
  templateUrl: './products-navbar.component.html',
  styleUrls: ['./products-navbar.component.scss']
})
export class ProductsNavbarComponent implements OnInit {

  @Input() integratedProductAmount = 0;
  @Input() newProductAmount = 0;
  @Output() emitEvent = new EventEmitter<string>();
  selected = 'cleanFilters';
  constructor() { }

  ngOnInit() {
  }

  sendToInRevision(button: string): void {
    this.selected = button;
    this.emitEvent.emit(button);
  }

}
