import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanDeactivateGuard } from 'src/app/services/guards/can-deactivate.guard';
import { AuthGuard } from 'src/app/services/guards/auth.guard';
import { DynamicFieldEditorComponent } from './dynamic-field-editor.component';
import { DynamicFieldEditorResolverService } from './dynamic-field-editor-resolver.service';


const routes: Routes = [
  {
    path: '', redirectTo: '/dashboard', pathMatch: 'full'
  },
  {
    path: 'new',
    component: DynamicFieldEditorComponent,
    canDeactivate: [
      CanDeactivateGuard
    ],
    canActivate: [AuthGuard],
    resolve: {
      field: DynamicFieldEditorResolverService
    },
    // to-do: criar permissao no banco
    // data: { permission: 'rhcdm.dynamic-field-editor.editar' }
  },
  {
    path: ':id',
    component: DynamicFieldEditorComponent,
    canDeactivate: [
      CanDeactivateGuard
    ],
    canActivate: [AuthGuard],
    resolve: {
      field: DynamicFieldEditorResolverService
    },
    // to-do: criar permissao no banco
    // data: { permission: 'rhcdm.dynamic-field-editor.editar' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicFieldEditorRoutingModule { }
