import { Component, OnInit } from '@angular/core';
import { ApiWorkflowService } from 'src/app/services/api/workflow/api-workflow.service';
import { ActivatedRoute } from '@angular/router';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';
import { LogIntegracao } from 'src/app/models/api/workflow/log-integracao.interface';
import { EnumSistemaIntegracaoStatus } from 'src/app/models/api/workflow/enum-sistema-integracao-status.enum';
import { ApiIntegracaoService } from 'src/app/services/api/workflow/api-integracao.service';

@Component({
  selector: 'c-product-integration',
  templateUrl: './product-integration.component.html',
  styleUrls: ['./product-integration.component.scss']
})
export class ProductIntegrationComponent implements OnInit {

  productId: string;
  generalData: WorkflowProduto;
  logData: LogIntegracao[];

  constructor(
    private route: ActivatedRoute,
    private apiWorkflowServ: ApiWorkflowService,
    private apiIntegracaoService: ApiIntegracaoService
  ) {
    this.productId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.loadProductIntegrationData();
    this.loadProductIntegrationLogData();
  }

  loadProductIntegrationData() {
    if (this.productId) {
      this.apiWorkflowServ.getById(this.productId).subscribe(res => {
        this.generalData = res;
      });
    }
  }

  loadProductIntegrationLogData() {
    if (this.productId) {
      this.apiWorkflowServ.getLogById(this.productId).subscribe(res => {
        if (res) {
          this.logData = res.reverse();
        }
      });
    }
  }

  getIntegrationStatusName(value: EnumSistemaIntegracaoStatus) {
    switch (value) {
      case EnumSistemaIntegracaoStatus.NaoIntegrado:
        return 'not integrated';

      case EnumSistemaIntegracaoStatus.Integrado:
        return 'integrated';

      case EnumSistemaIntegracaoStatus.ErroAoIntegrar:
        return 'error in integration';

      case EnumSistemaIntegracaoStatus.PrecisaSerAtualizado:
        return 'need update';
    }
  }

  get canIntegrateSap() {
    if (this.generalData) {
      return !this.generalData.bloquearIntegracaoSap;
    }
  }

  get canIntegrateVtex() {
    if (this.generalData) {
      return !this.generalData.bloquearIntegracaoVTEX;
    }
  }

  get isSapIntegrated() {
    if (this.generalData) {
      return this.generalData.integradoComSap;
    }
  }

  get isVtexIntegrated() {
    if (this.generalData) {
      return this.generalData.integradoComVTEX;
    }
  }

  get canApproveSapIntegration() {
    if (this.generalData) {
      return this.generalData.bloquearIntegracaoSap;
    }
  }

  get canApproveVtexIntegration() {
    if (this.generalData) {
      return this.generalData.bloquearIntegracaoVTEX;
    }
  }

  integrateSap() {
    this.apiIntegracaoService.integrateSap(this.productId).subscribe(() => {
    });
  }

  integrateVtex() {
    this.apiIntegracaoService.integrateVtex(this.productId).subscribe(() => {
    });
  }

  integrateCeva() {
    this.apiIntegracaoService.integrateCeva(this.productId).subscribe(() => {
    });
  }


  toggleApproveOrReproveSapIntegration() {
    if (this.generalData) {
      if (this.generalData.bloquearIntegracaoSap) {
        // Approve
        this.apiIntegracaoService.approveSapIntegration(this.productId, this.generalData.bloquearIntegracaoVTEX).subscribe();
      } else {
        // Reprove
        this.apiIntegracaoService.reproveSapIntegration(this.productId, this.generalData.bloquearIntegracaoVTEX).subscribe();
      }

      this.generalData.bloquearIntegracaoSap = !this.generalData.bloquearIntegracaoSap;
    }
  }

  toggleApproveOrReproveVtexIntegration() {
    if (this.generalData) {
      if (this.generalData.bloquearIntegracaoVTEX) {
        // Approve
        this.apiIntegracaoService.approveVtexIntegration(this.productId, this.generalData.bloquearIntegracaoSap).subscribe();
      } else {
        // Reprove
        this.apiIntegracaoService.reproveVtexIntegration(this.productId, this.generalData.bloquearIntegracaoSap).subscribe();
      }

      this.generalData.bloquearIntegracaoVTEX = !this.generalData.bloquearIntegracaoVTEX;
    }
  }

}
