import { Directive, OnDestroy, Input, OnInit } from '@angular/core';
import { FormControl, ControlContainer } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { Estado } from 'src/app/models/api/autenticacao/estado';
import { Cidade } from 'src/app/models/api/autenticacao/cidade';
import { ApiEstadoService } from 'src/app/services/api/autenticacao/api-estado.service';
import { shareReplay, startWith, switchMap, map, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[cCityState]',
  exportAs: 'cityState',
})
export class CityStateDirective implements OnDestroy, OnInit {
  static states$: Observable<Estado[]>;

  @Input() cityControlName = 'cidadeId';

  private destroy$ = new Subject();

  city: FormControl;
  state = new FormControl();

  cities$: Observable<Cidade[]>;
  states$: Observable<Estado[]>;

  constructor(
    public fg: ControlContainer,
    private api: ApiEstadoService,
  ) {

  }

  ngOnInit() {
    console.log(this.fg);
    this.city = this.fg.control.get(this.cityControlName) as FormControl;
    // pega todos estados da api
    if (!CityStateDirective.states$) {
      CityStateDirective.states$ = this.api.getAllWithCache().pipe(takeUntil(this.destroy$), shareReplay(1));
    }
    this.states$ = CityStateDirective.states$;

    // pega cidades de acordo com o estado selecionado
    this.cities$ = this.states$.pipe(
      takeUntil(this.destroy$),
      switchMap(states => this.state.valueChanges.pipe(
        startWith(this.state.value),
        map(stateId => {
          const state = states.find(s => s.id == stateId);
          return state ? state.cidadeList : states.flatMap( s => s.cidadeList );
        })
      ))
    );

    this.state.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe( state => {
      console.log({state});
      if (state) {
        this.city.reset(null);
      }
    });
    console.log(this.city);
    this.city.valueChanges.pipe(
      startWith(this.city.value),
      tap( value => console.log(value)),
      takeUntil(this.destroy$),
      switchMap((cityId: string) => this.states$.pipe(
        map(states => ({ cityId, states }))
      ))
    ).subscribe(({ cityId, states }) => {
      console.log({cityId, states});
      if (states) {
        if (cityId) {
          const state = states.find(s => s.cidadeList.some(c => c.id === cityId));
          this.state.setValue(state.id, { emitEvent: false });
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
