import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-dashboard-supplier',
  templateUrl: './dashboard-supplier.component.html',
  styleUrls: ['./dashboard-supplier.component.scss']
})
export class DashboardSupplierComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
