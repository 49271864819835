import { Component, OnInit, Input } from '@angular/core';
import { TabContentComponent } from '../tab-content/tab-content.component';

@Component({
  selector: 'c-tab-content-item',
  templateUrl: './tab-content-item.component.html',
  styleUrls: ['./tab-content-item.component.scss']
})
export class TabContentItemComponent implements OnInit {

  @Input() value: any;

  constructor(
    public tabContent: TabContentComponent
  ) { }

  ngOnInit() {
  }

}
