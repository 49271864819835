import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { IResponse } from 'src/app/models/api/response.interface';
import { DadosEspecificos } from 'src/app/models/api/dados-especificos/dados-especificos';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiDadosEspecificosService {

  private baseUrl = 'DadosEspecificos';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  get(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().useLoading().get<DadosEspecificos>(id);
  }

  getAll() {
    return this.controller.get<IResponse<DadosEspecificos[]>>('');
  }

  validateEan(ean: string, productId: string) {
    const params = new HttpParams()
      .set('id', productId)
      .set('ean', ean);

    return this.controller.unwrapMessage().get<string>('ValidaEan', { params });
  }

  validateDun(dun: string, productId: string) {
    const params = new HttpParams()
      .set('id', productId)
      .set('dun', dun);

    return this.controller.unwrapMessage().get<string>('ValidateDun', { params });
  }

  insertOrUpdate(data: DadosEspecificos) {
    return this.controller.unwrap().useLoading().post<DadosEspecificos>('', data);
  }

  validate(data: DadosEspecificos) {
    return this.controller.unwrap().useLoading().post<DadosEspecificos>('ValidarCadastro', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.delete(id);
  }

  saveDraft(draft: DadosEspecificos) {
    return this.controller.unwrap().useLoading().post<DadosEspecificos>('draft', draft);
  }

}
