import { FormControl, NgControl } from '@angular/forms';
import { isFunction } from 'src/app/shared/utils/is-function';
import { map, startWith, tap, first } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

export function greaterThan(controlToCompare: (() => FormControl) | FormControl, controlName: string) {
  return async (control: FormControl) => {
    if (isNullOrUndefined(control.value)) {
      return null;
    }

    const value = control.value;
    const valueToCompareControl = isFunction<FormControl>(controlToCompare) ? controlToCompare() : controlToCompare;
    const valueToCompare = valueToCompareControl.value;

    return value > valueToCompare ? null : { greaterThan: { controlName } };
  };
}

export function greaterOrEqualThan(controlToCompare: (() => FormControl) | FormControl, controlName: string) {
  return async (control: FormControl) => {
    if (isNullOrUndefined(control.value)) {
      return null;
    }

    const value = control.value;
    const valueToCompareControl = isFunction<FormControl>(controlToCompare) ? controlToCompare() : controlToCompare;
    const valueToCompare = valueToCompareControl.value;

    return value >= valueToCompare ? null : { greaterOrEqualThan: { controlName } };
  };
}

export function lessThan(controlToCompare: (() => FormControl) | FormControl, controlName: string) {
  return async (control: FormControl) => {
    if (isNullOrUndefined(control.value)) {
      return null;
    }

    const value = control.value;
    const valueToCompareControl = isFunction<FormControl>(controlToCompare) ? controlToCompare() : controlToCompare;
    const valueToCompare = valueToCompareControl.value;

    return value < valueToCompare ? null : { lessThan: { controlName } };
  };
}

export function lessOrEqualThan(controlToCompare: (() => FormControl) | FormControl, controlName: string) {
  return async (control: FormControl) => {
    if (isNullOrUndefined(control.value)) {
      return null;
    }

    const value = control.value;
    const valueToCompareControl = isFunction<FormControl>(controlToCompare) ? controlToCompare() : controlToCompare;
    const valueToCompare = valueToCompareControl.value;

    return value <= valueToCompare ? null : { lessOrEqualThan: { controlName } };
  };
}
