import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from './modal.service';
import { IconModule } from '../icon/icon.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogService } from './confirm-dialog.service';
import { AlertDialogService } from './alert-dialog.service';

@NgModule({
    declarations: [
        ModalComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
    ],
    imports: [
        CommonModule,
        IconModule
    ],
    providers: [
        ModalService,
        ConfirmDialogService,
        AlertDialogService
    ],
    exports: [
        ModalComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
    ]
})
export class ModalModule { }
