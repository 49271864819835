export enum EnumCampoDinamicoType {
  Text = 0,
  SimpleSelect = 1,
  Radio = 2,
  MultipleSelect = 3,
  CheckBox = 4,
  Number = 5,
  Range = 6,
  Composition = 7,
}
