import { Component, OnInit, Input } from '@angular/core';
import { Historico, HistoricoAbaList } from 'src/app/models/api/historico/historico.interface';
import { ApiHistoricoService } from 'src/app/services/api/historico/api-historico.service';

@Component({
  selector: 'c-product-historic-comment',
  templateUrl: './product-historic-comment.component.html',
  styleUrls: ['./product-historic-comment.component.scss']
})
export class ProductHistoricCommentComponent implements OnInit {

  historic: Historico;
  messages: HistoricMessage[];

  constructor(
    private apiHistoricoService: ApiHistoricoService
  ) { }

  ngOnInit() {
  }

  @Input()
  set data(value: Historico) {
    if (value && value.historicoAbaList) {
      this.historic = value;
      this.messages = value.historicoAbaList.reverse();
    }
  }

  answer(data: HistoricMessage) {
    this.apiHistoricoService.createAsnwer({
      abaId: data.id,
      historicoId: this.historic.id,
      mensagem: data.answerToSend
    }).subscribe(res => {
      this.data = res;
    });
  }

}

interface HistoricMessage extends HistoricoAbaList {
  answerToSend?: string;
}
