import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-loading-wave',
  templateUrl: './loading-wave.component.html',
  styleUrls: ['./loading-wave.component.scss']
})
export class LoadingWaveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
