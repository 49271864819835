import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { QueryFilter } from 'src/app/models/api/query/query-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiIntegracaoEmMassaService {

  private baseUrl = 'IntegracaoEmMassa';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.workflow)
      .useBaseUrl(this.baseUrl);
  }

  get(query?: QueryFilter) {
    return this.controller.useLoading().post<any>('BuscarComFiltro', {
      query: '',
      skip: 0,
      take: 25,
      ...query
    });
  }

  getLogByIntegrationId(id: string) {
    return this.controller.useLoading().get(`/Log/${id}`, { responseType: 'blob' });
  }

}
