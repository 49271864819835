import { Injectable } from '@angular/core';
import { ModalService, ModalConfig } from './modal.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { tap, take } from 'rxjs/operators';
import { AreYouSureDialog } from 'src/app/shared/directives/are-you-sure/are-you-sure-dialog.class';

@Injectable()
export class ConfirmDialogService extends AreYouSureDialog {
  constructor(
    private modalService: ModalService
  ) {
    super();
  }

  confirm(message: string, modalConfig: ModalConfig<any, ConfirmDialogComponent> = {}) {
    return new Promise<boolean>((resolve) => {
      const ref = this.modalService.open(ConfirmDialogComponent, {
        initialState: {
          type: 'dialog'
        },
        contentInitialState: {
          message
        },
        ...modalConfig
      });

      ref.contentComponentRef.instance.confirm.pipe(take(1)).subscribe( v => {
        resolve(v);
      });
    });

  }
}
