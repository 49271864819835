import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DashboardFornecedor } from 'src/app/models/api/dashboard-fornecedor/dashboard-fornecedor';
import { EnumElasticDashboardColumns } from 'src/app/models/api/elastic/elastic-dashboard-columns.enum';
import { QueryFilter } from 'src/app/models/api/query/query-filter.interface';
import { IResponse } from 'src/app/models/api/response.interface';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';
import { ApiElasticDashboardService } from 'src/app/services/api/elastic/api-elastic-dashboard.service';
import { SessionService } from 'src/app/services/session.service';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { DashboardService } from '../../services/dashboard.service';
import { EnumProductTableRegisteredsSubView, ProductTableRegistereds } from '../product-list.component';

@Component({
  selector: 'c-product-list-table-in-registereds',
  templateUrl: './product-list-table-in-registereds.component.html',
  styleUrls: ['./product-list-table-in-registereds.component.scss']
})
export class ProductListTableRegisteredsComponent implements OnInit {

  integrationDataSource = [];
  @Output() onPaginate = new EventEmitter<{
    paginator: IPaginator;
    status: EnumProductTableRegisteredsSubView;
  }>();
  tabSelected: EnumProductTableRegisteredsSubView =
    EnumProductTableRegisteredsSubView.registeredsSupplier;
  EnumProductTableRegisteredsSubView = EnumProductTableRegisteredsSubView;
  defaultTableColumns: string[] = [
    "reference",
    "supplierCodSap",
    "supplierName",
    "description",
    "category",
    "quality",
    "createdAt",
    "createdAtCommercial",
    "registerResponsable",
    "commercialResponsable",
    "actions",
  ];
  inRevisionTabFilterPermission: string;
  tabs!: ProductTableRegistereds[];
  page$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0,
  });

  columnFilters$: BehaviorSubject<string[]>;

  isNotFornecedor = true;

  constructor(
    private elasticDashboardServ: ApiElasticDashboardService,
    private sessionService: SessionService,
    private dashboardService: DashboardService,
  ) {
    this.configureSearch();
  }

  async ngOnInit() {
    this.isNotFornecedor = this.sessionService.user.role !== 'fornecedor';
    this.createColumnFilters();
    this.createTabs();
    this.loadAllProductsData();
  }

  configureSearch() {
    this.dashboardService.search$.pipe(skip(1), debounceTime(800)).subscribe(() => {
      this.loadAllProductsData(true);
    });
  }

  createColumnFilters() {
    const columnValues: string[] = Object.keys(EnumElasticDashboardColumns).map(
      (key) => EnumElasticDashboardColumns[key]
    );

    this.columnFilters$ = new BehaviorSubject<string[]>(columnValues);
  }

  loadAllProductsData(isSearch: boolean = false) {
    for (const key of Object.keys(EnumProductTableRegisteredsSubView)) {
      const status = Number(key);
      console.log({
        status,
        isNotFornecedor: this.isNotFornecedor,
        condicao2: this.isNotFornecedor && status === 0,
        condicaoTotal: this.isNotFornecedor || this.isNotFornecedor && status === 0
      })
      if (!isNaN(status)) {
        if (this.isNotFornecedor || !this.isNotFornecedor && status === 0) {
          this.loadProductsData(status, isSearch);
        }
      }
    }
  }

  loadProductsData(status: EnumProductTableRegisteredsSubView, isSearch: boolean = false) {
    let api: any;
    const tab: ProductTableRegistereds = this.tabs.find(
      (elem) => elem.status == status
    );

    // Select api
    switch (status) {

      case EnumProductTableRegisteredsSubView.registeredsCommercial:
        api = this.elasticDashboardServ.getInconsistencyCommercial;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumProductTableRegisteredsSubView.registeredsSupplier:
        api = this.elasticDashboardServ.getInconsistencySupplier;
        api = api.bind(this.elasticDashboardServ);
        break;

      default:
        break;
    }

    if (api) {
      const paginator = this.page$.value;

      api({
        query: this.dashboardService.search$.value,
        skip: isSearch ? 0 : (paginator.page - 1) * paginator.pageLength,
        take: paginator.pageLength,
        filteredColumns: this.columnFilters$.value,
        sortedColumns: tab ? tab.sort : undefined,
      } as QueryFilter).subscribe((res: any) => {
        this.createTabData(res, status);
      });
    }
  }

  private createTabData(
    data: IResponse<DashboardFornecedor[] | WorkflowProduto[]>,
    status: EnumProductTableRegisteredsSubView,
    subViewType?: EnumProductTableRegisteredsSubView
  ) {
    const tab = this.tabs.find((elem) => elem.status == status);

    if (tab) {
      tab.count = data.count || 0;
      tab.products = data.data || [];
      this.inRevisionTabFilterPermission =
        "rhcdm.dadosprodutos.listarIntegracoes";
    }
  }

  reload(paginator: IPaginator, status: EnumProductTableRegisteredsSubView) {
    this.page$.next(paginator);
    this.loadProductsData(status);
  }

  updateSort(
    data: { [x: string]: number },
    paginator: IPaginator,
    status: EnumProductTableRegisteredsSubView
  ) {
    const tab = this.tabs.find((elem) => {
      return elem.status == status;
    });

    tab.sort = data;
    this.reload(paginator, status);
  }

  private createTabs() {
    if (this.isNotFornecedor)
      this.tabs = [
        {
          name: "responsable",
          title: "Reprovados (Fornecedor)",
          status: EnumProductTableRegisteredsSubView.registeredsSupplier,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarInconsistencias",
          count: 0,
          products: [],
        },
        {
          name: "responsable",
          title: "Reprovados (Comercial)",
          status: EnumProductTableRegisteredsSubView.registeredsCommercial,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarInconsistencias",
          count: 0,
          products: [],
        }
      ]
      else {
        this.tabs = [
          {
            name: "responsable",
            title: "Todos os produtos",
            status: EnumProductTableRegisteredsSubView.registeredsSupplier,
            subViews: [],
            permission: "rhcdm.dadosprodutos.listarInconsistencias",
            count: 0,
            products: [],
          }
        ];
      }
  }

}
