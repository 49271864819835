// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let environment: any = {
  production: false,
  permissionPrefix: 'rhcdm',
  microservice: {
    autenticacao: 'Autenticacao',
    dadosBasicos: 'DadosBasicos',
    dadosEspecificos: 'DadosEspecificos',
    dadosProdutos: 'DadosProdutos',
    comercial: 'Comercial',
    importador: 'Importador',
    relatorio: 'relatorio-portal',
    midia: 'Midia',
    dadosFiscais: 'DadosFiscais',
    custoPreco: 'CustoPreco',
    historico: 'Historico',
    workflow: 'Workflow',
    xml: 'Relatorio'
  }
};

if (window.location.hostname == 'portalsmart.gruporihappy.com.br') {
  // Set Prod environment
  environment = {
    ...environment,
    production: true,
    localStoragePrefix: 'rihappy',
    api: 'https://portalsmart.gruporihappy.com.br/api',
    vtexProduto: 'https://rihappy.myvtex.com/admin/Site/ProdutoForm.aspx?id=',
    vtexSku: 'https://rihappy.myvtex.com/admin/Site/SkuForm.aspx?idSku='
  };
} else if (window.location.hostname == 'portalsmart-qa.gruporihappy.com.br') {
  // Set Qa environment
  environment = {
    ...environment,
    localStoragePrefix: 'rihappy_qa',
    api: 'https://portalsmart-qa.gruporihappy.com.br/api',
    vtexProduto: 'https://rihappyqa.myvtex.com/admin/Site/ProdutoForm.aspx?id=',
    vtexSku: 'https://rihappyqa.myvtex.com/admin/Site/SkuForm.aspx?idSku='
  };
} else if (
  window.location.hostname == 'a389058f81f5911eaad4b02a19fd31f7-515712bec7e2cc8f.elb.us-east-1.amazonaws.com' ||
  window.location.hostname == 'localhost'
) {
  // Set Dev environment
  environment = {
    ...environment,
    localStoragePrefix: 'rihappy_dev',
    api: 'https://portalsmart-qa.gruporihappy.com.br/api',
    vtexProduto: 'https://rihappyqa.myvtex.com/admin/Site/ProdutoForm.aspx?id=',
    vtexSku: 'https://rihappyqa.myvtex.com/admin/Site/SkuForm.aspx?idSku='
  };
}

export { environment as environment };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
