import { Component, OnInit } from '@angular/core';
import { DynamicFormConfigService } from './dynamic-form-config.service';

@Component({
  selector: 'c-dynamic-form-config',
  templateUrl: './dynamic-form-config.component.html',
  styleUrls: ['./dynamic-form-config.component.scss'],
  providers: [
    DynamicFormConfigService
  ]
})
export class DynamicFormConfigComponent implements OnInit {

  constructor(
    public dynamicFormConfigService: DynamicFormConfigService
  ) { }

  ngOnInit() {
  }

}
