import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';
import { IntegracaoEmMassaList } from 'src/app/models/api/workflow/integracao-em-massa.interface';
import { ApiIntegracaoService } from 'src/app/services/api/workflow/api-integracao.service';
import { IntegracaoQueryFilter } from 'src/app/models/api/workflow/integracao-query-filter.interface';
import { EnumSistemaIntegracaoStatus } from 'src/app/models/api/workflow/enum-sistema-integracao-status.enum';

@Component({
  selector: 'c-product-list-table-in-integration',
  templateUrl: './product-list-table-in-integration.component.html',
  styleUrls: ['./product-list-table-in-integration.component.scss']
})
export class ProductListTableInIntegrationComponent implements OnInit {

  @Input() dataSource: WorkflowProduto[];
  @Input() columns: string[];

  @Output() filterChange = new EventEmitter<IntegracaoQueryFilter>();

  selectedProducts = new Set<string>();
  selectedIntegrations: IntegrationEnum[] = new Array();
  integrationEnum = IntegrationEnum;

  constructor(
    private apiIntegracaoService: ApiIntegracaoService
  ) { }

  ngOnInit() {
  }

  filter() {
    if (this.selectedIntegrations) {
      this.filterChange.emit({
        integrarSAP: this.selectedIntegrations.includes(IntegrationEnum.sap),
        integrarVTEX: this.selectedIntegrations.includes(IntegrationEnum.vtex),
        integrarCEVA: this.selectedIntegrations.includes(IntegrationEnum.ceva)
      });

      this.selectedProducts.clear();
    }
  }

  toggleProductCheckbox(id: string) {
    if (!this.selectedProducts.has(id)) {
      this.selectedProducts.add(id);
    } else {
      this.selectedProducts.delete(id);
    }
  }

  getIntegrationStatusName(value: EnumSistemaIntegracaoStatus) {
    switch (value) {
      case EnumSistemaIntegracaoStatus.NaoIntegrado:
        return 'not integrated';

      case EnumSistemaIntegracaoStatus.Integrado:
        return 'integrated';

      case EnumSistemaIntegracaoStatus.ErroAoIntegrar:
        return 'error in integration';

      case EnumSistemaIntegracaoStatus.PrecisaSerAtualizado:
        return 'need update';
    }
  }

  async integrate() {
    const data: IntegracaoEmMassaList[] = new Array();

    this.selectedProducts.forEach((id) => {
      data.push({
        produtoId: id,
        integrarSap: this.selectedIntegrations.includes(IntegrationEnum.sap),
        integrarVTEX: this.selectedIntegrations.includes(IntegrationEnum.vtex),
        integrarCEVA: this.selectedIntegrations.includes(IntegrationEnum.ceva)
      });
    });

    await this.apiIntegracaoService.integrateManyProducts(data).toPromise();

    // Clear the selected products and integrations
    this.selectedIntegrations = new Array();
    this.selectedProducts.clear();
  }

  get canIntegrate() {
    return this.selectedProducts.size > 0 && this.selectedIntegrations && this.selectedIntegrations.length > 0;
  }

}

enum IntegrationEnum {
  sap = 0,
  vtex = 1,
  ceva = 3
}
