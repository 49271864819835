import { Injectable } from "@angular/core";
import { ApiHttpClientService } from "../../api-http-client.service";
import { AuthService } from "../../auth.service";
import { environment } from "src/environments/environment";
import { Estado } from "src/app/models/api/autenticacao/estado";
import { Cidade } from "src/app/models/api/autenticacao/cidade";
import { HttpParams } from "@angular/common/http";
import { shareReplay, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { CidadeFilial } from "src/app/models/api/dados-fiscais/cidadeFilial";

@Injectable({
  providedIn: "root",
})
export class ApiEstadoService {
  private baseUrl = "Estado";
  private controller: ApiHttpClientService;

  private getAllCache$: Observable<Estado[]>;

  constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
  }

  getOnlyStates() {
    return this.controller.unwrap().get<Estado[]>("BuscarApenasEstado");
  }

  getAll() {
    return this.controller.unwrap().get<Estado[]>("BuscaPorNome");
  }

  getAllWithCache() {
    if (!this.getAllCache$) {
      this.getAllCache$ = this.getAll().pipe(shareReplay(1));
    }

    return this.getAllCache$;
  }
  getByName(name: string) {
    const params = new HttpParams().append("nome", name);
    return this.controller.unwrap().get<Estado[]>("BuscaPorNome", { params });
  }

  getCityByState(stateId: string, cityName: string) {
    const params = new HttpParams()
      .append("EstadoId", stateId)
      .append("NomeCidade", cityName);
    return this.controller
      .unwrap()
      .get<Cidade[]>("BuscaCidadePorEstado", { params });
  }

  getCityById(cityId: string) {
    const params = new HttpParams().append("cidadeId", cityId);
    return this.controller.unwrap().get<Cidade>("BuscaCidadePorId", { params });
  }

  supplierGetByName(fornecedorId: string, name?: string) {
    const params = new HttpParams()
      .append("fornecedorId", fornecedorId)
      .append("nome", name);
    return this.controller
      .unwrap()
      .get<Estado[]>("BuscaPorNome/Fornecedor", { params });
  }

  getBranchesBySupplier(fornecedorId: string) {
    const params = new HttpParams().append("fornecedorId", fornecedorId);
    return this.controller
      .unwrap()
      .get<CidadeFilial[]>("BuscaFiliaisPorCidadeDoFornecedor/Fornecedor", { params });
  }

  supplierGetCityByState(
    fornecedorId: string,
    stateId: string,
    cityName?: string
  ) {
    const params = new HttpParams()
      .append("fornecedorId", fornecedorId)
      .append("EstadoId", stateId)
      .append("NomeCidade", cityName);
    return this.controller
      .unwrap()
      .get<Cidade[]>("BuscaCidadePorEstado/Fornecedor", { params });
  }

  insert(data: Estado) {
    return this.controller.unwrap().post<Estado>("", data);
  }
}
