import { Component, forwardRef, Input, ElementRef, Injector, Optional } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';
import { ColumnFilterItemComponent } from './column-filter-item/column-filter-item.component';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'c-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColumnFilterComponent),
      multi: true
    }
  ]
})
export class ColumnFilterComponent extends ControlValueAccessorMixin<(string|number)[]> {

  @Input() label: string;

  isOpen = false;
  selectedItems: Set<ColumnFilterItemComponent> = new Set();
  writeValue$ = new ReplaySubject();

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  writeValue(value: any) {
    if (Array.isArray(value) && value.length != 0) {
      super.writeValue(value);
      this.writeValue$.next(value);
    } else {
      this.reset();
    }
  }

  toggleItem(item: ColumnFilterItemComponent) {
    // Check if options don't exists
    if (!this.selectedItems.has(item)) {
      this.selectedItems.add(item);
    } else {
      this.selectedItems.delete(item);
    }

    this.value = Array.from(this.selectedItems).map( c => c.value );
  }

  toggleOpenClosed() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (!this.isDisabled && !this.isReadOnly) {
      this.isOpen = true;
    }
  }

  close() {
    if (this.isOpen) {
      this.blur();
    }

    this.isOpen = false;
  }

  reset() {
    this.value = undefined;
    this.selectedItems.clear();
  }

}
