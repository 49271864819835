import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { LoginComponent } from './login/login.component';
import { LoginSupplierComponent } from './login-supplier/login-supplier.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginSupplierComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    SharedModule,
    CheckboxModule,
    InputModule,
    IconModule
  ]
})
export class LoginModule { }
