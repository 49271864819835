import { Injectable } from '@angular/core';
import { ApiXmlService } from 'src/app/services/api/xml/api-xml.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class XmlResolverService implements Resolve<Partial<any>> {

  constructor(private apiXml: ApiXmlService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Partial<any>> | Partial<any> {
    const id = route.paramMap.get('id');
    if (id) {
      return this.apiXml.getXml(id);
    } else {
      return ({});
    }
  }

}
