import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { CategoriaFornecedor } from 'src/app/models/api/comercial/categoria-fornecedor';

@Injectable({
  providedIn: 'root'
})
export class ApiCategoriaFornecedorService {
  private baseUrl = 'CategoriaFornecedor';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<CategoriaFornecedor[]>('');
  }

  insert(data: CategoriaFornecedor) {
    return this.controller.unwrap().post<CategoriaFornecedor>('', data);
  }

  update(data: CategoriaFornecedor) {
    return this.controller.unwrap().put<CategoriaFornecedor>('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.delete(id);
  }
}
