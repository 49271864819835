import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { SessionService } from '../session.service';

/**
 * @ignore
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  userPermissions: string[];

  constructor(
    private authServ: AuthService,
    private sessionServ: SessionService,
    private router: Router
  ) {
    this.listenUser();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authServ.isAuthenticated()) {
      let hasPermission: boolean;

      if (next.data.permission) {
        hasPermission = this.authServ.hasPermission(next.data.permission);
      } else if (next.data.somePermission && Array.isArray(next.data.somePermission)) {
        hasPermission = next.data.somePermission.some((p: string) => this.authServ.hasPermission(p));
      } else if (next.data.everyPemission && Array.isArray(next.data.everyPemission)) {
        hasPermission = next.data.everyPemission.every((p: string) => this.authServ.hasPermission(p));
      } else {
        hasPermission = true;
      }

      if (hasPermission) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }

    } else {
      this.router.navigate(['/login'], { queryParams: { redirectTo: state.url } });
      return false;
    }
  }

  private listenUser() {
    this.sessionServ.user$.subscribe(res => {
      this.userPermissions = res && res.permissions ? res.permissions : [];
    });
  }

}
