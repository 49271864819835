import { Component, OnInit } from '@angular/core';
import { ProductSpecificDataService } from '../../services/product-specific-data.service';

@Component({
  selector: 'c-pdm-section',
  templateUrl: './pdm-section.component.html',
  styleUrls: ['./pdm-section.component.scss']
})
export class PdmSectionComponent implements OnInit {

  constructor(
    public specificDataService: ProductSpecificDataService,
  ) { }

  ngOnInit() {
  }

}
