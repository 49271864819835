import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTabComponent } from './dynamic-tab.component';
import { IconModule } from '../icon/icon.module';
import { DynamicTabItemComponent } from './dynamic-tab-item/dynamic-tab-item.component';
import { AreYouSureModule } from 'src/app/shared/directives/are-you-sure/are-you-sure.module';



@NgModule({
  declarations: [DynamicTabComponent, DynamicTabItemComponent],
  imports: [
    CommonModule,
    IconModule,
    AreYouSureModule
  ],
  exports: [DynamicTabComponent, DynamicTabItemComponent]
})
export class DynamicTabModule { }
