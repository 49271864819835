import { Injectable } from '@angular/core';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';

@Injectable()
export class ProductWorkflowService {

  productId: string;
  data: WorkflowProduto;

  constructor(
  ) {
  }

  setValue(productId: string, value: WorkflowProduto) {
    this.productId = productId;
    this.data = value;
  }

}
