import { Component, AfterViewInit } from "@angular/core";
import { IPaginator } from "src/app/ui/paginator/paginator.component";
import { EnumTabStatus } from "src/app/models/api/produto/enum-tab-status.enum";
import { DashboardFornecedor } from "src/app/models/api/dashboard-fornecedor/dashboard-fornecedor";
import { WorkflowProduto } from "src/app/models/api/workflow/workflow-produto.interface";
import { BehaviorSubject } from "rxjs";
import { ApiWorkflowService } from "src/app/services/api/workflow/api-workflow.service";
import { IResponse } from "src/app/models/api/response.interface";
import { skip, debounceTime } from "rxjs/operators";
import { ApiIntegracaoService } from "src/app/services/api/workflow/api-integracao.service";
import { IntegracaoQueryFilter } from "src/app/models/api/workflow/integracao-query-filter.interface";
import { ApiIntegracaoEmMassaService } from "src/app/services/api/workflow/api-integracao-em-massa.service";
import { EnumElasticDashboardColumns } from "src/app/models/api/elastic/elastic-dashboard-columns.enum";
import { ApiElasticDashboardService } from "src/app/services/api/elastic/api-elastic-dashboard.service";
import { QueryFilter } from "src/app/models/api/query/query-filter.interface";
import { DashboardService } from "../services/dashboard.service";

@Component({
  selector: "c-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements AfterViewInit {
  page$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0,
  });

  inIntegrationQueryFilter$ = new BehaviorSubject<IntegracaoQueryFilter>({
    integrarSAP: false,
    integrarVTEX: false,
    integrarCEVA: false,
  });

  columnFilters$: BehaviorSubject<string[]>;
  enumElasticDashboardColumns = EnumElasticDashboardColumns;

  EnumTabStatus = EnumTabStatus;
  defaultTableColumns: string[] = [
    "reference",
    "supplierCodSap",
    "supplierName",
    "description",
    "category",
    "quality",
    "createdAt",
    "createdAtCommercial",
    "registerResponsable",
    "commercialResponsable",
    "actions",
  ];
  inIntegrationTableColumns: string[] = [
    "reference",
    "description",
    "sap",
    "vtex",
    "ceva",
    "createdAt",
    "actions",
  ];
  tabs!: ProductTable[];
  inReviewTabs!: ProductTableInReview[];
  inResponsableTabs!: ProductTableInResponsable[];
  inRegisteredsTabs!: ProductTableRegistereds[];
  inRevisionTabFilterPermission: string;
  integrationData: ProductTable;
  massIntegrationData: ProductTable;

  inRevisionProductsArrays: {
    allInRevision: ProductTable;
    newInRevision: ProductTable;
    integratedInRevision: ProductTable;
  } = {
    allInRevision: <ProductTable>{},
    newInRevision: <ProductTable>{},
    integratedInRevision: <ProductTable>{},
  };

  constructor(
    private elasticDashboardServ: ApiElasticDashboardService,
    private apiWorkflowServ: ApiWorkflowService,
    private apiIntegracaoServ: ApiIntegracaoService,
    private apiIntegracaoEmMassaService: ApiIntegracaoEmMassaService,
    protected dashboardService: DashboardService
  ) {
    this.configureSearch();
    this.configureInIntegrationQueryFilter();
    this.createTabs();

    // Create column filters
    const columnValues: string[] = Object.keys(EnumElasticDashboardColumns).map(
      (key) => EnumElasticDashboardColumns[key]
    );

    this.columnFilters$ = new BehaviorSubject<string[]>(columnValues);
  }

  ngAfterViewInit() {
    this.loadAllProductsData();
  }

  updateSort(
    data: { [x: string]: number },
    paginator: IPaginator,
    status: EnumTabStatus
  ) {
    const tab = this.tabs.find((elem) => {
      return elem.status == status;
    });

    tab.sort = data;
    this.reload(paginator, status);
  }

  loadNewInRevisionProducts(status: number): void {
    // console.log("🚀 ~ file: product-list.component.ts ~ line 91 ~ ProductListComponent ~ loadNewInRevisionProducts ~ status", status)
    // for(let i; i < this.tabs.length; i++) {
    //   // if (this.tabs[i].status === EnumTabStatus.review) {
    //     this.tabs[1].products = this.inRevisionProductsArrays.newInRevision.products;
    //     console.log("🚀 ~ file: product-list.component.ts ~ line 95 ~ ProductListComponent ~ loadNewInRevisionProducts ~ this.tabs[i]", this.tabs[i])
    //   // }
    // }
    // console.log("🚀 ~ file: product-list.component.ts ~ line 100 ~ ProductListComponent ~ loadNewInRevisionProducts ~ this.inRevisionProductsArrays.newInRevision", this.inRevisionProductsArrays.newInRevision)
    // console.log('TABS: ', this.tabs);
  }

  reload(paginator: IPaginator, status: EnumTabStatus) {
    this.page$.next(paginator);
    this.loadProductsData(status);
  }

  loadAllProductsData(isSearch: boolean = false) {
    for (const key of Object.keys(EnumTabStatus)) {
      const status = Number(key);
      if (!isNaN(status)) {
        this.loadProductsData(status, isSearch);
      }
    }
    // const paginator = this.page$.value;
    // const status = EnumTabStatus.review;
    // const tab: ProductTable = this.tabs.find(elem => elem.status == status);

    // this.elasticDashboardServ.getReviewNovos({
    //   query: this.search$.value,
    //   skip: (paginator.page - 1) * paginator.pageLength,
    //   take: paginator.pageLength,
    //   filteredColumns: this.columnFilters$.value,
    //   sortedColumns: tab ? tab.sort : undefined
    // } as QueryFilter).subscribe((res: any) => {
    //   console.log("🚀 ~ file: product-list.component.ts ~ line 159 ~ ProductListComponent ~ loadProductsData ~ res", res)
    //   console.log("🚀 ~ file: product-list.component.ts ~ line 161 ~ ProductListComponent ~ loadProductsData ~ status", status)
    //   this.createTabData(res, status);
    // });
  }

  loadProductsData(status: EnumTabStatus, isSearch: boolean = false) {
    let api: any;
    const tab: ProductTable = this.tabs.find((elem) => elem.status == status);

    // Select api
    switch (status) {
      case EnumTabStatus.draft:
        api = this.elasticDashboardServ.getDraft;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumTabStatus.review:
        api = this.elasticDashboardServ.getReviewTodos;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumTabStatus.inconsistency:
        api = this.elasticDashboardServ.getInconsistency;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumTabStatus.registered:
        api = this.elasticDashboardServ.getRegistered;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumTabStatus.responsable:
        api = this.elasticDashboardServ.getReviewTodosResponsavel;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumTabStatus.integration:
        api = this.apiWorkflowServ.get;
        api = api.bind(this.apiWorkflowServ);
        break;

      case EnumTabStatus.massIntegration:
        api = this.apiIntegracaoEmMassaService.get;
        api = api.bind(this.apiIntegracaoEmMassaService);
        break;

      case EnumTabStatus.inIntegration:
        api = this.apiIntegracaoServ.getInIntegrations;
        api = api.bind(this.apiIntegracaoServ);
        break;

      case EnumTabStatus.inactive:
        break;
    }

    // Fazer um condição para quando o status for review chamar as 3 request
    // e chamar o this.createTabData 3 vezes também
    if (api) {
      const paginator = this.page$.value;

      api({
        query: this.dashboardService.search$.value,
        skip: isSearch ? 0 : (paginator.page - 1) * paginator.pageLength,
        take: paginator.pageLength,
        filteredColumns: this.columnFilters$.value,
        sortedColumns: tab ? tab.sort : undefined,
      } as QueryFilter).subscribe((res: any) => {
        this.createTabData(res, status);
      });
    }
  }

  configureInIntegrationQueryFilter() {
    this.inIntegrationQueryFilter$.pipe(skip(1)).subscribe((value) => {
      const paginator = this.page$.value;

      this.apiIntegracaoServ
        .getInIntegrations({
          query: this.dashboardService.search$.value,
          skip: (paginator.page - 1) * paginator.pageLength,
          take: paginator.pageLength,
          ...value,
        })
        .subscribe((res: any) => {
          this.createTabData(res, EnumTabStatus.inIntegration);
        });
    });
  }

  onProductImported() {
    this.loadProductsData(EnumTabStatus.draft);
  }

  createTabData(
    data: IResponse<DashboardFornecedor[] | WorkflowProduto[]>,
    status: EnumTabStatus,
    subViewType?: EnumProductTableInReviewSubView
  ) {
    const tab = this.tabs.find((elem) => elem.status == status);

    if (tab) {
      tab.count = data.count || 0;
      tab.products = data.data || [];
      this.inRevisionTabFilterPermission =
        "rhcdm.dadosprodutos.listarIntegracoes";
      if (status === EnumTabStatus.review) {
        this.inRevisionProductsArrays.allInRevision = tab;
      }
    }

    // se o status for inreview a maneira que vai montar o objeto da tab vai ser diferente

    // if (status == EnumTabStatus.review) {
    //   this.integrationData = tab;
    // }

    if (status == EnumTabStatus.integration) {
      this.integrationData = tab;
    }

    if (status == EnumTabStatus.massIntegration) {
      this.massIntegrationData = {
        name: "mass integration",
        status: EnumTabStatus.massIntegration,
        count: data.count,
        products: data.data,
        sort: {},
        permission: "rhcdm.dadosprodutos.listarIntegracoes",
      };
    }
  }

  configureSearch() {
    this.dashboardService.search$.pipe(skip(1), debounceTime(800)).subscribe(() => {
      this.loadAllProductsData(true);
    });
  }

  private createTabs() {
    this.tabs = [
      {
        name: "draft",
        status: EnumTabStatus.draft,
        count: 0,
        products: [],
        sort: {},
        permission: "rhcdm.dadosprodutos.listarRascunho",
      },
      {
        name: "in review",
        status: EnumTabStatus.review,
        count: 0,
        products: [],
        sort: {},
        permission: "rhcdm.dadosprodutos.listarEmRevisao",
      },
      // {
      //   name: 'in review new',
      //   status: EnumTabStatus.reviewNew,
      //   count: 0,
      //   products: [],
      //   sort: {},
      //   permission: 'rhcdm.dadosprodutos.listarEmRevisao'
      // },
      {
        name: "inconsistencies",
        status: EnumTabStatus.inconsistency,
        count: 0,
        products: [],
        sort: {},
        permission: "rhcdm.dadosprodutos.listarInconsistencias",
      },
      {
        name: "registereds",
        status: EnumTabStatus.registered,
        count: 0,
        products: [],
        sort: {},
        permission: "rhcdm.dadosprodutos.listarCadastrados",
      },
      {
        name: "responsable",
        status: EnumTabStatus.responsable,
        count: 0,
        products: [],
        sort: {},
        permission: "rhcdm.comercial.visualizarAba",
      },
      {
        name: "in integration",
        status: EnumTabStatus.inIntegration,
        count: 0,
        products: [],
        permission: "rhcdm.dadosprodutos.listarIntegracoes",
      },
      {
        name: "integrations",
        status: EnumTabStatus.integration,
        count: 0,
        products: [],
        permission: "rhcdm.dadosprodutos.listarIntegracoes",
      },
      // {
      //   name: "inactive",
      //   status: EnumTabStatus.inactive,
      //   count: 0,
      //   products: [],
      //   sort: {},
      //   permission: "rhcdm.dadosprodutos.listarCadastrados",
      // },
    ];

    this.inReviewTabs = [
      {
        name: "in review",
        title: "Produtos Novos",
        status: EnumProductTableInReviewSubView.newInReview,
        subViews: [],
        permission: "rhcdm.dadosprodutos.listarEmRevisao",
        count: 0,
        products: [],
      },
      {
        name: "in review",
        title: "Produtos Integrados",
        status: EnumProductTableInReviewSubView.integratedInReview,
        subViews: [],
        permission: "rhcdm.dadosprodutos.listarEmRevisao",
        count: 0,
        products: [],
      },
      {
        name: "in review",
        title: "Todos os produtos",
        status: EnumProductTableInReviewSubView.allInReview,
        subViews: [],
        permission: "rhcdm.dadosprodutos.listarEmRevisao",
        count: 0,
        products: [],
      },
    ];

    this.inRegisteredsTabs = [
      {
        name: "responsable",
        title: "Reprovados (Fornecedor)",
        status: EnumProductTableRegisteredsSubView.registeredsSupplier,
        subViews: [],
        permission: "rhcdm.dadosprodutos.listarInconsistencias",
        count: 0,
        products: [],
      },
      {
        name: "responsable",
        title: "Reprovados (Comercial)",
        status: EnumProductTableRegisteredsSubView.registeredsCommercial,
        subViews: [],
        permission: "rhcdm.dadosprodutos.listarInconsistencias",
        count: 0,
        products: [],
      },
    ]

    this.inResponsableTabs = [
      {
        name: "responsable",
        title: "Produtos em Revisão",
        status: EnumProductTableInResponsableSubView.inRevision,
        subViews: [],
        permission: "rhcdm.comercial.visualizarAba",
        count: 0,
        products: [],
      },
      {
        name: "responsable",
        title: "Produtos Liberados",
        status: EnumProductTableInResponsableSubView.inOpen,
        subViews: [],
        permission: "rhcdm.comercial.visualizarAba",
        count: 0,
        products: [],
      },
      {
        name: "responsable",
        title: "Todos",
        status: EnumProductTableInResponsableSubView.all,
        subViews: [],
        permission: "rhcdm.comercial.visualizarAba",
        count: 0,
        products: [],
      },
    ]
  }
}

type ProductTableAll = ProductTable | ProductTableInReview;
export interface ProductTable {
  name: string;
  status: EnumTabStatus;
  count: number;
  products: DashboardFornecedor[] | WorkflowProduto[];
  permission: string;
  sort?: { [x: string]: number };
}

export interface ProductTableInReview {
  title: string;
  name: string;
  status: EnumProductTableInReviewSubView;
  subViews: ProductTableInReviewSubView[];
  permission: string;
  count: number;
  products: DashboardFornecedor[] | WorkflowProduto[];
  sort?: { [x: string]: number };
}
export interface ProductTableInResponsable {
  title: string;
  name: string;
  status: EnumProductTableInResponsableSubView;
  subViews: ProductTableInReviewSubView[];
  permission: string;
  count: number;
  products: DashboardFornecedor[] | WorkflowProduto[];
  sort?: { [x: string]: number };
}
export interface ProductTableRegistereds {
  title: string;
  name: string;
  status: EnumProductTableRegisteredsSubView;
  subViews: ProductTableRegisteredsSubView[];
  permission: string;
  count: number;
  products: DashboardFornecedor[] | WorkflowProduto[];
  sort?: { [x: string]: number };
}

export enum EnumProductTableInReviewSubView {
  allInReview = 0,
  newInReview = 1,
  integratedInReview = 2,
  commercialDraft = 3,
  fiscalDataInReview = 4,
  coastAndPriceInReview = 5
}

export enum EnumProductTableInResponsableSubView {
  inRevision = 0,
  inOpen = 1,
  all = 2
}
export enum EnumProductTableRegisteredsSubView {
  registeredsSupplier = 0,
  registeredsCommercial = 1
}

export interface ProductTableInReviewSubView {
  count: number;
  products: DashboardFornecedor[] | WorkflowProduto[];
  sort?: { [x: string]: number };
}

export interface ProductTableRegisteredsSubView {
  count: number;
  products: DashboardFornecedor[] | WorkflowProduto[];
  sort?: { [x: string]: number };
}
