import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { CdkTableModule } from "@angular/cdk/table";
import {MatTooltipModule} from '@angular/material/tooltip';

// import { TooltipModule } from "ng2-tooltip-directive";

import { MediasComponent } from './medias.component';
import { InputModule } from "../../../ui/input/input.module";
import { CardModule } from "../../../ui/card/card.module";
import { PipesModule } from "../../../shared/pipes/pipes.module";
import { IconModule } from "../../../ui/icon/icon.module";
import { EmptyPatternModule } from "../../../ui/empty-pattern/empty-pattern.module";
import { DirectivesModule } from "../../../shared/directives/directives.module";
import { OpenImageModule } from "../../../ui/open-image/open-image.module";

@NgModule({
    declarations: [MediasComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputModule,
        CardModule,
        PipesModule,
        IconModule,
        EmptyPatternModule,
        OpenImageModule,
        CdkTableModule,
        DirectivesModule,
        MatTooltipModule
    ],
    exports: [MediasComponent],
    providers: [DatePipe]
})
export class MediasModule{}
