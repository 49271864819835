import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/api/autenticacao/usuario';
import { ApiUsuarioService } from 'src/app/services/api/autenticacao/api-usuario.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'c-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  user: Usuario;
  isFornecedor: boolean;

  constructor(
    public sessionService: SessionService,
    private apiUsuarioService: ApiUsuarioService,
  ) { }

  async ngOnInit() {
    this.user = this.sessionService.user;
    this.user.id = this.sessionService.user.id;
    this.user.gruposPermissaoIdList = this.sessionService.user.permissions;
    this.user.login = this.sessionService.user.email;
    this.user.nomeFantasiaFornecedor = this.sessionService.user.fantasyName;
    this.user.sapFornecedor = this.sessionService.user.sapCode;
    this.isFornecedor = this.sessionService.user.role !== "funcionario" ? true : false;

    // this.user = await this.apiUsuarioService.getByLogin(this.sessionService.user.email).toPromise() as Usuario;
    // this.isFornecedor = this.user.fornecedorId !== null && this.user.funcionarioId == null ? true : false;
  }

}
