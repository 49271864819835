import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  forwardRef,
  Injector,
  Optional,
  ContentChildren,
  AfterContentInit,
  QueryList,
  ChangeDetectionStrategy
} from '@angular/core';
import { SelectOptionDirective } from '../select-option.directive';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';
import { startWith, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { groupBy } from 'src/app/shared/utils/group-by';

@Component({
  selector: 'c-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends ControlValueAccessorMixin implements AfterContentInit {

  @Input() label: string;
  @Input() placeholder = 'selecione';
  @Input() isMultiple = false;
  @Input() isGrouped = false;
  @Input() virtualScroll = true;
  @Input() red: boolean;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ContentChildren(SelectOptionDirective) selectOptionsComponents: QueryList<SelectOptionDirective>;

  private selectedOption: SelectOptionDirective;
  private selectedOptions: Set<SelectOptionDirective>;
  selectedGroups: Set<string>;
  isOptionsVisible = false;
  search = new FormControl(undefined);

  options$ = new BehaviorSubject<SelectOptionDirective[]>([]);
  get options() {
    return this.options$.value;
  }

  /** descrição dos valores selecionados */
  description: string;

  computedOptions$ = combineLatest([
    this.options$.pipe(
      map(items => {
        const isGrouped = this.isGrouped;
        if (isGrouped) {
          /* separar os grupos => {
              id: [array de item],
              ...
          }*/
          const groupedItems = groupBy(items, (i) => i.group || '');
          /* tranforma-lo em um array => [
              [id: [array de item]],
              ...
          ]*/
          const groupedItemsAsArray = Object.entries(groupedItems);
          /* ordenar os grupos */
          groupedItemsAsArray.sort((a, b) => a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0);
          /* ordenar os itens de cada grupo */
          groupedItemsAsArray.forEach(item => item[1].sort(
            (a, b) => a.description > b.description ? 1 : a.description < b.description ? -1 : 0)
          );

          return {
            isGrouped,
            grouped: groupedItemsAsArray,
           };
        } else {
          return {
            isGrouped,
            ungrouped: items
          };
        }

      })
    ),
    this.search.valueChanges.pipe(
      startWith(this.search.value),
      distinctUntilChanged(),
      debounceTime(150),
    )
  ]).pipe(
    map(([optionsConfig, search]) => {
      // - Filtrar os itens baseado na query
      if (optionsConfig.isGrouped) {
        return optionsConfig.grouped.reduce<({
          type: string;
          item: SelectOptionDirective | { group: string };
        })[]>((acumulado, go) => {
          const groupName = go[0];
          const options = go[1];

          // filtra opções do grupo corrente
          const filteredOptions = search ? options.filter(elem => {
            const filterRegex = new RegExp(search.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
            return filterRegex.test(elem.description.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) ||
              filterRegex.test(elem.group && elem.group.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          }) : options;

          const mappedOptions: ({
            type: string;
            item: SelectOptionDirective | { group: string };
          })[] = groupName == '' ?
              filteredOptions.map(o => ({ type: 'normal', item: o })) :
              filteredOptions.map(o => ({ type: 'groupChild', item: o }));

          if (groupName != '' && mappedOptions.length > 0) {
            mappedOptions.unshift({
              type: 'groupIndex',
              item: {
                group: mappedOptions[0].item.group,
              }
            });
          }

          return [...acumulado, ...mappedOptions];
        }, []);
      } else {
        return search ? optionsConfig.ungrouped.filter(elem => {
          const filterRegex = new RegExp(search.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          return filterRegex.test(elem.description.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        }) : optionsConfig.ungrouped;
      }
    }),
  );

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  ngAfterContentInit() {
    this.selectOptionsComponents.changes.pipe(
      startWith(this.selectOptionsComponents)
    ).subscribe(selectedOptionsComponents => {
      this.options$.next(selectedOptionsComponents.toArray());
      this.trySelectOptionsWithValue();
    });
  }

  writeValue(value: any) {
    this.reset();
    super.writeValue(value);
    this.trySelectOptionsWithValue();
  }

  trySelectOptionsWithValue() {
    if (this.value != undefined) {
      if (this.isMultiple) {
        this.value.forEach((valueElem => {
          this.options.forEach(optionElem => {
            if (optionElem.value == valueElem) {
              this.selectOption(optionElem, false);
            }
          });
        }));
      } else {
        this.options.forEach(elem => {
          if (elem.value == this.value) {
            this.selectOption(elem, false);
          }
        });
      }
    }
  }

  hasOption(data: SelectOptionDirective) {
    if (this.isMultiple && this.selectedOptions) {
      return this.selectedOptions.has(data);
    } else {
      return this.selectedOption == data;
    }
  }

  selectOption(data: SelectOptionDirective, propaggateChanges = true) {
    if (this.isMultiple) {
      this.selectMultipleOption(data);
    } else {
      this.selectSingleOption(data);
      this.closeOptions();
    }

    // Set value accessor
    if (propaggateChanges) {
      this.value = this.createOptions();
    }
  }

  toggleGroup(group: string) {
    if (!this.selectedGroups) {
      this.selectedGroups = new Set();
    }
    if (!this.selectedOptions) {
      this.selectedOptions = new Set();
    }
    // procura todos os itens deste grupo e armazena
    const items = this.options && this.options.filter( o => o.group == group );
    // tem o grupo selecionado no set de itens de grupo?
    if (this.selectedGroups.has(group)) {
      // caso tenha =>
        // - desmarca todos os itens do grupo
        items.forEach( i => this.selectedOptions.delete(i));
        // - remove grupo do set
        this.selectedGroups.delete(group);
    } else {
      // caso não tenha =>
        // - marca todos os itens do grupo
        items.forEach( i => this.selectedOptions.add(i));
        // - adiciona grupo ao set
        this.selectedGroups.add(group);
    }
    this.updateDescription();
  }

  showOptions() {
    if (!this.isDisabled && !this.isReadOnly) {
      this.isOptionsVisible = true;
      this.searchInput.nativeElement.focus();
      this.focus();
    }
  }

  closeOptions() {
    if (this.isOptionsVisible) {
      this.blur();
    }

    this.isOptionsVisible = false;
    this.clearFilter();
  }

  clearFilter() {
    this.search.reset(undefined);
  }

  private selectMultipleOption(data: SelectOptionDirective) {
    if (!this.selectedOptions) {
      this.selectedOptions = new Set();
    }

    // Check if options don't exists
    if (!this.selectedOptions.has(data)) {
      this.selectedOptions.add(data);
    } else {
      this.selectedOptions.delete(data);
      if (this.selectedGroups && this.selectedGroups.has(data.group)) {
        this.selectedGroups.delete(data.group);
      }
    }

    this.updateDescription();
  }

  private updateDescription() {
    if (this.selectedOption) {
      this.description = this.selectedOption.label || this.selectedOption.description;
    } else if (this.selectedOptions) {
      let description = '';
      this.selectedOptions.forEach((elem) => {
        if (!description) {
          description = `${elem.label || elem.description}`;
        } else {
          description += `, ${elem.label || elem.description}`;
        }
      });

      this.description = description;
    } else {
      this.description = '';
    }
  }

  private selectSingleOption(data: SelectOptionDirective) {
    this.selectedOption = data;
    this.updateDescription();
  }

  private createOptions() {
    if (this.isMultiple) {
      const options = new Array();

      this.selectedOptions.forEach(elem => {
        options.push(elem.value);
      });

      return options;
    } else {
      return this.selectedOption.value;
    }
  }

  private reset() {
    this.innerValue = undefined;
    this.selectedOption = undefined;
    this.description = undefined;
    this.selectedGroups = undefined;

    if (this.selectedOptions) {
      this.selectedOptions.clear();
    }
  }

}


