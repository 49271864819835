import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceComponent } from './reference.component';
import { ReferenceRoutingModule } from './reference-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UiModule } from 'src/app/ui/ui.module';
import { DynamicFormModule } from 'src/app/lib/dynamic-form/dynamic-form.module';
import { DynamicFormTestComponent } from './dynamic-form-test/dynamic-form-test.component';
import { ModalModule } from 'src/app/ui/modal/modal.module';
import { CompositionInputModule } from 'src/app/ui/composition-input/composition-input.module';
import { CollapseModule } from 'src/app/ui/collapse/collapse.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ColumnFilterModule } from 'src/app/ui/column-filter/column-filter.module';

@NgModule({
  declarations: [
    ReferenceComponent,
    DynamicFormTestComponent
  ],
  imports: [
    CommonModule,
    ReferenceRoutingModule,
    SharedModule,
    UiModule,
    DynamicFormModule,
    ModalModule,
    CompositionInputModule,
    CollapseModule,
    ScrollingModule,
    ColumnFilterModule
  ]
})
export class ReferenceModule { }
