import { Component, OnInit } from '@angular/core';
import { DynamicFormConfigService } from '../dynamic-form-config.service';

@Component({
  selector: 'c-dynamic-form-config-editor',
  templateUrl: './dynamic-form-config-editor.component.html',
  styleUrls: ['./dynamic-form-config-editor.component.scss']
})
export class DynamicFormConfigEditorComponent implements OnInit {

  constructor(
    public dynamicFormConfigService: DynamicFormConfigService
  ) { }

  ngOnInit() {
  }

}
