import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeComponent } from './employee.component';
import { AuthGuard } from 'src/app/services/guards/auth.guard';

const routes: Routes = [
  {
    path: '', component: EmployeeComponent,
    canActivate: [AuthGuard],
    data: { permission: 'rhcdm.funcionario.criar' }
  },
  {
    path: ':id',
    component: EmployeeComponent,
    canActivate: [AuthGuard],
    data: { permission: 'rhcdm.funcionario.editar' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
