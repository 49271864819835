import { Component, OnInit } from '@angular/core';
import { ProductWorkflowService } from '../services/product-workflow.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'c-product-integration',
  templateUrl: './product-integration.component.html',
  styleUrls: ['./product-integration.component.scss']
})
export class ProductIntegrationComponent implements OnInit {

  constructor(
    public productWorkflowService: ProductWorkflowService,
    public productService: ProductService
  ) { }

  ngOnInit() {
  }

}
