import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { IResponse } from 'src/app/models/api/response.interface';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { PDM } from 'src/app/models/api/dados-especificos/pdm';
import { CampoRegra } from 'src/app/models/api/dados-especificos/regra-pdm';

@Injectable({
  providedIn: 'root'
})
export class ApiPDMService {

  private baseUrl = 'PDM';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  getById(query: {
    departamentoId: string;
    categoriaId?: string;
    subnivelId?: string;
  }) {
    if (!query.departamentoId) { throw new Error('Invalid Argument'); }

    return this.controller.unwrap().useLoading().get<PDM>('BuscaPorId', {
      params: {
        departamentoId: query.departamentoId,
        categoriaId: query.categoriaId || '',
        subnivelId: query.subnivelId || ''
      }
    });
  }

  getCamposRegra(query: {
    departamentoId: string;
    categoriaId?: string;
    subnivelId?: string;
  }) {
    if (!query.departamentoId) { throw new Error('Invalid Argument'); }

    return this.controller.unwrap().useLoading().get<CampoRegra[]>('', {
      params: {
        departamentoId: query.departamentoId,
        categoriaId: query.categoriaId || '',
        subnivelId: query.subnivelId || ''
      }
    });
  }

  insertOrUpdate(pdm) {
    const body = pdm;
    return this.controller.unwrap().useLoading().post<PDM>('', body);
  }
}
