import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { NcmDescricao } from 'src/app/models/api/dados-fiscais/ncm-descricao';

@Injectable({
  providedIn: 'root'
})
export class ApiNCMDescricaoService {

  private baseUrl = 'NcmDescricao';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosFiscais)
      .useBaseUrl(this.baseUrl);
  }

  search(ncm: string) {
    if (!ncm) { throw new Error('Invalid Argument'); }

    return this.controller.unwrap().useLoading().get<NcmDescricao[]>(ncm);
  }

  getAll() {
    return this.controller.unwrap().useLoading().get<NcmDescricao[]>('');
  }

  // não usado
  // insertOrUpdate(data: NcmDescricao[]) {
  //   return this.controller.unwrap().useLoading().post<NcmDescricao[]>('', data);
  // }

}
