import { Component, OnInit } from '@angular/core';
import { ProductSpecificDataService } from '../services/product-specific-data.service';
import { ProductBasicDataService } from '../services/product-basic-data.service';
import { Observable, combineLatest } from 'rxjs';
import { ProductService } from '../services/product.service';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ProductMediaService } from '../services/product-media.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'c-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss']
})
export class ProductHeaderComponent implements OnInit {
  environment = environment;
  descricao$: Observable<string>;
  ean$: Observable<string>;
  categoriaDescription$: Observable<string>;

  constructor(
    public productService: ProductService,
    public basicDataService: ProductBasicDataService,
    public productSpecificData: ProductSpecificDataService,
    public mediaService: ProductMediaService,
  ) {
    const descricaoControl = this.basicDataService.form.get('descricao');
    const eanControl = this.basicDataService.form.get('embalagemUnitaria.ean');
    this.descricao$ = descricaoControl.valueChanges.pipe( startWith(descricaoControl.value) );
    this.ean$ = eanControl.valueChanges.pipe( startWith(eanControl.value) );

    const categoriaControl = this.productSpecificData.form.get('categoriaId') as FormControl;
    this.categoriaDescription$ = combineLatest([
      categoriaControl.valueChanges.pipe(startWith(categoriaControl.value)),
      this.productSpecificData.categorias$
    ]).pipe(
      map( ([id, categorias]) => {
        const categoria = categorias.find( c => c.id == id);
        return categoria ? categoria.descricao : null;
      })
    );
  }

  ngOnInit() {
  }

}
