import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { CdkTableModule } from '@angular/cdk/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    PipesModule,
    FilterPipeModule,
    NgxMaskModule.forChild(),
    CurrencyMaskModule,
    DirectivesModule,
    CdkTableModule,
    MatTooltipModule,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    FilterPipeModule,
    NgxMaskModule,
    CurrencyMaskModule,
    DirectivesModule,
    CdkTableModule,
    MatTooltipModule,
  ],
  providers: []
})
export class SharedModule { }
