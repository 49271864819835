import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { CorGrade } from 'src/app/models/api/dados-especificos/cor-grade';
import { IResponse } from 'src/app/models/api/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCorGradeService {

  private baseUrl = 'CorGrade';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.get<IResponse<CorGrade[]>>('');
  }
}
