import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompositionInputComponent } from './composition-input.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputErrorModule } from '../input-error/input-error.module';
import { IconModule } from '../icon/icon.module';
import { SelectModule } from '../select/select.module';
import { InputModule } from '../input/input.module';



@NgModule({
  declarations: [CompositionInputComponent],
  imports: [
    CommonModule,
    SharedModule,
    InputModule,
    SelectModule,
    InputErrorModule,
    IconModule,
  ],
  exports: [
    CompositionInputComponent
  ]
})
export class CompositionInputModule { }
