import { Component, OnInit } from '@angular/core';
import { ProductSpecificDataService } from '../../services/product-specific-data.service';

@Component({
  selector: 'c-ecommerce-data-section',
  templateUrl: './ecommerce-data-section.component.html',
  styleUrls: ['./ecommerce-data-section.component.scss']
})
export class EcommerceDataSectionComponent implements OnInit {

  constructor(
    public specificDataService: ProductSpecificDataService,
  ) { }

  ngOnInit() {
  }

}
