import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Observable, combineLatest, of, defer, Subscription } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { ApiSubnivelService } from 'src/app/services/api/dados-especificos/api-subnivel.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ApiFormDinamicoService } from 'src/app/services/api/dados-especificos/api-form-dinamico.service';
import { FormDinamicoCampo, FormDinamico } from 'src/app/models/api/dados-especificos/form-dinamico';
import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { DashboardService } from '../services/dashboard.service';

interface HeaderConfig {
  departamentoId: string;
  categoriaId: string;
  subnivelId: string;
}

interface DynamicFormConfigForm {
  departamentoId?: string;
  categoriaId?: string;
  subnivelId?: string;
  camposList: string[];
}

@Injectable()
export class DynamicFormConfigService implements OnDestroy {
  //#region headerForm
  headerForm: FormGroup;
  departamentoControl: FormControl;
  categoriaControl: FormControl;
  subnivelControl: FormControl;

  departamentos$: Observable<Departamento[]>;
  categorias$: Observable<Categoria[]>;
  subniveis$: Observable<Subnivel[]>;

  departamentoId$: Observable<string>;
  categoriaId$: Observable<string>;
  subnivelId$: Observable<string>;

  filteredCategorias$: Observable<Categoria[]>;
  filteredSubniveis$: Observable<Subnivel[]>;
  // #endregion

  // #beginregion dynamicFormConfigForm
  dynamicFormConfigForm: FormGroup;
  campoDinamicosFormArray: FormArray;
  showDFCForm = false;
  currentDFCTitle$: Observable<string>;
  dynamicFields: { id: string; descricao: string }[];
  dynamicFieldsRawData: { id: string; descricao: string }[];
  canUpdateDynamicFieldOptions = true;
  departmentChange$: Subscription;
  categoryChange$: Subscription;
  sublevelChange$: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiDepartamento: ApiDepartamentoService,
    private apiCategoria: ApiCategoriaService,
    private apiSubnivel: ApiSubnivelService,
    private toastService: ToastService,
    private apiFormDinamicoService: ApiFormDinamicoService,
    private apiCampoDinamicoService: ApiCampoDinamicoService,
    public confirmDialogService: ConfirmDialogService,
    private dashboardService: DashboardService
  ) {

    // #region headerFormConfig

    // cria formulário do header
    this.headerForm = this.createHeaderForm();
    // atribui controls do formulario do header a variaveis, afim de facilitar o acesso dos mesmo
    this.departamentoControl = this.headerForm.get('departamentoId') as FormControl;
    this.categoriaControl = this.headerForm.get('categoriaId') as FormControl;
    this.subnivelControl = this.headerForm.get('subnivelId') as FormControl;

    // criar observable que busca os dados do header da api (usando replay subject como uma forma de cache)
    this.departamentos$ = this.apiDepartamento.getAll().pipe(map(response => response.data), shareReplay(1));
    this.categorias$ = this.apiCategoria.getAll().pipe(map(response => response.data), shareReplay(1));
    this.subniveis$ = this.apiSubnivel.getAll().pipe(map(response => response.data), shareReplay(1));

    this.createListenersForHeaderData(); // Create listeners for header data

    // criar observable representando o id selecionado de cada control
    this.departamentoId$ = this.departamentoControl.valueChanges.pipe(startWith(this.departamentoControl.value));
    this.categoriaId$ = this.categoriaControl.valueChanges.pipe(startWith(this.categoriaControl.value));
    this.subnivelId$ = this.subnivelControl.valueChanges.pipe(startWith(this.subnivelControl.value));

    // configura observable de categorias filtradas pelo departamento escolhido
    this.filteredCategorias$ = combineLatest([
      this.categorias$,
      this.departamentoId$
    ]).pipe(map(([categorias, departamentoId]) => categorias.filter(c => c.departamentoId === departamentoId)));

    // configura observable de subniveis filtradas pela categoria escolhido
    this.filteredSubniveis$ = combineLatest([
      this.subniveis$,
      this.filteredCategorias$,
      this.categoriaId$,
    ]).pipe(
      map(
        ([subniveis, categorias, categoriaId]) => subniveis.filter(
          s => categoriaId ? s.categoriaId === categoriaId : false
        )
      )
    );

    // cuida para que seja restado campo de categoria e subnivel quando nivel anterior muda

    // reseta categoria quando departamento muda
    this.departamentoControl.valueChanges.subscribe(departamentoId => {
      this.categoriaControl.reset(null, { emitEvent: true });
    });

    // reseta subnivel quando categoria muda
    this.categoriaControl.valueChanges.subscribe(categoriaId => {
      this.subnivelControl.reset(null, { emitEvent: true });
    });

    // #endregion

    // Carrega os campos dinâmicos cria as options
    this.apiCampoDinamicoService.get().subscribe((res) => {
      this.createDynamicFields(res);
    });

    // #region dynamic form config form
    this.dynamicFormConfigForm = this.createDynamicFormConfigForm();
    this.campoDinamicosFormArray = this.dynamicFormConfigForm.get('camposList') as FormArray;

    // Escuta mudanças no campo dinâmico array
    this.campoDinamicosFormArray.valueChanges.subscribe(() => this.updateDynamicFieldOptions());

    this.currentDFCTitle$ = combineLatest([
      combineLatest([
        this.departamentos$,
        this.categorias$,
        this.subniveis$
      ]),
      defer(() => this.dynamicFormConfigForm.valueChanges.pipe(
        startWith(this.dynamicFormConfigForm.value)
      ) as Observable<DynamicFormConfigForm>)
    ]).pipe(
      tap(console.log),
      map(([data, pdm]) => {
        const departamento = data[0].find(d => d.id === pdm.departamentoId);
        const categoria = data[1].find(d => d.id === pdm.categoriaId);
        const subnivel = data[2].find(d => d.id === pdm.subnivelId);
        return (departamento ? departamento.descricao : '') +
          (categoria ? ' - ' + categoria.descricao : '') +
          (subnivel ? ' - ' + subnivel.descricao : '') || 'nothing';
      }),
    );
    // #endregion

  }

  createListenersForHeaderData() {
    this.departmentChange$ = this.dashboardService.departmentChange$.subscribe(() => {
      this.departamentos$ = this.apiDepartamento.getAll().pipe(map(response => response.data), shareReplay(1));
    });

    this.categoryChange$ = this.dashboardService.categoryChange$.subscribe(() => {
      this.categorias$ = this.apiCategoria.getAll().pipe(map(response => response.data), shareReplay(1));

      this.filteredCategorias$ = combineLatest([
        this.categorias$,
        this.departamentoId$
      ]).pipe(map(([categorias, departamentoId]) => categorias.filter(c => c.departamentoId === departamentoId)));
    });

    this.sublevelChange$ = this.dashboardService.sublevelChange$.subscribe(() => {
      this.subniveis$ = this.apiSubnivel.getAll().pipe(map(response => response.data), shareReplay(1));
    });
  }

  ngOnDestroy() {
    if (this.departmentChange$) {
      this.departmentChange$.unsubscribe();
    }

    if (this.categoryChange$) {
      this.categoryChange$.unsubscribe();
    }

    if (this.sublevelChange$) {
      this.sublevelChange$.unsubscribe();
    }
  }

  createDynamicFields(data: CampoDinamico[]) {
    this.dynamicFieldsRawData = data.map(elem => ({ id: elem.id, descricao: elem.label }));
    this.dynamicFields = data.map(elem => ({ id: elem.id, descricao: elem.label }));
  }

  createHeaderForm() {
    return this.fb.group({
      departamentoId: [undefined, Validators.required],
      categoriaId: [undefined],
      subnivelId: [undefined],
    });
  }

  createDynamicFormConfigForm() {
    return this.fb.group({
      departamentoId: [undefined, Validators.required],
      categoriaId: [undefined],
      subnivelId: [undefined],
      camposList: this.fb.array([])
    });
  }

  createCampo(value?: string) {
    return this.fb.control(value, Validators.required);
  }

  async edit() {
    const headerFormValue = this.headerForm.value;
    combineLatest([
      this.apiFormDinamicoService.getById(headerFormValue),
      of(headerFormValue),
    ]).subscribe(([data, headerConfig]) => {
      this.patchForm(data, headerConfig);
      // mostra a sessão de edição do pdm
      this.showDFCForm = true;
    });
  }

  patchForm(data: FormDinamicoCampo[], headerConfig: HeaderConfig) {
    // configura metadados
    if (headerConfig.departamentoId) { this.dynamicFormConfigForm.get('departamentoId').setValue(headerConfig.departamentoId); }
    if (headerConfig.categoriaId) { this.dynamicFormConfigForm.get('categoriaId').setValue(headerConfig.categoriaId); }
    if (headerConfig.subnivelId) { this.dynamicFormConfigForm.get('subnivelId').setValue(headerConfig.subnivelId); }

    this.canUpdateDynamicFieldOptions = false;
    this.campoDinamicosFormArray.clear();
    this.dynamicFields = this.dynamicFieldsRawData;

    data.forEach(elem => {
      this.campoDinamicosFormArray.push(this.createCampo(elem.campoDinamicoId));
    });

    setTimeout(() => {
      this.canUpdateDynamicFieldOptions = true;
      this.updateDynamicFieldOptions();
    }, 0);
  }

  addCampoAbove(index: number) {
    this.campoDinamicosFormArray.insert(index, this.createCampo());
  }
  addCampoBelow(index: number) {
    this.campoDinamicosFormArray.insert(index + 1, this.createCampo());
  }
  addCampoAtEnd() {
    this.campoDinamicosFormArray.push(this.createCampo());
  }

  deleteCampo(index: number) {
    this.campoDinamicosFormArray.removeAt(index);
  }

  apply() {
    if (this.dynamicFormConfigForm.invalid) {
      this.dynamicFormConfigForm.markAllAsTouched();
      this.toastService.toast('Formulário esta inválido!', ToastType.error);
      return;
    }

    const dynamicFormValue = this.dynamicFormConfigForm.getRawValue() as DynamicFormConfigForm;
    const formattedData = {
      ...dynamicFormValue
    } as any as FormDinamico;

    // Map the dynamic fields
    formattedData.camposList = dynamicFormValue.camposList.map((elem, i) => ({ campoDinamicoId: elem, ordem: i }));

    // Delete unnecessary data
    if (!formattedData.categoriaId) {
      delete formattedData.categoriaId;
    }

    if (!formattedData.subnivelId) {
      delete formattedData.subnivelId;
    }

    this.apiFormDinamicoService.insertOrUpdate(formattedData).subscribe(res => {
      this.dynamicFormConfigForm.markAsUntouched();
      this.toastService.toast('Salvo com sucesso!');
      this.reset();
    });
  }

  private updateDynamicFieldOptions() {
    if (this.canUpdateDynamicFieldOptions) {
      const selectedOptions: string[] = this.campoDinamicosFormArray.value;
      this.dynamicFields = this.dynamicFieldsRawData.filter(elem => {
        return !selectedOptions.some(optionId => optionId == elem.id);
      });
    }
  }

  reset() {
    this.showDFCForm = false;
    this.headerForm.reset();
    this.dynamicFormConfigForm.reset();
  }


}
