import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnFilterComponent } from './column-filter.component';
import { InputErrorModule } from '../input-error/input-error.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { IconModule } from '../icon/icon.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ColumnFilterItemComponent } from './column-filter-item/column-filter-item.component';



@NgModule({
  declarations: [
    ColumnFilterComponent,
    ColumnFilterItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IconModule,
    CheckboxModule,
    InputErrorModule,
  ],
  exports: [
    ColumnFilterComponent,
    ColumnFilterItemComponent
  ]
})
export class ColumnFilterModule { }
