import { Component, OnInit } from '@angular/core';
import { SupplierService } from './services/supplier.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { CanComponentDeactivate } from 'src/app/services/guards/can-deactivate.guard';

@Component({
  selector: 'c-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
  providers: [
    SupplierService
  ]
})
export class SupplierComponent implements OnInit, CanComponentDeactivate {

  constructor(
    public supplierService: SupplierService,
    public confirmDialogService: ConfirmDialogService,
  ) { }

  canDeactivate() {
    return this.supplierService.readonly ||
      this.supplierService.form.pristine ||
      this.confirmDialogService.confirm('Tem certeza que deseja sair sem salvar?');
  }

  ngOnInit() {
  }

}
