import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Fornecedor } from 'src/app/models/api/autenticacao/fornecedor';
import { ApiFornecedorService } from 'src/app/services/api/autenticacao/api-fornecedor.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { validateCnpj } from 'src/app/shared/validators/cnpj';
import { validateCep } from 'src/app/shared/validators/cep';
import { convertFornecedorToSupplier, convertSupplierToFornecedor } from './convertions';
import { Supplier } from '../models/supplier';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { Marca } from 'src/app/models/api/dados-basicos/marca';
import { Observable } from 'rxjs';
import { ApiMarcaLicensaService } from 'src/app/services/api/dados-basicos/api-marca-licensa.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class SupplierService {
  form: FormGroup;
  get filiaisForm() {
    return this.form && this.form.get('filialList') as FormArray;
  }

  @IsAllowed('rhcdm.fornecedor.editar') canEdit: boolean;

  marca$: Observable<Marca[]>;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private apiFornecedor: ApiFornecedorService,
    private toastService: ToastService,
    private router: Router,
    private apiMarcaLicensaService: ApiMarcaLicensaService

  ) {
    this.init();

    this.marca$ = this.apiMarcaLicensaService.getAll().pipe(
      map(response => response.data),
      shareReplay(1)
    );
  }

  // #region inicialização

  private init() {
    this.form = this.createForm();

    this.activatedRoute.data.subscribe(({ supplier }: { supplier: Partial<Fornecedor> }) => {
      this.setSupplierData(supplier);
    });
  }

  private setSupplierData(fornecedor: Partial<Fornecedor>) {
    const supplier = convertFornecedorToSupplier(fornecedor);
    this.form.reset();
    this.form.patchValue(supplier);
    if (supplier && supplier.filialList && this.filiaisForm) {
      supplier.filialList.forEach( (f, i) => {
        const item = this.createFilialForm();
        item.patchValue(f);
        this.filiaisForm.insert(i, item);
      });
    }
  }

  private getFormData(): Partial<Supplier> {
    return this.form.getRawValue();
  }

  private getFormDataAsFornecedor() {
    return convertSupplierToFornecedor(this.getFormData()) as Fornecedor;
  }

  get readonly() {
    return this.form.get('id').value ? !this.canEdit : false;
  }

  // #endregion inicialização

  // #region ações da tela

  get canFinalizarCadastro() {
    return !this.form.get('id').value;
  }

  finalizarCadastro() {
    if (this.form.valid) {
      this.apiFornecedor.insert(this.getFormDataAsFornecedor()).subscribe( res => {
        this.form.reset();
        this.router.navigateByUrl('/');
      });
    } else {
      this.checkFormErrors();
    }
  }

  get canSalvarCadastro() {
    return !!this.form.get('id').value && this.canEdit;
  }

  salvarCadastro() {
    if (this.form.valid) {
      this.apiFornecedor.update(this.getFormDataAsFornecedor()).subscribe( res => {
        this.form.reset();
        this.router.navigateByUrl('/');
      });
    } else {
      this.checkFormErrors();
    }
  }

  addFilial() {
    this.filiaisForm.push(this.createFilialForm());
  }

  deleteFilial(index: number) {
    this.filiaisForm.removeAt(index);
  }

  // #endregion ações da tela

  private checkFormErrors() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.toast('Formulário esta inválido', ToastType.error);
    }
  }

  // #region métodos auxiliares na criação do form

  private createForm() {
    return this.fb.group({
      cnpj: [undefined, [Validators.required, validateCnpj]],
      nomeFantasia: [undefined, [Validators.required]],
      nomeFantasiaFornecedor: [undefined, [Validators.required]],
      razaoSocial: [undefined, [Validators.required]],
      codSap: [undefined, [Validators.required]],
      cep: [undefined, [Validators.required, validateCep]],
      logradouro: [undefined, [Validators.required]],
      bairro: [undefined, [Validators.required]],
      numero: [undefined, [Validators.required]],
      complemento: [undefined],
      cidadeId: [undefined, [Validators.required]],
      modeloDeVenda: [undefined, [Validators.required]],
      operacao: [undefined, [Validators.required]],
      filialList: this.createFiliaisForm(),
      vtex: this.createVtexForm(),
      simplesNacional: [false],
      marcasId: [undefined, [Validators.required]],
      id: [undefined],

      email: [undefined, [Validators.required, Validators.email]],

      contatoComercial: this.createContactForm(),
      contatoFinanceiro: this.createContactForm(),
      contatoFiscal: this.createContactForm(),

      // dataCadastro: [undefined],
      // dataAtualizacao: [undefined],
      // dataInativacao: [undefined],
    });
  }

  private createContactForm() {
    return this.fb.group({
      nome: [undefined, [Validators.required]],
      email: [undefined, [Validators.required, Validators.email]],
    });
  }

  // private createModeloDeVendaForm() {
  //   return this.fb.group({
  //     centralizado: [undefined],
  //     descentralizado: [undefined],
  //   });
  // }
  // private createOperacaoForm() {
  //   return this.fb.group({
  //     b2B: [undefined],
  //     b2C: [undefined],
  //   });
  // }

  private createFiliaisForm() {
    return this.fb.array([]);
  }

  private createFilialForm() {
    return this.fb.group({
      cnpj: [undefined, [Validators.required, validateCnpj]],
      cidadeId: [undefined, [Validators.required]],
    });
  }

  private createVtexForm() {
    return this.fb.group({
      institucional: [undefined, Validators.required],
      sac: [undefined, Validators.required],
      atendimento: [undefined, Validators.required],
    });
  }
}
