import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductIntegrationComponent } from './product-integration.component';
import { ProductIntegrationRoutingModule } from './product-integration-routing.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { TabModule } from 'src/app/ui/tab/tab.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmptyPatternModule } from 'src/app/ui/empty-pattern/empty-pattern.module';
import { GeneralIntegrationInfoModule } from 'src/app/ui/general-integration-info/general-integration-info.module';
import { IconModule } from 'src/app/ui/icon/icon.module';

@NgModule({
  declarations: [
    ProductIntegrationComponent
  ],
  imports: [
    CommonModule,
    ProductIntegrationRoutingModule,
    SharedModule,
    CardModule,
    TabModule,
    EmptyPatternModule,
    GeneralIntegrationInfoModule,
    IconModule
  ]
})
export class ProductIntegrationModule { }
