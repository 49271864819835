import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { debounceTime, switchMap, map, tap } from 'rxjs/operators';
import { FornecedorBusca } from 'src/app/models/api/autenticacao/fornecedor';
import { ApiXmlService } from 'src/app/services/api/xml/api-xml.service';

@Component({
  selector: 'c-xml-list',
  templateUrl: './xml-list.component.html',
  styleUrls: ['./xml-list.component.scss']
})
export class XmlListComponent implements OnInit {

  totalItems:number = 0;

  page$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });

  data$: Observable<any[]>;

  constructor(
    private apiXml: ApiXmlService
  ) { }

  ngOnInit() {

    this.data$ = this.page$.pipe(
      switchMap(page=>this.apiXml.get(page.page,page.pageLength)),
      tap(response => this.totalItems = (response as any).count),
      map(response => (response as any).data)
    )
  }

  realod() {
    const currentPage = this.page$.value;
    this.page$.next({
      isLastPage: false,
      page: 0,
      total: 0,
      ...currentPage,
    });
  }

}
