import { ComponentRef, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ENTER, ESCAPE, SPACE } from "@angular/cdk/keycodes";
import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";

import { fromEvent, merge, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { Img } from "../../models/api/elements/img";
import { OpenImageComponent, TIME_ANIMATION } from "../../ui/open-image/open-image.component";

@Directive({
    selector: '[cOpenImage]'
})
export class OpenImageDirective implements OnInit, OnDestroy{
    @Input('cOpenImage') image: Img;

    private stillAlive$ = new Subject();
    private isOpened = false;
    private overlayRef = this.overlay.create({ hasBackdrop: true });
    private reference: ComponentRef<OpenImageComponent>;

    constructor(private elementRef: ElementRef, private overlay: Overlay){}

    ngOnInit(): void {
        this.onOpen();
        this.onClose();
    }

    ngOnDestroy(): void {
        this.stillAlive$.next();
        this.stillAlive$.complete();

        this.reference = null;
    }

    private onClose(): void{
        const events = merge(
            this.overlayRef.backdropClick(),
            fromEvent(document, 'keyup').pipe(filter((event: KeyboardEvent) => event.keyCode === ESCAPE))
        );

        events.pipe(takeUntil(this.stillAlive$)).subscribe(() => this.close());
    }

    private onOpen(): void {
        const element = this.elementRef.nativeElement;

        const events = merge(
            fromEvent(element, 'click'),
            fromEvent(element, 'keyup').pipe(filter((event: KeyboardEvent) => event.keyCode === ENTER || event.keyCode === SPACE))
        );

        events.pipe(takeUntil(this.stillAlive$)).subscribe(() => this.open());
    }

    private close(): void{
        if(!this.isOpened) return;

        this.isOpened = false;
        this.reference.instance.image = null;

        setTimeout(() => this.overlayRef.detach(), TIME_ANIMATION);
    }

    private open(): void{
        if(this.isOpened) return;

        this.isOpened = true;
        this.reference = this.overlayRef.attach(new ComponentPortal(OpenImageComponent));
        this.reference.instance.image = this.image;
    }
}
