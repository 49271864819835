import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { ProductTaxDataService } from '../services/product-tax-data.service';
import { ProductCostAndPriceService } from '../services/product-cost-and-price.service';

@Component({
  selector: 'c-product-cost-and-price',
  templateUrl: './product-cost-and-price.component.html',
  styleUrls: ['./product-cost-and-price.component.scss']
})
export class ProductCostAndPriceComponent implements OnInit {

  enumAba = EnumAba;
  selectedFilialIndex = 0;

  constructor(
    public productService: ProductService,
    public taxDataService: ProductTaxDataService,
    public costAndPriceService: ProductCostAndPriceService
  ) { }

  ngOnInit() {
    this.costAndPriceService.codigoSapFornecedor.setValue(this.productService.header.sapFornecedor);
  }

  get selectedFilial() {
    return this.taxDataService.filiaisForm.get([this.selectedFilialIndex]);
  }

}
