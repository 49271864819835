import { Component, OnInit, forwardRef, Input, Injector, DoCheck, OnDestroy, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, NG_VALIDATORS, Validator, ValidationErrors, ControlValueAccessor, FormBuilder, FormArray, NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { errorMapper } from 'src/app/shared/classes/error.map';
import { CompositionInput } from './composition-input';

type Data = CompositionInput;
type OnChangeFn = (data: Data) => any;
type OnTouchedFn = () => any;

@Component({
  selector: 'c-composition-input',
  templateUrl: './composition-input.component.html',
  styleUrls: ['./composition-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompositionInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompositionInputComponent),
      multi: true,
    }
  ]
})
export class CompositionInputComponent implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, Validator {
  destroy$ = new Subject();

  @Input() options: { description: string; optionId: string }[] = [];
  @Input() rows = 5;
  @Input() maxTotal: number;
  @Input() minTotal: number;
  @Input() symbol = '%';
  @Input() label: string;

  private onTouched: OnTouchedFn;
  form: FormArray;

  ngControl: NgControl;

  touched = false;
  isMouseOver: boolean;

  constructor(
    private injector: Injector,
    private fb: FormBuilder
  ) {
    this.form = this.fb.array([]);
    // adicionar validação de min e max
    // console.log(this);

    for (let i = 0; i < this.rows; i++) {
      this.form.insert(i, this.createItem());
    }
  }

  ngOnInit() {
    // tslint:disable-next-line: deprecation
    this.ngControl = this.injector.get(NgControl);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngDoCheck() {
    if (this.ngControl && this.touched !== this.ngControl.touched) {
      this.touched = this.ngControl.touched;
      if (this.touched) {
        this.form.markAllAsTouched();
      }
    }
  }

  writeValue(data: Data) {
    if (Array.isArray(data)) {
      // patch form value
      data.forEach((item, index) => {
        this.form.get([index]).patchValue(item, { emitEvent: false });
      });
    } else {
      this.reset();
    }
  }

  private createItem() {
    return this.fb.group({
      percentage: [0, [Validators.max(this.maxTotal), Validators.min(this.minTotal)]],
      optionId: [undefined]
    });
  }

  reset() {
    this.form.reset([]);
  }

  registerOnChange(fn: OnChangeFn) {
    // console.log('registerOnChange');
    fn(this.normalizeValue(this.form.value));
    // this.form.valueChanges.subscribe(value => console.log('new value', value));
    this.form.valueChanges.pipe(
      map(this.normalizeValue)
    ).subscribe(fn);
  }

  /** quando todos os valores estão sem optionId e sem porcentagem
   * retorna nulo ao invez do objeto, para que o mesmo seja validado quando o input esta como required
   * caso o input contem qualquer valor caira na validação interna do método validate
   */
  private normalizeValue(value: Data) {
    if (value) {
      if (value.every( v => !v.optionId && !v.percentage)) {
        return null;
      }
    }

    return value;
  }

  registerOnTouched(fn: OnTouchedFn) {
    // console.log('registerOnTouched');
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  get isInvalidAndTocuhed() {
    return this.ngControl && this.ngControl.invalid && (this.ngControl.touched || this.form.touched);
  }

  get isDisabled() {
    return this.ngControl && this.ngControl.disabled;
  }

  get isRequired() {
    const control: any = this.ngControl;
    const validators = control && control.validator('');
    return validators && validators.required;
  }

  @HostListener('mouseenter') mouseenter() {
    this.isMouseOver = true;
  }

  @HostListener('mouseleave') mouseleave() {
    this.isMouseOver = false;
  }

  validate(control: FormControl): ValidationErrors | null {
    const value: Data | null = control.value;

    // if (!value || value.every( v => !v.optionId)) {
    //   return {
    //     required: true
    //   };
    // }

    if (value) {
      const sum = value.reduce((previous, current) => previous + current.percentage, 0);

      if (sum == 0 && value.every( v => !v.optionId)) {
        return null;
      }

      if (sum > this.maxTotal) {
        return {
          max: { max: this.maxTotal }
        };
      }

      if (sum < this.minTotal) {
        return {
          min: { min: this.minTotal }
        };
      }

      const ids = value.map( v => v.optionId ).filter(id => !!id);
      if ((new Set(ids)).size !== ids.length) {
        return {
          duplicatedItens: 'elimine itens duplicados'
        };
      }

      if (value.some( v => v.percentage > 0 && !v.optionId)) {
        return {
          invalidItens: 'Itens com valor sem tipo selecionado'
        };
      }
    }


    return null;
  }

  get errors() {
    return this.ngControl && this.ngControl.invalid ? Object.entries(this.ngControl.errors).map(
      ([type, param]) => this.getErrorWithParameters(type, this.ngControl, param)) : [];
  }

  private getErrorWithParameters(error: string, control: NgControl, param?: string | string[]) {
    return (errorMapper.get(error) || errorMapper.get('custom'))(error, control, param);
  }

}
