import { FormControl } from '@angular/forms';

export function validateCep(control: FormControl) {
  if (control.value && control.value.length != 8) {
    return {
      cep: 'Cep inválido'
    };
  }
  return null;
}
