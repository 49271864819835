import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'c-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  /** icon name */
  @Input() icon: string;
  /** svg icon source */
  @Input() src = '/assets/icon-sprite.svg';

  constructor() { }

  ngOnInit() {
  }

}
