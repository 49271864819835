import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { HttpParams } from '@angular/common/http';
import { IResponse } from 'src/app/models/api/response.interface';
import { TagItem } from 'src/app/models/api/xml/tag-item';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiXmlService {

  private baseUrl = 'ProdutoXML';
  private controller: ApiHttpClientService;

  constructor(private api: ApiHttpClientService) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.xml)
      .useBaseUrl(this.baseUrl);
  }

  getTags(){
    return this.controller.useLoading().get<IResponse<TagItem[]>>('CamposTagItem');
  }
  getFiltros(){
    return this.controller.useLoading().get<IResponse<string[]>>('CamposFiltro');
  }
  getOperacoes(){
    return this.controller.useLoading().get<IResponse<string[]>>('OperacaoLogica');
  }
  getOperadores(){
    return this.controller.useLoading().get<IResponse<string[]>>('Operador');
  }

  get(pagina: number, tamanho: number){
    return this.controller.useLoading().get('LayoutXml?pagina='+pagina+'&tamanho='+tamanho);
  }

  getXml(id:string){
    return this.controller.useLoading().unwrap().get('LayoutXml/'+id);
  }

  save(data){
    return this.controller.useLoading().post('LayoutXml', data);
  }
  update(data){
    return this.controller.useLoading().put('LayoutXml', data);
  }

  delete(name){
    return this.controller.useLoading().delete('LayoutXml?nomeArquivo='+name);
  }

}
