import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdmComponent } from './pdm.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { RadioModule } from 'src/app/ui/radio/radio.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { ModalModule } from 'src/app/ui/modal/modal.module';
import { PdmHeaderComponent } from './pdm-header/pdm-header.component';
import { PdmFormComponent } from './pdm-form/pdm-form.component';
import { CollapseModule } from 'src/app/ui/collapse/collapse.module';
import { PdmRuleEditorComponent } from './pdm-rule-editor/pdm-rule-editor.component';


@NgModule({
  declarations: [
    PdmComponent,
    PdmHeaderComponent,
    PdmFormComponent,
    PdmRuleEditorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    InputModule,
    SelectModule,
    CheckboxModule,
    RadioModule,
    CardModule,
    IconModule,
    ModalModule,
    CollapseModule,
  ],
  exports: [
    PdmComponent
  ]
})
export class PdmModule { }
