import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'c-dynamic-tab',
  templateUrl: './dynamic-tab.component.html',
  styleUrls: ['./dynamic-tab.component.scss']
})
export class DynamicTabComponent implements OnInit {

  @Input() showAdd = true;
  @Output() add = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
