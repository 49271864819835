import { Component, OnInit } from '@angular/core';
import { PdmService } from './pdm.service';

@Component({
  selector: 'c-pdm',
  templateUrl: './pdm.component.html',
  styleUrls: ['./pdm.component.scss'],
  providers: [
    PdmService
  ]
})
export class PdmComponent implements OnInit {

  constructor(
    public pdmService: PdmService
  ) { }

  ngOnInit() {
  }

  // canDeactivate() {
  //   return this.pdmService.canDeactivate();
  // }

}
