import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginSupplierComponent } from './login-supplier/login-supplier.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'fornecedor', component: LoginSupplierComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
