import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Observable, combineLatest, of, defer, Subscription } from 'rxjs';
import { shareReplay, map, startWith, tap } from 'rxjs/operators';
import { ApiPDMService } from 'src/app/services/api/dados-especificos/api-pdm.service';
import { CampoRegra } from 'src/app/models/api/dados-especificos/regra-pdm';
import { PDM, PDMTipo } from 'src/app/models/api/dados-especificos/pdm';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { ApiSubnivelService } from 'src/app/services/api/dados-especificos/api-subnivel.service';
import { DashboardService } from '../services/dashboard.service';

interface HeaderConfig {
  departamentoId: string;
  categoriaId: string;
  subnivelId: string;
}

interface PDMForm {
  id?: string;
  departamentoId: string;
  categoriaId?: string;
  subnivelId?: string;

  regraDescricaoLonga: string[];
  regraDescricaoEstendida: string[];
  regraTituloDePublicacao: string[];
  regraDescricaoEcommerce: string[];
}

@Injectable()
export class PdmService implements OnDestroy {
  //#region headerForm
  headerForm: FormGroup;
  departamentoControl: FormControl;
  categoriaControl: FormControl;
  subnivelControl: FormControl;

  departamentos$: Observable<Departamento[]>;
  categorias$: Observable<Categoria[]>;
  subniveis$: Observable<Subnivel[]>;

  departamentoId$: Observable<string>;
  categoriaId$: Observable<string>;
  subnivelId$: Observable<string>;

  filteredCategorias$: Observable<Categoria[]>;
  filteredSubniveis$: Observable<Subnivel[]>;
  // #endregion

  // #beginregion pdmForm
  pdmForm: FormGroup;
  showPdmForm = false;
  currentPdmTitle$: Observable<string>;
  campos: { id: string; descricao: string }[];
  departmentChange$: Subscription;
  categoryChange$: Subscription;
  sublevelChange$: Subscription;

  // #endregion


  constructor(
    private fb: FormBuilder,
    private apiDepartamento: ApiDepartamentoService,
    private apiCategoria: ApiCategoriaService,
    private apiSubnivel: ApiSubnivelService,
    private apiPDM: ApiPDMService,
    private toastService: ToastService,
    public confirmDialogService: ConfirmDialogService,
    private dashboardService: DashboardService
  ) {
    // #region headerFormConfig

    // cria formulário do header
    this.headerForm = this.createHeaderForm();
    // atribui controls do formulario do header a variaveis, afim de facilitar o acesso dos mesmo
    this.departamentoControl = this.headerForm.get('departamentoId') as FormControl;
    this.categoriaControl = this.headerForm.get('categoriaId') as FormControl;
    this.subnivelControl = this.headerForm.get('subnivelId') as FormControl;

    // criar observable que busca os dados do header da api (usando replay subject como uma forma de cache)
    this.departamentos$ = this.apiDepartamento.getAll().pipe(map(response => response.data), shareReplay(1));
    this.categorias$ = this.apiCategoria.getAll().pipe(map(response => response.data), shareReplay(1));
    this.subniveis$ = this.apiSubnivel.getAll().pipe(map(response => response.data), shareReplay(1));

    this.createListenersForHeaderData(); // Create listeners for header data

    // criar observable representando o id selecionado de cada control
    this.departamentoId$ = this.departamentoControl.valueChanges.pipe(startWith(this.departamentoControl.value));
    this.categoriaId$ = this.categoriaControl.valueChanges.pipe(startWith(this.categoriaControl.value));
    this.subnivelId$ = this.subnivelControl.valueChanges.pipe(startWith(this.subnivelControl.value));

    // configura observable de categorias filtradas pelo departamento escolhido
    this.filteredCategorias$ = combineLatest([
      this.categorias$,
      this.departamentoId$
    ]).pipe(map(([categorias, departamentoId]) => categorias.filter(c => c.departamentoId === departamentoId)));

    // configura observable de subniveis filtradas pela categoria escolhido
    this.filteredSubniveis$ = combineLatest([
      this.subniveis$,
      this.filteredCategorias$,
      this.categoriaId$,
    ]).pipe(
      map(
        ([subniveis, categorias, categoriaId]) => subniveis.filter(
          s => categoriaId ? s.categoriaId === categoriaId : false
        )
      )
    );

    // cuida para que seja restado campo de categoria e subnivel quando nivel anterior muda

    // reseta categoria quando departamento muda
    this.departamentoControl.valueChanges.subscribe(departamentoId => {
      this.categoriaControl.reset(null, { emitEvent: true });
    });

    // reseta subnivel quando categoria muda
    this.categoriaControl.valueChanges.subscribe(categoriaId => {
      this.subnivelControl.reset(null, { emitEvent: true });
    });

    // #endregion

    // #region pdm form
    this.pdmForm = this.createPdmForm();

    this.currentPdmTitle$ = combineLatest([
      combineLatest([
        this.departamentos$,
        this.categorias$,
        this.subniveis$
      ]),
      defer(() => this.pdmForm.valueChanges.pipe(
        startWith(this.pdmForm.value)
      ) as Observable<PDMForm>)
    ]).pipe(
      tap(console.log),
      map(([data, pdm]) => {
        const departamento = data[0].find(d => d.id === pdm.departamentoId);
        const categoria = data[1].find(d => d.id === pdm.categoriaId);
        const subnivel = data[2].find(d => d.id === pdm.subnivelId);
        return (departamento ? departamento.descricao : '') +
          (categoria ? ' - ' + categoria.descricao : '') +
          (subnivel ? ' - ' + subnivel.descricao : '') || 'nothing';
      }),
    );
    // #endregion
  }

  createListenersForHeaderData() {
    this.departmentChange$ = this.dashboardService.departmentChange$.subscribe(() => {
      this.departamentos$ = this.apiDepartamento.getAll().pipe(map(response => response.data), shareReplay(1));
    });

    this.categoryChange$ = this.dashboardService.categoryChange$.subscribe(() => {
      this.categorias$ = this.apiCategoria.getAll().pipe(map(response => response.data), shareReplay(1));

      this.filteredCategorias$ = combineLatest([
        this.categorias$,
        this.departamentoId$
      ]).pipe(map(([categorias, departamentoId]) => categorias.filter(c => c.departamentoId === departamentoId)));
    });

    this.sublevelChange$ = this.dashboardService.sublevelChange$.subscribe(() => {
      this.subniveis$ = this.apiSubnivel.getAll().pipe(map(response => response.data), shareReplay(1));
    });
  }

  ngOnDestroy() {
    if (this.departmentChange$) {
      this.departmentChange$.unsubscribe();
    }

    if (this.categoryChange$) {
      this.categoryChange$.unsubscribe();
    }

    if (this.sublevelChange$) {
      this.sublevelChange$.unsubscribe();
    }
  }

  createHeaderForm() {
    return this.fb.group({
      departamentoId: [undefined, Validators.required],
      categoriaId: [undefined],
      subnivelId: [undefined],
    });
  }

  createPdmForm() {
    return this.fb.group({
      id: [undefined],
      departamentoId: [undefined, Validators.required],
      categoriaId: [undefined],
      subnivelId: [undefined],

      regraDescricaoLonga: this.fb.array([]),
      regraDescricaoEstendida: this.fb.array([]),
      regraTituloDePublicacao: this.fb.array([]),
      regraDescricaoEcommerce: this.fb.array([])
    });
  }

  createRegraItem(value?: string) {
    return this.fb.control(value, Validators.required);
  }

  async edit() {
    // if (!await this.canDeactivate()) {
    //   return;
    // }

    const headerFormValue = this.headerForm.value;
    combineLatest([
      this.apiPDM.getById(headerFormValue),
      this.apiPDM.getCamposRegra(headerFormValue),
      of(headerFormValue),
    ]).subscribe(([data, camposRegra, headerConfig]) => {
      // configura sessão de edição do pdm
      this.campos = camposRegra.map(cr => ({
        id: this.createCampoRegraId(cr),
        descricao: `${cr.pertenceADadosBasicos ? '[DADOS BÁSICOS]' : '[DADOS ESPECÍFICOS]'} ${cr.descricao}`
      }));
      this.patchForm(data, headerConfig);
      // mostra a sessão de edição do pdm
      this.showPdmForm = true;
    });
  }

  addCampoRegraAbove(index: number, rule: string) {
    const control = this.pdmForm.get(rule) as FormArray;
    control.insert(index, this.createRegraItem());
  }
  addCampoRegraBelow(index: number, rule: string) {
    const control = this.pdmForm.get(rule) as FormArray;
    control.insert(index + 1, this.createRegraItem());
  }
  addCampoRegraAtEnd(rule: string) {
    console.log('adicionar no final');
    const control = this.pdmForm.get(rule) as FormArray;
    control.push(this.createRegraItem());
    console.log(this);
  }

  deleteCampoRegra(index: number, rule: string) {
    const control = this.pdmForm.get(rule) as FormArray;
    control.removeAt(index);
  }

  patchForm(data: PDM, headerConfig: HeaderConfig) {
    const regraDescricaoLonga = this.pdmForm.get('regraDescricaoLonga') as FormArray;
    const regraDescricaoEstendida = this.pdmForm.get('regraDescricaoEstendida') as FormArray;
    const regraTituloDePublicacao = this.pdmForm.get('regraTituloDePublicacao') as FormArray;
    const regraDescricaoEcommerce = this.pdmForm.get('regraDescricaoEcommerce') as FormArray;

    // reseta o form

    regraDescricaoEstendida.clear();
    regraDescricaoLonga.clear();
    regraTituloDePublicacao.clear();
    regraDescricaoEcommerce.clear();
    this.pdmForm.reset();

    // configura metadados

    if (data && data.id) { this.pdmForm.get('id').patchValue(data.id); }
    if (headerConfig.departamentoId) { this.pdmForm.get('departamentoId').patchValue(headerConfig.departamentoId); }
    if (headerConfig.categoriaId) { this.pdmForm.get('categoriaId').patchValue(headerConfig.categoriaId); }
    if (headerConfig.subnivelId) { this.pdmForm.get('subnivelId').patchValue(headerConfig.subnivelId); }
    console.log(this.pdmForm.value);

    // ordena campos

    const camposDL = data &&
      data.regraDescricaoLonga &&
      data.regraDescricaoLonga.camposList.sort((a, b) => a.ordem - b.ordem) || [];

    const camposDE = data &&
      data.regraDescricaoEstendida &&
      data.regraDescricaoEstendida.camposList.sort((a, b) => a.ordem - b.ordem) || [];

    const camposML = data &&
      data.regraTituloDePublicacao &&
      data.regraTituloDePublicacao.camposList.sort((a, b) => a.ordem - b.ordem) || [];
      
    const camposDEC = data &&
      data.regraDescricaoEcommerce &&
      data.regraDescricaoEcommerce.camposList.sort((a, b) => a.ordem - b.ordem) || [];
      

    // cria form dos campos

    camposDL.forEach(campo => {
      const rule = this.createRegraItem(this.createCampoRegraId(campo));
      regraDescricaoLonga.push(rule);
    });

    camposDE.forEach(campo => {
      const rule = this.createRegraItem(this.createCampoRegraId(campo));
      regraDescricaoEstendida.push(rule);
    });

    camposML.forEach(campo => {
      const rule = this.createRegraItem(this.createCampoRegraId(campo));
      regraTituloDePublicacao.push(rule);
    });

    camposDEC.forEach(campo => {
      const rule = this.createRegraItem(this.createCampoRegraId(campo));
      regraDescricaoEcommerce.push(rule);
    });

    this.pdmForm.updateValueAndValidity();
  }

  private createCampoRegraId(campoRegra: CampoRegra) {
    return `{
      "campoDinamico": ${campoRegra.campoDinamico ? 'true' : 'false'},
      "campoDinamicoId": "${campoRegra.campoDinamicoId}",
      "nomeCampo": "${campoRegra.nomeCampo}",
      "pertenceADadosBasicos": ${campoRegra.pertenceADadosBasicos ? 'true' : 'false'},
      "usaId": ${campoRegra.usaId ? 'true' : 'false'}
    }`;
  }

  apply() {
    if (this.pdmForm.invalid) {
      this.pdmForm.markAllAsTouched();
      this.toastService.toast('Formulário esta inválido!', ToastType.error);
      return;
    }

    const pdmFormValue = this.pdmForm.getRawValue() as PDMForm;

    const pdm: PDM = {
      id: pdmFormValue.id,

      departamentoId: pdmFormValue.departamentoId,
      categoriaId: pdmFormValue.categoriaId,
      subnivelId: pdmFormValue.subnivelId,
      // to-do: mock criar enum e colocar valores corretos
      tipo: !pdmFormValue.subnivelId ? PDMTipo.subnivel : (!pdmFormValue.categoriaId ? PDMTipo.categoria : PDMTipo.departamento),

      regraDescricaoEstendida: {
        concatenaCom: ' - ',
        camposList: pdmFormValue.regraDescricaoEstendida.map(this.campoRegraMapper)
      },
      regraDescricaoLonga: {
        concatenaCom: ' - ',
        camposList: pdmFormValue.regraDescricaoLonga.map(this.campoRegraMapper)
      },
      regraTituloDePublicacao: {
        concatenaCom: ' - ',
        camposList: pdmFormValue.regraTituloDePublicacao.map(this.campoRegraMapper)
      },      
      regraDescricaoEcommerce: {
        concatenaCom: ' - ',
        camposList: pdmFormValue.regraDescricaoEcommerce.map(this.campoRegraMapper)
      }
    };

    console.log({ pdm });
    this.apiPDM.insertOrUpdate(pdm).subscribe(result => {
      this.pdmForm.get('id').setValue(result.id);
      this.pdmForm.markAsUntouched();
      this.toastService.toast('Salvo com sucesso!');
    }, error => {
      // não faz nada, sera exibido no alert
    });
  }

  private campoRegraMapper(value: string, ordem: number): CampoRegra {
    const parsedId: {
      campoDinamico: boolean;
      campoDinamicoId: string;
      nomeCampo: string;
      pertenceADadosBasicos: boolean;
      usaId: boolean;
    } = JSON.parse(value);

    return {
      ...parsedId,
      ordem
    };
  }

  // canDeactivate() {
  //   return this.pdmForm.untouched ? Promise.resolve(true) :
  //     this.confirmDialogService.confirm('Tem certeza que deseja descartar suas alterações?');
  // }

  reset() {
    this.showPdmForm = false;
    const regraDescricaoLonga = this.pdmForm.get('regraDescricaoLonga') as FormArray;
    const regraDescricaoEstendida = this.pdmForm.get('regraDescricaoEstendida') as FormArray;
    const regraTituloDePublicacao = this.pdmForm.get('regraTituloDePublicacao') as FormArray;
    const regraDescricaoEcommerce = this.pdmForm.get('regraDescricaoEcommerce') as FormArray;
    regraDescricaoEstendida.clear();
    regraDescricaoLonga.clear();
    regraTituloDePublicacao.clear();
    regraDescricaoEcommerce.clear();
    this.pdmForm.reset();
    this.headerForm.reset();
  }

}
