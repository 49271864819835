import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip, debounceTime } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { ApiSubnivelService } from 'src/app/services/api/dados-especificos/api-subnivel.service';
import { DashboardService } from '../services/dashboard.service';
import { CategoriaGlobal } from 'src/app/models/api/dados-especificos/categoria-global.interface';
import { ApiCategoriaGlobalService } from 'src/app/services/api/dados-especificos/api-global-category.service';
import { SubnivelCampoDinamico } from 'src/app/models/api/dados-especificos/subnivel-campo-dinamico';
import { FormDinamico, FormDinamicoCampo } from 'src/app/models/api/dados-especificos/form-dinamico';

@Component({
  selector: 'c-sublevel',
  templateUrl: './sublevel.component.html',
  styleUrls: ['./sublevel.component.scss']
})
export class SublevelComponent implements OnInit, OnDestroy {

  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });

  sublevelData: SublevelEditable[];
  sublevelPaginatedData: SublevelEditable[];
  sublevelFilteredData: SublevelEditable[];
  columns: string[] = ['name', 'category', 'categoryId', 'globalCategory', 'active', 'actions'];
  search$ = new BehaviorSubject('');
  categoriesData: Categoria[];
  form: FormGroup;
  categoryChange$: Subscription;
  globalCategoriesData: CategoriaGlobal[];
  listStock: string[] = [];

  constructor(
    private apiCategoriaService: ApiCategoriaService,
    private apiSubnivelService: ApiSubnivelService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private apiGlobalCategoryService: ApiCategoriaGlobalService
  ) {
    this.getStock()
    this.createForm();
    this.listenPaginator();
    this.listenSearch();
  }
  getStock() {
    this.listStock = ["SPECIFICATION", "LIST", "RADIO", "COMBO"];
  }

  async ngOnInit() {
    await this.loadCategoryData();
    this.loadSublevelData();
    this.loadGlobalCategories();

    // Listen category changes
    this.categoryChange$ = this.dashboardService.categoryChange$.subscribe(() => {
      this.loadCategoryData();
    });
  }

  ngOnDestroy() {
    if (this.categoryChange$) {
      this.categoryChange$.unsubscribe();
    }
  }

  createForm() {
    this.form = this.fb.group({
      descricao: [null],
      metaTag: [null],
      stockKeepingUnitSelectionMode: [null],
      categoriaId: [null],
      globalCategoryId: [null],
      cloneFrom: [null],
      categoryId: 0
    });
  }

  loadGlobalCategories() {
    this.apiGlobalCategoryService.getAll().subscribe(res => {
      this.globalCategoriesData = res;
    });
  }

  async loadCategoryData() {
    const res = await this.apiCategoriaService.getAllAtivosInativos().toPromise();
    this.categoriesData = res.data;
  }

  loadSublevelData() {
    this.apiSubnivelService.getAllAtivosInativos().subscribe(res => {
      this.sublevelData = res.data as SublevelEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: SublevelEditable) {
    data.newDescription = data.descricao;
    data.newCategoryId = data.categoriaId;
    data.newGlobalCategoryId = data.globalCategoryId;
    data.isEditActive = true;
  }

  cancelEdit(data: SublevelEditable) {
    data.newDescription = data.descricao;
    data.newCategoryId = data.categoriaId;
    data.newGlobalCategoryId = data.globalCategoryId;
    data.isEditActive = false;
  }

  async save(data: SublevelEditable) {
    if (!data) {
      const category: Categoria = this.form.value;
      if (category.categoryId == null){
        category.categoryId = 0;
      }
      const { cloneFrom } = this.form.value;
      category.descricao = category.descricao.toUpperCase();
      category.camposList = this.getCamposList(cloneFrom);


      this.apiSubnivelService.insert(category).subscribe(res => {
        this.form.reset();
        this.loadSublevelData();
      });
    } else {
      const description = data.newDescription.toUpperCase();
      const categoryId = data.newCategoryId;
      const globalCategoryId = data.newGlobalCategoryId;
      const camposList = data.camposList;

      this.apiSubnivelService.update({
        id: data.id, descricao: description, categoriaId: categoryId, globalCategoryId, camposList
      }).subscribe(res => {
        data.descricao = description;
        data.categoriaId = categoryId;
        data.isEditActive = false;
      });
    }

    this.dashboardService.sublevelChange$.next();
  }
  async changeEnableDisable(data: SublevelEditable) {
    try {
      await this.apiSubnivelService.delete(data.id).toPromise();
    } catch {
      setTimeout(() => {
        window.location.reload();
      }, 4000);

    }
    this.dashboardService.sublevelChange$.next();
  }
  getCamposList(sublevelId: string): FormDinamicoCampo[] {
    let sublevels = this.sublevelData.filter(
      c => c.id === sublevelId);

    return sublevels == null || sublevels.length == 0 ? [] : (sublevels[0].camposList as FormDinamicoCampo[]);
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.sublevelData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.sublevelPaginatedData = this.sublevelData.slice(startAt, startAt + data.pageLength);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.sublevelFilteredData = this.sublevelData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          const subnivel = regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          const category = regex.test(this.categoryIdToDescription(elem.categoriaId.normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
          const categoryId = regex.test(String(elem.categoryId));
          return subnivel || category || categoryId;
        });
      } else {
        this.sublevelFilteredData = undefined;
      }
    });
  }

  categoryIdToDescription(id: string) {
    const category = this.categoriesData.find(elem => elem.id == id);

    if (category) {
      return category.descricao;
    }
  }

  globalCategoryIdToDescription(id: string) {
    const globalCategory = this.globalCategoriesData.find(elem => elem.id == id);

    if (globalCategory) {
      return globalCategory.descricao;
    }
  }

  get canCreate() {
    return this.descricao.value && this.categoriaId.value && this.globalCategoryId.value;
  }

  // Form gets
  get descricao() { return this.form.get('descricao'); }
  get categoriaId() { return this.form.get('categoriaId'); }
  get globalCategoryId() { return this.form.get('globalCategoryId'); }

}

interface SublevelEditable extends Subnivel {
  isEditActive: boolean;
  newDescription: string;
  newCategoryId: string;
  newGlobalCategoryId: string;
}
