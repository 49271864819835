import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { EnumTabStatus } from 'src/app/models/api/produto/enum-tab-status.enum';
import { ProductTable } from '../product-list.component';

@Component({
  selector: 'c-product-list-integrations',
  templateUrl: './product-list-integrations.component.html',
  styleUrls: ['./product-list-integrations.component.scss']
})
export class ProductListIntegrationsComponent implements OnInit {

  @Input() integrationDataSource: ProductTable;
  @Input() massIntegrationDataSource: ProductTable;
  @Output() onPaginate = new EventEmitter<{ paginator: IPaginator, status: EnumTabStatus }>();
  tabSelected: EnumTabStatus = EnumTabStatus.integration;
  EnumTabStatus = EnumTabStatus;
  integrationTableColumns: string[] = ['reference', 'description', 'sap', 'vtex', 'ceva', 'createdAt', 'actions'];
  massIntegrationTableColumns: string[] = ['id', 'products', 'active', 'createdAt', 'actions'];

  constructor() { }

  ngOnInit() {
  }

  reload(paginator: IPaginator, status: EnumTabStatus) {
    this.onPaginate.emit({
      paginator,
      status
    });
  }

}


