import { Injectable } from '@angular/core';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { ProductSpecificDataService } from './product-specific-data.service';
import { EnumVariacoesDadosEspecificos } from 'src/app/models/api/dados-especificos/enum-variacoes-dados-especificos.enum';
import { MediaForm } from '../models/media-form';
import { ProductBaseEntity } from './product-base-entity.class';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { imageRequired } from '../helpers/image-required.validator';

@Injectable()
export class ProductMediaService extends ProductBaseEntity {

  @IsAllowed('rhcdm.midia.editarFinalizado') canSaveFinished: boolean;
  @IsAllowed('rhcdm.midia.editarRevisao') canSaveReview: boolean;
  @IsAllowed('rhcdm.midia.editarReprovado') canSaveRepproved: boolean;
  @IsAllowed('rhcdm.midia.aprovar') canApproveOrReprove: boolean;

  itemListName = 'mídia do produto';
  variationStatus = EnumVariacoesDadosEspecificos.Nenhum;

  constructor(
    fb: FormBuilder,
    private specificDataService: ProductSpecificDataService
  ) {
    super(fb);
    this.initForm();
    this.listenSpecificDataVariations();
    this.createFormArray();
  }

  // Métodos auxiliares do formulário
  setValue(productId: string, status: EnumStatus, value: MediaForm) {
    this.form.reset();

    if (value.midias.length > 1) {
      for (let i = this.midias.length; i < value.midias.length; i++) {
        this.createFormArray();
      }
    }

    this.productId = productId;
    this.status = status;

    this.form.patchValue(value);
  }

  private listenSpecificDataVariations() {
    // Listen delete variations to delete in midia
    this.specificDataService.removeVariation$.subscribe(res => {
      this.midias.removeAt(res);
    });

    // Listen sortimento to create items for midia
    this.specificDataService.sortimentoListForm.valueChanges.subscribe(res => {
      if (this.specificDataService.sortimentoListForm.length >= this.midias.length) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = this.midias.length; i <= this.specificDataService.sortimentoListForm.length; i++) {
          this.createFormArray();
        }
      }
    });

    // Listen grade to create items for midia
    this.specificDataService.gradeListForm.valueChanges.subscribe(res => {
      if (this.specificDataService.gradeListForm.length > this.midias.length) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = this.midias.length; i < this.specificDataService.gradeListForm.length; i++) {
          this.createFormArray();
        }
      }
    });

    this.specificDataService.variacoes.valueChanges.subscribe(res => {
      this.variationStatus = res;

      if (this.midias.length > 1) {
        const firstControl = this.midias.controls[0];
        this.form = this.createForm();
        this.midias.push(firstControl);
      }

      if (res == EnumVariacoesDadosEspecificos.Nenhum) {
        this.itemListName = 'mídia do produto';
      } else if (res == EnumVariacoesDadosEspecificos.Grade) {
        this.itemListName = 'variação';
      } else if (res == EnumVariacoesDadosEspecificos.Sortimento) {
        this.itemListName = 'sortimento';
      }
    });
  }

  getItemListName(index: number) {
    if (this.variationStatus == EnumVariacoesDadosEspecificos.Grade) {
      return `${this.itemListName} ${index + 1}`;
    } else if (this.variationStatus == EnumVariacoesDadosEspecificos.Sortimento) {
      if (index == 0) {
        return 'mídia do produto';
      } else {
        return `${this.itemListName} ${index}`;
      }
    } else {
      return `${this.itemListName}`;
    }
  }

  // Criação do Formulário
  createForm() {
    return this.fb.group({
      midias: this.fb.array([], [imageRequired])
    });
  }

  createFormArray() {
    const control = this.midias as FormArray;
    control.push(new FormControl());
  }

  // helpers de acesso aos dados do form

  get midias() {
    return this.form.get('midias') as FormArray;
  }

  get mainImage() {
    if (this.midias && this.midias.value && this.midias.value[0] && this.midias.value[0].imagens && this.midias.value[0].imagens[0]) {
      return this.midias.value[0].imagens[0].link;
    }
  }

}

