/* tslint:disable:no-string-literal */

import {
  Injectable,
  Injector,
  Type,
  ComponentFactoryResolver,
  TemplateRef,
} from '@angular/core';
import { ModalComponent } from './modal/modal.component';
import { ComponentLoaderService, Config } from 'src/app/services/component-loader.service';

// fallback typescript 3.4 (recurso nativo do typescript 3.5)
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Content<T> = string | TemplateRef<T> | Type<T>;
export type ModalConfig<T, C> = Omit<Config<T, C>, 'component' | 'content'>;
@Injectable()
export class ModalService {

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private componentLoaderService: ComponentLoaderService,
  ) { }

  open<C>(content?: Content<C>, config?: ModalConfig<ModalComponent, C>) {
    return this.openWith(ModalComponent, content, config);
  }

  openWith<T, C>(component: Type<T>, content?: Content<C>, config?: ModalConfig<T, C>) {
    return this.componentLoaderService.create({
      resolver: this.resolver,
      injector: this.injector,
      component,
      content,
      ...config,
     });
  }

}
