import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'count'
})
export class CountPipe implements PipeTransform {
    transform(value: number, more: string, one: string, nothing: string): string{
        let message = '';

        if(value === 0) message = nothing;
        else if(value === 1) message = `${value} ${one}`;
        else message = `${value} ${more}`;

        return message;
    }
}
