import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiAutenticacaoService {

  private baseUrl = 'Autenticacao';
  private controller: ApiHttpClientService;
  private controllerText: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
    this.controllerText = this.api
      .useBaseUrl(this.baseUrl);
  }

  auth(login: string, senha: string) {
    return this.controller
      .useLoading('login')
      .unwrap()
      .post<string>('loginv2', {
        login,
        senha
      }).pipe(tap((res) => {
        this.authServ.login(res, login);
      }));
  }

  getTerms(): Observable<any> {
    return this.controllerText
      .useLoading('login')
      .unwrap()
      .get<string>('Termos/terms-of-use');
  }

  postTerms(body:string) {
    return this.controllerText
      .useLoading('login')
      .unwrap()
      .put<string>('Termos/terms-of-use', {text:body});
  }

  getPrivacy(): Observable<any> {
    return this.controllerText
      .useLoading('login')
      .unwrap()
      .get<string>('Termos/terms-of-privacity');
  }

  postPrivacy(body:string) {
    return this.controllerText
      .useLoading('login')
      .unwrap()
      .put<string>('Termos/terms-of-privacity', {text:body});
  }
}
