import { Component, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as waves from 'node-waves';
import { LoadingService } from '../services/loading.service';
import { AppTemplates } from './app-template.enum';

@Component({
  selector: 'core-root',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class AppComponent implements AfterViewChecked {

  template$: Observable<AppTemplates>;
  loading$: Observable<string>;

  constructor(
    private translateServ: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingServ: LoadingService,
    private cdRef: ChangeDetectorRef
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateServ.setDefaultLang('pt-br');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateServ.use('pt-br');

    this.initTemplate$();
    this.initWavesEffect();

    // Set the loading$ to listen which loading show or hide all
    this.loading$ = this.loadingServ.loading$;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private initTemplate$() {
    this.template$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute.snapshot),
      map(routeSnapshot => routeSnapshot?.root?.firstChild?.data?.template || 'main')
    );
  }

  private initWavesEffect() {
    waves.attach('.waves-effect', ['waves-button']);
    waves.attach('.waves-light', ['waves-light']);
    waves.init();
  }

  @HostListener('dragleave', ['$event'])
  dragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('dragover', ['$event'])
  dragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  drop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

}
