import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  showCookies = false

  constructor() {
    const terms = localStorage.getItem('terms-smart')
    if(!terms) this.showCookies = true
  }

  saveCookies(){
    localStorage.setItem('terms-smart', `true`)
    this.showCookies = false
  }

}
