import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TableSortDirective } from '../table-sort.directive';

@Component({
  selector: 'c-table-sort-item',
  templateUrl: './table-sort-item.component.html',
  styleUrls: ['./table-sort-item.component.scss']
})
export class TableSortItemComponent implements OnInit {

  @Input() column: string;
  direction: number;
  isMouseOver: boolean;

  constructor(
    private tableSortDirective: TableSortDirective
  ) { }

  ngOnInit() {
  }

  @HostListener('click')
  toggle() {
    if (!this.direction) {
      this.direction = 1;
      this.tableSortDirective.setPropertieAscending(this.column);
    } else if (this.direction == 1) {
      this.direction = -1;
      this.tableSortDirective.setPropertieDescending(this.column);
    } else {
      this.direction = 0;
      this.tableSortDirective.removePropertie(this.column);
    }
  }

  @HostListener('mouseenter')
  listenMouseEnter() {
    this.isMouseOver = true;
  }

  @HostListener('mouseleave')
  listenMouseOut() {
    this.isMouseOver = false;
  }

}
