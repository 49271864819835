import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Licenca } from 'src/app/models/api/comercial/licenca';
import { IResponse } from 'src/app/models/api/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiLicencaService {
  private baseUrl = 'Licenca';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Licenca[]>('');
  }
  getAllAtivosInativos() {
    return this.controller.useLoading('waves').get<IResponse<Licenca[]>>('/BuscarAtivosInativos');
  }
  insert(data: Licenca) {
    return this.controller.useLoading().post('', data);
  }

  update(data: Licenca) {
    return this.controller.useLoading().put('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().delete(id);
  }
}
