import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPatternComponent } from './empty-pattern.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [
    EmptyPatternComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    EmptyPatternComponent
  ]
})
export class EmptyPatternModule { }
