import { Component, OnInit } from '@angular/core';
import { ProductSpecificDataService } from '../services/product-specific-data.service';
import { ProductService } from '../services/product.service';
import { FormControl } from '@angular/forms';
import { ProductSpecificDataSubnivelService } from '../services/product-specific-data-subnivel.service';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';

@Component({
  selector: 'c-product-specific-data',
  templateUrl: './product-specific-data.component.html',
  styleUrls: ['./product-specific-data.component.scss']
})
export class ProductSpecificDataComponent implements OnInit {

  enumAba = EnumAba;

  subnivelControl: FormControl;

  constructor(
    public specificDataService: ProductSpecificDataService,
    public productService: ProductService,
    public subnivelService: ProductSpecificDataSubnivelService,
  ) {
    this.subnivelControl = this.specificDataService.form.get('subnivelId') as FormControl;
  }

  ngOnInit() {
  }

}
