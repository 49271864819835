import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { DadosBasicos } from 'src/app/models/api/dados-basicos/dados-basicos';
import { environment } from 'src/environments/environment';
import { CustoPreco } from 'src/app/models/api/custo-preco/custo-preco.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiCustoPrecoService {

  private baseUrl = 'CustoPreco';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.custoPreco)
      .useBaseUrl(this.baseUrl);
  }

  get(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().useLoading().get<CustoPreco>(id);
  }

  insertOrUpdate(data: CustoPreco) {
    return this.controller.unwrap().useLoading().post<CustoPreco>('', data);
  }

  validate(data: CustoPreco) {
    return this.controller.unwrap().useLoading().post<CustoPreco>('ValidarCadastro', data);
  }

  saveDraft(draft: CustoPreco) {
    return this.controller.unwrap().useLoading().post<CustoPreco>('draft', draft);
  }

}
