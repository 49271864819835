import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouteDataService } from 'src/app/services/route-data.service';
import { ApiGrupoPermissaoService } from 'src/app/services/api/autenticacao/api-grupo-permissao.service';
import { ApiPermissaoService } from 'src/app/services/api/autenticacao/api-permissao.service';
import { Permissao } from 'src/app/models/api/autenticacao/permissao';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'c-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy {

  roleId: string;
  form: FormGroup;
  isEdit: boolean;
  permissionData: Permissao[];
  permissionByResource: PermissionByResource[];

  constructor(
    private routeData: RouteDataService,
    private router: Router,
    private apiGrupoPermissaoService: ApiGrupoPermissaoService,
    private apiPermissaoService: ApiPermissaoService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.loadPermissionData();
    this.checkIsEdit();
  }

  ngOnDestroy() {
    this.routeData.data = undefined;
  }

  createForm() {
    this.form = this.fb.group({
      nome: [null, Validators.required],
      permissoesIdList: [null, Validators.required]
    });
  }

  checkIsEdit() {
    if (this.routeData.data) {
      this.isEdit = true;
      this.roleId = this.routeData.data.id;
      this.form.patchValue(this.routeData.data);
    }
  }

  loadPermissionData() {
    this.apiPermissaoService.getAll().subscribe(res => {
      this.permissionData = res;

      const groupedByResourceMap = new Map();
      res.forEach(elem => {
        const resource = elem.nome.split('.')[1];

        let group: Array<Permissao> = groupedByResourceMap.get(resource);
        if (!group) {
          group = new Array();
          groupedByResourceMap.set(resource, group);
        }

        group.push(elem);
      });

      const permissionsByResource: PermissionByResource[] = new Array();
      groupedByResourceMap.forEach((elem, key) => {
        permissionsByResource.push({
          resource: key,
          permission: elem
        });
      });

      this.permissionByResource = permissionsByResource;
    });
  }

  save() {
    if (!this.isEdit) {
      this.apiGrupoPermissaoService.create(this.createSaveData()).subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });
    } else {
      this.apiGrupoPermissaoService.update(this.createSaveData()).subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });
    }
  }

  private createSaveData() {
    return {
      id: this.roleId,
      ...this.form.value
    };
  }

  get nome() { return this.form.get('nome'); }

}

interface PermissionByResource {
  resource: string;
  permission: Permissao;
}
