import { Component, Input, ElementRef, Injector, QueryList, ContentChildren, AfterViewInit, forwardRef } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { ControlValueAccessorGroupMixin } from 'src/app/shared/classes/control-value-accessor-group-mixin.class';

@Component({
  selector: 'c-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputGroupComponent),
      multi: true,
    }
  ]
})
export class InputGroupComponent extends ControlValueAccessorGroupMixin implements AfterViewInit {

  @ContentChildren(InputComponent) inputs: QueryList<InputComponent>;

  @Input() label: string;

  constructor(
    elementRef: ElementRef,
    injector: Injector
  ) {
    super(elementRef, injector);
  }

  ngAfterViewInit() {
    this.init(this.inputs);
  }

}
