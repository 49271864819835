import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUsuarioService } from 'src/app/services/api/autenticacao/api-usuario.service';
import { validatePassword } from 'src/app/shared/validators/password';

@Component({
  selector: 'c-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  resetCode: string;

  constructor(
    private fb: FormBuilder,
    private apiUsuarioService: ApiUsuarioService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.setPasswordValidator();
    this.resetCode = this.activatedRoute.snapshot.params.id;
    // if (this.resetCode) {
    //   this.form.get('senhaAntiga').disable();
    // }
  }

  ngOnInit() {

  }

  private createForm() {
    this.form = this.fb.group({
      // senhaAntiga: [null, [Validators.required]],
      senhaNova: [null, [Validators.required]],
      senhaNovaRepetir: [null, [Validators.required]]
    });
  }

  async changePassword() {
    const data = this.form.value;

    if (!this.resetCode) {
      await this.apiUsuarioService.changePassword(data).toPromise();
    } else {
      await this.apiUsuarioService.changePasswordWithCode(data, this.resetCode).toPromise();
    }

    this.router.navigateByUrl('/');
  }

  private setPasswordValidator() {
    this.senhaNovaRepetir.setValidators(validatePassword(this.senhaNova));
  }

  get senhaNova() { return this.form.get('senhaNova') as FormControl; }
  get senhaNovaRepetir() { return this.form.get('senhaNovaRepetir'); }

}
