import { Component, OnInit } from '@angular/core';
import { ApiGrupoPermissaoService } from 'src/app/services/api/autenticacao/api-grupo-permissao.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GrupoPermissao } from 'src/app/models/api/autenticacao/grupo-permissao.interface';
import { ApiUsuarioService } from 'src/app/services/api/autenticacao/api-usuario.service';
import { validatePassword } from 'src/app/shared/validators/password';
import { ApiFuncionarioService } from 'src/app/services/api/autenticacao/api-funcionario.service';
import { Usuario } from 'src/app/models/api/autenticacao/usuario';
import { Funcionario } from 'src/app/models/api/autenticacao/funcionario';

@Component({
  selector: 'c-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  userId: string;
  employeeId: string;
  form: FormGroup;
  isEdit: boolean;
  permissionGroupData: GrupoPermissao[];
  userData: Usuario;
  employeeData: Funcionario;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiGrupoPermissaoService: ApiGrupoPermissaoService,
    private apiFuncionarioService: ApiFuncionarioService,
    private apiUsuarioService: ApiUsuarioService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.loadPermissionGroupData();
    this.checkIsEdit();
  }

  createForm() {
    this.form = this.fb.group({
      nome: [null, Validators.required],
      login: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      senha: [null, Validators.required],
      repetirSenha: [null, [Validators.required, validatePassword(() => this.senha)]],
      gruposPermissaoIdList: [null, Validators.required],
    });
  }

  checkIsEdit() {
    if (this.route.snapshot.params.id) {
      this.employeeId = this.route.snapshot.params.id;
      this.isEdit = true;
      this.senha.disable();
      this.repetirSenha.disable();
      this.loadUserData();
    }
  }

  async loadUserData() {
    this.employeeData = await this.apiFuncionarioService.getById(this.employeeId).toPromise();
    this.userData = await this.apiFuncionarioService.getUserByEmployeeId(this.employeeId).toPromise();
    this.userId = this.userData.id;
    this.form.patchValue({
      nome: this.employeeData.nome,
      email: this.employeeData.email,
      login: this.userData.login,
      gruposPermissaoIdList: this.userData.gruposPermissaoIdList
    });
  }

  loadPermissionGroupData() {
    this.apiGrupoPermissaoService.get().subscribe(res => {
      this.permissionGroupData = res;
    });
  }

  async save() {
    if (!this.isEdit) {
      const employeeData = await this.apiFuncionarioService.insert(this.createFuncionarioSaveData()).toPromise(); // Create employee
      await this.apiUsuarioService.insert(this.createUsuarioSaveData(employeeData.id)).toPromise(); // Create user with employee id

      this.router.navigateByUrl('/dashboard');
    } else {
      await this.apiFuncionarioService.update(this.createFuncionarioSaveData()).toPromise(); // Update employee
      await this.apiUsuarioService.update(this.createUsuarioSaveData(this.employeeId)).toPromise(); // Update user with employee id

      this.router.navigateByUrl('/dashboard');
    }
  }

  private createFuncionarioSaveData() {
    const formValue = this.form.value;
    const data = {
      id: this.employeeId,
      nome: formValue.nome,
      email: formValue.email
    };

    return data;
  }

  private createUsuarioSaveData(employeeId: string) {
    const formValue = this.form.value;

    const data = {
      id: this.userId,
      login: formValue.login,
      gruposPermissaoIdList: formValue.gruposPermissaoIdList,
      funcionarioId: employeeId
    } as Usuario;

    if (!this.isEdit) {
      data.senha = formValue.senha;
    }

    return data;
  }

  get nome() { return this.form.get('nome'); }
  get email() { return this.form.get('email'); }
  get senha() { return this.form && this.form.get('senha') as FormControl; }
  get repetirSenha() { return this.form.get('repetirSenha') as FormControl; }

}
