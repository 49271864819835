import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Fornecedor, FornecedorBusca } from 'src/app/models/api/autenticacao/fornecedor';
import { IResponse } from 'src/app/models/api/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiFornecedorService {

  private baseUrl = 'Fornecedor';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Fornecedor[]>('');
  }

  find(query: {
    skip: number,
    take: number,
    filtro: string
  }) {
    return this.controller.useLoading().post<IResponse<FornecedorBusca[]>>('/busca', query);
  }

  get(id: string) {
    return this.controller.useLoading().unwrap().get<Fornecedor>(id);
  }

  insert(data: Fornecedor) {
    return this.controller.useLoading().unwrap().post<Fornecedor>('', data);
  }

  update(data: Fornecedor) {
    return this.controller.useLoading().unwrap().put<Fornecedor>('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().delete(id);
  }

  redefinePassword(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().put<Fornecedor>(`${id}`, {});
  }

}
