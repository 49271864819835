import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'c-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string;
  private subscription: Subscription;

  readonly destroy: () => void;
  readonly beforeDestroy = (from: 'container' | 'content' | 'outside') => from != 'content' && this.confirm.next(false);

  constructor() { }

  ngOnInit() {
    this.subscription = this.confirm.pipe(
      first()
    ).subscribe(() => {
      this.destroy();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
