import { Component, OnInit, forwardRef, ElementRef, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, NG_VALIDATORS } from '@angular/forms';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'c-product-media-item',
  templateUrl: './product-media-item.component.html',
  styleUrls: ['./product-media-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductMediaItemComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductMediaItemComponent),
      multi: true
    }
  ]
})
export class ProductMediaItemComponent extends ControlValueAccessorMixin implements OnInit {

  @Input() label: string;
  @Input() name: string;
  @Input() description: string;

  form: FormGroup;

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    private fb: FormBuilder,
    public productServ: ProductService
  ) {
    super(elementRef, injector);
    this.createForm();
  }

  ngOnInit() {
  }

  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
      this.value = value;
    } else {
      this.form.reset();
    }
  }

  createForm() {
    this.form = this.fb.group({
      videos: [],
      imagens: [],
      imagemAltaResolucao: [],
      imagemEspecial: []
    });

    this.form.valueChanges.subscribe(value => {
      this.value = value;
    });
  }

  validate() {
    if (this.form.invalid) {
      return { errors: true };
    }

    return null;
  }

}
