import { EnumTipoContato } from 'src/app/models/api/autenticacao/contato';
import { Supplier } from '../models/supplier';
import { Fornecedor } from 'src/app/models/api/autenticacao/fornecedor';

export function convertFornecedorToSupplier(fornecedor: Partial<Fornecedor>): Partial<Supplier> {
  const { contatoList, ...supplier } = {
    ...fornecedor,

    simplesNacional: !!fornecedor.simplesNacional,
    contatoComercial: fornecedor && fornecedor.contatoList &&
      fornecedor.contatoList.find(i => i.tipo === EnumTipoContato.Comercial) || { nome: undefined, email: undefined },
    contatoFinanceiro: fornecedor && fornecedor.contatoList &&
      fornecedor.contatoList.find(i => i.tipo === EnumTipoContato.Financeiro) || { nome: undefined, email: undefined },
    contatoFiscal: fornecedor && fornecedor.contatoList &&
      fornecedor.contatoList.find(i => i.tipo === EnumTipoContato.Fiscal) || { nome: undefined, email: undefined },
    operacao: {
      b2B: !!fornecedor.operacao.b2B,
      b2C: !!fornecedor.operacao.b2C
    },
    modeloDeVenda: {
      centralizado: !!fornecedor.modeloDeVenda.centralizado,
      descentralizado: !!fornecedor.modeloDeVenda.descentralizado
    }
  };

  return supplier;
}

export function convertSupplierToFornecedor(supplier: Partial<Supplier>): Partial<Fornecedor> {
  const { contatoComercial, contatoFinanceiro, contatoFiscal, ...fornecedor } = {
    ...supplier,

    simplesNacional: !!supplier.simplesNacional,
    contatoList: [
      supplier.contatoComercial ? { ...supplier.contatoComercial, tipo: EnumTipoContato.Comercial } : null,
      supplier.contatoFinanceiro ? { ...supplier.contatoFinanceiro, tipo: EnumTipoContato.Financeiro } : null,
      supplier.contatoFiscal ? { ...supplier.contatoFiscal, tipo: EnumTipoContato.Fiscal } : null
    ].filter(i => i !== undefined || i !== null),
    operacao: {
      b2B: !!supplier.operacao.b2B,
      b2C: !!supplier.operacao.b2C
    },
    modeloDeVenda: {
      centralizado: !!supplier.modeloDeVenda.centralizado,
      descentralizado: !!supplier.modeloDeVenda.descentralizado
    }
  };
  return fornecedor;
}
