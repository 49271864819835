import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { ProductTaxDataService } from '../services/product-tax-data.service';
import { Subject, Observable } from 'rxjs';
import { TipoExcecao } from 'src/app/models/api/dados-fiscais/tipo-excecao';
import { Grupo } from 'src/app/models/api/dados-fiscais/grupo';
import { startWith, switchMap, takeUntil, distinctUntilChanged, map, tap, shareReplay } from 'rxjs/operators';

@Directive({
  selector: '[cTaxDataGroupByType]',
  exportAs: 'groupByType',
})
export class TaxDataGroupByTypeDirective implements OnDestroy, OnInit {
  @Input() typeControlName = 'tipoId';
  @Input() groupControlName = 'grupoId';

  private destroy$ = new Subject();

  typeControl: FormControl;
  groupControl: FormControl;

  types$: Observable<TipoExcecao[]>;
  groups$: Observable<Grupo[]>;
  campo1Label$: Observable<string>;
  campo2Label$: Observable<string>;
  campo3Label$: Observable<string>;



  constructor(
    private fg: ControlContainer,
    private taxDataService: ProductTaxDataService,
  ) { }

  ngOnInit() {
    this.typeControl = this.fg.control.get(this.typeControlName) as FormControl;
    this.groupControl = this.fg.control.get(this.groupControlName) as FormControl;

    this.types$ = this.taxDataService.tipoExcecoes$.pipe(
      takeUntil(this.destroy$)
    );

    this.groups$ = this.typeControl.valueChanges.pipe(
      startWith(this.typeControl.value),
      takeUntil(this.destroy$),
      switchMap( typeId => this.taxDataService.getGroups(typeId)),
      shareReplay(1)
    );

    this.typeControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe( type => {
      if (type) {
        this.groupControl.reset(null);
      }
    });

    const selectedGroup$: Observable<Grupo> = this.groupControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.groupControl.value),
      switchMap( groupId => this.groups$.pipe(
        map( groups => groups ? groups.find( g => g.id === groupId) : null),
      )),
      shareReplay(1)
    );

    this.campo1Label$ = selectedGroup$.pipe(
      takeUntil(this.destroy$),
      map( g => g && g.campo1 )
    );
    this.campo2Label$ = selectedGroup$.pipe(
      takeUntil(this.destroy$),
      map( g => g && g.campo2 )
    );
    this.campo3Label$ = selectedGroup$.pipe(
      takeUntil(this.destroy$),
      map( g => g && g.campo3 )
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
