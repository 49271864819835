import { Component, OnInit, Input, ElementRef, Injector, forwardRef, Optional } from '@angular/core';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { IUploadDirectiveData } from 'src/app/shared/directives/upload.directive';
import { ApiMidiaService } from 'src/app/services/api/midia/api-midia.service';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';

@Component({
  selector: 'c-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    }
  ]
})
export class ImageUploaderComponent extends ControlValueAccessorMixin implements OnInit {

  @Input() label: string;
  @Input() productId: string;
  @Input() uploadEnabled: boolean;
  @Input() minWidth: number;
  @Input() minHeight: number;
  @Input() maxWidth: number;
  @Input() maxHeight: number;
  @Input() especialUpload: boolean;
  @Input() altaResolucaoUpload: boolean;

  images: ImageUploaderData[];
  imageUploaderDataValidationsEnum = ImageUploaderDataValidationsEnum;

  private draggingItemIndex: number;
  private latestEnteredItem: ImageUploaderData;

  constructor(
    private midiaServ: ApiMidiaService,
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  ngOnInit() {
  }

  writeValue(value: ImageUploaderData[]) {
    if (value) {
      value.forEach(elem => {
        if (typeof (elem) == 'string') {
          this.addImage({
            link: elem
          } as any as ImageUploaderData);
        } else {
          this.addImage(elem);
        }
      });
    }
  }

  dragEnterImage(e: DragEvent, i: number, data: ImageUploaderData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();

      if (i != this.draggingItemIndex) {
        if (this.latestEnteredItem) {
          this.latestEnteredItem.isLeftVisible = false;
          this.latestEnteredItem.isRightVisible = false;
        }

        if (this.draggingItemIndex > i) {
          data.isLeftVisible = true;
        } else {
          data.isRightVisible = true;
        }

        this.latestEnteredItem = data;
      }
    }
  }

  dragLeaveImage(e: DragEvent, i: number, data: ImageUploaderData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();

      if (i != this.draggingItemIndex) {
        data.isLeftVisible = undefined;
        data.isRightVisible = undefined;
      }
    }
  }

  dropImage(e: DragEvent, i: number, data: ImageUploaderData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();

      if (i != this.draggingItemIndex) {
        // Change item position
        const draggingItem: ImageUploaderData = this.images[this.draggingItemIndex];
        this.images.splice(this.draggingItemIndex, 1);
        this.images.splice(i, 0, draggingItem);

        this.draggingItemIndex = undefined;
        data.isLeftVisible = undefined;
        data.isRightVisible = undefined;

        this.updateValue();
      }
    }
  }

  dragOverImage(e: DragEvent) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  dragStartImage(e: DragEvent, i: number, data: ImageUploaderData) {
    e.stopPropagation();
    this.draggingItemIndex = i;
  }

  dragEndImage(e: DragEvent, i: number, data: ImageUploaderData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();
      this.closeAllLeftAndRightItems();
    }
  }

  removeImage(i: number) {
    this.images.splice(i, 1);
    this.updateValue();
  }

  async addImage(data: ImageUploaderData, makeUpload?: boolean) {
    if (!this.images) {
      this.images = new Array();
    }

    let imageData: ImageUploaderData = data;

    if (makeUpload && this.uploadEnabled) {
      imageData = await this.uploadImage(data) as ImageUploaderData;
    }

    this.images.push(imageData);
    imageData.validation = await this.checkImageValidation(imageData);

    this.updateValue();
  }

  private uploadImage(data: ImageUploaderData) {
    if (this.especialUpload) {
      return this.midiaServ.uploadEspecialImage(this.productId, data.file).toPromise();
    } else if (this.altaResolucaoUpload) {
      return this.midiaServ.uploadImageAltaResolucao(this.productId, data.file).toPromise();
    } else {
      return this.midiaServ.uploadImage(this.productId, data.file).toPromise();
    }
  }

  private updateValue() {
    this.blur();

    if (this.images.length) {
      this.value = this.images.slice().map(elem => {
        const data = {
          ...elem
        };

        delete data.isLeftVisible;
        delete data.isRightVisible;

        return data;
      });
    } else {
      this.value = undefined;
    }
  }

  private closeAllLeftAndRightItems() {
    this.images.forEach(elem => {
      elem.isLeftVisible = false;
      elem.isRightVisible = false;
    });
  }

  private checkImageValidation(data: ImageUploaderData): Promise<ImageUploaderDataValidationsEnum> {
    return new Promise(resolve => {
      const source = data && data.link || data && data.rawData;

      const img = new Image();
      img.onload = () => {
        if (img.width < this.minWidth || img.height < this.minHeight) {
          resolve(ImageUploaderDataValidationsEnum.error);
        } else if (img.width > this.maxWidth || img.height > this.maxHeight) {
          resolve(ImageUploaderDataValidationsEnum.warning);
        } else {
          resolve(ImageUploaderDataValidationsEnum.valid);
        }
      };
      img.src = source;
    });
  }

  validate() {
    if (this.images) {
      const imageWithError = this.images.find(elem => elem.validation == ImageUploaderDataValidationsEnum.error);
      return imageWithError ? { errors: true } : null;
    }

    return null;
  }

}

export interface ImageUploaderData extends IUploadDirectiveData {
  isLeftVisible: boolean;
  isRightVisible: boolean;
  isMouseOver: boolean;
  validation: ImageUploaderDataValidationsEnum;
  link?: string;
  id?: string;
}

enum ImageUploaderDataValidationsEnum {
  valid = 0,
  error = 1,
  warning = 2
}
