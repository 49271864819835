import { Component, OnInit, Input } from '@angular/core';
import { EnumSistemaIntegracaoStatus } from 'src/app/models/api/workflow/enum-sistema-integracao-status.enum';
import { IntegracaoEmMassaDashboard } from 'src/app/models/api/workflow/integracao-em-massa.interface';
import { ApiIntegracaoEmMassaService } from 'src/app/services/api/workflow/api-integracao-em-massa.service';

@Component({
  selector: 'c-product-list-table-mass-integration',
  templateUrl: './product-list-table-mass-integration.component.html',
  styleUrls: ['./product-list-table-mass-integration.component.scss']
})
export class ProductListTableMassIntegrationComponent implements OnInit {

  @Input() dataSource: IntegracaoEmMassaDashboardWithRowInfo[];
  @Input() columns: string[];

  constructor(
    private apiIntegracaoEmMassaService: ApiIntegracaoEmMassaService
  ) { }

  ngOnInit() {
  }

  toggleRow(data: IntegracaoEmMassaDashboardWithRowInfo) {
    data.isRowExpanded = !data.isRowExpanded;
  }

  getIntegrationStatusName(value: EnumSistemaIntegracaoStatus) {
    switch (value) {
      case EnumSistemaIntegracaoStatus.NaoIntegrado:
        return 'not integrated';

      case EnumSistemaIntegracaoStatus.Integrado:
        return 'integrated';

      case EnumSistemaIntegracaoStatus.ErroAoIntegrar:
        return 'error in integration';

      case EnumSistemaIntegracaoStatus.PrecisaSerAtualizado:
        return 'need update';
    }
  }

  exportMassIntegration(id: string) {
    this.apiIntegracaoEmMassaService.getLogByIntegrationId(id).subscribe(res => {
      const downloadUrl = window.URL.createObjectURL(res);
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.download = `${id}_${new Date().getTime()}.xlsx`;
      downloadLink.click();
      window.URL.revokeObjectURL(downloadUrl);
    });
  }

}

interface IntegracaoEmMassaDashboardWithRowInfo extends IntegracaoEmMassaDashboard {
  isRowExpanded: boolean;
}
