import { Component, OnInit } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject } from 'rxjs';
import { skip, debounceTime, delay } from 'rxjs/operators';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { DashboardService } from '../services/dashboard.service';
import { CategoriaGlobal } from 'src/app/models/api/dados-especificos/categoria-global.interface';
import { ApiCategoriaGlobalService } from 'src/app/services/api/dados-especificos/api-global-category.service';
import { FormDinamicoCampo } from 'src/app/models/api/dados-especificos/form-dinamico';

@Component({
  selector: 'c-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });

  departmentData: DepartmentEditable[];
  departmentPaginatedData: DepartmentEditable[];
  departmentFilteredData: DepartmentEditable[];
  columns: string[] = ['name', 'categoryId', 'globalCategory', 'active', 'actions'];
  search$ = new BehaviorSubject('');
  form: FormGroup;
  enableDisableForm: FormGroup;
  globalCategoriesData: CategoriaGlobal[];
  listStock: string[] = [];

  constructor(
    private apiDepartamentoService: ApiDepartamentoService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private apiGlobalCategoryService: ApiCategoriaGlobalService
  ) {
    this.getStock()
    this.createForm();
    this.buildEnableDisableForm();
    this.listenPaginator();
    this.listenSearch();
  }
  getStock() {
    this.listStock = ["SPECIFICATION", "LIST", "RADIO", "COMBO"];
  }

  ngOnInit() {
    this.loadDepartmentData();
    this.loadGlobalCategories();
  }

  createForm() {
    this.form = this.fb.group({
      descricao: [null],
      metaTag: [null],
      stockKeepingUnitSelectionMode: [null],
      globalCategoryId: [null],
      cloneFrom: [null],
      categoryId: 0
    });
  }
  buildEnableDisableForm() {
    this.enableDisableForm = this.fb.group({
      enable: [null],
    });
  }

  loadGlobalCategories() {
    this.apiGlobalCategoryService.getAll().subscribe(res => {
      this.globalCategoriesData = res;
    });
  }

  loadDepartmentData() {
    this.apiDepartamentoService.getAllAtivosInativos().subscribe(res => {
      this.departmentData = res.data as DepartmentEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: DepartmentEditable) {
    data.newDescription = data.descricao;
    data.newGlobalCategoryId = data.globalCategoryId;
    data.isEditActive = true;
  }

  cancelEdit(data: DepartmentEditable) {
    data.newDescription = data.descricao;
    data.newGlobalCategoryId = data.globalCategoryId;
    data.isEditActive = false;
  }

  async changeEnableDisable(data: DepartmentEditable) {
    try {
      await this.apiDepartamentoService.delete(data.id).toPromise();
    } catch {
      setTimeout(() => {
        window.location.reload();
      }, 4000);

    }
    // await this.apiDepartamentoService.delete(data.id).toPromise();
    this.dashboardService.departmentChange$.next();
  }

  async save(data: DepartmentEditable) {
    if (!data) {
      const { descricao, categoryId, metaTag, stockKeepingUnitSelectionMode, globalCategoryId, cloneFrom} = this.form.value;
      if (categoryId == null){
        await this.apiDepartamentoService.insert({ descricao: descricao.toUpperCase(), categoryId: 0, metaTag: metaTag, stockKeepingUnitSelectionMode: stockKeepingUnitSelectionMode, globalCategoryId, camposList: this.getCamposList(cloneFrom) }).toPromise();
      }
      else{
        await this.apiDepartamentoService.insert({ descricao: descricao.toUpperCase(), categoryId: categoryId, metaTag: metaTag, stockKeepingUnitSelectionMode: stockKeepingUnitSelectionMode, globalCategoryId, camposList: this.getCamposList(cloneFrom) }).toPromise();
      }
      this.form.reset();
      this.loadDepartmentData();
    } else {
      const id = data.id;
      const descricao = data.newDescription.toUpperCase();
      const globalCategoryId = data.newGlobalCategoryId;
      const camposList = data.camposList;

      await this.apiDepartamentoService.update({ id, descricao, globalCategoryId, camposList }).toPromise();
      data.descricao = descricao;
      data.isEditActive = false;
    }

    this.dashboardService.departmentChange$.next();
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.departmentData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.departmentPaginatedData = this.departmentData.slice(startAt, startAt + data.pageLength);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.departmentFilteredData = this.departmentData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          const department = regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          const categoryId = regex.test(String(elem.categoryId));
          return department || categoryId ;
        });
      } else {
        this.departmentFilteredData = undefined;
      }
    });
  }

  globalCategoryIdToDescription(id: string) {
    const globalCategory = this.globalCategoriesData.find(elem => elem.id == id);

    if (globalCategory) {
      return globalCategory.descricao;
    }
  }

  getCamposList(departmentId: string): FormDinamicoCampo[] {
    let departments = this.departmentData.filter(
      d => d.id === departmentId);

    return departments == null || departments.length == 0 ? [] : departments[0].camposList;
  }

  get canCreate() {
    return this.descricao.value && this.globalCategoryId.value;
  }

  // Form gets
  get descricao() { return this.form.get('descricao'); }
  get globalCategoryId() { return this.form.get('globalCategoryId'); }

}

interface DepartmentEditable extends Departamento {
  isEditActive: boolean;
  newDescription: string;
  newGlobalCategoryId: string;
}


