import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { RadioModule } from 'src/app/ui/radio/radio.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    CheckboxModule,
    RadioModule
  ]
})
export class HomeModule { }
