import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { Observable, defer } from 'rxjs';
import { DynamicFormConfig } from 'src/app/lib/dynamic-form/models/dyanmic-form.interface';
import { startWith, map, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { convertFormToDynamicField, convertCampoDinamicoToForm, convertFormToCampoDinamico } from './dynamic-field-editor-convertions';
import { EnumCampoDinamicoType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-type.enum';
import { getUniqueId } from 'src/app/shared/utils/get-unique-id';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { DynamicFieldEditorForm } from './form';
import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { disableRules } from './disable-rules';

@Injectable()
export class DynamicFieldEditorService {
  form: FormGroup;
  dynamicFormConfigPreview = this.fb.control({});
  dynamicFormConfig$: Observable<DynamicFormConfig>;

  readonly disableRules = disableRules;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private apiCampoDinamicoService: ApiCampoDinamicoService,
  ) {
    this.init();
  }

  // #region inicialização

  get optionsFormArray() {
    return this.form && this.form.get('options') as FormArray;
  }

  private init() {
    this.form = this.createForm();

    this.form.get('type').valueChanges.pipe(
      startWith(this.form.get('type').value),
      distinctUntilChanged()
    ).subscribe( value => {
      // habilita todos os campos
      this.form.enable();

      const rule = this.disableRules.get(value) || [];
      rule.forEach( fieldPath => {
        this.form.get(fieldPath).disable();
      });
    });

    this.dynamicFormConfig$ = defer(() => this.form.valueChanges.pipe(
      startWith(this.form.value),
      debounceTime(100),
      map(value => [convertFormToDynamicField(value)]),
    ));

    this.activatedRoute.data.subscribe(({ field }: { field: CampoDinamico }) => {
      const form = field ? convertCampoDinamicoToForm(field) : null;
      this.setFormData(form);
    });
  }

  private setFormData(data?: DynamicFieldEditorForm) {
    console.log('resetando');
    this.form.reset();
    if (data) {
      this.form.patchValue(data);

      if (data.options) {
        data.options.forEach(o => {
          const optionForm = this.createOptionsForm();
          optionForm.patchValue(o);
          this.optionsFormArray.push(optionForm);
        });
      }

    } else {
      this.form.patchValue({
        type: EnumCampoDinamicoType.Text,
        layout: {
          size: 4,
          hightlight: false
        },
        mask: ''
      });
    }
    console.log('formulario atualizado', this.form.value);
  }

  private getFormData() {

  }

  // #endregion inicialização

  // #region ações da tela

  save() {
    if (this.form.valid) {
      const campoDinamico = convertFormToCampoDinamico(this.form.value);

      if (this.form.get('id').value) {
        this.apiCampoDinamicoService.update(campoDinamico).subscribe(result => {
          this.form.markAsUntouched();
          this.router.navigateByUrl('/');
        });
      } else {
        this.apiCampoDinamicoService.insert(campoDinamico).subscribe(result => {
          this.form.get('id').setValue(result.id);
          this.form.markAsUntouched();
          this.toastService.toast('Salvo com sucesso!');
          this.router.navigateByUrl('/');
        });
      }
    } else {
      this.checkFormErrors();
    }
  }

  addOption() {
    const option = this.createOptionsForm();
    option.patchValue({
      optionId: getUniqueId(4), // criar novo id
      description: '',
    });
    this.optionsFormArray.push(option);
  }

  deleteOption(index: number) {
    this.optionsFormArray.removeAt(index);
  }

  // #endregion ações da tela

  private checkFormErrors() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.toast('Formulário esta inválido', ToastType.error);
    }
  }

  private createForm() {
    return this.fb.group({
      id: this.fb.control([undefined]),

      // configurações gerais
      label: this.fb.control('', Validators.required),
      type: this.fb.control([undefined], Validators.required),
      layout: this.fb.group({
        size: this.fb.control([undefined], Validators.required),
        hightlight: this.fb.control([undefined]), // não incluso no template
      }),
      iconName: this.fb.control([undefined]),
      prefix: this.fb.control([undefined]),
      sufix: this.fb.control([undefined]),
      mask: this.fb.control([undefined]),

      // validações
      dynamicFieldValidator: this.createValidatorForm(),
      min: this.fb.control([undefined], Validators.required), // range min
      max: this.fb.control([undefined], Validators.required), // range max

      // opções
      options: this.fb.array([]),
    });
  }

  private createValidatorForm() {
    return this.fb.group({
      required: this.fb.control([undefined]),
      email: this.fb.control([undefined]),

      minLength: this.fb.control([undefined]),
      minLengthValue: this.fb.control([undefined]),

      maxLength: this.fb.control([undefined]),
      maxLengthValue: this.fb.control([undefined]),

      min: this.fb.control([undefined]),
      minValue: this.fb.control([undefined]),

      max: this.fb.control([undefined]),
      maxValue: this.fb.control([undefined]),

      pattern: this.fb.control([undefined]),
      patternValue: this.fb.control([undefined]),
    });
  }

  private createOptionsForm() {
    return this.fb.group({
      optionId: ['', Validators.required],
      description: ['', Validators.required],
    });
  }
}
