import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApiHttpClientService } from '../../api-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRelatorioService {

  private baseUrl = 'relatorio';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.relatorio)
      .useBaseUrl(this.baseUrl);
  }

  exportReportAll() {
    return this.controller.useLoading().get('RelatorioCompleto');
  }

  exportReportProductsRevisionAll() {
    return this.controller.useLoading().get('RelatorioProdutosIntegradosEmRevisao');
  }
}
