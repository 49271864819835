import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Logs } from 'src/app/models/api/logs';

@Injectable({
  providedIn: 'root'
})
export class ApiLogService {

  private baseUrl = 'Log';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  getByProduct(id: string) {
    return this.controller.unwrap().useLoading().get<Logs>(`DadosComerciais/${id}`);
  }

}
