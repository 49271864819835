import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';

@Injectable({
  providedIn: 'root'
})
export class DynamicFieldEditorResolverService implements Resolve<CampoDinamico> {

  constructor(
    private apiCampoDinamicoService: ApiCampoDinamicoService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.paramMap.get('id');

    if (id) {
      return this.apiCampoDinamicoService.getById(id);
    } else {
      return null;
    }
  }
}
