import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Formato } from 'src/app/models/api/comercial/pricefy-formato';

@Injectable({
  providedIn: 'root'
})
export class ApiPricefyFormatosService {
  private baseUrl = 'DadosPricefy';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Formato[]>('ObterFormatos');
  }
}
