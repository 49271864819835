import { Injectable } from '@angular/core';
import { ProductBaseEntity } from './product-base-entity.class';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiDadosComerciaisService } from 'src/app/services/api/comercial/api-dados-comerciais.service';
import { CanSave } from './interfaces';
import { ApiCategoriaFornecedorService } from 'src/app/services/api/comercial/api-categoria-fornecedor.service';
import { ApiTipoMaterialService } from 'src/app/services/api/comercial/api-tipo-material.service';
import { ApiGrupoCompradorService } from 'src/app/services/api/comercial/api-grupo-comprador.service';
import { tap, shareReplay, startWith, map, switchMap } from 'rxjs/operators';
import { TipoMaterial } from 'src/app/models/api/comercial/tipo-material';
import { Observable } from 'rxjs';
import { GrupoComprador } from 'src/app/models/api/comercial/grupo-comprador';
import { CategoriaFornecedor } from 'src/app/models/api/comercial/categoria-fornecedor';
import { Classificacao } from 'src/app/models/api/comercial/classificacao';
import { Colecao } from 'src/app/models/api/comercial/colecao';
import { CvprmModa } from 'src/app/models/api/comercial/cvprm-moda';
import { FaixaPreco } from 'src/app/models/api/comercial/faixa-preco';
import { Hierarquia } from 'src/app/models/api/comercial/hierarquia';
import { Padronagem } from 'src/app/models/api/comercial/padronagem';
import { TipoPadronagem } from 'src/app/models/api/comercial/tipo-padronagem';
import { ApiClassificacaoService } from 'src/app/services/api/comercial/api-classificacao.service';
import { ApiColecaoService } from 'src/app/services/api/comercial/api-colecao.service';
import { ApiCvprmModaService } from 'src/app/services/api/comercial/api-cvprm-moda.service';
import { ApiEpocaService } from 'src/app/services/api/comercial/api-epoca.service';
import { ApiExercicioService } from 'src/app/services/api/comercial/api-exercicio.service';
import { ApiFaixaPrecoService } from 'src/app/services/api/comercial/api-faixa-preco.service';
import { ApiHierarquiaService } from 'src/app/services/api/comercial/api-hierarquia.service';
import { ApiLicencaService } from 'src/app/services/api/comercial/api-licenca-service';
import { ApiLicenciadorService } from 'src/app/services/api/comercial/api-licenciador-service';
import { ApiLinhaService } from 'src/app/services/api/comercial/api-linha-service';
import { ApiGrupoService } from 'src/app/services/api/comercial/api-grupo-service';
import { ApiExclusivoService } from 'src/app/services/api/comercial/api-exclusivo-service';
import { ApiPadronagemService } from 'src/app/services/api/comercial/api-padronagem.service';
import { ApiTipoPadronagemService } from 'src/app/services/api/comercial/api-tipo-padronagem.service';
import { Epoca } from 'src/app/models/api/comercial/epoca';
import { Exercicio } from 'src/app/models/api/comercial/exercicio';
import { Departamento } from 'src/app/models/api/comercial/departamento';
import { ApiDepartamentoService } from 'src/app/services/api/comercial/api-departamento.service';
import { Cluster } from 'src/app/models/api/comercial/cluster';
import { ApiClusterService } from 'src/app/services/api/comercial/api-cluster.service';
import { NormaSuprimento } from 'src/app/models/api/comercial/norma-suprimento';
import { ApiNormaSuprimentoService } from 'src/app/services/api/comercial/api-norma-suprimento.service';
import { ProductBasicDataService } from './product-basic-data.service';
import { StatusComercial } from 'src/app/models/api/comercial/status-comercial';
import { ApiStatusComercialService } from 'src/app/services/api/comercial/api-status-comercial.service';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { CurvaCEVAEnum } from 'src/app/models/api/comercial/curva-ceva.enum';
import { Licenca } from 'src/app/models/api/comercial/licenca';
import { Licenciador } from 'src/app/models/api/comercial/licenciador';
import { Linha } from 'src/app/models/api/comercial/linha';
import { Grupo } from 'src/app/models/api/comercial/grupo';
import { Exclusivo } from 'src/app/models/api/comercial/exclusivo';
import { ApiPricefyTemasService } from 'src/app/services/api/comercial/api-pricefy-temas.service';
import { ApiPricefyFormatosService } from 'src/app/services/api/comercial/api-pricefy-formatos.service';
import { ApiPricefyDinamicasService } from 'src/app/services/api/comercial/api-pricefy-dinamicas.service';
import { Tema } from 'src/app/models/api/comercial/pricefy-tema';
import { Formato } from 'src/app/models/api/comercial/pricefy-formato';
import { Dinamica } from 'src/app/models/api/comercial/pricefy-dinamica';

@Injectable()
export class ProductCommercialDataService extends ProductBaseEntity implements CanSave {

  @IsAllowed('rhcdm.comercial.aprovar') canApproveOrReprove: boolean;

  // gets da api
  tipoMaterial$: Observable<TipoMaterial[]>;
  grupoComprador$: Observable<GrupoComprador[]>;
  categoriaFornecedor$: Observable<CategoriaFornecedor[]>;
  hierarquia$: Observable<Hierarquia[]>;
  licenca$: Observable<Licenca[]>;
  licenciador$: Observable<Licenciador[]>;
  linha$: Observable<Linha[]>;
  grupo$: Observable<Grupo[]>;
  exclusivo$: Observable<Exclusivo[]>;

  classificacao$: Observable<Classificacao[]>;
  colecao$: Observable<Colecao[]>;
  cvprmModal$: Observable<CvprmModa[]>;
  epoca$: Observable<Epoca[]>;
  exercicio$: Observable<Exercicio[]>;
  faixaPreco$: Observable<FaixaPreco[]>;
  padronagem$: Observable<Padronagem[]>;
  tipoPadronagem$: Observable<TipoPadronagem[]>;
  departamento$: Observable<Departamento[]>;
  cluster$: Observable<Cluster[]>;
  normaSuprimento$: Observable<NormaSuprimento[]>;
  statusComercial$: Observable<StatusComercial[]>;

  idModeMaterial: string;

  showAditionalFields$: Observable<boolean>;

  mirroredForm: FormGroup;
  // pricefyTemas$: Observable<Tema[]>;
  // pricefyFormatos$: Observable<Formato[]>;
  // pricefyDinamicas$: Observable<Dinamica[]>;

  precoSugerido?: number;
  

  constructor(
    fb: FormBuilder,
    private api: ApiDadosComerciaisService,
    private apiDadosComerciais: ApiDadosComerciaisService,
    private apiTipoMaterial: ApiTipoMaterialService,
    private apiCategoriaFornecedor: ApiCategoriaFornecedorService,
    private apiGrupoComprador: ApiGrupoCompradorService,
    private apiClassificacao: ApiClassificacaoService,
    private apiColecao: ApiColecaoService,
    private apiCvprmModa: ApiCvprmModaService,
    private apiEpoca: ApiEpocaService,
    private apiExercicio: ApiExercicioService,
    private apiFaixaPreco: ApiFaixaPrecoService,
    private apiHierarquia: ApiHierarquiaService,
    private apiLicenca: ApiLicencaService,
    private apiLicenciador: ApiLicenciadorService,
    private apiLinha: ApiLinhaService,
    private apiGrupo: ApiGrupoService,
    private apiExclusivo: ApiExclusivoService,
    private apiPadronagem: ApiPadronagemService,
    private apiTipoPadronagem: ApiTipoPadronagemService,
    private apiDepartamento: ApiDepartamentoService,
    private apiCluster: ApiClusterService,
    private apiStatusComercial: ApiStatusComercialService,
    private apiNormaSuprimento: ApiNormaSuprimentoService,
    private basicDataService: ProductBasicDataService,
    private apiPricefyTemas: ApiPricefyTemasService,
    private apiPricefyFormatos: ApiPricefyFormatosService,
    private apiPricefyDinamicas: ApiPricefyDinamicasService,
  ) {
    super(fb);
    this.initForm();

    this.tipoMaterial$ = this.apiTipoMaterial.getAll().pipe(
      shareReplay(1),
      // solução rapida =(
      // soluções mais adequadas:
      // - adicionar id condicional do tipo material nos campos adicionais (afim de determinar se deve exibir ou não)
      // - indicar por boolean quais campos do tipo material qunado selecionado exibi os campos adicionais
      tap(data => {
        const item = data.find(d => d.descricao == 'Mode');
        if (item) { this.idModeMaterial = item.id; }
      })
    );
    this.grupoComprador$ = this.apiGrupoComprador.getAll().pipe(shareReplay(1));
    this.categoriaFornecedor$ = this.apiCategoriaFornecedor.getAll().pipe(shareReplay(1));

    this.classificacao$ = this.apiClassificacao.getAll().pipe(shareReplay(1));
    this.colecao$ = this.apiColecao.getAll().pipe(shareReplay(1));
    this.cvprmModal$ = this.apiCvprmModa.getAll().pipe(shareReplay(1));
    this.epoca$ = this.apiEpoca.getAll().pipe(shareReplay(1));
    this.exercicio$ = this.apiExercicio.getAll().pipe(shareReplay(1));
    this.faixaPreco$ = this.apiFaixaPreco.getAll().pipe(shareReplay(1));
    this.hierarquia$ = this.apiHierarquia.getAll().pipe(shareReplay(1));
    this.licenca$ = this.apiLicenca.getAll().pipe(shareReplay(1));
    this.licenciador$ = this.apiLicenciador.getAll().pipe(shareReplay(1));
    this.linha$ = this.apiLinha.getAll().pipe(shareReplay(1));
    this.grupo$ = this.apiGrupo.getAll().pipe(shareReplay(1));
    this.exclusivo$ = this.apiExclusivo.getAll().pipe(shareReplay(1));
    this.padronagem$ = this.apiPadronagem.getAll().pipe(shareReplay(1));
    this.tipoPadronagem$ = this.apiTipoPadronagem.getAll().pipe(shareReplay(1));
    this.departamento$ = this.apiDepartamento.getAll().pipe(shareReplay(1));
    this.cluster$ = this.apiCluster.getAll().pipe(shareReplay(1));
    this.normaSuprimento$ = this.apiNormaSuprimento.getAll().pipe(shareReplay(1));
    this.statusComercial$ = this.apiStatusComercial.getAll().pipe(shareReplay(1));

    // this.pricefyTemas$ = this.apiPricefyTemas.getAll().pipe(shareReplay(1));
    // this.pricefyFormatos$ = this.apiPricefyFormatos.getAll().pipe(shareReplay(1));
    // this.pricefyDinamicas$ = this.apiPricefyDinamicas.getAll().pipe(shareReplay(1));

    this.showAditionalFields$ = this.tipoMaterial$.pipe(
      switchMap(() => this.form.get('tipoMaterialId').valueChanges.pipe(
        startWith(this.form.get('tipoMaterialId').value),
        map(value => this.idModeMaterial == value)
      ))
    );

    this.showAditionalFields$.subscribe(show => {
      const formControls = [
        this.form.get('padronagemId'),
        this.form.get('tipoPadronagemId'),
        this.form.get('classificacaoId'),
        this.form.get('faixaPrecoId'),
        this.form.get('cvprmModaId'),
        this.form.get('epocaId'),
        this.form.get('exercicioId'),
        this.form.get('colecaoId'),
      ];

      if (show) {
        formControls.forEach(c => c.enable());
      } else {
        formControls.forEach(c => c.disable());
      }
    });
  }

  get canSaveDraftitForReview() {
    return false;
  }

  get canSave() {
    return true;
  }

  createForm() {
    const form = this.fb.group({
      descricaoCurta: [undefined, [Validators.required, Validators.maxLength(40)]],
      descricaoAbreviada: [undefined, [Validators.required, Validators.maxLength(23)]],
      statusComercialId: [undefined, Validators.required],
      tipoMaterialId: [undefined, Validators.required],
      grupoCompradorId: [undefined, Validators.required],
      hieraquiaId: [undefined, Validators.required],
      licencaId: [undefined, Validators.required],
      licenciadorId: [undefined, Validators.required],
      linhaId: [undefined, Validators.required],
      grupoId: [undefined, Validators.required],
      exclusivoId: [undefined, Validators.required],
      itemExclusivo: this.createItemExclusivoForm(),
      formatoId: [undefined, Validators.required],
      temaId: [undefined, Validators.required],
      dinamicaId: [undefined, Validators.required],
      custoSP: [undefined, [Validators.required, Validators.max(100000)]],
      // precoSugerido: [undefined, [Validators.required, Validators.max(100000)],
      precoVenda: [undefined, [Validators.required, Validators.max(100000)]],
      valorArredondamento: [undefined, Validators.required],
      // clusterIdList: [[]],
      clusters: [undefined],
      departamentoId: [undefined, Validators.required],
      id: [undefined, Validators.required],
      normaSuprimentoId: [undefined, Validators.required],
      curvaCEVA: [CurvaCEVAEnum.C],

      // Campos adicionais
      padronagemId: [undefined, Validators.required],
      tipoPadronagemId: [undefined, Validators.required],
      classificacaoId: [undefined, Validators.required],
      faixaPrecoId: [undefined, Validators.required],
      cvprmModaId: [undefined, Validators.required],
      epocaId: [undefined, Validators.required],
      exercicioId: [undefined, Validators.required],
      colecaoId: [undefined, Validators.required],
    });

    return form;
  }

  private createItemExclusivoForm() {
    return this.fb.control({
      marketPlace: false,
      pbKids: false,
      riHappy: false,
      vendidoEmOmni: false
    });
  }
}
