import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductRoutingModule } from './product-routing.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { TabModule } from 'src/app/ui/tab/tab.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { RadioModule } from 'src/app/ui/radio/radio.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { ProductBasicDataComponent } from './product-basic-data/product-basic-data.component';
import { ProductSpecificDataComponent } from './product-specific-data/product-specific-data.component';
import { ProductHeaderComponent } from './product-header/product-header.component';
import { EcommerceDataSectionComponent } from './product-specific-data/ecommerce-data-section/ecommerce-data-section.component';
import { DynamicDataSectionComponent } from './product-specific-data/dynamic-data-section/dynamic-data-section.component';
import { VariationsSectionComponent } from './product-specific-data/variations-section/variations-section.component';
import { PdmSectionComponent } from './product-specific-data/pdm-section/pdm-section.component';
import { DynamicFormModule } from 'src/app/lib/dynamic-form/dynamic-form.module';
import { RangeModule } from 'src/app/ui/range/range.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { ProductHistoricComponent } from './product-historic/product-historic.component';
import { ProductMediaComponent } from './product-media/product-media.component';
import { ProductMediaItemComponent } from './product-media/product-media-item/product-media-item.component';
import { ImageUploaderModule } from 'src/app/ui/image-uploader/image-uploader.module';
import { SubnivelComponent } from './product-specific-data/subnivel/subnivel.component';
import { DescriptionsSectionComponent } from './product-specific-data/descriptions-section/descriptions-section.component';
import { VideoLinkUploaderModule } from 'src/app/ui/video-link-uploader/video-link-uploader.module';
import { ImgModule } from 'src/app/ui/img/img.module';
import { ProductCommercialDataComponent } from './product-commercial-data/product-commercial-data.component';
import { ProductHistoricCommentComponent } from './product-historic/product-historic-comment/product-historic-comment.component';
import { ProductApprovalComponent } from './product-approval/product-approval.component';
import { EmptyPatternModule } from 'src/app/ui/empty-pattern/empty-pattern.module';
import { CompositionInputModule } from 'src/app/ui/composition-input/composition-input.module';
import { ProductIntegrationComponent } from './product-integration/product-integration.component';
import { GeneralIntegrationInfoModule } from 'src/app/ui/general-integration-info/general-integration-info.module';
import { OnlineEanValidator } from './helpers/online-ean.validator';
import { ModalModule } from 'src/app/ui/modal/modal.module';
import { LogsModalService } from './services/logs-modal.service';
import { ProductLogsModalComponent } from './product-logs-modal/product-logs-modal.component';
import { EanStatusDirective } from './ean-status.directive';
import { ProductTaxDataComponent } from './product-tax-data/product-tax-data.component';
import { ProgressBarModule } from 'src/app/ui/progress-bar/progress-bar.module';
import { DynamicTabModule } from 'src/app/ui/dynamic-tab/dynamic-tab.module';
import { ProductCostAndPriceComponent } from './product-cost-and-price/product-cost-and-price.component';
import { TaxDataGroupByTypeDirective } from './product-tax-data/tax-data-group-by-type.directive';
import { NFGNREPorTributacaoDirective } from './product-tax-data/nfgnrepor-tributacao.directive';

@NgModule({
    declarations: [
        ProductComponent,
        ProductBasicDataComponent,
        ProductSpecificDataComponent,
        ProductHeaderComponent,
        EcommerceDataSectionComponent,
        DynamicDataSectionComponent,
        VariationsSectionComponent,
        PdmSectionComponent,
        ProductHistoricComponent,
        ProductMediaComponent,
        ProductMediaItemComponent,
        SubnivelComponent,
        DescriptionsSectionComponent,
        ProductCommercialDataComponent,
        ProductHistoricCommentComponent,
        ProductApprovalComponent,
        ProductIntegrationComponent,
        ProductLogsModalComponent,
        EanStatusDirective,
        ProductTaxDataComponent,
        ProductCostAndPriceComponent,
        TaxDataGroupByTypeDirective,
        NFGNREPorTributacaoDirective,
    ],
    imports: [
        CommonModule,
        ProductRoutingModule,
        SharedModule,
        InputModule,
        SelectModule,
        TabModule,
        CheckboxModule,
        RadioModule,
        RangeModule,
        CardModule,
        DynamicFormModule,
        IconModule,
        ImageUploaderModule,
        VideoLinkUploaderModule,
        ImgModule,
        EmptyPatternModule,
        CompositionInputModule,
        GeneralIntegrationInfoModule,
        ModalModule,
        ProgressBarModule,
        DynamicTabModule,
    ],
    providers: [
        LogsModalService
    ]
})
export class ProductModule { }
