import { ProductListTableInReviewComponent } from './product-list/product-list-table-in-review/product-list-table-in-review.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { ProductListComponent } from './product-list/product-list.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { DashboardSupplierComponent } from './dashboard-supplier/dashboard-supplier.component';
import { DashboardEmployeeComponent } from './dashboard-employee/dashboard-employee.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TabModule } from 'src/app/ui/tab/tab.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { PageTitleModule } from 'src/app/ui/page-title/page-title.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { PaginatorModule } from 'src/app/ui/paginator/paginator.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { EmptyPatternModule } from 'src/app/ui/empty-pattern/empty-pattern.module';
import { ProductListTableComponent } from './product-list/product-list-table/product-list-table.component';
// tslint:disable-next-line: max-line-length
import { ProductListTableIntegrationComponent } from './product-list/product-list-integrations/product-list-table-integration/product-list-table-integration.component';
import { ProductImportComponent } from './product-import/product-import.component';
import { ProductsNavbarComponent } from './product-list/components/products-navbar/products-navbar.component';
import { CollapseModule } from 'src/app/ui/collapse/collapse.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SupplierListTableComponent } from './dashboard-supplier/supplier-list-table/supplier-list-table.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { RoleListComponent } from './role-list/role-list.component';
// tslint:disable-next-line: max-line-length
import { ProductListTableInIntegrationComponent } from './product-list/product-list-table-in-integration/product-list-table-in-integration.component';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { ColumnFilterModule } from 'src/app/ui/column-filter/column-filter.module';
import { TableSortModule } from 'src/app/ui/table-sort/table-sort.module';
import { BrandAndLicenseComponent } from './brand-and-license/brand-and-license.component';
import { LicenseGroupComponent } from './license-group/license-group.component';
import { LicenseComponent } from './license-group/license/license.component';
import { LicensorComponent } from './license-group/licensor/licensor.component';
import { HierarchyComponent } from './license-group/hierarchy/hierarchy.component';
import { CommercialGroupComponent } from './license-group/group/group.component';
import { CommercialLineComponent } from './license-group/line/line.component';
import { DepartmentComponent } from './department/department.component';
import { CategoryComponent } from './category/category.component';
import { DynamicFieldListComponent } from './dynamic-field-list/dynamic-field-list.component';
import { PdmModule } from './pdm/pdm.module';
import { DynamicFormConfigModule } from './dynamic-form-config/dynamic-form-config.module';
import { SublevelComponent } from './sublevel/sublevel.component';
import { DashboardService } from './services/dashboard.service';
// tslint:disable-next-line: max-line-length
import { ProductListIntegrationsComponent } from './product-list/product-list-integrations/product-list-integrations.component';
// tslint:disable-next-line: max-line-length
import { ProductListTableMassIntegrationComponent } from './product-list/product-list-integrations/product-list-table-mass-integration/product-list-table-mass-integration.component';
import { XmlListComponent } from './xml-list/xml-list.component';
import { XmlListTableComponent } from './xml-list/xml-list-table/xml-list-table.component';
import { MediasModule } from "./medias/medias.module";
import { LogImportsComponent } from './log-imports/log-imports.component';
import { ProductListTableResponsableComponent } from './product-list/product-list-table-in-responsable/product-list-table-in-responsable.component';
import { ProductListTableRegisteredsComponent } from './product-list/product-list-table-in-registereds/product-list-table-in-registereds.component';

@NgModule({
  declarations: [
    ProductListComponent,
    ProductListTableComponent,
    ProductListTableIntegrationComponent,
    ProductListTableInIntegrationComponent,
    ProductImportComponent,
    ProductsNavbarComponent,
    SupplierListComponent,
    DashboardSupplierComponent,
    DashboardEmployeeComponent,
    DashboardComponent,
    SupplierListTableComponent,
    EmployeeListComponent,
    RoleListComponent,
    BrandAndLicenseComponent,
    LicenseGroupComponent,
    LicenseComponent,
    LicensorComponent,
    HierarchyComponent,
    CommercialGroupComponent,
    CommercialLineComponent,
    DepartmentComponent,
    CategoryComponent,
    DynamicFieldListComponent,
    SublevelComponent,
    ProductListTableMassIntegrationComponent,
    ProductListIntegrationsComponent,
    XmlListComponent,
    XmlListTableComponent,
    ProductListTableInReviewComponent,
    LogImportsComponent,
    ProductListTableResponsableComponent,
    ProductListTableRegisteredsComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    TabModule,
    CardModule,
    PageTitleModule,
    IconModule,
    PaginatorModule,
    InputModule,
    EmptyPatternModule,
    CollapseModule,
    CheckboxModule,
    SelectModule,
    ColumnFilterModule,
    TableSortModule,
    PdmModule,
    DynamicFormConfigModule,
    MediasModule
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule { }
