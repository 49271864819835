import { Injectable } from "@angular/core";
import { ApiHttpClientService } from "../../api-http-client.service";
import { environment } from "src/environments/environment";
import { DadosFiscais } from "src/app/models/api/dados-fiscais/dados-fiscais";
import { EstadoOrdenado } from "src/app/models/api/dados-fiscais/estado";
import { Observable } from "rxjs";
import { CidadeFilial } from "src/app/models/api/dados-fiscais/cidadeFilial";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiDadosFiscaisService {
  private baseUrl = "DadosFiscais";
  private controller: ApiHttpClientService;

  constructor(private api: ApiHttpClientService) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosFiscais)
      .useBaseUrl(this.baseUrl);
  }

  get(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().useLoading().get<DadosFiscais>(id);
  }

  // getAll() {
  //   return this.controller.get<IResponse<DadosFiscais[]>>('');
  // }

  insertOrUpdate(data: DadosFiscais) {
    return this.controller.unwrap().useLoading().post<DadosFiscais>("", data);
  }

  validate(data: DadosFiscais) {
    return this.controller
      .unwrap()
      .useLoading()
      .post<DadosFiscais>("ValidarCadastro", data);
  }

  // delete(id: string) {
  //   if (!id) { throw new Error('Invalid Argument'); }
  //   return this.controller.delete(id);
  // }

  saveDraft(draft: DadosFiscais) {
    return this.controller
      .unwrap()
      .useLoading()
      .post<DadosFiscais>("draft", draft);
  }

  getOrderedStates() {
    return this.controller
      .unwrap()
      .useLoading()
      .get<EstadoOrdenado[]>("BuscarEstadosOrdenados");
  }

  getSupplierBranchesById(fornecedorId: string) {
    const params = new HttpParams().append("fornecedorId", fornecedorId);

    return this.controller
      .unwrap()
      .useLoading()
      .get<CidadeFilial[]>("BuscaFiliaisPorCidadeDoFornecedor", { params });
  }
}
