import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { debounceTime, switchMap, map, tap } from 'rxjs/operators';
import { FornecedorBusca } from 'src/app/models/api/autenticacao/fornecedor';
import { ApiFornecedorService } from 'src/app/services/api/autenticacao/api-fornecedor.service';

@Component({
  selector: 'c-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit {
  totalItems = 0;

  page$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });

  search$ = new BehaviorSubject('');
  data$: Observable<FornecedorBusca[]>;

  constructor(
    private apiFornecedor: ApiFornecedorService
  ) { }

  ngOnInit() {
    this.data$ = combineLatest([
      this.search$.pipe(
        debounceTime(800),
      ),
      this.page$
    ]).pipe(
      switchMap(([query, page]) => this.apiFornecedor.find({
        filtro: query,
        take: page.pageLength,
        skip: (page.page - 1) * page.pageLength
      })),
      tap(response => this.totalItems = response.count),
      map(response => response.data)
    );
  }

  realod() {
    const currentPage = this.page$.value;
    this.page$.next({
      isLastPage: false,
      page: 0,
      total: 0,
      ...currentPage,
    });
  }

}
