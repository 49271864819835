import { Input, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'c-select-option'
})
export class SelectOptionDirective {
  @Input() value: any;
  @Input() description: string;
  @Input() label: string;
  @Input() selected = false;
  @Input() group?: string;
}
