import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiXmlService } from 'src/app/services/api/xml/api-xml.service';
import { TagItem } from 'src/app/models/api/xml/tag-item';
import { catchError, retry } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable()
export class XmlService {

  form: FormGroup;

  tagItens$: Observable<TagItem[]>;
  filtros$: Observable<string[]>;
  operacoes$: Observable<string[]>;
  regras$: Observable<string[]>;

  campos:TagItem[] = []
  csvContent: string;

  periodicidades = [
    {
      name:"Uma vez por mês",
      value:"0 0 1 * *"
    },
    {
      name:"Uma vez por semana",
      value:"0 0 * * 0"
    },
    {
      name:"Uma vez por dia",
      value:"0 0 * * *"
    },
    {
      name:"Duas vezes por dia",
      value:"0 0,12 * * *"
    },
    {
      name:"Três vezes por dia",
      value:"0 0,8,16 * * *"
    },
    {
      name:"A cada hora",
      value:"0 * * * *"
    },
    {
      name:"A cada minuto",
      value:"* * * * *"
    }
  ]

  @IsAllowed('rhcdm.fornecedor.editar') canEdit: boolean;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private apiXml: ApiXmlService,
    private toastService: ToastService,
    private router: Router
  ) {
    this.init();

    this.tagItens$ = this.apiXml.getTags().pipe(map(x => x.data));
    this.filtros$ = this.apiXml.getFiltros().pipe(map(x => x.data));
    this.operacoes$ = this.apiXml.getOperacoes().pipe(map(x => x.data));
    this.regras$ = this.apiXml.getOperadores().pipe(map(x => x.data));

    let self = this;
    this.tagItens$.subscribe(tags=>{
      self.campos = tags;
      this.activatedRoute.data.subscribe(({ xml }: { xml: Partial<any> }) => {
        console.log(xml)
        this.setXmlData(xml)
      });
    })



  }

  private init() {
    this.form = this.createForm();
  }

  private setXmlData(xml: Partial<any>){
    if(typeof xml !== 'undefined'){
      this.form.reset();
      this.form.patchValue(xml);
      if (xml && xml.tags && this.tagsForm) {
        xml.tags.forEach( (f, i) => {
          const item = this.createTagForm();
          item.patchValue(f);
          this.tagsForm.insert(i, item);
        });
      }
      if (xml && xml.filtros && this.filtrosForm) {
        xml.filtros.forEach( (f, i) => {
          f.campo = this.campos.find(c => c.nomeCampo == f.campo).nomeDescritivo
          const item = this.createFiltroForm(f.operacaoLogica);
          item.patchValue(f);
          this.filtrosForm.insert(i, item);
        });
      }
    }
  }

  countValues(index){
    let valor = this.filtrosForm.value[index].operacao.valor;
    if (typeof valor !== "string"){return valor.length}
    if (valor !== null){return valor.split("\n").length }
    else{ return 0 }
  }

  removeFile(index){
    (this.filtrosForm as any).controls[index].controls.operacao.controls.valor.setValue("");
  }

  onFileSelect(input: HTMLInputElement,index:number) {
    let self = this;
    const files = input.files;
    var content = this.csvContent;
    if (files && files.length) {
        const fileToRead = files[0];
        const fileReader = new FileReader();
        fileReader.onload = function(fileLoadedEvent:any){
          const textFromFileLoaded = fileLoadedEvent.target.result;
          (self.filtrosForm as any).controls[index].controls.operacao.controls.valor.setValue(textFromFileLoaded);
        };
        fileReader.readAsText(fileToRead, "UTF-8");
    }
  }

  get readonly() {
    return this.form.get('id').value ? !this.canEdit : false;
  }

  get canFinalizarCadastro() {
    return !this.form.get('id').value;
  }

  get canSalvarCadastro() {
    return !!this.form.get('id').value && this.canEdit;
  }

  addTag(){
    this.tagsForm.push(this.createTagForm());
  }

  removeTag(index: number){
    this.tagsForm.removeAt(index);
  }

  newFiltro(operacao){
    this.filtrosForm.push(this.createFiltroForm(operacao));
  }

  removeFiltro(index){
    this.filtrosForm.removeAt(index);
  }

  checkForErrors(value) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.toast('Formulário esta inválido', ToastType.error);
      return true;
    } else if (value.tags.length == 0){
      this.toastService.toast('Pelo menos uma TAG é obrigatória', ToastType.error);
      return true;
    } else {
      return false;
    }
  }

  private getFormValue(){

    let value = this.form.value;

    if (value.id == null) {
      delete value.id;
    }

    value.nomeArquivo = value.nomeDoProcesso;
    for(let tag of value.tags){
      let campo = this.campos.find(c => c.nomeDescritivo == tag.nomeDescritivo)
      if(typeof campo !== 'undefined'){
        tag.nomeCampo = campo.nomeCampo;
        tag.argumento = tag.argumento == null ? null : tag.argumento.toString();
      }
    }
    for(let [i, filtro] of value.filtros.entries()){
      filtro.indice = i;  
      let campo = this.campos.find(c => c.nomeDescritivo == filtro.campo);
      if(typeof campo !== 'undefined'){
        filtro.campo = campo.nomeCampo;
      }

      if(typeof filtro.operacao.valor === "string"){
        filtro.operacao.valor = filtro.operacao.valor.replaceAll("\r","").split("\n");
      }
      
    }

    return value;

  }

  salvar(){

    let value = this.getFormValue()

    if(this.checkForErrors(value)){
      return;
    }

    console.log(value)
    console.log(JSON.stringify(value))

    if(typeof value.id === 'undefined'){
      this.apiXml.save(value).subscribe( res => {
        this.form.reset();
        this.router.navigateByUrl('/');
      });
    } else {
      this.apiXml.update(value).subscribe( res => {
        this.form.reset();
        this.router.navigateByUrl('/');
      });
    }


  }

  private checkFormErrors() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.toast('Formulário esta inválido', ToastType.error);
    }
  }

  private createForm() {
    return this.fb.group({
      id: [undefined],
      nomeDoProcesso: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      periodicidade: [undefined, [Validators.required]],
      tagRaiz: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      tagSubraiz: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      tagItem: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      utm_Source: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      utm_Campaign: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      utm_Medium: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      tags: this.createTagsForm(),
      filtros: this.createFiltrosForm()
    });
  }

  get tagsForm():FormArray {
    return this.form && this.form.get('tags') as FormArray;
  }
  get filtrosForm():FormArray {
    return this.form && this.form.get('filtros') as FormArray;
  }

  get numFiltros(){
    return this.filtrosForm.controls.length;
  }

  get camposEscolhidos(){
    return this.tagsForm.value.filter(c => c.nomeDescritivo !== null)
  }

  private createTagsForm() {
    return this.fb.array([]);
  }

  private createFiltrosForm() {
    return this.fb.array([]);
  }

  private createTagForm() {
    return this.fb.group({
      nomeTag: [undefined, [Validators.required, Validators.pattern(/^\S*$/)]],
      nomeCampo: [undefined],
      argumento: [undefined],
      nomeDescritivo: [undefined, [Validators.required]],
      filtro: [true, [Validators.required]],
      layout: [true, [Validators.required]]
    });
  }

  private createFiltroForm(operacao) {
    return this.fb.group({
      campo: [undefined, [Validators.required]],
      operacao: this.createOperacaoForm(),
      operacaoLogica: [operacao, [Validators.required]],
    });
  }

  private createOperacaoForm() {
    return this.fb.group({
      tipo: [undefined, [Validators.required]],
      valor: [undefined, [Validators.required]]
    });
  }

}
