import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHttpClientService } from '../../api-http-client.service';
import { tap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiImportadorPlanilhaService {

  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.importador);
  }

  export(id: string) {
    return this.controller.useLoading().get(`Planilha/${id}`, { responseType: 'blob' });
  }

}
