import { Injectable } from '@angular/core';
import { Produto } from 'src/app/models/api/dados-produto/produto';
import { environment } from 'src/environments/environment';
import { ApiHttpClientService } from '../../api-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDadosProdutosService {

  private baseUrl = 'DadosProdutos';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosProdutos)
      .useBaseUrl(this.baseUrl);
  }

  createProductId() {
    return this.controller.unwrap().useLoading().post<Produto>('', null);
  }

  get(id: string) {
    return this.controller.unwrap().useLoading().get<Produto>(id);
  }

  assignResponsible(id: string) {
    return this.controller.unwrap().useLoading().post<Produto>(`AtribuirResponsavel/${id}`, {});
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().delete(id);
    // return of(null);
  }

}
