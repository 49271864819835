import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AreYouSureModule } from 'src/app/shared/directives/are-you-sure/are-you-sure.module';
import { IconModule } from '../icon/icon.module';
import { CookiesComponent } from './cookies.component';



@NgModule({
  declarations: [CookiesComponent],
  imports: [
    CommonModule,
    IconModule,
    AreYouSureModule
  ],
  exports: [CookiesComponent]
})
export class CookiesModule { }
