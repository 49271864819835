import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { IResponse } from 'src/app/models/api/response.interface';
import { BehaviorSubject } from 'rxjs';
import { skip, debounceTime } from 'rxjs/operators';
import { PaginatorComponent } from 'src/app/ui/paginator/paginator.component';
import { Usuario } from 'src/app/models/api/autenticacao/usuario';
import { ApiFuncionarioService } from 'src/app/services/api/autenticacao/api-funcionario.service';

@Component({
  selector: 'c-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements AfterViewInit {

  @ViewChild('paginator', { static: true }) paginator: PaginatorComponent;

  userData: IResponse<Usuario[]>;
  columns: string[] = ['name', 'email', 'actions'];
  search$ = new BehaviorSubject('');

  constructor(
    private apiFuncionarioService: ApiFuncionarioService
  ) {
    this.configureSearch();
  }

  ngAfterViewInit() {
    this.loadUserData();
  }

  loadUserData() {
    this.apiFuncionarioService.getAll(this.createQueryFilter()).subscribe(res => {
      this.userData = res;
    });
  }

  createQueryFilter() {
    return {
      query: this.search$.value,
      skip: (this.paginator.page - 1) * this.paginator.pageLength,
      take: this.paginator.pageLength
    };
  }

  configureSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe(() => {
      this.loadUserData();
    });
  }

}
