import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'c-core-notification',
  templateUrl: './core-notification.component.html',
  styleUrls: ['./core-notification.component.scss']
})
export class CoreNotificationComponent implements OnInit {

  @Input() isVisible: boolean;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.notificationService.toggle$.subscribe(res => {
      this.isVisible = res;
    });
  }

  toggle() {
    this.isVisible = !this.isVisible;
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    if (this.isVisible) {
      this.notificationService.toggle();
    }
    this.isVisible = false;
  }

}
