import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupplierRoutingModule } from './supplier-routing.module';
import { SupplierComponent } from './supplier.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { RadioModule } from 'src/app/ui/radio/radio.module';
import { RangeModule } from 'src/app/ui/range/range.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { CityStateDirective } from './city-state.directive';
import { ModalModule } from 'src/app/ui/modal/modal.module';


@NgModule({
  declarations: [SupplierComponent, CityStateDirective],
  imports: [
    CommonModule,
    SupplierRoutingModule,
    SharedModule,
    InputModule,
    SelectModule,
    CheckboxModule,
    RadioModule,
    RangeModule,
    CardModule,
    IconModule,
    ModalModule,
  ]
})
export class SupplierModule { }
