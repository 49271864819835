import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputInfoComponent } from './input-info.component';



@NgModule({
  declarations: [InputInfoComponent],
  imports: [
    CommonModule
  ],
  exports: [
    InputInfoComponent
  ]
})
export class InputInfoModule { }
