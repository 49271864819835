/* tslint:disable:no-string-literal no-redundant-jsdoc */

/**
 * Referencias ultilizadas
 * - https://itnext.io/angular-create-your-own-modal-boxes-20bb663084a1
 * - https://medium.com/@ole.ersoy/rendering-a-string-into-a-dynamic-components-ng-content-element-810d9e074087
 */

import { Injectable, ApplicationRef, Injector, ComponentFactoryResolver, Inject, Type, TemplateRef, ViewContainerRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type Content<T> = string | TemplateRef<T> | Type<T>;
export interface Config<T, C> {
  component: Type<T>;
  content?: Content<C>;
  initialState?: Partial<T>;
  contentInitialState?: Partial<C>;
  /** @default bodyElement */
  container?: HTMLElement | string | ViewContainerRef;
  injector?: Injector;
  resolver?: ComponentFactoryResolver;
}

@Injectable({
  providedIn: 'root'
})
export class ComponentLoaderService {

  private elementContainer = this.document.body;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) private document: Document
  ) { }

  create<T, C>(config: Config<T, C>) {
    const {
      component,
      content,
      initialState,
      contentInitialState,
      container,
      injector,
      resolver,
    } = config;

    // cria a factory do component container (que pode ser a modal padrão)
    const factory = (resolver || this.resolver).resolveComponentFactory(component);
    // Cria o ngContent, e traz o componentRef caso
    const { componentRef: contentComponentRef, embeddedViewRef, ngContent } = this.resolveNgContent(content, contentInitialState, resolver);
    // cria o componente container (modal) com o ngContent passado
    const componentRef = factory.create(injector || this.injector, ngContent);
    // função ultilizada para destruir o componente
    const destroyFnFactory = (from: 'container' | 'content' | 'outside') => {
      return () => {
        if (contentComponentRef && contentComponentRef.instance['beforeDestroy']) {
          contentComponentRef.instance['beforeDestroy'](from);
        }
        if (componentRef.instance['beforeDestroy']) {
          componentRef.instance['beforeDestroy'](from);
        }
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      };
    };
    // atribui função de destruição no container e no content (modal)
    componentRef.instance['destroy'] = destroyFnFactory('container');
    if (contentComponentRef) {
      contentComponentRef.instance['destroy'] = destroyFnFactory('content');
    } else if (embeddedViewRef) {
      // revalidar -> talvez precise chamar a detecção de mudança
      Object.assign(embeddedViewRef.context, { destroy: destroyFnFactory('content') });
    }
    // atribui initial state
    Object.assign(componentRef.instance, initialState);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);
    // containerCmpRef.hostView.detectChanges();

    const { nativeElement } = componentRef.location;
    // Adiciona o elemento do componente no Body, ou em algum elemento passado por parametro
    this.resolveElementContainer(container).appendChild(nativeElement);

    return {
      componentRef,
      contentComponentRef,
      destroy: destroyFnFactory('outside')
    };
  }

  private resolveNgContent<T>(content: Content<T> = '', initialState?: T, resolver?: ComponentFactoryResolver) {
    if (typeof content === 'string') {
      const element = this.document.createTextNode(content);
      return {
        ngContent: [[element]],
      };
    }

    if (content instanceof TemplateRef) {
      // Cria viewRef com initial state
      const embeddedViewRef = content.createEmbeddedView(initialState);
      // Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(embeddedViewRef);
      return {
        ngContent: [embeddedViewRef.rootNodes],
        embeddedViewRef,
      };
    }

    /** Otherwise it's a component */
    const factory = (resolver || this.resolver).resolveComponentFactory(content);
    const componentRef = factory.create(this.injector);

    // atribui initial state
    Object.assign(componentRef.instance, initialState);
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    return {
      ngContent: [[componentRef.location.nativeElement]],
      componentRef,
    };
  }

  private resolveElementContainer(container?: HTMLElement | string | ViewContainerRef) {
    if (container) {
      if (typeof container == 'string') {
        const element = this.document.querySelector(container) as HTMLElement;
        if (!element) { throw new Error('Invalid selector for the container'); }
        return element;
      } else if (this.isViewContainerRef(container)) {
        return this.resolveElementContainer(container.element.nativeElement);
      } else {
        return container;
      }
    } else {
      return this.elementContainer;
    }
  }

  private isViewContainerRef(vcr: any): vcr is ViewContainerRef {
    return vcr && vcr.element && vcr.element.nativeElement;
  }

}
