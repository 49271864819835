import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ElementRef,
  Injector,
  HostListener,
  ViewChild,
  Optional,
  ChangeDetectionStrategy
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';

@Component({
  selector: 'c-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent extends ControlValueAccessorMixin implements OnInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() prefix: string;
  @Input() sufix: string;
  @Input() type = 'text';
  @Input() rows = 1;
  @Input() textMask: string;
  @Input() icon: string;
  @Input() step = 0.1;
  @Input() info: string;
  @Input() red: boolean;

  @ViewChild('input1', { static: false }) input1: ElementRef<HTMLInputElement>;
  @ViewChild('input2', { static: false }) input2: ElementRef<HTMLInputElement>;
  @ViewChild('input3', { static: false }) input3: ElementRef<HTMLInputElement>;
  @ViewChild('input4', { static: false }) input4: ElementRef<HTMLInputElement>;
  @ViewChild('input5', { static: false }) input5: ElementRef<HTMLInputElement>;
  @ViewChild('input6', { static: false }) input6: ElementRef<HTMLInputElement>;

  valueAsNumber(): number | undefined {
    return typeof (+this.value) === 'number' ? this.value : undefined;
  }

  valueAsNumberTax(): number | undefined {

    if (this.value === undefined){
      this.value = 0;
      return this.value
    }
    else{
      return typeof (+this.value) === 'number' ? this.value : undefined;
    }
  }

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  ngOnInit() {
  }

  @HostListener('focus')
  focus() {
    const input = this.input1 || this.input2 || this.input3 || this.input4 || this.input5 || this.input6;
    if (input) {
      input.nativeElement.focus();
    }
    super.focus();
  }

}
