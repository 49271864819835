import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFieldDirective } from './dynamic-field.directive';



@NgModule({
  declarations: [DynamicFormComponent, DynamicFieldDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    DynamicFormComponent,
    DynamicFieldDirective,
  ]
})
export class DynamicFormModule { }
