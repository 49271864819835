import { ApiDadosBasicosService } from 'src/app/services/api/dados-basicos/api-dados-basicos.service';
import { ApiDadosEspecificosService } from 'src/app/services/api/dados-especificos/api-dados-especificos.service';
import { AsyncValidator, AbstractControl } from '@angular/forms';
import { tap, mapTo, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of, merge, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineEanValidator {
  constructor(
    private apiDadosBasicos: ApiDadosBasicosService,
    private apiDadosEspecificos: ApiDadosEspecificosService,
  ) { }

  validate(productId?: string | (() => string)) {
    return (control: AbstractControl) => {
      const value: string = control.value;
      const id = typeof productId == 'string' ? productId : productId && productId();

      if (value && id) {
        return combineLatest([
          this.apiDadosBasicos.validateEan(value, id),
          this.apiDadosEspecificos.validateEan(value, id),
        ]).pipe(
          map( ([dadosBasicosError, dadosEspecificosError]) => {
            if (dadosBasicosError) {
              return {
                custom: dadosBasicosError
              };
            }

            if (dadosEspecificosError) {
              return {
                custom: dadosEspecificosError
              };
            }

            return null;
          })
        );
      }

      return of(null);
    };
  }
}

