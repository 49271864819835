import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Funcionario } from 'src/app/models/api/autenticacao/funcionario';
import { IResponse } from 'src/app/models/api/response.interface';
import { QueryFilter } from 'src/app/models/api/query/query-filter.interface';
import { Usuario } from 'src/app/models/api/autenticacao/usuario';

@Injectable({
  providedIn: 'root'
})
export class ApiFuncionarioService {

  private baseUrl = 'Funcionario';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
  }

  getAll(query?: QueryFilter) {
    const filtro = query ? query.query : '';
    delete query.query;

    return this.controller
      .useLoading()
      .post<IResponse<Funcionario[]>>('busca', {
        filtro,
        skip: 0,
        take: 0,
        filtrosPesquisados: [
          0,
          1
        ],
        ...query
      });
  }

  getById(id: string) {
    return this.controller
      .useLoading()
      .unwrap()
      .get<Funcionario>(id);
  }

  getUserByEmployeeId(id: string) {
    return this.controller
      .useLoading()
      .unwrap()
      .get<Usuario>(`Usuario/${id}`);
  }

  insert(data: Funcionario) {
    return this.controller.unwrap().post<Funcionario>('', data);
  }

  update(data: Funcionario) {
    return this.controller.unwrap().put<Funcionario>('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.delete(id);
  }

}
