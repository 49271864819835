import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { GrupoPermissao } from 'src/app/models/api/autenticacao/grupo-permissao.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiGrupoPermissaoService {

  private baseUrl = 'GrupoPermissao';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
  }

  get() {
    return this.controller.useLoading().unwrap().get<GrupoPermissao[]>('');
  }

  getByName(name: string) {
    return this.controller.useLoading().unwrap().get<GrupoPermissao[]>(`nome?nome=${name}`);
  }

  update(data: GrupoPermissao) {
    return this.controller.useLoading().put('', data);
  }

  create(data: GrupoPermissao) {
    return this.controller.useLoading().post('', data);
  }

}
