import { Component, OnInit, Input, ElementRef, Injector, forwardRef, Optional } from '@angular/core';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';
import { XhrFactory } from '@angular/common';

@Component({
  selector: 'c-video-link-uploader',
  templateUrl: './video-link-uploader.component.html',
  styleUrls: ['./video-link-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoLinkUploaderComponent),
      multi: true
    }
  ]
})
export class VideoLinkUploaderComponent extends ControlValueAccessorMixin implements OnInit {

  @Input() label: string;

  videos: VideoData[];

  private draggingItemIndex: number;
  private latestEnteredItem: VideoData;
  linkControl = new FormControl(null);

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  ngOnInit() {
  }

  writeValue(value: string[]) {
    if (value) {
      value.forEach(elem => {
        this.addVideo(elem);
      });
    }
  }

  dragEnterItem(e: DragEvent, i: number, data: VideoData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();

      if (i != this.draggingItemIndex) {
        if (this.latestEnteredItem) {
          this.latestEnteredItem.isBorderVisible = false;
        }

        data.isBorderVisible = true;

        this.latestEnteredItem = data;
      }
    }
  }

  dragLeaveItem(e: DragEvent, i: number, data: VideoData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();

      if (i != this.draggingItemIndex) {
        data.isBorderVisible = undefined;
      }
    }
  }

  dropItem(e: DragEvent, i: number, data: VideoData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();

      if (i != this.draggingItemIndex) {
        // Change item position
        const draggingItem: VideoData = this.videos[this.draggingItemIndex];
        this.videos.splice(this.draggingItemIndex, 1);
        this.videos.splice(i, 0, draggingItem);

        this.draggingItemIndex = undefined;
        data.isBorderVisible = undefined;

        this.updateValue();
      }
    }
  }

  dragOverItem(e: DragEvent) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  dragStartItem(e: DragEvent, i: number, data: VideoData) {
    e.stopPropagation();
    this.draggingItemIndex = i;
  }

  dragEndItem(e: DragEvent, i: number, data: VideoData) {
    if (!e.dataTransfer.types.length) {
      e.preventDefault();
      e.stopPropagation();
      this.closeAllLeftAndRightItems();
    }
  }

  removeVideo(i: number) {
    this.videos.splice(i, 1);
    this.updateValue();
  }

  addVideo(url: string) {
    if (!this.videos) {
      this.videos = new Array();
    }

    this.videos.push({
      link: this.convertYoutubeLinkToEmbedLink(url),
      isBorderVisible: false
    });
    this.updateValue();

    this.linkControl.reset();
  }

  private updateValue() {
    this.blur();

    this.value = this.videos.slice().map(elem => {
      return elem.link;
    });
  }

  private closeAllLeftAndRightItems() {
    this.videos.forEach(elem => {
      elem.isBorderVisible = false;
    });
  }

  private convertYoutubeLinkToEmbedLink(link: string): string {
    
    if (!(isYoutubeVideo(link) && linkYoutubeSucess(link))){
      throw new Error('Link do Youtube não é válido!');
    }
    
    const url = new URL(link.trim());
    const urlDefault = 'https://www.youtube.com/embed';

    if (url.hostname.indexOf('youtube.com') > -1) {
      let videoId: string;

      const params = new URLSearchParams(url.search);
      if (params && params.get('v')) {
        videoId = params.get('v');
      } else {
        videoId = url.search.split('=')[1];

        if (!videoId) {
          videoId = url.pathname.split('/')[2];
        }
      }

      return `${urlDefault}/${videoId}`;
    }

    if (url.hostname === 'youtu.be') {
      return `${urlDefault}/${url.pathname.split('/')[1]}`;
    }

    return link;
  }

}

function isYoutubeVideo(url) {
  var v = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return (url.match(v)) ? true : false;
}

function linkYoutubeSucess(theUrl)
{
  var httpRequest = new XMLHttpRequest();
  httpRequest.open('GET', theUrl, false);
  httpRequest.send;

  return httpRequest.onreadystatechange = function(){
    return (httpRequest.readyState ==4 && httpRequest.status ==200) ? true : false
  }
}

interface VideoData {
  link: string;
  isBorderVisible: boolean;
}
