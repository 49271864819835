import { Component, OnInit } from '@angular/core';
import { DynamicFieldEditorService } from './dynamic-field-editor.service';
import { FormGroup } from '@angular/forms';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { EnumCampoDinamicoType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-type.enum';
import { icons } from './icons';

@Component({
  selector: 'c-dynamic-field-editor',
  templateUrl: './dynamic-field-editor.component.html',
  styleUrls: ['./dynamic-field-editor.component.scss'],
  providers: [
    DynamicFieldEditorService
  ]
})
export class DynamicFieldEditorComponent implements OnInit {
  EnumCampoDinamicoType = EnumCampoDinamicoType;
  icons = icons;
  constructor(
    public dynamicFieldEditorService: DynamicFieldEditorService,
    public confirmDialogService: ConfirmDialogService,

  ) { }

  canDeactivate() {
    /*this.dynamicFieldEditorService.readonly ||*/

    return this.dynamicFieldEditorService.form.pristine ||
      this.confirmDialogService.confirm('Tem certeza que deseja sair sem salvar?');
  }

  ngOnInit() {
  }

}
