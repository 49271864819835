import {
  Component,
  OnInit,
  forwardRef,
  ElementRef,
  HostListener,
  Injector,
  Optional,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';
import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';

@Component({
  selector: 'c-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends ControlValueAccessorMixin implements OnInit {

  @HostBinding('attr.tabindex') get tabindex() {
    return (this.control && this.control.disabled) || this.isReadOnly ? '-1' : '0';
  }

  @Input() name: string;

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective: ReadOnlyDirective,
    @Optional() private checkboxGroupComponent: CheckboxGroupComponent,
    private cdr: ChangeDetectorRef
  ) {
    super(elementRef, injector, readOnlyDirective);
    this.innerValue = false;
  }

  ngOnInit() {
    if (this.checkboxGroupComponent) {
      this.checkboxGroupComponent.createAndConnectFormControlWithCustomControlAndFormControl(this);
    }
  }

  @HostListener('click')
  @HostListener('keyup.enter')
  toggle() {
    if (!this.control || this.control && this.control.enabled && !this.isReadOnly) {
      this.value = !this.value;
      this.onTouchedCallback();
    }
  }

  writeValue(value: any) {
    super.writeValue(value);
    this.cdr.detectChanges();
  }

}
