import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { IconModule } from '../icon/icon.module';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [
    CheckboxComponent,
    CheckboxGroupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IconModule,
    InputErrorModule
  ],
  exports: [
    CheckboxComponent,
    CheckboxGroupComponent
  ]
})
export class CheckboxModule { }
