import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'c-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss']
})
export class TabItemComponent implements OnInit {

  @Input() value: any;
  @Input() selected = false;
  @Input() icon: string;

  constructor(
    public tab: TabComponent
  ) { }

  ngOnInit() {
    if (this.selected) {
      this.toggle();
    } else if (this.tab.value == undefined) {
      this.toggle();
    }
  }

  @HostListener('click')
  toggle() {
    this.tab.value = this.value;
  }

}
