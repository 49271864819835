import { Injectable } from '@angular/core';
import { Historico } from 'src/app/models/api/historico/historico.interface';

@Injectable()
export class ProductHistoricService {

  productId: string;
  data: Historico;

  constructor(
  ) {
  }

  setValue(productId: string, value: Historico) {
    this.productId = productId;
    this.data = value;
  }

}
