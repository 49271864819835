import { Injectable, Injector, ComponentFactoryResolver } from '@angular/core';
import { ModalService } from 'src/app/ui/modal/modal.service';
import { ProductLogsModalComponent } from '../product-logs-modal/product-logs-modal.component';

@Injectable()
export class LogsModalService {

  constructor(
    private modalService: ModalService,
    // private injector: Injector,
    // private resolver: ComponentFactoryResolver,
  ) { }

  openLogs(productId: string) {
    return this.modalService.open(ProductLogsModalComponent, {
      contentInitialState: {
        productId
      },
      initialState: {
        type: 'large',
      }
      // resolver: this.resolver,
      // injector: this.injector
    });
  }


}
