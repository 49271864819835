import { Directive, Output, EventEmitter, Input, HostListener, Inject, OnDestroy } from '@angular/core';
import { isObservable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QUESTION_DIALOG_FN, QuestionDialogFn } from './are-you-sure.model';
import { AreYouSureDialog } from './are-you-sure-dialog.class';

@Directive({
  selector: '[areYouSure]'
})
export class AreYouSureDirective implements OnDestroy {
  private destroy$ = new Subject();

  @Input() areYouSure: string;

  @Output() then = new EventEmitter();
  @Output() else = new EventEmitter();

  private emit = (value: boolean) => {
    if (value) {
      this.then.emit();
    } else {
      this.else.emit();
    }
  }

  constructor(
    private dialog: AreYouSureDialog
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  @HostListener('click')
  onClick() {
    const value = this.dialog.confirm(this.areYouSure);

    if (isObservable(value)) {
      return value.pipe(takeUntil(this.destroy$)).subscribe(this.emit);
    } else if (value instanceof Promise) {
      value.then(this.emit);
    } else if (typeof value === 'boolean') {
      this.emit(value);
    } else {
      throw new Error('Argumento invalido \nQUESTION_DIALOG_FN deve retornar no padrão do tipo QuestionDialogFn');
    }
  }
}
