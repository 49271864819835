import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';

import { Licenca } from 'src/app/models/api/comercial/licenca';
import { ApiLicencaService } from 'src/app/services/api/comercial/api-licenca-service';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';

@Component({
  selector: 'c-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {
  licenseData: LicenseEditable[];
  licensePaginatedData: LicenseEditable[];
  licenseFilteredData: LicenseEditable[];
  columns: string[] = ['name', 'active', 'actions'];
  form: FormGroup;
  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });
  search$ = new BehaviorSubject('');
  constructor(
    private apiLicenca: ApiLicencaService,
    private fb: FormBuilder,
  ) {
    this.createForm();
    this.listenPaginator();
    this.listenSearch();
  }

  ngOnInit() {
    this.loadLicenseData();
  }

  createForm() {
    this.form = this.fb.group({
      descricao: [null],
      ativo: [null]
    });
  }

  loadLicenseData() {
    this.apiLicenca.getAllAtivosInativos().subscribe(res => {
      this.licenseData = res.data as LicenseEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: LicenseEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = true;
  }

  cancelEdit(data: LicenseEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = false;
  }

  async save(data: LicenseEditable) {
    if (!data) {
      const license: LicenseEditable = this.form.value;
      license.descricao = license.descricao;
      license.ativo = true;

      await this.apiLicenca.insert(license).toPromise();
      this.form.reset();
      this.loadLicenseData();
    } else {
      const description = data.newDescription;

      await this.apiLicenca.update({
        id: data.id, descricao: description, ativo: data.ativo
      }).toPromise();
      data.descricao = description;
      data.isEditActive = false;
    }
  }

  async changeEnableDisable(data: LicenseEditable) {
    try {
      await this.apiLicenca.delete(data.id).toPromise();
    } catch {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.licenseFilteredData = this.licenseData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          const license = regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          return license;
        });
      } else {
        this.licenseFilteredData = undefined;
      }
    });
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.licenseData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.licensePaginatedData = this.licenseData.slice(startAt, startAt + data.pageLength);
    }
  }

  get canCreate() {
    return this.descricao.value;
  }
  get descricao() { return this.form.get('descricao'); }
}

interface LicenseEditable extends Licenca {
  isEditActive: boolean;
  newDescription: string;
}