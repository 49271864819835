import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { IResponse } from 'src/app/models/api/response.interface';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiDepartamentoService {

  private baseUrl = 'Departamento';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.useLoading().get<IResponse<Departamento[]>>('');
  }

  getAllAtivosInativos() {
    return this.controller.useLoading().get<IResponse<Departamento[]>>('/BuscarAtivosInativos');
  }

  insert(data: Departamento) {
    return this.controller.useLoading().post('', data);
  }

  update(data: Departamento) {
    return this.controller.useLoading().put('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().delete(id);
  }
}
