import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';
import { ColumnFilterComponent } from '../column-filter.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'c-column-filter-item',
  templateUrl: './column-filter-item.component.html',
  styleUrls: ['./column-filter-item.component.scss']
})
export class ColumnFilterItemComponent implements OnInit {

  @HostBinding('attr.tabindex') tabindex = '0';
  @Input() value: string | number;
  self = this;
  writeValue$: Subscription;

  constructor(
    public columnFilterComponent: ColumnFilterComponent
  ) { }

  ngOnInit() {
    this.listenWriteValue();
  }

  @HostListener('click', ['$event'])
  @HostListener('keyup.enter', ['$event'])
  toggle() {
    this.columnFilterComponent.toggleItem(this);
  }

  private listenWriteValue() {
    this.writeValue$ = this.columnFilterComponent.writeValue$.subscribe((res: any) => {
      this.writeValue(res);
    });
  }

  private writeValue(data: any) {
    if (data.includes(this.value)) {
      this.columnFilterComponent.selectedItems.add(this);
    }
  }

}
