import { Component, OnInit } from '@angular/core';
import { ProductSpecificDataService } from '../../services/product-specific-data.service';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { ApiSubnivelService } from 'src/app/services/api/dados-especificos/api-subnivel.service';
import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { PdmDescriptionBuilderService } from '../../services/pdm-description-builder.service';
import { ProductSpecificDataSubnivelService } from '../../services/product-specific-data-subnivel.service';

@Component({
  selector: 'c-subnivel',
  templateUrl: './subnivel.component.html',
  styleUrls: ['./subnivel.component.scss']
})
export class SubnivelComponent implements OnInit {

  constructor(
    public specificDataService: ProductSpecificDataService,
    public apiDepartamento: ApiDepartamentoService,
    public apiCategoria: ApiCategoriaService,
    public apiSubnivel: ApiSubnivelService,
    public apiCampoDinamico: ApiCampoDinamicoService,
    public pdmDescriptionBuilder: PdmDescriptionBuilderService,
    public subnivelService: ProductSpecificDataSubnivelService,
  ) { }
  ngOnInit() {
  }
}
