import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { HistoricoAprovarOuReprovar } from 'src/app/models/api/historico/historico-approvar-reprovar.interface';
import { HistoricoNovaMensagem } from 'src/app/models/api/historico/historico-nova-mensagem.interface';
import { HistoricoAprovarOuReprovarNovaAba } from 'src/app/models/api/historico/historico-approvar-reprovar-nova-aba.interface';
import { HistoricoNovo } from 'src/app/models/api/historico/historico-novo.interface';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { Historico } from 'src/app/models/api/historico/historico.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiHistoricoService {

  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.historico);
  }

  /**
   * Create a answer to historic
   */
  createAsnwer(data: HistoricoNovaMensagem) {
    return this.controller.useLoading().unwrap().post<Historico>(`Historico/AdicionarNovaMensagem`, data);
  }

  /**
   * Change the tab approved status if the tab exists
   * To use this method the tab need be added before
   */
  approveOrReproveTab(data: HistoricoAprovarOuReprovar) {
    return this.controller.useLoading().unwrap().post<any>(`Historico/AprovarDesaprovarAba`, data);
  }

  /**
   * If the historic exists and the tab not, create a new tab on historic
   */
  approveOrReproveNewTab(historicId: string, reason: string, tab: EnumAba, approved: boolean, idFornecedor: string, referencia: string) {
    const data: HistoricoAprovarOuReprovarNovaAba = {
      historicoId: historicId,
      aprovado: approved,
      aba: tab,
      fornecedorId: idFornecedor,
      referencia: referencia,
      mensagensList: [
        {
          mensagem: reason
        }
      ]
    };

    return this.controller.useLoading().unwrap().post<any>(`Historico/AdicionarNovaAba`, data);
  }

  create(historicId: string, reason: string, tab: EnumAba, approved: boolean) {
    const data: HistoricoNovo = {
      id: historicId,
      historicoAbaList: [{
        aprovado: approved,
        aba: tab,
        mensagensList: [
          {
            mensagem: reason
          }
        ]
      }]
    };

    return this.controller.useLoading().unwrap().post<any>(`Historico`, data);
  }

  get(id: string) {
    return this.controller.unwrap().useLoading().get<any>(`Historico/${id}`);
  }

  releaseProduct(id : string){
    return this.controller.unwrap().useLoading().get(`Historico/NotificarLiberacaoProduto/${id}`);
  }

}
