import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Usuario } from "src/app/models/api/autenticacao/usuario";
import { EnumAba } from "src/app/models/api/produto/aba.enum";
import { ApiUsuarioService } from "src/app/services/api/autenticacao/api-usuario.service";
import { SessionService } from "src/app/services/session.service";
import { ToastType } from "src/app/ui/toast/toast.model";
import { ToastService } from "src/app/ui/toast/toast.service";
import { ProductTaxDataService } from "../services/product-tax-data.service";
import { ProductService } from "../services/product.service";

@Component({
  selector: "c-product-tax-data",
  templateUrl: "./product-tax-data.component.html",
  styleUrls: ["./product-tax-data.component.scss"],
})
export class ProductTaxDataComponent implements OnInit {
  private destroy$ = new Subject();
  enumAba = EnumAba;
  selectedFilialIndex = 0;

  user: Usuario;
  isFornecedor = false;

  constructor(
    public productService: ProductService,
    private apiUsuarioService: ApiUsuarioService,
    public taxDataService: ProductTaxDataService,
    public sessionService: SessionService,
    private toastService: ToastService,
  ) { }

  async ngOnInit() {
    this.user = this.sessionService.user;
    this.user.id = this.sessionService.user.id;
    this.user.gruposPermissaoIdList = this.sessionService.user.permissions;
    this.user.login = this.sessionService.user.email;
    this.user.nomeFantasiaFornecedor = this.sessionService.user.fantasyName;
    this.user.sapFornecedor = this.sessionService.user.sapCode;
    this.isFornecedor = this.sessionService.user.role !== "funcionario" ? true : false;
    
    // this.user = await this.apiUsuarioService.getByLogin(this.sessionService.user.email).toPromise() as Usuario;
    // this.isFornecedor = this.user.fornecedorId !== null && this.user.funcionarioId == null ? true : false;

    this.taxDataService.filterFilial();

    this.taxDataService.filiaisFiltered$.subscribe(result => {
      if (result.length <= 0) {
        this.toastService.toast('Nenhuma filial válida encontrada, verifique o seu cadastro de fornecedor!', ToastType.error);
      }
    });
  }
}
