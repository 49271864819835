import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'c-input-group-container',
  templateUrl: './input-group-container.component.html',
  styleUrls: ['./input-group-container.component.scss']
})
export class InputGroupContainerComponent implements OnInit {

  @Input() isRequired: boolean;
  @Input() label: string;

  @Input() disableValidation = false;

  get isDisabled() {
    return !this.controlContainer.control.enabled;
  }

  get isFormInvalid() {
    return this.controlContainer.control.invalid && this.controlContainer.control.touched;
  }

  constructor(
    public controlContainer: ControlContainer,
    private ref: ElementRef,
  ) { }

  ngOnInit() {
  }

}
