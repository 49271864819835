import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Produto } from 'src/app/models/api/dados-produto/produto';
import { environment } from 'src/environments/environment';
import { ApiImportadorPlanilhaService } from 'src/app/services/api/importador/api-importador-planilha.service';
import { ApiDadosProdutosService } from 'src/app/services/api/dados-produto/api-dados-produtos.service';
import { EnumElasticDashboardColumns } from 'src/app/models/api/elastic/elastic-dashboard-columns.enum';
import { ApiHistoricoService } from 'src/app/services/api/historico/api-historico.service';

@Component({
  selector: 'c-product-list-table',
  templateUrl: './product-list-table.component.html',
  styleUrls: ['./product-list-table.component.scss']
})
export class ProductListTableComponent implements OnInit {

  @Output() listUpdated = new EventEmitter();
  @Input() dataSource: Produto[];
  @Input() columns: string[];

  @Input() currentStatus: number;
  @Input() showEmailSend: boolean = false;
  @Input() inRevisionTabFilterPermission: string;
  @Output() sortEvent = new EventEmitter<{ [x: string]: number }>();
  @Output() inRevisionTableEvent = new EventEmitter<number>();

  

  EnumElasticDashboardColumns = EnumElasticDashboardColumns;

  newProductInRevision: string[] = [];
  integratedProductInRevision: string[] = [];
  newProductAmount: number;
  integratedProductAmount: number;

  constructor(
    private apiImportadorPlanilhaServ: ApiImportadorPlanilhaService,
    private apiDadosProduto: ApiDadosProdutosService,
    private apiHistorico: ApiHistoricoService
  ) {
  }

  ngOnInit() {
  }

  getButtonSelected(button: string): void {

    // if(button == 'newProduct') {
    //   this.inRevisionTableEvent.emit(8);
    // }
  }

  ngOnChanges() {
    if (this.currentStatus == 1) {
      this.newProductAmount = 0;
      this.integratedProductAmount = 0;

      this.dataSource.forEach((item) => {
        if(item.dadosSap.length == 0 && item.dadosVtex.length == 0) {
          // console.log("novo - item", item)
          this.newProductInRevision.push(item.referencia);
          this.newProductAmount++;
        } else {
          // console.log("integrado - item", item)
          this.integratedProductInRevision.push(item.referencia);
          this.integratedProductAmount++;
        }
      });
    }
  }

  updateSort(data: { [x: string]: number }) {
    this.sortEvent.emit(data);
  }

  getProductQualityString(value: number): string {
    const ranges = [
      { start: 0, end: 20, text: 'poor' },
      { start: 21, end: 40, text: 'medium' },
      { start: 41, end: 80, text: 'good' },
      { start: 81, end: 100, text: 'excellent' }
    ];

    for (const range of ranges) {
      if (value >= range.start && value <= range.end) {
        return range.text;
      }
    }

    return 'poor';
  }

  getExportLink(id: string) {
    const link = `${environment.api}/${environment.microservice.importador}/Planilha/${id}`;
    return link;
  }

  exportProdut(id: string, reference: string) {
    this.apiImportadorPlanilhaServ.export(id).subscribe(res => {
      const downloadUrl = window.URL.createObjectURL(res);
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.download = `${reference}_${id}_${new Date().getTime()}.xlsx`;
      downloadLink.click();
      window.URL.revokeObjectURL(downloadUrl);
    });
  }

  assignResponsible(id:string){
    this.apiDadosProduto.assignResponsible(id).subscribe(() => {
      setTimeout(() => {
        this.listUpdated.emit();
      }, 2500);
    })
  }

  releaseProduct(id: string){
    var retorno = this.apiHistorico.releaseProduct(id).subscribe(() => {
      setTimeout(() => {
        this.listUpdated.emit();
      }, 2500);
      return retorno;
    })
  }

  delete(id: string) {
    this.apiDadosProduto.delete(id).subscribe(() => {
      this.listUpdated.emit();
    });
  }

}
