import { map } from 'rxjs/operators';
import { ApiImportadorService } from './../../../services/api/importador/api-importador.service';
import { LogImport } from '../../../models/api/log-import';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'c-log-imports',
  templateUrl: './log-imports.component.html',
  styleUrls: ['./log-imports.component.scss']
})
export class LogImportsComponent implements OnInit {

  logData: LogImport[] = [];

  constructor(private apiImportadorService: ApiImportadorService) { }

  ngOnInit() {
    this.loadLogImportData();
  }

  loadLogImportData() {
    this.apiImportadorService.getLogImport().pipe(map(res => res.data))
      .subscribe(logs => this.logData = logs);
  }

}
