import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { EnumCampoDinamicoValidationType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-validation-type.enum';
import { FormDinamico } from 'src/app/models/api/dados-especificos/form-dinamico';

@Injectable({
  providedIn: 'root'
})
export class ApiFormDinamicoService {

  private baseUrl = 'CampoDinamico';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  getById(query: {
    departamentoId: string;
    categoriaId?: string;
    subnivelId?: string;
  }) {
    return this.controller.useLoading().unwrap().get<any>('BuscaPor', {
      params: {
        departamentoId: query.departamentoId,
        categoriaId: query.categoriaId || '',
        subnivelId: query.subnivelId || ''
      }
    });
  }

  insertOrUpdate(data: FormDinamico) {
    return this.controller.useLoading().unwrap().post<any>('VincularCom', data);
  }

}
