import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfPipe } from './cpf.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { PhonePipe } from './phone.pipe';
import { RangeToArrayPipe } from './rangeToArray.pipe';
import { SafePipe } from './safe.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { ToStringPipe } from './to-string.pipe';
import { CountPipe } from './count.pipe';

@NgModule({
  declarations: [
    CpfPipe,
    CnpjPipe,
    PhonePipe,
    RangeToArrayPipe,
    SafePipe,
    EnumToArrayPipe,
    ToStringPipe,
    CountPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CpfPipe,
    CnpjPipe,
    PhonePipe,
    RangeToArrayPipe,
    SafePipe,
    EnumToArrayPipe,
    ToStringPipe,
    CountPipe
  ]
})
export class PipesModule { }
