import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { TipoExcecao } from 'src/app/models/api/dados-fiscais/tipo-excecao';

@Injectable({
  providedIn: 'root'
})
export class ApiTipoExcecaoService {

  private baseUrl = 'TipoExcecao';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosFiscais)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<TipoExcecao[]>('');
  }

}
