import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AuthGuard } from '../services/guards/auth.guard';
import { AppTemplates } from './app-template.enum';
import { SupplierModule } from '../pages/supplier/supplier.module';
import { LoginModule } from '../pages/login/login.module';
import { ProductModule } from '../pages/product/product/product.module';
import { ProductIntegrationModule } from '../pages/product/product-integration/product-integration.module';
import { RoleModule } from '../pages/role/role/role.module';
import { NotFoundModule } from '../pages/not-found/not-found.module';
import { ReferenceModule } from '../pages/reference/reference.module';
import { DashboardModule } from '../pages/dashboard/dashboard.module';
import { EmployeeModule } from '../pages/employee/employee/employee.module';
import { HomeModule } from '../pages/home/home.module';
import { ResetPasswordModule } from '../pages/reset-password/reset-password.module';
import { DynamicFieldEditorModule } from '../pages/dynamic-field-editor/dynamic-field-editor.module';
import { XmlModule } from '../pages/xml/xml.module';
import { ConfigTextsModule } from '../pages/config-texts/config-texts.module';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => LoginModule,
    data: { template: AppTemplates.blank }
  },
  {
    path: 'home', loadChildren: () => HomeModule
  },
  // {
  //   path: 'configuracoes',
  //   loadChildren: () => ConfigTextsModule,
  // },
  {
    path: 'reset-password',
    loadChildren: () => ResetPasswordModule,
    data: { template: AppTemplates.blank }
  },
  // Dashboard
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule
  },
  // Supplier
  {
    path: 'supplier',
    loadChildren: () => SupplierModule,
  },
  // Employee
  {
    path: 'employee',
    loadChildren: () => EmployeeModule
  },
  // Product
  {
    path: 'product',
    loadChildren: () => ProductModule,
  },
  {
    path: 'product-integration',
    loadChildren: () => ProductIntegrationModule,
  },
  // Role
  {
    path: 'role',
    loadChildren: () => RoleModule
  },
  // Dynamic field editor
  {
    path: 'dynamic-field-editor',
    loadChildren: () => DynamicFieldEditorModule
  },
  // Not found
  {
    path: 'not-found',
    loadChildren: () => NotFoundModule,
    data: { template: AppTemplates.blank }
  },
  // Reference
  {
    path: 'reference',
    loadChildren: () => ReferenceModule
  },
  {
    path: 'xml',
    loadChildren: () => XmlModule
  },
  // Redirect any route not founded to home
  { path: '**', redirectTo: 'not-found' }
];

// Set auth guard for all routes
// If necessary remove auth guard in specific router, just pass the route name
setAuthGuardAll('login', 'reset-password', '', '**');

/**
 * Set guard in all routes except informed path
 */
function setAuthGuardAll(...exceptions: string[]) {
  routes.forEach((elem: Route) => {
    if (!exceptions.includes(elem.path)) {
      elem.canActivate = [AuthGuard];
    }
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
