import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { AuthService } from '../../auth.service';
import { environment } from 'src/environments/environment';
import { Permissao } from 'src/app/models/api/autenticacao/permissao';

@Injectable({
  providedIn: 'root'
})
export class ApiPermissaoService {

  private baseUrl = 'Permissao';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Permissao[]>('');
  }

  insert(data: Permissao) {
    return this.controller.unwrap().post<Permissao>('', data);
  }

  update(data: Permissao) {
    return this.controller.unwrap().put<Permissao>('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.delete(id);
  }

}
