import { Component, Input, HostListener, AfterContentChecked, OnInit, Attribute, HostBinding, Optional } from '@angular/core';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { FormControl } from '@angular/forms';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';

@Component({
  selector: 'c-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit, AfterContentChecked {
  @HostBinding('attr.tabindex') get tabindex() {
    return (this.control && this.control.disabled) ? '-1' : '0';
  }

  @Input() value: any;
  @Input() selected = false;
  @Input() isDisabled = false;
  self = this;

  control: FormControl;

  constructor(
    public radioGroup: RadioGroupComponent,
  ) { }

  ngOnInit() {
    if (this.selected) {
      this.value = this.radioGroup.value;
    }
  }

  ngAfterContentChecked() {
    // Update control
    this.control = this.radioGroup.control;

    // Check the radio is selected
    if (this.radioGroup.value != undefined && this.radioGroup.value == this.value) {
      this.radioGroup.selectedRadio = this;
      this.value = this.radioGroup.value;
    }
  }

  @HostListener('click')
  @HostListener('keyup.enter')
  toggle() {
    if (!this.control || this.control && this.control.enabled && !this.radioGroup.isReadOnly) {
      this.radioGroup.selectedRadio = this;
      this.radioGroup.value = this.value;
      this.radioGroup.onTouchedCallback();
    }
  }

}
