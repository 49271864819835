export enum EnumElasticDashboardColumns {
  reference = 'DadosProduto.Referencia.keyword',
  description = 'DadosProduto.Header.DescricaoProduto.keyword',
  category = 'DadosProduto.Categoria.keyword',
  ean = 'DadosProduto.Header.EAN',
  dun = 'DadosProduto.DUN',
  department = 'DadosProduto.Departamento',
  subLevel = 'DadosProduto.Subnivel',
  supplierCodSap = 'DadosProduto.Header.SAPFornecedor.keyword',
  supplierName = 'DadosProduto.Header.NomeFornecedor.keyword',
  brand = 'DadosProduto.Marca',
  license = 'DadosBasicos.License',
  cstOrigin = 'DadosBasicos.OrigemCST',
  id = 'DadosProduto.Id',
  ncm = 'DadosBasicos.NCM',
  hierarchy = 'DadosProduto.Hierarquia',
  codSap = 'DadosProduto.DadosSap.CodigoSap',
  idSkuVex = 'DadosProduto.DadosVtex.Skus.IdVtex',
  idVex = 'DadosProduto.DadosVtex.IdVtex',
  buyerGroup = 'Comercial.GrupoComprador',
  materialType = 'Comercial.TipoMaterial'
}
