import { Injectable } from '@angular/core';
import { DynamicField, DynamicFieldValidator, DynamicFieldValidationType } from 'src/app/lib/dynamic-form/models/dynamic-field.interface';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DynamicFormConfig } from 'src/app/lib/dynamic-form/models/dyanmic-form.interface';

@Injectable({
  providedIn: 'root'
})
export class FieldControlService {

  constructor(
    private fb: FormBuilder
  ) { }

  dynamicFormToFormGroup(dynamicFormConfig: DynamicFormConfig) {
    return this.fieldsToFormGroup(dynamicFormConfig);
  }

  dynamicFormToControls(dynamicFormConfig: DynamicFormConfig) {
    return this.fieldsToControl(dynamicFormConfig);
  }

  private fieldsToControl(fields: DynamicField[] = []) {
    return fields.map(f => ({ name: f.id, control: this.fieldToControl(f) }));
  }

  private fieldsToFormGroup(fields: DynamicField[] = []): FormGroup {
    const group: object = {};

    for (const field of fields) {
      group[field.id] = this.fieldToControl(field);
    }

    return this.fb.group(group);
  }

  private fieldToControl(field: DynamicField): FormControl {
    return this.fb.control(
      field.initialValue,
      {
        validators: this.dynamicValidator(field.dynamicFieldValidator),
        // updateOn: field.updateOn || 'change',
      }
    );
  }

  private dynamicValidator(validators: DynamicFieldValidator[] = []) {
    const validationsMap = new Map([
      [DynamicFieldValidationType.Pattern, (v: DynamicFieldValidator, c: FormControl) => Validators.pattern(v.pattern)(c)],
      [DynamicFieldValidationType.Required, (v: DynamicFieldValidator, c: FormControl) => Validators.required(c)],
      [DynamicFieldValidationType.Min, (v: DynamicFieldValidator, c: FormControl) => Validators.min(v.value)(c)],
      [DynamicFieldValidationType.MinLength, (v: DynamicFieldValidator, c: FormControl) => Validators.minLength(v.value)(c)],
      [DynamicFieldValidationType.Max, (v: DynamicFieldValidator, c: FormControl) => Validators.max(v.value)(c)],
      [DynamicFieldValidationType.MaxLength, (v: DynamicFieldValidator, c: FormControl) => Validators.maxLength(v.value)(c)],
      [DynamicFieldValidationType.Email, (v: DynamicFieldValidator, c: FormControl) => Validators.email(c)],
    ]);

    return (control: FormControl) => {
      const errors = validators.map(v => validationsMap.get(v.type)(v, control) && v).filter(v => !!v);
      return errors.length ? ({
        dynamicValidator: errors
      }) : null;
    };
  }
}
