import { Component, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { skip, debounceTime } from 'rxjs/operators';

import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';

@Component({
  selector: 'c-dynamic-field-list',
  templateUrl: './dynamic-field-list.component.html',
  styleUrls: ['./dynamic-field-list.component.scss']
})
export class DynamicFieldListComponent implements AfterViewInit {

  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });
  data: CampoDinamico[];
  paginatedData: CampoDinamico[];
  filteredData: CampoDinamico[];
  columns: string[] = ['name', 'createdAt', 'actions'];
  search$ = new BehaviorSubject('');

  constructor(
    private apiCampoDinamicoService: ApiCampoDinamicoService
  ) {
    this.listenPaginator();
    this.listenSearch();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    this.apiCampoDinamicoService.get().subscribe(res => {
      this.data = res;
      this.paginate(this.paginator$.value);
    });
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.data) {
      const startAt = (data.page - 1) * data.pageLength;
      this.paginatedData = this.data.slice(startAt, startAt + data.pageLength);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.filteredData = this.data.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          return regex.test(elem.label.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        });
      } else {
        this.filteredData = undefined;
      }
    });
  }

}
