import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicFieldValidationType } from 'src/app/lib/dynamic-form/models/dynamic-field.interface';
import { DynamicFormConfig } from 'src/app/lib/dynamic-form/models/dyanmic-form.interface';

@Component({
  selector: 'c-dynamic-form-test',
  templateUrl: './dynamic-form-test.component.html',
  styleUrls: ['./dynamic-form-test.component.scss'],
})
export class DynamicFormTestComponent implements OnInit {

  form: FormGroup;
  config: DynamicFormConfig = [{
    id: 'a1',
    initialValue: 11,
    label: 'a1',
    type: 'number',
    layout: {
      size: 12,
      highlight: true
    },
    dynamicFieldValidator: [
      {
        type: DynamicFieldValidationType.Min,
        value: 15,
        errorMessage: 'Deve conter no minimo 10 items'
      }
    ]
  }, {
    id: 'a2',
    initialValue: 'initialValueA2',
    label: 'a2',
    type: 'text'
  }, {
    id: 'a3',
    initialValue: 'initialValueA2',
    label: 'a3',
    type: 'text'
  }, {
    id: 'a4',
    initialValue: 'initialValueA2',
    label: 'a4',
    type: 'text'
  }, {
    id: 'a5',
    initialValue: 'initialValueA2',
    label: 'a5',
    type: 'text'
  }, {
    id: 'a6',
    initialValue: 'initialValueA2',
    label: 'a6',
    type: 'text'
  }, {
    id: 'a7',
    initialValue: 'initialValueA2',
    label: 'a7',
    type: 'text'
  }, {
    id: 'a8',
    initialValue: 'initialValueA2',
    label: 'a8',
    type: 'text'
  }, {
    id: 'a9',
    initialValue: 'initialValueA2',
    label: 'a9',
    type: 'text'
  }, {
    id: 'a10',
    initialValue: 'initialValueA2',
    label: 'a10',
    type: 'text'
  }, {
    id: 'b1',
    initialValue: 1,
    label: 'b1',
    type: 'number'
  }, {
    id: 'b2',
    initialValue: 'initialValueA2',
    label: 'b2',
    type: 'text'
  }, {
    id: 'b3',
    initialValue: 'initialValueA2',
    label: 'b3',
    type: 'text'
  }, {
    id: 'b4',
    initialValue: 'initialValueA2',
    label: 'b4',
    type: 'text'
  }, {
    id: 'b5',
    initialValue: 'initialValueA2',
    label: 'b5',
    type: 'text'
  }, {
    id: 'b6',
    initialValue: 'initialValueA2',
    label: 'b6',
    type: 'text'
  }, {
    id: 'b7',
    initialValue: 'initialValueA2',
    label: 'b7',
    type: 'text'
  }, {
    id: 'b8',
    initialValue: 'initialValueA2',
    label: 'b8',
    type: 'text'
  }, {
    id: 'b9',
    initialValue: 'initialValueA2',
    label: 'b9',
    type: 'text'
  }, {
    id: 'b10',
    initialValue: 'initialValueA2',
    label: 'a10',
    type: 'text'
  }];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      test: {
        a2: 'valorDefinido no component controllador',
      }
    });
  }

  ngOnInit() {
  }

  resetTest() {
    this.form.get('test').reset();
  }

}
