import { Component } from '@angular/core';

import { Img } from "../../models/api/elements/img";

type OpenImage = Img|null;

export const TIME_ANIMATION = 250;

@Component({
    selector: 'c-open-image',
    templateUrl: './open-image.component.html',
    styleUrls: ['./open-image.component.scss']
})
export class OpenImageComponent{
    classes = '';

    private img: OpenImage = null;

    set image(img: OpenImage){
        if(!img) this.applyClass('').then(() => this.img = null);
        else this.img = img;
    }

    get image(): OpenImage{
        return this.img;
    }

    applyClass(classes: string): Promise<void>{
        return new Promise(resolve => {
            const hasClasses = !!classes;

            if(!hasClasses) this.classes = classes;

            setTimeout(() => {
                if(hasClasses) this.classes = classes;

                resolve();
            }, TIME_ANIMATION);
        });
    }
}
