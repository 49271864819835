import { Component, OnInit } from '@angular/core';
import { ProductService } from './services/product.service';
import { ProductSpecificDataService } from './services/product-specific-data.service';
import { ProductBasicDataService } from './services/product-basic-data.service';
import { ProductMediaService } from './services/product-media.service';
import { PdmDescriptionBuilderService } from './services/pdm-description-builder.service';
import { ProductSpecificDataSubnivelService } from './services/product-specific-data-subnivel.service';
import { ProductHistoricService } from './services/product-historic.service';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { ProductCommercialDataService } from './services/product-commercial-data.service';
import { ProductWorkflowService } from './services/product-workflow.service';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';
import { CanComponentDeactivate } from 'src/app/services/guards/can-deactivate.guard';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ProductTaxDataService } from './services/product-tax-data.service';
import { ProductCostAndPriceService } from './services/product-cost-and-price.service';

@Component({
  selector: 'c-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [
    ProductService,
    PdmDescriptionBuilderService,
    ProductBasicDataService,
    ProductSpecificDataService,
    ProductMediaService,
    ProductTaxDataService,
    ProductCostAndPriceService,
    ProductSpecificDataSubnivelService,
    ProductHistoricService,
    ProductCommercialDataService,
    ProductWorkflowService,
    ReadOnlyDirective
  ]
})
export class ProductComponent implements OnInit, CanComponentDeactivate {

  EnumStatus = EnumStatus;
  tabs: {
    name: string;
    permission: string;
    service?: any;
    selected?: boolean;
  }[];

  constructor(
    public productService: ProductService,
    private basicDataService: ProductBasicDataService,
    private specificDataService: ProductSpecificDataService,
    private mediaService: ProductMediaService,
    private taxDataService: ProductTaxDataService,
    private costAndPriceService: ProductCostAndPriceService,
    private commercialService: ProductCommercialDataService,
    private confirmDialogService: ConfirmDialogService,
  ) {
    this.createTabs();
  }
  ngOnInit() {
  }

  canDeactivate() {
    return this.productService.isUnsaved() ? this.confirmDialogService.confirm('Tem certeza que deseja sair sem salvar?') : true;
  }

  createTabs() {
    this.tabs = [
      { name: 'basic data', permission: 'rhcdm.dadosbasicos.visualizarAba', service: this.basicDataService },
      { name: 'specific data', permission: 'rhcdm.dadosespecificos.visualizarAba', service: this.specificDataService },
      { name: 'media', permission: 'rhcdm.midia.visualizarAba', service: this.mediaService },
      { name: 'tax data', permission: 'rhcdm.dadosfiscais.visualizarAba', service: this.taxDataService },
      { name: 'cost and price', permission: 'rhcdm.custopreco.visualizarAba', service: this.costAndPriceService },
      { name: 'commercial', permission: 'rhcdm.comercial.visualizarAba', service: this.commercialService },
      { name: 'historic', permission: 'rhcdm.historico.visualizarAba' }
    ];
  }

  getTabIcon(data: any) {
    if (data && data.service) {
      if (data.service.status == EnumStatus.Revisao) {
        return 'waiting';
      }

      if (data.service.status == EnumStatus.Reprovado) {
        return 'warning';
      }

      if (data.service.status == EnumStatus.Finalizado) {
        return 'approved_outline';
      }
    }
  }

}
