import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';
import { LogIntegracao } from 'src/app/models/api/workflow/log-integracao.interface';
import { QueryFilter } from 'src/app/models/api/query/query-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiWorkflowService {

  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.workflow);
  }

  get(query?: QueryFilter) {
    return this.controller.useLoading().post<any>(`Produto`, {
      query: '',
      skip: 0,
      take: 0,
      filtrosPesquisados: [
        ...query.filteredColumns
      ],
      ...query
    });
  }

  getById(id: string) {
    return this.controller.unwrap().useLoading().get<WorkflowProduto>(`Produto/${id}`);
  }

  getLogById(id: string) {
    return this.controller.unwrap().useLoading().get<LogIntegracao[]>(`Produto/${id}/log`);
  }

}
