import { DadosBasicos } from 'src/app/models/api/dados-basicos/dados-basicos';
import { BasicDataForm } from '../models/basic-data-form';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { DadosEspecificos } from 'src/app/models/api/dados-especificos/dados-especificos';
import { SpecificDataForm } from '../models/specific-data-form';
import { Midia, Video, MidiaGrupo } from 'src/app/models/api/dados-midia/midia.interface';
import { MediaForm } from '../models/media-form';
import { CommerceDataForm } from '../models/commerce-data-form';
import { DadosComerciais } from 'src/app/models/api/comercial/dados-comerciais';
import { TaxDataForm } from '../models/tax-data-form';
import { DadosFiscais } from 'src/app/models/api/dados-fiscais/dados-fiscais';
import { CustoPreco } from 'src/app/models/api/custo-preco/custo-preco.interface';
import { CostAndPriceForm } from '../models/cost-and-price-form';
import { CurvaCEVAEnum } from 'src/app/models/api/comercial/curva-ceva.enum';

interface FormToReq<T> {
  data: T;
  status: EnumStatus;
  produtoId: string;
}
export function BasicDataFormToReq({
  data, status, produtoId
}: FormToReq<BasicDataForm>): DadosBasicos {
  return {
    ...data,

    id: produtoId,
    status,

    // conversões de dados
    palavrasChave: data.palavrasChave ? data.palavrasChave.split(',').map(pc => pc.trim()) : []
  };
}

export function BasicDataFormFromReq(data: DadosBasicos): BasicDataForm {
  const { id, qualidadeCadastro, status, ...obj } = {
    ...data,

    // conversões de dados
    palavrasChave: data.palavrasChave ? data.palavrasChave.join(', ') : ''
  };

  return obj;
}

export function SpecificDataToReq({
  data, status, produtoId
}: FormToReq<SpecificDataForm>): DadosEspecificos {
  return {
    ...data,

    id: produtoId,
    status,
  };
}

export function SpecificDataFromReq(data: DadosEspecificos): SpecificDataForm {
  const { id, qualidadeCadastro, status, ...obj } = {
    ...data,
    // conversões de dados
    descricaoLongaPersonalizada: data.descricaoLongaPersonalizada == undefined ? true : data.descricaoLongaPersonalizada,
    descricaoExtendidaPersonalizada: data.descricaoExtendidaPersonalizada == undefined ? true : data.descricaoExtendidaPersonalizada,
    tituloDePublicacaoPersonalizada: data.tituloDePublicacaoPersonalizada == undefined ? true : data.tituloDePublicacaoPersonalizada,
    descricaoEcommercePersonalizada: data.descricaoEcommercePersonalizada == undefined ? true : data.descricaoEcommercePersonalizada
  };

  return obj;
}

export function MediaToReq(data: MediaForm): Midia {
  const clonedMedia: MediaForm = JSON.parse(JSON.stringify(data));

  const formatedMidia = {
    midias: clonedMedia.midias || [],
  } as unknown as Midia;

  clonedMedia.midias.forEach((midia, i) => {
    if (midia) {
      // Check exists videos to format
      if (midia.videos) {
        formatedMidia.midias[i].videos = midia.videos.map((elem) => {
          return {
            link: elem
          };
        });
      } else {
        formatedMidia.midias[i].videos = [];
      }

      // Check don't exist images
      if (!midia.imagens) {
        formatedMidia.midias[i].imagens = [];
      }

      // Check don't exist special images
      if (!midia.imagemEspecial) {
        formatedMidia.midias[i].imagemEspecial = [];
      }

      // Check don't exist high definition images
      if (!midia.imagemAltaResolucao) {
        formatedMidia.midias[i].imagemAltaResolucao = [];
      }
    } else {
      formatedMidia.midias[i] = {
        imagens: [],
        imagemEspecial: [],
        imagemAltaResolucao: [],
        videos: [],
      };
    }
  });

  return formatedMidia;
}

export function MediaFromReq(data: Midia): MediaForm {
  const formatedMidia = {
    midias: data.midias || [],
    status: data.status
  } as any as MediaForm;

  formatedMidia.midias.forEach(midia => {
    if (midia.videos) {
      midia.videos = midia.videos.map((elem) => {
        const video = elem as any as Video;
        return video.link;
      });
    } else {
      midia.videos = [];
    }
  });

  return formatedMidia;
}

export function TaxDataToReq({
  data, status, produtoId
}: FormToReq<TaxDataForm>): DadosFiscais {
  return {
    ...data,

    id: produtoId,
    status,
  };
}

export function TaxDataFromReq(data: DadosFiscais): TaxDataForm {
  const { id, qualidadeCadastro, status, ...obj } = {
    ...data,
    // conversões de dados
  };

  return obj;
}

export function CostAndPriceToReq({
  data, status, produtoId
}: FormToReq<CostAndPriceForm>): CustoPreco {
  return {
    ...data,

    id: produtoId,
    status,
  };
}

export function CostAndPriceFromReq(data: CustoPreco): CostAndPriceForm {
  const { id, qualidadeCadastro, status, ...obj } = {
    ...data,
    // conversões de dados
  };

  return obj;
}

export function CommerceDataToReq({ data, produtoId }: { data: CommerceDataForm, produtoId: string }): DadosComerciais {
  return {
    ...data,
    id: produtoId,
    itemExclusivo: {
      marketPlace: data.itemExclusivo ? !!data.itemExclusivo.marketPlace : false,
      pbKids: data.itemExclusivo ? !!data.itemExclusivo.pbKids : false,
      riHappy: data.itemExclusivo ? !!data.itemExclusivo.riHappy : false,
      vendidoEmOmni: data.itemExclusivo ? !!data.itemExclusivo.vendidoEmOmni : false
    },
  };
}

export function CommerceDataFromReq(data: DadosComerciais): CommerceDataForm {
  const { id, ...obj } = {
    ...data,
    curvaCEVA: data.curvaCEVA ? data.curvaCEVA : CurvaCEVAEnum.C
  };

  return obj;
}
