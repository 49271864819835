import { Directive, Attribute, TemplateRef, Input } from '@angular/core';

@Directive({
  selector: '[cDynamicField]'
})
export class DynamicFieldDirective {
  @Input('cDynamicFieldType') type = 'default';

  constructor(
    public tpl: TemplateRef<any>,
  ) {
    // console.log(this);
  }

}
