import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarModule } from './navbar/navbar.module';
import { CardModule } from './card/card.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { LoadingModule } from './loading/loading.module';
import { SelectModule } from './select/select.module';
import { ImgModule } from './img/img.module';
import { RadioModule } from './radio/radio.module';
import { IconModule } from './icon/icon.module';
import { TabModule } from './tab/tab.module';
import { InputModule } from './input/input.module';
import { RangeModule } from './range/range.module';
import { PageTitleModule } from './page-title/page-title.module';
import { PaginatorModule } from './paginator/paginator.module';
import { ImageUploaderModule } from './image-uploader/image-uploader.module';
import { VideoLinkUploaderModule } from './video-link-uploader/video-link-uploader.module';
import { EmptyPatternModule } from './empty-pattern/empty-pattern.module';
import { GeneralIntegrationInfoModule } from './general-integration-info/general-integration-info.module';
import { ColumnFilterModule } from './column-filter/column-filter.module';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { DynamicTabModule } from './dynamic-tab/dynamic-tab.module';
import { TableSortModule } from './table-sort/table-sort.module';
import { CookiesComponent } from './cookies/cookies.component';
import { CookiesModule } from './cookies/cookies.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    SidebarModule,
    NavbarModule,
    ImgModule,
    CardModule,
    CheckboxModule,
    LoadingModule,
    SelectModule,
    RadioModule,
    TabModule,
    InputModule,
    RangeModule,
    PageTitleModule,
    PaginatorModule,
    ImageUploaderModule,
    VideoLinkUploaderModule,
    EmptyPatternModule,
    GeneralIntegrationInfoModule,
    ColumnFilterModule,
    ProgressBarModule,
    DynamicTabModule,
    TableSortModule,
    CookiesModule
  ],
  exports: [
    IconModule,
    SidebarModule,
    NavbarModule,
    ImgModule,
    CardModule,
    CheckboxModule,
    LoadingModule,
    SelectModule,
    RadioModule,
    TabModule,
    InputModule,
    RangeModule,
    PageTitleModule,
    PaginatorModule,
    ImageUploaderModule,
    VideoLinkUploaderModule,
    EmptyPatternModule,
    GeneralIntegrationInfoModule,
    ColumnFilterModule,
    ProgressBarModule,
    DynamicTabModule,
    TableSortModule,
    CookiesModule
  ],
  declarations: []
})
export class UiModule { }
