import {
  Component, OnInit, Input, ElementRef, Injector, HostListener, ViewChild, forwardRef, AfterContentChecked, Optional
} from '@angular/core';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';

@Component({
  selector: 'c-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeComponent),
      multi: true
    }
  ]
})
export class RangeComponent extends ControlValueAccessorMixin implements OnInit, AfterContentChecked {

  @Input() label: string;
  @Input() unit: string;
  @Input() unitPlural: string;
  @Input() step = 1;
  @Input() min = 0;
  @Input() max = 50;

  @ViewChild('container', { static: true }) private containerRef: ElementRef;
  @ViewChild('controlOne', { static: true }) private controlOneRef: ElementRef;
  @ViewChild('controlTwo', { static: true }) private controlTwoRef: ElementRef;

  container: HTMLElement;
  controlOne: HTMLElement;
  controlTwo: HTMLElement;
  isControlOneActive: boolean;
  isControlTwoActive: boolean;
  controlOneCurrentPosition = 0;
  controlTwoCurrentPosition = 0;
  controlOneCurrentPercentage = 0;
  controlTwoCurrentPercentage = 100;

  controlOneValue: number;
  controlTwoValue: number;
  private controlOneOldValue: number;
  private controlTwoOldValue: number;

  private mouseEventFn: any;

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  ngOnInit() {
    // Set html elements
    this.controlOne = this.controlOneRef.nativeElement;
    this.controlTwo = this.controlTwoRef.nativeElement;
    this.container = this.containerRef.nativeElement;

    // Create mouse event method
    this.mouseEventFn = this.listenMouseX.bind(this);

    // Update controls if exist values
    this.updateControlPositionByMinAndMaxScale(this.controlOneValue, this.controlTwoValue);
  }

  ngAfterContentChecked() {
    this.updateControlsPosition();
  }

  writeValue(value: any) {
    if (value && value.start != undefined && value.end != undefined) {
      this.controlOneValue = this.preventValueOutsideScale(value.start);
      this.controlTwoValue = this.preventValueOutsideScale(value.end);
      this.updateValue();

      if (this.totalWidth) {
        this.updateControlPositionByMinAndMaxScale(value.start, value.end);
      }
    }
  }

  updateControlsPosition() {
    if (this.totalWidth && this.value && !this.isControlOneActive && !this.isControlTwoActive) {
      if (this.controlOneValue < this.controlTwoValue) {
        this.updateControlPositionByMinAndMaxScale(this.value.start, this.value.end);
      } else {
        this.updateControlPositionByMinAndMaxScale(this.value.end, this.value.start);
      }
    }
  }

  @HostListener('mouseup')
  cancelMove() {
    this.fixPositionWhenValuesAreTheSame();

    this.isControlOneActive = false;
    this.isControlTwoActive = false;
    this.updateValue();

    // Remove listener from method
    document.removeEventListener('mousemove', this.mouseEventFn);

    if (this.isOnFocus) {
      this.blur();
    }
  }

  moveControl(e: MouseEvent, value: ControlEnum) {
    this.focus();

    // Check which control is active
    switch (value) {
      case ControlEnum.one:
        this.isControlOneActive = true;
        break;

      case ControlEnum.two:
        this.isControlTwoActive = true;
        break;
    }

    // Listen mouse in x axis
    document.addEventListener('mousemove', this.mouseEventFn);
  }

  private listenMouseX(e: MouseEvent) {
    if (this.activeControl && (!this.control || this.control && this.control.enabled)) {
      // Get parent width
      const offsetLeft = this.activeControl.parentElement.offsetLeft;

      // Get parent limit
      const limitLeft = 0;
      const limitRight = this.totalWidth;

      // Get position of control
      let position = e.clientX - offsetLeft;

      // Check need block position
      if (position > limitRight) {
        position = limitRight;
      } else if (position < limitLeft) {
        position = limitLeft;
      }

      // Move control
      this.updateControlPosition(position);
    }
  }

  private fixPositionWhenValuesAreTheSame() {
    if (this.activeControl && this.controlOneValue == this.controlTwoValue) {
      if (this.isControlOneActive) {
        this.controlOneCurrentPosition = this.controlTwoCurrentPosition;
        this.controlOneCurrentPercentage = this.controlTwoCurrentPercentage;
        this.activeControl.style.left = `${this.controlTwoCurrentPercentage}%`;
      } else {
        this.controlTwoCurrentPosition = this.controlOneCurrentPosition;
        this.controlTwoCurrentPercentage = this.controlOneCurrentPercentage;
        this.activeControl.style.left = `${this.controlOneCurrentPercentage}%`;
      }
    }
  }

  private convertInternalValuesToMinAndMaxScale() {
    const controlOnePercentage = this.controlOneCurrentPercentage / 100;
    const controlTwoPercentage = this.controlTwoCurrentPercentage / 100;

    // Create the max on scale using min and max
    const maxOnScale = this.max - this.min;

    this.controlOneValue = Math.round(maxOnScale * controlOnePercentage) + Number(this.min);
    this.controlTwoValue = Math.round(maxOnScale * controlTwoPercentage) + Number(this.min);
  }

  private updateControlPositionByMinAndMaxScale(value1: number, value2: number) {
    const maxOnScale = this.max - this.min;
    let firstValue: number = this.preventValueOutsideScale(value1) - this.min;
    let secondValue: number = this.preventValueOutsideScale(value2) - this.min;
    firstValue = firstValue < 0 ? 0 : firstValue;
    secondValue = secondValue < 0 ? 0 : secondValue;

    const controlOnePercentage = firstValue * 100 / maxOnScale;
    const controlTwoPercentage = secondValue * 100 / maxOnScale;
    const controlOnePosition = controlOnePercentage / 100 * this.totalWidth;
    const controlTwoPosition = controlTwoPercentage / 100 * this.totalWidth;

    this.isControlOneActive = true;
    this.updateControlPosition(controlOnePosition);
    this.isControlOneActive = false;

    this.isControlTwoActive = true;
    this.updateControlPosition(controlTwoPosition);
    this.isControlTwoActive = false;
  }

  /**
   * @param position Receive a position in pixels
   */
  private updateControlPosition(position: number) {
    if (this.checkPositionIsAValidValueOnMinMaxScale(position)) {
      // Update control current position and percentage
      if (this.isControlOneActive) {
        this.controlOneCurrentPosition = position;
        this.controlOneCurrentPercentage = this.convertPositionToPercentage(position);

        this.activeControl.style.left = `${this.controlOneCurrentPercentage}%`;
      } else {
        this.controlTwoCurrentPosition = position;
        this.controlTwoCurrentPercentage = this.convertPositionToPercentage(position);

        this.activeControl.style.left = `${this.controlTwoCurrentPercentage}%`;
      }

      // Convert values to min and max scale
      this.convertInternalValuesToMinAndMaxScale();
    }
  }

  /**
   * @param position Receive a position in pixels
   */
  private convertPositionToPercentage(position: number) {
    const percentage = position * 100 / this.totalWidth;
    return Number(percentage.toFixed(2));
  }

  /**
   * @param position Receive a position in pixels
   */
  private checkPositionIsAValidValueOnMinMaxScale(position: number) {
    const maxOnScale = this.max - this.min;
    const percentageFactor = this.convertPositionToPercentage(position) / 100;
    const valueOnScale = Math.ceil(maxOnScale * percentageFactor) + Number(this.min);

    // Check the value is the start or end of scale
    if (valueOnScale == this.min || valueOnScale == this.max) {
      return true;
    }

    // Check the value respect the step using mod
    if (valueOnScale % this.step == 0) {
      return true;
    }

    return false;
  }


  private updateValue() {
    if (this.controlOneValue != this.controlOneOldValue || this.controlTwoValue != this.controlTwoOldValue) {
      // Update old value
      this.controlOneOldValue = this.controlOneValue;
      this.controlTwoOldValue = this.controlTwoValue;

      let start = 0;
      let end = 0;

      if (this.controlOneValue < this.controlTwoValue) {
        start = this.controlOneValue;
        end = this.controlTwoValue;
      } else if (this.controlOneValue > this.controlTwoValue) {
        start = this.controlTwoValue;
        end = this.controlOneValue;
      } else {
        start = this.controlOneValue;
        end = this.controlOneValue;
      }

      this.value = {
        start: Number(start),
        end: Number(end)
      };
    }
  }

  get leftDistanceToBar() {
    if (this.controlOneValue != undefined && this.controlTwoValue != undefined) {
      if (this.controlOneCurrentPercentage < this.controlTwoCurrentPercentage) {
        return `${this.controlOneCurrentPercentage}%`;
      } else {
        return `${this.controlTwoCurrentPercentage}%`;
      }
    }
  }

  get rightDistanceToBar() {
    if (this.controlTwoCurrentPercentage > this.controlOneCurrentPercentage) {
      return `${100 - this.controlTwoCurrentPercentage}%`;
    } else {
      return `${100 - this.controlOneCurrentPercentage}%`;
    }
  }

  private get activeControl() {
    if (this.isControlOneActive) {
      return this.controlOne;
    } else if (this.isControlTwoActive) {
      return this.controlTwo;
    }
  }

  private preventValueOutsideScale(value: number) {
    if (value < this.min) {
      return this.min;
    } else if (value > this.max) {
      return this.max;
    }
    return value;
  }

  private get totalWidth() {
    if (this.container && this.controlOne) {
      return this.container.offsetWidth;
    }
    return 0;
  }
}

enum ControlEnum {
  one = 1,
  two = 2
}
