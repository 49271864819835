import { Injectable } from '@angular/core';
import { IResponse } from 'src/app/models/api/response.interface';
import { environment } from 'src/environments/environment';
import { ApiHttpClientService } from '../../api-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ApiTemplatesService {

  private baseUrl = 'Templates';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.importador)
      .useBaseUrl(this.baseUrl);
  }

  getTemplate() {
    return this.controller.useLoading().get('DownloadTemplateFornecedor', { responseType: 'blob' });
  }

  getTemplateDadosComerciais() {
    return this.controller.useLoading().get('DownloadTemplateComercial', { responseType: 'blob' });
  }

  getAtualizaIndicesDinamicosTemplateFornecedor() {
    return this.controller.useLoading().get<IResponse<boolean>>('AtualizaIndicesDinamicosTemplateFornecedor');
  }

  getAtualizaIndicesDinamicosTemplateComercial() {
    return this.controller.useLoading().get<IResponse<boolean>>('AtualizaIndicesDinamicosTemplateComercial');
  }
}
