import { Injectable } from "@angular/core";
import { ApiHttpClientService } from "../../api-http-client.service";
import { environment } from "src/environments/environment";
import {
  QueryFilter,
  SortedColumn,
} from "src/app/models/api/query/query-filter.interface";
import { IResponse } from "src/app/models/api/response.interface";
import { DashboardFornecedor } from "src/app/models/api/dashboard-fornecedor/dashboard-fornecedor";
import { IElasticTerm } from "src/app/models/api/elastic/elastic-term.interface";
import { IElasticSortedColumn } from "src/app/models/api/elastic/elastic-sorted-column.interface";

@Injectable({
  providedIn: "root",
})
export class ApiElasticDashboardService {
  private baseUrl = "Elastic";
  private controller: ApiHttpClientService;

  constructor(private api: ApiHttpClientService) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosProdutos)
      .useBaseUrl(this.baseUrl);
  }

  getDraft(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarRascunho")
      .post<IResponse<DashboardFornecedor[]>>(
        "Rascunho",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getResponsavelTodosEmRevisao(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "Responsavel/EmRevisao",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getResponsavelTodosIntegrados(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "Responsavel/Integrados",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getTodosResponsavel(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "Responsavel/Todos",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewTodosResponsavel(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "Responsavel/EmRevisao",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewComercialRascunho(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "EmRevisao/ComercialRascunho",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewFiscal(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "EmRevisao/Fiscal",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewCustoPreco(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "EmRevisao/CustoPreco",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewTodos(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "EmRevisao",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewNovos(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "EmRevisao/Novos",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getReviewIntegrados(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarEmRevisao")
      .post<IResponse<DashboardFornecedor[]>>(
        "EmRevisao/Integrados",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getInconsistencyCommercial(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission(
        "rhcdm.dadosprodutos.listarInconsistencias"
      )
      .post<IResponse<DashboardFornecedor[]>>(
        "Inconsistencias/Comercial",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getInconsistencySupplier(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission(
        "rhcdm.dadosprodutos.listarInconsistencias"
      )
      .post<IResponse<DashboardFornecedor[]>>(
        "Inconsistencias/Fornecedor",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getInconsistency(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission(
        "rhcdm.dadosprodutos.listarInconsistencias"
      )
      .post<IResponse<DashboardFornecedor[]>>(
        "Inconsistencias",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  getRegistered(query?: QueryFilter) {
    return this.controller
      .useLoading()
      .cancelRequestWithoutPermission("rhcdm.dadosprodutos.listarCadastrados")
      .post<IResponse<DashboardFornecedor[]>>(
        "Cadastrados",
        this.convertQueryFilterToQueryFilterElastic(query)
      );
  }

  private convertQueryFilterToQueryFilterElastic(query?: QueryFilter) {
    const terms = this.convertColumnsToElasticTerms(
      query.query,
      query.filteredColumns
    );
    const sortedColumns = this.convertSortedColumnsToElasticSort(
      query.sortedColumns
    );

    const elasticQuery = {
      query: {
        bool: {
          must: {
            bool: {
              should: terms,
            },
          },
        },
      },
      from: query.skip,
      size: query.take,
      sort: sortedColumns,
    };

    return elasticQuery;
  }

  private convertColumnsToElasticTerms(value: string, columns: string[]) {
    if (value && columns) {
      return columns.map((column) => {
        const term: IElasticTerm = {
          match: {},
        };

        term.match[column] = value;

        return term;
      });
    }

    return [];
  }

  private convertSortedColumnsToElasticSort(columns: SortedColumn) {
    if (columns) {
      const sortedColumns = new Array();

      for (const column of Object.keys(columns)) {
        const sortedColumn = new Object() as IElasticSortedColumn;
        sortedColumn[column] = columns[column] == 1 ? "asc" : "desc";

        sortedColumns.push(sortedColumn);
      }

      return sortedColumns;
    }

    return [];
  }
}
