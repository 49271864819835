import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fornecedor } from 'src/app/models/api/autenticacao/fornecedor';
import { ApiFornecedorService } from 'src/app/services/api/autenticacao/api-fornecedor.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ToastService } from 'src/app/ui/toast/toast.service';

@Component({
  selector: 'c-supplier-list-table',
  templateUrl: './supplier-list-table.component.html',
  styleUrls: ['./supplier-list-table.component.scss']
})
export class SupplierListTableComponent implements OnInit {
  @Output() listUpdated = new EventEmitter();
  @Input() dataSource: Fornecedor[];
  columns: string[] = ['fornecedor', 'cnpj', 'sap', 'responsavel', 'local', 'actions'];

  constructor(
    private apiFornecedor: ApiFornecedorService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
  }

  delete(id: string) {
    this.apiFornecedor.delete(id).subscribe(() => {
      this.listUpdated.emit();
    });
  }

  redefinePassword(id: string) {
    this.apiFornecedor.redefinePassword(id).subscribe(res => {
      if(res == null){
        this.toastService.toast('Ocorreu um erro ao redefinir a senha!', ToastType.error);
      }
      else{
        this.toastService.toast('Senha redefinida com sucesso!', ToastType.success);
      }
    });
  }

}
