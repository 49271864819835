import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputGroupComponent } from './input-group/input-group.component';
import { InputErrorModule } from '../input-error/input-error.module';
import { IconModule } from '../icon/icon.module';
import { InputGroupContainerComponent } from './input-group-container/input-group-container.component';
import { InputInfoModule } from '../input-info/input-info.module';

@NgModule({
  declarations: [
    InputComponent,
    InputGroupComponent,
    InputGroupContainerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    InputErrorModule,
    InputInfoModule,
    IconModule
  ],
  exports: [
    InputComponent,
    InputGroupComponent,
    InputGroupContainerComponent
  ]
})
export class InputModule { }
