import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeComponent } from './employee.component';
import { EmployeeRoutingModule } from './employee-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { IconModule } from 'src/app/ui/icon/icon.module';

@NgModule({
  declarations: [
    EmployeeComponent
  ],
  imports: [
    CommonModule,
    EmployeeRoutingModule,
    SharedModule,
    CardModule,
    CheckboxModule,
    InputModule,
    IconModule
  ]
})
export class EmployeeModule { }
