import { Component, OnInit, Input , Output, EventEmitter } from '@angular/core';
import { Fornecedor } from 'src/app/models/api/autenticacao/fornecedor';
import { ApiXmlService } from 'src/app/services/api/xml/api-xml.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'c-xml-list-table',
  templateUrl: './xml-list-table.component.html',
  styleUrls: ['./xml-list-table.component.scss']
})
export class XmlListTableComponent implements OnInit {
  @Output() listUpdated = new EventEmitter();
  @Input() dataSource: Fornecedor[];
  columns: string[] = ['nome', 'url', 'actions'];

  constructor(
    private apiXml: ApiXmlService
  ) { }

  ngOnInit() {
  }

  url(elem){
    return environment.api + "/" + environment.microservice.xml + "/ProdutoXML/Download/" + elem.nomeArquivo
  }

  delete(name: string) {
    this.apiXml.delete(name).subscribe(() => {
      this.listUpdated.emit();
    });
  }

}
