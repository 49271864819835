import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';

import { Hierarquia } from 'src/app/models/api/comercial/hierarquia';
import { ApiHierarquiaService } from 'src/app/services/api/comercial/api-hierarquia.service';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';

@Component({
  selector: 'c-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {
  hierarchyData: hierarchyEditable[];
  hierarchyPaginatedData: hierarchyEditable[];
  hierarchyFilteredData: hierarchyEditable[];
  columns: string[] = ['name', 'active', 'actions'];
  form: FormGroup;
  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });
  search$ = new BehaviorSubject('');
  constructor(
    private apiHierarquia: ApiHierarquiaService,
    private fb: FormBuilder,
  ) {
    this.createForm();
    this.listenPaginator();
    this.listenSearch();
  }

  ngOnInit() {
    this.loadhierarchyData();
  }

  createForm() {
    this.form = this.fb.group({
      descricao: [null],
      ativo: [null],
      valorSap: [null]
    });
  }

  loadhierarchyData() {
    this.apiHierarquia.getAllAtivosInativos().subscribe(res => {
      this.hierarchyData = res.data as hierarchyEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: hierarchyEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = true;
  }

  cancelEdit(data: hierarchyEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = false;
  }

  async save(data: hierarchyEditable) {
    if (!data) {
      const hierarchy: hierarchyEditable = this.form.value;
      hierarchy.descricao = hierarchy.valorSap.trim() + " - " + hierarchy.descricao.toUpperCase().trim();
      hierarchy.ativo = true;
      
      await this.apiHierarquia.insert(hierarchy).toPromise();
      
      this.form.reset();
      this.loadhierarchyData();
    } else {
      const description = data.newDescription.toUpperCase().trimEnd().trimStart();

      await this.apiHierarquia.update({
        id: data.id, descricao: description, ativo: data.ativo
      }).toPromise();
      data.descricao = description.toUpperCase().trimEnd().trimStart();
      data.isEditActive = false;
    }
  }

  async changeEnableDisable(data: hierarchyEditable) {
    try {
      await this.apiHierarquia.delete(data.id).toPromise();
    } catch {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.hierarchyFilteredData = this.hierarchyData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          const hierarchy = regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          return hierarchy;
        });
      } else {
        this.hierarchyFilteredData = undefined;
      }
    });
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.hierarchyData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.hierarchyPaginatedData = this.hierarchyData.slice(startAt, startAt + data.pageLength);
    }
  }

  get canCreate() {
    return this.descricao.value;
  }
  get descricao() { return this.form.get('descricao'); }
}

interface hierarchyEditable extends Hierarquia {
  isEditActive: boolean;
  newDescription: string;
}