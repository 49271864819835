import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { validateEanDun } from 'src/app/shared/validators/ean-dun';
import { ProductBaseEntity } from './product-base-entity.class';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ApiDadosBasicosService } from 'src/app/services/api/dados-basicos/api-dados-basicos.service';
import { shareReplay, map, switchMap, tap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { UnidadeFaturamento } from 'src/app/models/api/dados-basicos/unidade-faturamento';
import { ApiUnidadeFaturamentoService } from 'src/app/services/api/dados-basicos/api-unidade-faturamento.service';
import { ApiMarcaLicensaService } from 'src/app/services/api/dados-basicos/api-marca-licensa.service';
import { greaterOrEqualThan, greaterThan, lessOrEqualThan } from '../helpers/compare-control.validator';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { onlyInteger } from 'src/app/shared/validators/only-integer';
import { OnlineEanValidator } from '../helpers/online-ean.validator';
import { OnlineDunValidator } from '../helpers/online-dun.validator';
import { OrigemCst } from 'src/app/models/api/dados-basicos/origem-cst';
import { ApiOrigemCstService } from 'src/app/services/api/dados-basicos/api-origem-cst.service';
import { Licensa } from 'src/app/models/api/dados-basicos/licensa';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { BasicDataForm } from '../models/basic-data-form';


@Injectable()
export class ProductBasicDataService extends ProductBaseEntity {

  @IsAllowed('rhcdm.dadosbasicos.editarFinalizado') canSaveFinished: boolean;
  @IsAllowed('rhcdm.dadosbasicos.editarRevisao') canSaveReview: boolean;
  @IsAllowed('rhcdm.dadosbasicos.editarReprovado') canSaveRepproved: boolean;
  @IsAllowed('rhcdm.dadosbasicos.aprovar') canApproveOrReprove: boolean;

  ncm?: string;
  origemCSTId?: string;
  ipi?: number;

  unidadeFaturamento$: Observable<UnidadeFaturamento[]>;
  origemCst$: Observable<OrigemCst[]>;
  marcasId: string[];
  marca$: Observable<Licensa[]>;
  licenca$: Observable<Licensa[]>;

  constructor(
    fb: FormBuilder,
    private toastService: ToastService,
    private api: ApiDadosBasicosService,
    private apiUnidadeFaturamentoService: ApiUnidadeFaturamentoService,
    private apiMarcaLicensaService: ApiMarcaLicensaService,
    private apiOrigemCst: ApiOrigemCstService,
    private onlineEanValidator: OnlineEanValidator,
    private onlineDunValidator: OnlineDunValidator
  ) {
    super(fb);
    this.initForm();
    this.init();
  }

  async init() {
    this.unidadeFaturamento$ = this.apiUnidadeFaturamentoService.getAll().pipe(
      map(response => response.data),
      shareReplay(1)
    );

    const licenseData = await this.apiMarcaLicensaService.getAll().pipe(
      map(response => response.data),
    ).toPromise();

    this.licenca$ = new BehaviorSubject<Licensa[]>(licenseData);

    this.marca$ = new BehaviorSubject<Licensa[]>([]).pipe(
      switchMap(() => this.licenca$),
      map(licencas => {
        // Remove no license if exists in marcasId
        const filteredMarcasId = this.marcasId ? this.marcasId.filter(marcaId => marcaId != '5e14b9fbc06b25cda3c8b750') : [];

        // Filter
        const filteredBrands = licencas.filter(elem => {
          return filteredMarcasId.find(marcaId => elem.id == marcaId) ? true : false;
        });

        // Find no license and add on array
        const noLicense = licencas.find(elem => elem.id == '5e14b9fbc06b25cda3c8b750');
        if (noLicense) {
          filteredBrands.unshift(noLicense);
        }

        return filteredBrands;
      }),
      tap(marcas => {
        if (marcas && marcas.length == 1) {
          this.marcaId.setValue(marcas[0].id);
        }
      })
    );

    this.origemCst$ = this.apiOrigemCst.getAll().pipe(shareReplay(1));

    this.controladoPorValidade.valueChanges.subscribe(() => {
      this.checkValidityControl();
    });
  }

  setValue(productId: string, status: EnumStatus, value: BasicDataForm, marcasId?: string[]) {
    this.marcasId = marcasId;
    super.setValue(productId, status, value);
    this.checkValidityControl();
  }

  // Criação do Formulário

  createForm() {
    return this.fb.group({
      controladoPorValidade: [undefined, Validators.required],
      vencimento: [undefined, [Validators.required, Validators.min(1), onlyInteger()]],
      descricao: [undefined, Validators.required],
      referencia: [undefined, Validators.required],
      palavrasChave: [undefined, Validators.required],
      marcaId: [undefined],
      licencaId: [undefined],
      unidadeFaturamentoId: [undefined, Validators.required],
      embalagemUnitaria: this.createEmbalagemUnitaria(),
      embalagemCaixa: this.createEmbalagemCaixa(),
      foraDaEmbalagem: this.createForaDaEmbalagem(),

      // origemCSTId: [undefined, Validators.required],
      // ncm: [undefined, Validators.required],
      // ipi: [undefined, Validators.required]
    });
  }

  get referencia(): string {
    return this.form.get('referencia').value;
  }

  private createForaDaEmbalagem() {
    const formGroup = this.fb.group({
      pesoBruto: [undefined, [Validators.required, Validators.max(25)]],
      dimensoesProduto: this.createDimensoesProdutoForaDaEmbalagem()
    });

    formGroup.get('pesoBruto').setAsyncValidators([
      lessOrEqualThan(
        () => this.form.get('embalagemUnitaria.pesoBruto') as FormControl, 'Peso Bruto (UNIT.)* da seção Unidade'
      )
    ]);

    return formGroup;
  }

  private createEmbalagemUnitaria() {
    const formGroup = this.fb.group({
      ean: [undefined, validateEanDun()],
      pesoBruto: [undefined, [Validators.required, Validators.max(25)]],
      pesoLiquido: [undefined, [Validators.required, Validators.max(25)]],
      dimensoesProduto: this.createDimensoesProdutoUnidade()
    });

    // Set timeout to fix angular problems on form group
    setTimeout(() => {
      
      formGroup.get('pesoBruto').setAsyncValidators([
        greaterOrEqualThan(
          () => this.form.get('embalagemUnitaria.pesoLiquido') as FormControl, 'Peso Líquido (UNIT.)* da seção Unidade'
        )
      ]);

      formGroup.get('pesoBruto').setAsyncValidators([
        greaterOrEqualThan(
          () => this.form.get('foraDaEmbalagem.pesoBruto') as FormControl, 'Peso Líquido (UNIT.)* da seção Fora da Embalagem'
        )
      ]);

    });

    return formGroup;
  }

  private createEmbalagemCaixa() {
    const formGroup = this.fb.group({
      dun: [undefined, validateEanDun()],
      embalagemMultipla: [undefined],
      pesoBruto: [undefined, Validators.max(25)],
      pesoLiquido: [undefined, Validators.max(25)],
      dimensoesProduto: this.createDimensoesProdutoCaixa()
    });

    formGroup.get('pesoBruto').setAsyncValidators([
      greaterThan(
        () => this.form.get('embalagemUnitaria.pesoBruto') as FormControl, 'Peso Bruto (UNIT.)* da seção Unidade'
      )
    ]);

    formGroup.get('pesoLiquido').setAsyncValidators([
      greaterOrEqualThan(
        () => this.form.get('embalagemUnitaria.pesoLiquido') as FormControl, 'Peso Líquido (UNIT.)* da seção Unidade'
      )
    ]);

    formGroup.get('pesoBruto').setAsyncValidators([
      greaterOrEqualThan(
        () => this.form.get('embalagemCaixa.pesoLiquido') as FormControl, 'Peso x Embalagem Múltipla (PACK) da seção Caixa'
      )
    ]);

    // Regra removida devido ao fornecedor Lego
    // formGroup.get('dimensoesProduto.altura').setAsyncValidators([
    //   greaterOrEqualThan(
    //     () => this.form.get('embalagemUnitaria.dimensoesProduto.altura') as FormControl, 'Campo "A - Altura" da seção Unidade > Dimensões'
    //   )
    // ]);
    // formGroup.get('dimensoesProduto.largura').setAsyncValidators([
    //   greaterOrEqualThan(
    //     () => this.form.get('embalagemUnitaria.dimensoesProduto.largura') as FormControl, 'Campo "L - Largura" da seção Unidade > Dimensões'
    //   )
    // ]);
    // formGroup.get('dimensoesProduto.profundidade').setAsyncValidators([
    //   greaterOrEqualThan(
    //     () => this.form.get('embalagemUnitaria.dimensoesProduto.profundidade') as FormControl, 'Campo "P - Profundidade" da seção Unidade > Dimensões'
    //   )
    // ]);

    return formGroup;
  }

  private createDimensoesProdutoForaDaEmbalagem() {
    return this.fb.group({
      altura: [undefined, [Validators.required, Validators.max(500)]],
      largura: [undefined, [Validators.required, Validators.max(500)]],
      profundidade: [undefined, [Validators.required, Validators.max(500)]],
    });
  }

  private createDimensoesProdutoUnidade() {
    return this.fb.group({
      altura: [undefined, [Validators.required, Validators.max(150)]],
      largura: [undefined, [Validators.required, Validators.max(150)]],
      profundidade: [undefined, [Validators.required, Validators.max(150)]],
    });
  }

  private createDimensoesProdutoCaixa() {
    return this.fb.group({
      altura: [undefined, Validators.max(150)],
      largura: [undefined, Validators.max(150)],
      profundidade: [undefined, Validators.max(150)],
    });
  }

  private checkValidityControl() {
    if (this.controladoPorValidade.value) {
      this.vencimento.enable();
      this.vencimento.setValidators([Validators.required, Validators.min(1), onlyInteger()]);
    } else {
      this.vencimento.disable();
      this.vencimento.clearValidators();
    }
  }

  private get controladoPorValidade() {
    return this.form.get('controladoPorValidade');
  }

  private get vencimento() {
    return this.form.get('vencimento');
  }

  private get marcaId() {
    return this.form.get('marcaId');
  }

}

