import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSpecificDataService } from './product-specific-data.service';
import { ProductBasicDataService } from './product-basic-data.service';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { BasicDataForm } from '../models/basic-data-form';
import { DadosBasicos } from 'src/app/models/api/dados-basicos/dados-basicos';
import { ApiDadosBasicosService } from 'src/app/services/api/dados-basicos/api-dados-basicos.service';
import { DadosEspecificos } from 'src/app/models/api/dados-especificos/dados-especificos';
import { SpecificDataForm } from '../models/specific-data-form';
import { ApiDadosEspecificosService } from 'src/app/services/api/dados-especificos/api-dados-especificos.service';
import {
  BasicDataFormToReq,
  BasicDataFormFromReq,
  SpecificDataFromReq,
  MediaToReq,
  MediaFromReq,
  CommerceDataToReq,
  CommerceDataFromReq,
  TaxDataFromReq,
  TaxDataToReq,
  CostAndPriceToReq,
  CostAndPriceFromReq
} from './convertions';
import { Product, ProductHeader } from '../models/product';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ProductMediaService } from './product-media.service';
import { ApiMidiaService } from 'src/app/services/api/midia/api-midia.service';
import { Midia } from 'src/app/models/api/dados-midia/midia.interface';
import { ProductCommercialDataService } from './product-commercial-data.service';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { ApiHistoricoService } from 'src/app/services/api/historico/api-historico.service';
import { ApiDadosComerciaisService } from 'src/app/services/api/comercial/api-dados-comerciais.service';
import { DadosComerciais } from 'src/app/models/api/comercial/dados-comerciais';
import { ProductHistoricService } from './product-historic.service';
import { zip, of } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { ApiIntegracaoService } from 'src/app/services/api/workflow/api-integracao.service';
import { ProductWorkflowService } from './product-workflow.service';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';
import { LogsModalService } from './logs-modal.service';
import { EstadoOrdenado } from 'src/app/models/api/dados-fiscais/estado';
import { ProductTaxDataService } from './product-tax-data.service';
import { DadosFiscais } from 'src/app/models/api/dados-fiscais/dados-fiscais';
import { TaxDataForm } from '../models/tax-data-form';
import { ApiDadosFiscaisService } from 'src/app/services/api/dados-fiscais/api-dados-fiscais.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ProductCostAndPriceService } from './product-cost-and-price.service';
import { ApiCustoPrecoService } from 'src/app/services/api/custo-preco/api-custo-preco.service';
import { CustoPreco } from 'src/app/models/api/custo-preco/custo-preco.interface';
import { CostAndPriceForm } from '../models/cost-and-price-form';
import { CommerceDataForm } from '../models/commerce-data-form';

@Injectable()
export class ProductService {

  /** Armazena id corrente do produto
   *  Sempre que esta tela for aberte existira um id de produto, tanto na criação quanto edição
   */
  productId: string;
  /** Dados fixos do Header que vem do backend */
  header: ProductHeader;
  status: EnumStatus;
  rodouCodimp: boolean;

  @IsAllowed('rhcdm.produto.enviarParaRevisao') canSubmitForReviewPermission: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private basicDataService: ProductBasicDataService,
    private specificDataService: ProductSpecificDataService,
    private mediaService: ProductMediaService,
    private taxDataService: ProductTaxDataService,
    private costAndPriceService: ProductCostAndPriceService,
    private commercialDataService: ProductCommercialDataService,
    private historicService: ProductHistoricService,
    private workflowService: ProductWorkflowService,
    private apiDadosBasicos: ApiDadosBasicosService,
    private apiDadosEspecificos: ApiDadosEspecificosService,
    private apiMidia: ApiMidiaService,
    private apiDadosFiscaisService: ApiDadosFiscaisService,
    private apiCustoPrecoService: ApiCustoPrecoService,
    private apiHistorico: ApiHistoricoService,
    private apiDadosComerciais: ApiDadosComerciaisService,
    public apiIntegracaoService: ApiIntegracaoService,
    private router: Router,
    public toastService: ToastService,
    private logModal: LogsModalService,
    private confirmDialogService: ConfirmDialogService,
  ) {
    this.init();
  }

  init() {
    // esculta por dados da rota, e pega o produto que foi preparado pelo Resolver
    this.activatedRoute.data.subscribe(({ product, states }: { product: Product, states: EstadoOrdenado[] }) => {
      this.setProductData(product, states);
    });
  }

  /** inicializa tela com o produto passado como argumento */
  private setProductData(product: Product, states: EstadoOrdenado[]) {
    this.productId = product.id;
    this.header = product.header;
    this.status = product.status || EnumStatus.Draft;


    // Inicializa dados basicos
    if (product.dadosBasicos) {
      this.basicDataService.setValue(
        product.id,
        product.dadosBasicos.status || EnumStatus.Draft,
        BasicDataFormFromReq(product.dadosBasicos),
        product.header.marcasId
      );

      this.basicDataService.ncm = product.dadosBasicos.ncm;
      this.basicDataService.origemCSTId = product.dadosBasicos.origemCSTId;
      this.basicDataService.ipi = product.dadosBasicos.ipi;

      console.log(product);
    } else {
      this.basicDataService.productId = product.id;
      this.basicDataService.updateStatus(EnumStatus.Draft);
      this.basicDataService.marcasId = product.header.marcasId;
    }

    // Inicializa dados especificos
    if (product.dadosEspecificos) {
      this.specificDataService.sortimentosIntegrationData = product.sortimento;
      this.specificDataService.gradeIntegrationData = product.grade;

      if (product.header.idVtex) {
        this.specificDataService.setUntouchableLinks(product.dadosEspecificos);
      }

      this.specificDataService.setValue(
        product.id,
        product.dadosEspecificos.status || EnumStatus.Draft,
        SpecificDataFromReq(product.dadosEspecificos)
      );

      if (product.dadosEspecificos.sortimentoList || product.dadosEspecificos.gradeList) {
        this.specificDataService.canUpdatePDM = false;
      }
    } else {
      this.specificDataService.productId = product.id;
      this.specificDataService.updateStatus(EnumStatus.Draft);
    }

    // Inicializa midia
    if (product.midia) {
      this.mediaService.setValue(
        product.id,
        product.midia.status || EnumStatus.Draft,
        MediaFromReq(product.midia)
      );
    } else {
      this.mediaService.productId = product.id;
      this.mediaService.updateStatus(EnumStatus.Draft);
    }

    // Inicializa dados fiscais
    this.taxDataService.setFornecedorId(product.header.fornecedorId);
    this.taxDataService.setStates(states);
    if (product.dadosFiscais && product.dadosFiscais.filialList) {
      this.rodouCodimp = product.dadosFiscais.filialList[0].dadosTributariosListSap?.length > 0 && product.dadosFiscais.filialList[0].dadosTributariosListSap?.some(e => e.codimp !== null)
      this.taxDataService.isFiscalIntegrado$.next(product.dadosFiscais.fiscalIntegrado);
      this.taxDataService.setValue(
        product.id,
        product.dadosFiscais.status || EnumStatus.Draft,
        TaxDataFromReq(product.dadosFiscais)
      );
    } else {
      this.taxDataService.productId = product.id;
      this.taxDataService.addFilial();
      this.taxDataService.filiais$.subscribe(filial => {
        this.taxDataService.filiaisForm.get('0').get('filialId').setValue(filial[0].filialId);
      });

      this.taxDataService.updateStatus(EnumStatus.Draft);
    }

    // Inicializa dados custo e preço
    this.costAndPriceService.fornecedorId = this.header.fornecedorId;
    if (product.custoPreco) {
      this.costAndPriceService.isCustoPrecoIntegrado$.next(product.custoPreco.custoPrecoIntegrado);
      this.costAndPriceService.setValue(
        product.id,
        product.custoPreco.status || EnumStatus.Draft,
        CostAndPriceFromReq(product.custoPreco));
    } else {
      this.costAndPriceService.productId = product.id;
      this.costAndPriceService.updateStatus(EnumStatus.Draft);
    }

    // Inicializa dados comerciais
    if (product.dadosComerciais) {
      this.commercialDataService.setValue(
        this.productId,
        product.dadosComerciais.status,
        CommerceDataFromReq(product.dadosComerciais) as DadosComerciais
      );

      this.commercialDataService.precoSugerido = product.dadosComerciais.precoSugerido;
    } else {
      this.commercialDataService.productId = product.id;
      this.commercialDataService.updateStatus(EnumStatus.Draft);
    }

    // Inicializa histórico
    if (product.historico) {
      this.historicService.setValue(
        this.productId,
        product.historico
      );
    }

    // Inicializa workflow
    if (product.workflow) {
      this.workflowService.setValue(
        this.productId,
        product.workflow
      );
    }
  }

  /** Submete o produto para revisão */
  async submitForReview() {
    console.log(this.canSubmitForReview, this.isAllTabsValid, this.canSubmitSomeTabForReview);
    console.log(this.basicDataService.isValid, this.specificDataService.isValid, this.mediaService.isValid,
      this.taxDataService.isValid, this.costAndPriceService.isValid);

    let confirmSubmitForm = await this.confirmDialogService.confirm('Tem certeza que deseja enviar o cadastro?').then(
      data => data
    );

    if (!confirmSubmitForm)
      return;

    // Verifica se pode ir para revisão
    if (this.canSubmitForReview) {
      // Validate all tabs
      await this.validateAllTabs();
    
      //  Extract all tabs data
      const dadosBasicos = this.extractDadosBasicos();
      const dadosEspecificos = this.extractDadosEspecificos();
      const midia = this.extractMidia();
      const dadosFiscais = this.extractDadosFiscais();
      const custoPreco = this.extractCustoPreco();
    
      const submitBasicDataForReview = this.basicDataService.canSubmitForReview && this.basicDataService.canSubmitBasicDataForReview(dadosBasicos) ? this.submitBasicDataForReview(dadosBasicos) : of(null);
      const submitSpecificDataForReview = this.specificDataService.canSubmitForReview && this.specificDataService.canSubmitSpecificDataForReview(dadosEspecificos) ? this.submitSpecificDataForReview(dadosEspecificos) : of(null);
      const submitMidiaForReview = this.mediaService.canSubmitForReview && this.mediaService.canSubmitMediaDataForReview(midia) ? this.submitMidiaForReview(midia) : of(null);
      const submitTaxDataForReview = this.taxDataService.canSubmitForReview && this.taxDataService.canSubmitTaxDataForReview(dadosFiscais) ? this.submitTaxDataForReview(dadosFiscais) : of(null);
      const submitCostAndPriceForReview = this.costAndPriceService.canSubmitForReview && this.costAndPriceService.canSubmitCoastPriceDataForReview(custoPreco) ? this.submitCostAndPriceForReview(custoPreco) : of(null);
    
      submitBasicDataForReview.pipe(
        concatMap(basicDataResult => {
          console.log("basic data " + new Date().getTime());
          return submitSpecificDataForReview.pipe(
            map(specificDataResult => ({ basicDataResult, specificDataResult }))
          );
        }),
        concatMap(data => {
          console.log("specific data " + new Date().getTime());
          return submitMidiaForReview.pipe(
            map(midiaResult => ({ ...data, midiaResult }))
          );
        }),
        concatMap(data => {
          console.log("midia " + new Date().getTime());
          return submitTaxDataForReview.pipe(
            map(taxDataResult => ({ ...data, taxDataResult }))
          );
        }),
        concatMap(data => {
          console.log("tax data " + new Date().getTime());
          return submitCostAndPriceForReview.pipe(
            map(costAndPriceResult => ({ ...data, costAndPriceResult }))
          );
        })
      ).subscribe(() => {
        console.log("cost price " + new Date().getTime());
        // assim que todas as abas são submetidas para revisão redireciona para listagem
        this.router.navigateByUrl('/dashboard');
      });
    
    } else {
      this.checkTabErrors();
    }
    
  }

  /** envia aba Dados Basicos para revisão e atualiza status */
  private submitBasicDataForReview(dadosBasicos: DadosBasicos) {
    return this.apiDadosBasicos.insertOrUpdate(dadosBasicos).pipe(tap(res => {
      if (res) {
        this.basicDataService.form.markAsUntouched();
        this.basicDataService.status = res.status;
      }
    }));
  }

  /** envia aba Dados Especificos para revisão e atualiza status */
  private submitSpecificDataForReview(dadosEspecificos: DadosEspecificos) {
    return this.apiDadosEspecificos.insertOrUpdate(dadosEspecificos).pipe(tap(res => {
      if (res) {
        this.specificDataService.form.markAsUntouched();
        this.specificDataService.status = res.status;
      }
    }));
  }

  /** envia aba Midia para revisão e atualiza status */
  private submitMidiaForReview(midia: Midia) {
    return this.apiMidia.insertOrUpdate(midia).pipe(tap(res => {
      if (res) {
        this.mediaService.form.markAsUntouched();
        this.mediaService.status = res.status;
      }
    }));
  }

  private submitTaxDataForReview(dadosFiscais: DadosFiscais) {
    return this.apiDadosFiscaisService.insertOrUpdate(dadosFiscais).pipe(tap(res => {
      if (res) {
        this.taxDataService.form.markAsUntouched();
        this.taxDataService.status = res.status;

        if (dadosFiscais.status == EnumStatus.Reprovado && !dadosFiscais.fiscalIntegrado){
          this.apiIntegracaoService.integrateSap(this.productId).subscribe(() => {
          });
        }
      }
    }));
  }

  private submitCostAndPriceForReview(data: CustoPreco) {
    return this.apiCustoPrecoService.insertOrUpdate(data).pipe(tap(res => {
      if (res) {
        this.costAndPriceService.form.markAsUntouched();
        this.costAndPriceService.status = res.status;
      }
    }));
  }

  private validateAllTabs() {
    return Promise.all([
      this.validateBasicData(),
      this.validateSpecificData(),
      this.validateMedia(),
      this.validateTaxData(),
      this.validateCostAndPrice()
    ]);
  }

  private validateBasicData() {
    return this.apiDadosBasicos.validate(this.extractDadosBasicos()).toPromise();
  }

  private validateSpecificData() {
    return this.apiDadosEspecificos.validate(this.extractDadosEspecificos()).toPromise();
  }

  private validateMedia() {
    return this.apiMidia.validate(this.extractMidia()).toPromise();
  }

  private validateTaxData() {
    var dadosFiscais = this.extractDadosFiscais();
    if (dadosFiscais.status != 3){
      return this.apiDadosFiscaisService.validate(dadosFiscais).toPromise();
    }
  }

  private validateCostAndPrice() {
    var custoPreco = this.extractCustoPreco();
    if (custoPreco.status != 3)
    return this.apiCustoPrecoService.validate(custoPreco).toPromise();
  }

  saveAllTabs() {
    this.saveBasicData();
    this.saveSpecificData();
    this.saveMidia();
    this.saveTaxData();
    this.saveCostAndPrice();
    this.saveCommercialData();
  }

  saveBasicData() {
    // se produto esta reprovado ou é um draft salva o draft
    // se não, tenta enviar esta aba para revisão
    // mostra os erros caso algo esteja invalido

    if (this.basicDataService.status === EnumStatus.Draft || this.basicDataService.status === EnumStatus.Reprovado) {
      this.apiDadosBasicos.saveDraft(this.extractDadosBasicos()).subscribe(() => {
        this.basicDataService.form.markAsUntouched();
      }, () => {
        this.toastService.toast('Erro ao salvar!', ToastType.error);
        this.basicDataService.form.markAllAsTouched();
      });
    } else {
      const dadosBasicos = this.extractDadosBasicos();
      if (this.basicDataService.form.valid) {
        this.submitBasicDataForReview(dadosBasicos).subscribe(() => {
          this.basicDataService.form.markAsUntouched();
        });
      } else {
        this.checkBasicDataErrors();
      }
    }
  }

  saveSpecificData() {
    // se produto esta reprovado ou é um draft salva o draft
    // se não, tenta enviar esta aba para revisão
    // mostra os erros caso algo esteja invalido

    if (this.specificDataService.status === EnumStatus.Draft || this.specificDataService.status === EnumStatus.Reprovado) {
      this.apiDadosEspecificos.saveDraft(this.extractDadosEspecificos()).subscribe(() => {
        this.specificDataService.form.markAsUntouched();
        this.saveMidia();
      }, () => {
        this.toastService.toast('Erro ao salvar!', ToastType.error);
        this.specificDataService.form.markAllAsTouched();
      });
    } else {
      const dadosEspecificos = this.extractDadosEspecificos();
      if (this.specificDataService.form.valid) {
        this.submitSpecificDataForReview(dadosEspecificos).subscribe(() => {
          this.specificDataService.form.markAsUntouched();
          this.saveMidia();
        });
      } else {
        this.checkSpecificDataErrors();
      }
    }
  }

  saveMidia() {
    // se produto esta reprovado ou é um draft salva o draft
    // se não, tenta enviar esta aba para revisão
    // mostra os erros caso algo esteja invalido

    if (this.mediaService.status === EnumStatus.Draft || this.mediaService.status === EnumStatus.Reprovado) {
      this.apiMidia.saveDraft(this.extractMidia()).subscribe(() => {
        this.mediaService.form.markAsUntouched();
      }, () => {
        this.toastService.toast('Erro ao salvar!', ToastType.error);
        this.mediaService.form.markAllAsTouched();
      });
    } else {
      const dadosMidia = this.extractMidia();
      if (this.mediaService.form.valid) {
        this.submitMidiaForReview(dadosMidia).subscribe(() => {
          this.mediaService.form.markAsUntouched();
        });
      } else {
        this.checkMediaErrors();
      }
    }
  }

  saveTaxData() {
    // se produto esta reprovado ou é um draft salva o draft
    // se não, tenta enviar esta aba para revisão
    // mostra os erros caso algo esteja invalido

    if (this.taxDataService.status === EnumStatus.Draft || this.taxDataService.status === EnumStatus.Reprovado) {
      this.apiDadosFiscaisService.saveDraft(this.extractDadosFiscais()).subscribe(() => {
        this.taxDataService.form.markAsUntouched();
      }, () => {
        this.toastService.toast('Erro ao salvar!', ToastType.error);
        this.taxDataService.form.markAllAsTouched();
      });
    } else {
      const dadosFiscais = this.extractDadosFiscais();
      if (this.taxDataService.form.valid) {
        this.submitTaxDataForReview(dadosFiscais).subscribe(() => {
          this.taxDataService.form.markAsUntouched();
        });
      } else {
        this.checkTaxDataErrors();
      }
    }
  }

  saveCostAndPrice() {
    // se produto esta reprovado ou é um draft salva o draft
    // se não, tenta enviar esta aba para revisão
    // mostra os erros caso algo esteja invalido

    if (this.costAndPriceService.status === EnumStatus.Draft || this.costAndPriceService.status === EnumStatus.Reprovado) {
      this.apiCustoPrecoService.saveDraft(this.extractCustoPreco()).subscribe(() => {
        this.costAndPriceService.form.markAsUntouched();
        //this.saveTaxData();
      }, () => {
        this.toastService.toast('Erro ao salvar!', ToastType.error);
        this.costAndPriceService.form.markAllAsTouched();
      });
    } else {
      const costAndPrice = this.extractCustoPreco();
      if (this.costAndPriceService.form.valid) {
        this.submitCostAndPriceForReview(costAndPrice).subscribe(() => {
          this.costAndPriceService.form.markAsUntouched();
          //this.saveTaxData();
        });
      } else {
        this.checkCostAndPriceErrors();
      }
    }
  }

  saveCommercialData() {
    // tenta salvar a aba de dados comerciais
    // mostra os erros caso algo tenha dado errado

    this.apiDadosComerciais.insertOrUpdate(this.extractDadosComerciais()).subscribe((res) => {
      this.commercialDataService.form.markAsUntouched();
      this.commercialDataService.status = res.status;
    }, () => {
      this.toastService.toast('Erro ao salvar!', ToastType.error);
      this.commercialDataService.form.markAllAsTouched();
    });
  }

  submitCommercialForReview() {
    // salva o cadastro
    // muda o status do cadastro para revisao

    this.apiDadosComerciais.submitCommercialForReview(this.extractDadosComerciais()).subscribe((res) => {
      this.commercialDataService.form.markAsUntouched();
      this.commercialDataService.status = res.status;
      // assim que a aba for submetida para revisão redireciona para dashboard
      this.router.navigateByUrl('/dashboard');
    }, () => {
      this.toastService.toast('Erro ao salvar!', ToastType.error);
      this.commercialDataService.form.markAllAsTouched();
    });
  }

  approveOrReprove(reason: string, tab: EnumAba, approved: boolean) {
    // Cria o histórico caso não exista
    // Se existir adiciona uma mensagem

    if (!this.historicService.data || this.historicService.data && !this.historicService.data.historicoAbaList) {
      this.apiHistorico.create(this.productId, reason, tab, approved).subscribe(res => {
        this.updateTabStatusWhenApproveOrReprove(tab, approved, this.header.codigoSap);

        this.historicService.setValue(
          this.productId,
          res
        );
      });
    } else {
      this.apiHistorico.approveOrReproveNewTab(this.productId, reason, tab, approved, this.header.fornecedorId, this.header.referencia).subscribe(res => {
        this.updateTabStatusWhenApproveOrReprove(tab, approved, this.header.codigoSap);

        this.historicService.setValue(
          this.productId,
          res
        );
      });
    }
  }

  private updateTabStatusWhenApproveOrReprove(tab: EnumAba, approved: boolean, codigoSap: string) {
    if (approved) {
      switch (tab) {
        case EnumAba.dadosBasicos:
          this.basicDataService.status = EnumStatus.Finalizado;
          break;

        case EnumAba.dadosEspecificos:
          this.specificDataService.status = EnumStatus.Finalizado;
          break;

        case EnumAba.midia:
          this.mediaService.status = EnumStatus.Finalizado;
          break;

        case EnumAba.dadosFiscais:
          this.taxDataService.status = EnumStatus.Finalizado;
          break;

        case EnumAba.custo:
          this.costAndPriceService.status = EnumStatus.Finalizado;
          break;

        case EnumAba.comercial:
          this.commercialDataService.status = EnumStatus.Finalizado;
          break;
      }
    } else {
      switch (tab) {
        case EnumAba.dadosBasicos:
          this.basicDataService.status = EnumStatus.Reprovado;
          this.specificDataService.status = EnumStatus.Reprovado;
          this.mediaService.status = EnumStatus.Reprovado;

          if (codigoSap == null || codigoSap ==  ""){
            this.taxDataService.status = EnumStatus.Reprovado;
            this.costAndPriceService.status = EnumStatus.Reprovado;
          }
          break;

        case EnumAba.dadosEspecificos:
          this.specificDataService.status = EnumStatus.Reprovado;
          this.mediaService.status = EnumStatus.Reprovado;
          break;

        case EnumAba.midia:
          this.mediaService.status = EnumStatus.Reprovado;
          break;

        case EnumAba.dadosFiscais:
          this.taxDataService.status = EnumStatus.Reprovado;
          break;

        case EnumAba.custo:
          this.costAndPriceService.status = EnumStatus.Reprovado;
          break;

        case EnumAba.comercial:
          this.commercialDataService.status = EnumStatus.Reprovado;
          break;
      }
    }
  }

  integrateSap() {
    document.getElementById('integrar').style.display = "none";
    this.apiIntegracaoService.integrateSap(this.productId).subscribe(() => {
    });
  }

  integrateVtex() {
    this.apiIntegracaoService.integrateVtex(this.productId).subscribe(() => {
    });
  }

  integrateCeva() {
    this.apiIntegracaoService.integrateCeva(this.productId).subscribe(() => {
    });
  }

  integrateAll() {
    this.apiIntegracaoService.integrateAll(this.productId).subscribe(() => {
    });
  }

  toggleApproveOrReproveSapIntegration() {
    if (this.workflowService.data) {
      if (this.workflowService.data.bloquearIntegracaoSap) {
        // Approve
        this.apiIntegracaoService.approveSapIntegration(this.productId, this.workflowService.data.bloquearIntegracaoVTEX).subscribe();
      } else {
        // Reprove
        this.apiIntegracaoService.reproveSapIntegration(this.productId, this.workflowService.data.bloquearIntegracaoVTEX).subscribe();
      }

      this.workflowService.data.bloquearIntegracaoSap = !this.workflowService.data.bloquearIntegracaoSap;
    }
  }

  toggleApproveOrReproveVtexIntegration() {
    if (this.workflowService.data) {
      if (this.workflowService.data.bloquearIntegracaoVTEX) {
        // Approve
        this.apiIntegracaoService.approveVtexIntegration(this.productId, this.workflowService.data.bloquearIntegracaoSap).subscribe();
      } else {
        // Reprove
        this.apiIntegracaoService.reproveVtexIntegration(this.productId, this.workflowService.data.bloquearIntegracaoSap).subscribe();
      }

      this.workflowService.data.bloquearIntegracaoVTEX = !this.workflowService.data.bloquearIntegracaoVTEX;
    }
  }

  openLogs() {
    this.logModal.openLogs(this.productId);
  }

  // #endregion

  // #region VERIFICAÇÃO DE ERROS E FEEDBACK VISUAL
  private checkTabErrors() {
    this.checkBasicDataErrors();
    this.checkSpecificDataErrors();
    this.checkMediaErrors();
    this.checkTaxDataErrors();
    this.checkCostAndPriceErrors();
  }

  private checkBasicDataErrors() {
    if (!this.basicDataService.form.valid) {
      this.basicDataService.form.markAllAsTouched();
      this.toastService.toast('Aba de Dados Básicos está inválida!', ToastType.error);
    }
  }

  private checkSpecificDataErrors() {
    if (!this.specificDataService.form.valid) {
      this.specificDataService.form.markAllAsTouched();
      this.toastService.toast('Aba de Dados Específicos está inválida!', ToastType.error);
    }
  }

  private checkMediaErrors() {
    if (!this.mediaService.form.valid) {
      this.mediaService.form.markAllAsTouched();
      this.toastService.toast('Aba de Mídia está inválida!', ToastType.error);
    }
  }

  private checkTaxDataErrors() {
    if (this.taxDataService.form.invalid) {
      this.taxDataService.form.markAllAsTouched();
      this.toastService.toast('Aba de Dados Fiscais está inválida!', ToastType.error);
    }
  }

  private checkCostAndPriceErrors() {
    if (this.costAndPriceService.form.invalid) {
      this.costAndPriceService.form.markAllAsTouched();
      this.toastService.toast('Aba de custo e preço está inválida!', ToastType.error);
    }
  }

  private checkCommercialErrors() {
    if (this.commercialDataService.form.invalid) {
      this.commercialDataService.form.markAllAsTouched();
      this.toastService.toast('Aba de Comercial está inválida!', ToastType.error);
    }
  }

  // #endregion

  // #region MÉTODOS AUXILIARES

  // #region PERMISSIONS

  isUnsaved() {
    // tslint:disable-next-line: max-line-length
    return /*(this.canSubmitForReview) && */ (
      (this.specificDataService.canSave || this.specificDataService.canSubmitForReview ? this.specificDataService.form.touched : false) ||
      (this.basicDataService.canSave || this.basicDataService.canSubmitForReview ? this.basicDataService.form.touched : false) ||
      // tslint:disable-next-line: max-line-length
      (this.commercialDataService.canSave || this.commercialDataService.canSubmitForReview ? this.commercialDataService.form.touched : false) ||
      (this.mediaService.canSave || this.mediaService.canSubmitForReview ? this.mediaService.form.touched : false) ||
      (this.taxDataService.canSave || this.taxDataService.canSubmitForReview ? this.taxDataService.form.touched : false) ||
      (this.costAndPriceService.canSave || this.costAndPriceService.canSubmitForReview ? this.costAndPriceService.form.touched : false)
    );
  }

  get canSaveBasicData() {
    return this.basicDataService.canSave;
  }

  get canSaveSpecificData() {
    return this.specificDataService.canSave;
  }

  get canSaveMidia() {
    return this.mediaService.canSave;
  }

  get canSaveTaxData() {
    return this.taxDataService.canSave;
  }

  get canSaveCostAndPrice() {
    return this.costAndPriceService.canSave;
  }

  get canApproveOrReproveCommercial() {
    return (this.commercialDataService.status == EnumStatus.Revisao || this.commercialDataService.status == EnumStatus.Draft) &&
      this.commercialDataService.canApproveOrReprove;
  }

  get canApproveOrReproveCostAndPrice() {
    return this.status == EnumStatus.Revisao &&
      this.costAndPriceService.status == EnumStatus.Revisao &&
      this.costAndPriceService.canApproveOrReprove;
  }

  get canApproveOrReproveTaxData() {
    return this.status == EnumStatus.Revisao &&
      this.taxDataService.status == EnumStatus.Revisao &&
      this.taxDataService.canApproveOrReprove;
  }

  get canIntegrateTaxData() {
    return this.taxDataService.status == EnumStatus.Finalizado && this.taxDataService.isFiscalIntegrado$.getValue() === false
  }

  get canIntegrateCoastAndPrice() {
    return this.costAndPriceService.status == EnumStatus.Finalizado && this.taxDataService.isFiscalIntegrado$.getValue() === true && this.costAndPriceService.isCustoPrecoIntegrado$.getValue() === false
  }

  get canApproveOrReproveMedia() {
    return this.mediaService.status == EnumStatus.Revisao &&
      this.mediaService.canApproveOrReprove;
  }

  get canApproveOrReproveSpecificData() {
    return this.specificDataService.status == EnumStatus.Revisao &&
      this.specificDataService.canApproveOrReprove;
  }

  get canApproveOrReproveBasicData() {
    return this.basicDataService.status == EnumStatus.Revisao &&
      this.basicDataService.canApproveOrReprove;
  }

  get canSaveAny() {
    return !!this.basicDataService.referencia;
  }

  get canSubmitForReview() {
    return this.canSubmitForReviewPermission && this.isAllTabsValid && this.canSubmitSomeTabForReview &&
      [EnumStatus.Draft, EnumStatus.Finalizado, EnumStatus.Reprovado].includes(this.status);
  }

  get canSubmitAllTabsForReview() {
    return this.basicDataService.canSubmitForReview &&
      this.specificDataService.canSubmitForReview &&
      this.mediaService.canSubmitForReview &&
      this.taxDataService.canSubmitForReview &&
      this.costAndPriceService.canSubmitForReview;
  }

  get canSubmitSomeTabForReview() {
    return this.basicDataService.canSubmitForReview ||
      this.specificDataService.canSubmitForReview ||
      this.mediaService.canSubmitForReview ||
      this.taxDataService.canSubmitForReview ||
      this.costAndPriceService.canSubmitForReview;
  }

  get isAllTabsValid() {
    return this.basicDataService.isValid &&
      this.specificDataService.isValid &&
      this.mediaService.isValid &&
      this.taxDataService.isValid &&
      this.costAndPriceService.isValid;
  }

  get canIntegrate() {
    return this.status == EnumStatus.Finalizado;
  }

  get canIntegrateSap() {
    if (this.workflowService.data) {
      return this.canIntegrate && !this.workflowService.data.bloquearIntegracaoSap;
    }
  }

  get canIntegrateVtex() {
    if (this.workflowService.data) {
      return this.canIntegrate && !this.workflowService.data.bloquearIntegracaoVTEX;
    }
  }

  get isSapIntegrated() {
    if (this.workflowService.data) {
      return this.workflowService.data.integradoComSap;
    }
  }

  get isVtexIntegrated() {
    if (this.workflowService.data) {
      return this.workflowService.data.integradoComVTEX;
    }
  }

  get isCevaIntegrated() {
    if (this.workflowService.data) {
      return this.workflowService.data.integradoComCEVA;
    }
  }

  get canApproveSapIntegration() {
    if (this.workflowService.data) {
      return this.workflowService.data.bloquearIntegracaoSap;
    }
  }

  get canApproveVtexIntegration() {
    if (this.workflowService.data) {
      return this.workflowService.data.bloquearIntegracaoVTEX;
    }
  }
  get canApproveCevaIntegration() {
    if (this.workflowService.data) {
      return this.workflowService.data.bloquearIntegracaoCEVA;
    }
  }

  get canShowIntegrationTab() {
    return this.status == EnumStatus.Finalizado;
  }

  // #endregion PERMISSIONS

  // #region EXTRACT DATA

  /** Junta os dados do formulário com os metadados necessário para api */
  private extractDadosBasicos(): DadosBasicos {
    const basicDataForm = this.basicDataService.form.getRawValue() as BasicDataForm;
    basicDataForm.origemCSTId = this.basicDataService.origemCSTId;
    basicDataForm.ncm = this.basicDataService.ncm;
    basicDataForm.ipi = this.basicDataService.ipi;

    return BasicDataFormToReq({
      data: basicDataForm,
      status: this.basicDataService.status,
      produtoId: this.productId
    });
  }

  /** Junta os dados do formulário com os metadados necessário para api */
  private extractDadosEspecificos(): DadosEspecificos {
    return {
      ...this.specificDataService.form.getRawValue() as SpecificDataForm,
      ...{
        id: this.productId,
        status: this.specificDataService.status,
      }
    };
  }

  /** Junta os dados do formulário com os metadados necessário para api */
  private extractMidia(): Midia {
    const data = {
      ...MediaToReq(this.mediaService.form.getRawValue()) as Midia,
      ...{
        idProduto: this.productId,
        status: this.mediaService.status,
      }
    };

    return data;
  }

  private extractDadosFiscais(): DadosFiscais {
    const data = TaxDataToReq({
      data: this.taxDataService.form.getRawValue() as TaxDataForm,
      status: this.taxDataService.status,
      produtoId: this.productId,
    });

    return data;
  }

  private extractCustoPreco(): CustoPreco {
    const data = CostAndPriceToReq({
      data: this.costAndPriceService.form.getRawValue() as CostAndPriceForm,
      status: this.costAndPriceService.status,
      produtoId: this.productId,
    });

    return data;
  }

  private extractDadosComerciais(): DadosComerciais {
    const commerceDataForm = this.commercialDataService.form.getRawValue() as CommerceDataForm;
    //commerceDataForm.precoVenda = this.commercialDataService.precoVenda;
    commerceDataForm.precoSugerido = this.commercialDataService.precoSugerido;
    commerceDataForm.status = this.commercialDataService.status;

    return CommerceDataToReq({ data: commerceDataForm, produtoId: this.productId });
  }

  // #endregion EXTRACT DATA

  // #endregion
}
