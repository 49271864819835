import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DashboardService {
  search$ = new BehaviorSubject("");

  departmentChange$ = new Subject();
  categoryChange$ = new Subject();
  sublevelChange$ = new Subject();

}
