import { Directive, Input, ViewContainerRef, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[isAllowed]'
})
export class IsAllowedDirective implements OnInit, OnDestroy {

  private requiredPermission: string | string[];
  private userPermissions: string[];
  private userSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionServ: SessionService
  ) {
    this.userSubscription = this.listenUser();
  }

  ngOnInit() {
    this.updateView();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  @Input()
  set isAllowed(value: string | string[]) {
    this.requiredPermission = value;
  }

  private listenUser() {
    return this.sessionServ.user$.subscribe(res => {
      this.userPermissions = res && res.permissions ? res.permissions : [];
      this.updateView();
    });
  }

  private updateView() {
    this.viewContainer.clear();

    if (this.checkUserHasPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkUserHasPermission() {
    if (this.userPermissions) {
      if (this.requiredPermission instanceof Array) {
        let permit = false;

        this.requiredPermission.forEach(permission => {
          if (this.userPermissions.find(elem => elem === permission || elem === `${environment.permissionPrefix}.*`)) {
            permit = true;
          }
        });

        return permit;
      } else {
        const permission = this.userPermissions.find(elem => {
          return elem === this.requiredPermission || elem === `${environment.permissionPrefix}.*`;
        });

        if (permission) {
          return true;
        } else {
          return false;
        }
      }

    }
    return false;
  }

}
