import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab/tab.component';
import { TabItemComponent } from './tab-item/tab-item.component';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabContentItemComponent } from './tab-content-item/tab-content-item.component';
import { TabItemSubtitleComponent } from './tab-item-subtitle/tab-item-subtitle.component';
import { TabItemTitleComponent } from './tab-item-title/tab-item-title.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [
    TabComponent,
    TabItemComponent,
    TabContentComponent,
    TabContentItemComponent,
    TabItemSubtitleComponent,
    TabItemTitleComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    TabComponent,
    TabItemComponent,
    TabContentComponent,
    TabContentItemComponent,
    TabItemSubtitleComponent,
    TabItemTitleComponent
  ]
})
export class TabModule { }
