import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'c-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgComponent implements OnInit {

  @Input() src = '';

  constructor() { }

  ngOnInit() {
  }

  getImg() {
    return this.src ? `url(${this.src})` : '';
  }

}
