import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'c-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  internalPercentage = 0;

  constructor() { }

  ngOnInit() {
  }

  @Input()
  set percentage(value: number) {
    const formatedValue = value > 100 ? 100 : value < 0 ? 0 : value;
    this.internalPercentage = formatedValue;
  }

  get percentage() {
    return this.internalPercentage;
  }

  leftCircleStyle() {
    const degrees = this.percentageToDeg();
    const value = degrees > 180 ? degrees : 180;
    return { transform: `rotate(${value}deg)` };
  }

  rightCircleStyle() {
    const degrees = this.percentageToDeg();
    const value = degrees > 180 ? 180 : degrees;

    return { transform: `rotate(${180 + value}deg)` };
  }

  private percentageToDeg() {
    return this.percentage / 100 * 360;
  }

  getProductQualityString(value: number): string {
    const ranges = [
      { start: 0, end: 20, text: 'poor' },
      { start: 21, end: 40, text: 'medium' },
      { start: 41, end: 80, text: 'good' },
      { start: 81, end: 100, text: 'excellent' }
    ];

    for (const range of ranges) {
      if (value == 0 || !value) {
        return 'no quality';
      }

      if (value >= range.start && value <= range.end) {
        return range.text;
      }
    }

    return 'poor';
  }


}
