import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiImportadorService } from 'src/app/services/api/importador/api-importador.service';
import { map, switchMap } from 'rxjs/operators';
import { ApiDadosComerciaisService } from 'src/app/services/api/comercial/api-dados-comerciais.service';
import { ApiRelatorioService } from 'src/app/services/api/relatorio/api-relatorio.service';
import { AlertDialogComponent } from 'src/app/ui/modal/alert-dialog/alert-dialog.component';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ApiTemplatesService } from 'src/app/services/api/importador/api-templates.service';

enum Elements {
  'IMPORT_PRODUCTS' = 'import-products',
  'IMPORT_OMNI' = 'import-omni',
  'IMPORT_COMERCIAL' = 'import-comercial',
  'EXPORT_REPORT' = 'export-report',
}

@Component({
  selector: 'c-product-import',
  templateUrl: './product-import.component.html',
  styleUrls: ['./product-import.component.scss']
})
export class ProductImportComponent implements OnInit {
  elements = Elements;
  @Output() imported = new EventEmitter();
  @Input() showElements = [];

  constructor(
    private apiImportadorService: ApiImportadorService,
    private apiDadosComerciaisService: ApiDadosComerciaisService,
    private apiRelatorioService: ApiRelatorioService,
    private apiTemplatesService: ApiTemplatesService,
    private toastService: ToastService,

  ) { }

  ngOnInit() {
  }

  showElement(element: Elements) {
    if (this.showElements.includes(element) || this.showElements.includes('*'))
      return true;
    return false;
  }

  downloadTemplateUrl() {
    return this.apiTemplatesService.getTemplate().subscribe(
      res => {
        const downloadUrl = window.URL.createObjectURL(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = 'ModeloImportacao_RiHappy.xlsx';
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
      });
  }

  downloadTemplateOmni() {
    return this.apiDadosComerciaisService.getTemplateOmni().subscribe(
      res => {
        const downloadUrl = window.URL.createObjectURL(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = 'ModeloImportacao_Omni_RiHappy.xlsx';
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
      });
  }

  downloadTemplateDadosComerciaisUrl() {
    return this.apiTemplatesService.getTemplateDadosComerciais().subscribe(
      res => {
        const downloadUrl = window.URL.createObjectURL(res);
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = 'ModeloImportacao_DadosComerciais_RiHappy.xlsx';
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
      });
  }

  atualizaIndicesDinamicosTemplateFornecedor() {
    this.apiTemplatesService.getAtualizaIndicesDinamicosTemplateFornecedor().pipe(map(res => res.data))
      .subscribe();
  }

  atualizaIndicesDinamicosTemplateComercial() {
    this.apiTemplatesService.getAtualizaIndicesDinamicosTemplateComercial().pipe(map(res => res.data))
      .subscribe();
  }

  exportReportAll(){
    return this.apiRelatorioService.exportReportAll().subscribe(
      res => {
        this.toastService.toast({description: 'Relatório enviado para o e-mail com sucesso!', type: ToastType.success})
      });
  }

  exportReportProductsRevisionAll(){
    return this.apiRelatorioService.exportReportProductsRevisionAll().subscribe(
      res => {
       this.toastService.toast({description: 'Relatório está sendo gerado e será enviado para seu e-mail dentro de alguns minutos...', type: ToastType.success})
      });
  }

  validateAndUploadSpreadSheet(fileToUpload: File) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      this.apiImportadorService.validateSpreadSheet(formData).pipe(
        switchMap(res => this.apiImportadorService.saveSpreadSheet(res))
      ).subscribe(() => {
        this.imported.next();
      });
    }
  }

  importarPlanilhaCriar(fileToUpload: File) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      this.apiImportadorService.importSpreadSheetCreate(formData).pipe().subscribe(() => {
        this.imported.next();
      });
    }
  }

  importarPlanilhaAtualizar(fileToUpload: File) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      this.apiImportadorService.importSpreadSheetUpdate(formData).pipe().subscribe(() => {
        this.imported.next();
      });
    }
  }

  importarPlanilhaCriarDadosComerciais(fileToUpload: File) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      this.apiImportadorService.importSpreadSheetCreateDadosComerciais(formData).pipe().subscribe(() => {
        this.imported.next();
      });
    }
  }

  importarPlanilhaUpdateDadosComerciais(fileToUpload: File) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      this.apiImportadorService.importSpreadSheetUpdateDadosComerciais(formData).pipe().subscribe(() => {
        this.imported.next();
      });
    }
  }

  importarPlanilhaOmni(fileToUpload: File) {
    if (fileToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      this.apiDadosComerciaisService.importSpreadSheetOmni(formData).pipe().subscribe(() => {
        this.imported.next();
      });
    }
  }

}
