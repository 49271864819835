import { Injectable, ViewChild } from "@angular/core";
import {
  FormArray, FormBuilder, FormControl, FormGroup, Validators
} from "@angular/forms";
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from "rxjs";
import {
  distinctUntilChanged, map,
  shareReplay, startWith,
  switchMap, take, tap
} from "rxjs/operators";
import { Estado } from "src/app/models/api/autenticacao/estado";
import { EnumStatus } from "src/app/models/api/dados-basicos/enum-status.enum";
import { CidadeFilial } from "src/app/models/api/dados-fiscais/cidadeFilial";
import { EstadoOrdenado } from "src/app/models/api/dados-fiscais/estado";
import { Grupo } from "src/app/models/api/dados-fiscais/grupo";
import { ApiEstadoService } from "src/app/services/api/autenticacao/api-estado.service";
import { ApiOrigemCstService } from "src/app/services/api/dados-basicos/api-origem-cst.service";
import { ApiDadosFiscaisService } from "src/app/services/api/dados-fiscais/api-dados-fiscais.service";
import { ApiGrupoService } from "src/app/services/api/dados-fiscais/api-grupo.service";
import { ApiNCMDescricaoService } from "src/app/services/api/dados-fiscais/api-ncm-descricao.service";
import { ApiTipoExcecaoService } from "src/app/services/api/dados-fiscais/api-tipo-excecao.service";
import { ApiTributacaoCSTService } from "src/app/services/api/dados-fiscais/api-tributacao-cst.service";
import { IsAllowed } from "src/app/shared/decorators/is-allowed.decorator";
import { removeValidators } from "src/app/shared/utils/remove-validators-form";
import { ToastType } from "src/app/ui/toast/toast.model";
import { ToastService } from "src/app/ui/toast/toast.service";
import { TaxDataForm } from "../models/tax-data-form";
import { CanSubmitForReview } from "./interfaces";
import { ProductBaseEntity } from "./product-base-entity.class";

@Injectable()
export class ProductTaxDataService
  extends ProductBaseEntity
  implements CanSubmitForReview
{

  @ViewChild('selectState', {static: true}) selectState: any
  @IsAllowed("rhcdm.dadosfiscais.editarFinalizado") canSaveFinished: boolean;
  @IsAllowed("rhcdm.dadosfiscais.editarRevisao") canSaveReview: boolean;
  @IsAllowed("rhcdm.dadosfiscais.editarReprovado") canSaveRepproved: boolean;
  @IsAllowed("rhcdm.dadosfiscais.aprovar") canApproveOrReprove: boolean;

  @IsAllowed("rhcdm.dadosfiscais.ManipularImpostoEntrada")
  podeManipularImpostoEntrada: boolean;
  @IsAllowed("rhcdm.dadosfiscais.ManipularExcecao")
  podeManipularExcecao: boolean;

  filialChange$ = new Subject<{ uf: string; index: number }[]>();

  protected tributacaoCST:any;

  tributacaoCSTIImposto$ = this.apiTributacaoCst.getAllImposto().pipe(shareReplay(1));

  nfgnre$ = of([{"value": 1, "description": "Destaque NF"}, {"value": 2, "description": "GNRE"}])

  states$ = this.apiEstadoService.getOnlyStates().pipe(shareReplay(1));

  tipoExcecoes$ = this.apiTipoExcecao.getAll().pipe(shareReplay(1));

  origemCst$ = this.apiOrigemCST.getAll().pipe(shareReplay(1));

  isSelectedCstForeigner$ = new BehaviorSubject<boolean>(false);

  private fornecedorId$ = new ReplaySubject<string>();


  simplesNacional$ = new BehaviorSubject<boolean>(null);

  // estadosArray;

 isFiscalIntegrado$ = new BehaviorSubject<boolean>(false);

  protected estados:Estado[];
  private tributacaoCSTIImposto: any;
  protected tributacaoCSTIpi: any;
  protected tributacaoCSTPisCofins: any;

  filiais$ = this.fornecedorId$.pipe(take(1)).pipe(
    switchMap((id) => {
      if (id && id != undefined) {
        return this.apiEstadoService.getBranchesBySupplier(id)
      } else {
        this.toastService.toast('Id do fornecedor inválido!', ToastType.error);
        return [];
      }
    }),
    tap((filiais) => {
      return this.simplesNacional$.next(
        filiais && filiais[0] && filiais[0].simplesNacional
      );
    }),
    shareReplay(1)
  );

  filiaisFiltered$: Observable<CidadeFilial[]>;

  private states: EstadoOrdenado[];
  constructor(
    fb: FormBuilder,
    private toastService: ToastService,
    private api: ApiDadosFiscaisService,
    private apiNCMDescricao: ApiNCMDescricaoService,
    private apiEstadoService: ApiEstadoService,
    private apiTributacaoCst: ApiTributacaoCSTService,
    private apiTipoExcecao: ApiTipoExcecaoService,
    private apiGrupo: ApiGrupoService,
    private apiOrigemCST: ApiOrigemCstService
  ) {
    super(fb);
    this.initForm();
    this.listenEstados();
    this.listenTributacaoCSTImposto();
    this.listenTributacaoCst()
  }

  initForm() {
    super.initForm();
  }

  setStates(states: EstadoOrdenado[]) {
    this.states = states;
  }

  setFornecedorId(fornecedorId: string) {
    this.fornecedorId$.next(fornecedorId);
  }

  setValue(productId: string, status: EnumStatus, value: TaxDataForm) {
    if (!value.filialList || value.filialList.length === 0)
      value.filialList = [];

    super.setValue(productId, status, value);
    this.updateSelectedCst((value as any).origemCSTId);
    // this.filiaisForm.clear();

    // caso tenha alguma filial cria formArrays
    // caso não tenha, cria a primeira 'filial' automaticamente
    if (value && value.filialList) {
      value.filialList.forEach((filial, index) => {
        const form = this.createFilialForm();

        this.listenFilialChange(form.get("filialId") as FormControl, index);



        // criar forms de dados tributarios
        if (filial && filial.dadosTributariosList) {
          const dtFormArray = form.get("dadosTributariosList") as FormArray;
          filial.dadosTributariosList.forEach((dt, dtIndex) => {
            filial.dadosTributariosListSap.map(dtListSap => {
              if (dt.uf == dtListSap.uf) {
                dt.infoSap = dtListSap;
                dt.dirFiscal = dt.dirFiscal ? dt.dirFiscal : dtListSap.dirFiscal;
                dt.mva = dt.mva ? dt.mva : dtListSap.mva;
                dt.codimp = dt.codimp ? dt.codimp : dtListSap.codimp;
                dt.cstEntrada = dtListSap.tributacaoCstId;
                dt.bsOutras = dtListSap.bsOutras;
              }
            });

            const dtForm = this.createDadosTributariosItemForm();

            dtForm.patchValue(dt);
            dtFormArray.insert(dtIndex, dtForm);
          });
        }

        // criar forms de exceção
        // if (filial && filial.excecaoList) {
        //   const excecaoFormArray = form.get('excecaoList') as FormArray;
        //   filial.excecaoList.forEach((excecao, excecaoIndex) => {
        //     const excecaoForm = this.createExcecaoForm();

        //     excecaoForm.patchValue(excecao);
        //     excecaoFormArray.insert(excecaoIndex, excecaoForm);
        //   });
        // }
        form.patchValue(filial);
        this.filiaisForm.insert(index, form);
      });
    }

    if (this.isFiscalIntegrado$.getValue()){
      removeValidators(this.form)
    }
  }

  save() {
    if (this.canSave) {
      this.api.saveDraft(this.getRequestData()).subscribe(() => {
        this.toastService.toast('Salvo com sucesso!');
      }, () => {
        this.toastService.toast('Erro ao salvar!', ToastType.error);
        this.form.markAllAsTouched();
      });
    } else {
      throw new Error('Não é possivel salvar');
    }
  }

  submitForReview() {
    if (this.canSubmitForReview) {
      this.api.insertOrUpdate(this.getRequestData()).subscribe(({ status }) => {
        this.updateStatus(status);
      });
    } else {
      throw new Error('Não é possivel submeter para revisão');
    }
  }

  private getRequestData() {
    return {
      ...(this.form.getRawValue() as TaxDataForm),
      ...{
        id: this.productId,
        status: this.status,
      },
    };
  }

  // #region Métodos auxiliares do formulário

  applyNcm() {
    const ncmControl = this.form.get("ncm");
    const ncm = this.form.get("ncm").value;
    if (ncmControl.invalid) {
      this.toastService.toast("NCM invalido!", ToastType.error);
      return;
    }

    this.apiNCMDescricao.search(ncm).subscribe((result) => {
      if (result.length > 0) {
        const firstItem = result[0];

        const descricaoNcmControl = this.form.get("descricaoNcm");
        const segmentoNcmControl = this.form.get("segmentoNcm");

        ncmControl.patchValue(firstItem.ncm);
        descricaoNcmControl.patchValue(firstItem.descricao);
        segmentoNcmControl.patchValue(firstItem.segmento);
      } else {
        this.toastService.toast("NCM não encontrado!", ToastType.error);
      }
    });
  }

  addFilial() {
    const newItem = this.createFilialForm();

    this.listenFilialChange(
      newItem.get("filialId") as FormControl,
      this.filiaisForm.length
    );

    this.filiaisForm.push(newItem);
    this.filterFilial(this.filiaisForm.controls.length);
  }

  updateSelectedCst(selectedId: string){
    this.origemCst$.pipe(take(1)).subscribe((origemCst) => {
      const isForeign = !!origemCst.find(x => x.id === selectedId && x.descricao.includes('Estrangeiro'));
      this.isSelectedCstForeigner$.next(isForeign);
    });
  }

  removeFilial(index: number) {
    this.filiaisForm.removeAt(index);
    this.filterFilial(index - 1);

    this.filialChange$.next(this.filiaisForm.controls.map((control, index) => {
      return {uf: control.get('uf').value, index};
    }));
  }

  filterFilial(position: number = 0) {
    const filiaisForm = this.filiaisForm.value;
    this.filiaisFiltered$ = this.filiais$.pipe(take(1)).pipe(map(filial => filial.filter(data => data.filialId)));

    filiaisForm.map((filialForm, index:number) => {
      if (index != position) {
        this.filiaisFiltered$ = this.filiaisFiltered$.pipe(take(1)).pipe(
          map((filiais) => filiais.filter((f) => f.filialId != filialForm.filialId))
        );
      }
    });
  }

  valueStateForIdx(filialIdx:number, dadosTributariosIndex:number):string{
    return (((this.filiaisForm.get([ filialIdx, "dadosTributariosList" ]) as FormArray).controls[dadosTributariosIndex] as FormGroup).controls['uf'].value);
  }

  getStatesNotSelected(arrayStates:Estado[], dadosTributarios:FormGroup[]): Observable<Estado[]>{
    return of(arrayStates.filter(item => !(dadosTributarios.find(dada => dada.controls['uf'].value == item.uf)) ))
  }

  stateIsSelected(state:string, dadosTributarios:FormGroup[]):boolean{
    if(dadosTributarios.find(dada => dada.controls['uf'].value == state)){
      return false
    }
    return true
  }

  getValueItem(uf:string):Estado{
    return this.estados.find(e => e.uf == uf);
  }

  updateRegiao(dadosTributarioItem:FormGroup, dadosTributario:FormGroup[],filialIdx?:number,dadosTributariosIndex?:number){
    if(dadosTributario.filter(item => item.controls['uf'].value == dadosTributarioItem.controls['uf'].value).length == 2){
      this.toastService.toast(`${dadosTributarioItem.controls['uf'].value} já foi selecionado para os Dados Tributários!`);
      (((this.filiaisForm.get([ filialIdx, "dadosTributariosList" ]) as FormArray).controls[dadosTributariosIndex] as FormGroup).controls['uf'].patchValue(''));
    }else{
      let estado = this.estados.find(x => x.uf == dadosTributarioItem.controls['uf'].value);
      dadosTributarioItem.controls.regiao.patchValue(estado.regiao);
    }
  }

  addTableUf(filialIndex: number) {
    const newItem = this.createDadosTributariosItemForm();

    const dadosTributariosForm = this.getTributacaoForm(filialIndex);
    dadosTributariosForm.push(newItem)
  }

  removeTableUf(filialIndex: number, index: number) {
    const dadosTributariosForm = this.getTributacaoForm(filialIndex);
    dadosTributariosForm.removeAt(index);
  }

  addExcecao(filialIndex: number) {
    const excecaoFormArray = this.getExcecaoForm(filialIndex);
    const newItem = this.createExcecaoForm();
    excecaoFormArray.push(newItem);
  }

  removeExcecao(filialIndex: number, index: number) {
    const excecaoForm = this.getExcecaoForm(filialIndex);
    excecaoForm.removeAt(index);
  }

  // #endregion Métodos auxiliares do formulário

  // #region Funções de criação do form

  createForm() {
    return this.fb.group({
      origemCSTId: [undefined, Validators.required],
      ncm: [undefined, Validators.required],
      descricaoNcm: [undefined, Validators.required],
      segmentoNcm: [undefined, Validators.required],

      filialList: this.createFiliaisFormArray(),
    });
  }

  createFiliaisFormArray() {
    return this.fb.array([]);
  }

  createFilialForm() {
    const form = this.fb.group({
      filialId: [undefined, Validators.required],
      uf: [undefined, Validators.required],

      impostoFederalSaida: this.fb.group({
        ipi: this.fb.group({
          value: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
          base: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
          cstId: [undefined, Validators.required],
        }),
        pis: this.fb.group({
          value: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
          base: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
          cstId: [undefined, Validators.required],
        }),
        cofins: this.fb.group({
          value: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
          base: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
          cstId: [undefined, Validators.required],
        }),
      }),

      impostoFederalEntrada: this.fb.group({
        ipi: this.fb.group({
          value: [undefined],
          base: [undefined],
          cstId: [undefined],

          outras: [false],
          documentoFiscalId: [undefined],
        }),
        pis: this.fb.group({
          value: [undefined],
          base: [undefined],
          cstId: [undefined],
        }),
        cofins: this.fb.group({
          value: [undefined],
          base: [undefined],
          cstId: [undefined],
        }),
      }),

      temST: [false],
      cest: [undefined],

      dadosTributariosList: this.fb.array([]),
      // excecaoList: this.createExcecaoFormArray(),
    });

    const temST = form.get('temST');
    const cest = form.get('cest');

    temST.valueChanges.subscribe(value => {
      if(value)
        cest.setValidators([Validators.required]);
      else
        cest.clearValidators();

      cest.updateValueAndValidity({emitEvent: false});
    });

    return form;
  }


  // createDadosTributariosItemFormArray() {
  //   return this.fb.array([]);
  // }

  createDadosTributariosItemForm() {
    return this.fb.group({
      regiao: [undefined, Validators.required],
      uf: [undefined, Validators.required],
      icms: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
      baseCalculo: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
      tributacaoCstId: [undefined, Validators.required],
      cstEntrada: [undefined],
      bsOutras: [undefined],
      dirFiscal: [undefined],
      icmsFCP: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
      baseFCP: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
      aliquotaSt: [undefined, [Validators.min(0), Validators.max(100)]],
      aliquotaStFCP: [undefined, [Validators.min(0), Validators.max(100)]],
      mva: [undefined, [Validators.min(0), Validators.max(200)]],
      tipoSt: [undefined],
      infoSap: this.fb.group({
        regiao: [undefined],
        uf: [undefined],
        icms: [undefined],
        tributacaoCstId: [undefined],
        cstEntrada: [undefined],
        bsOutras: [undefined],
        dirFiscal: [undefined],
        icmsFCP: [undefined],
        codimp: [undefined],
        baseCalculo: [undefined],
        mva: [undefined],
        tipoSt: [undefined],
        baseFCP: [undefined],
        aliquotaSt: [undefined],
        aliquotaStFCP: [undefined],
      })
    });
  }

  // createExcecaoFormArray() {
  //   return this.fb.array([]);
  // }

  createExcecaoForm() {
    return this.fb.group({
      tipoId: [undefined, Validators.required],
      grupoId: [undefined, Validators.required],

      campo1: [undefined, Validators.required],
      campo2: [undefined, Validators.required],
      campo3: [undefined, Validators.required],
    });
  }

  // #endregion Funções de criação do form

  // #region helpers de acesso aos dados do form
  get filiaisForm() {
    return this.form.get("filialList") as FormArray;
  }

  private getFilial(id: string) {
    return this.filiais$.pipe(take(1)).pipe(
      map((filiais) => filiais.filter(filial => filial.filialId).find((f) => f.filialId == id))
    );
  }

  getBranchName(index: number, type: number) {
    const filialControl = this.filiaisForm.get([index]);
    const filialIdControl = filialControl.get("filialId");

    if (filialControl && type === 0) {
      return filialIdControl.valueChanges.pipe(take(1)).pipe(
        startWith(filialIdControl.value),
        switchMap((filialId) => this.getFilial(filialId)),
        map((filial) => (filial ? filial.estadoUF : ""))
      );
    } else if (filialControl && type === 1) {
      return filialIdControl.valueChanges.pipe(take(1)).pipe(
        startWith(filialIdControl.value),
        switchMap((filialId) => this.getFilial(filialId)),
        map((filial) => (filial ? `${filial.cidadeDescricao}, ${filial.estadoUF}` : ""))
      );
    } {
      return of("");
    }
  }

  listenFilialChange(control: FormControl, index: number) {
    control.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((filialId) => {
          return this.getFilial(filialId);
        })
      )
      .subscribe((filial) => {
        if (!filial) {
          return
        }

        this.filiaisForm.controls.map((x, i) => {
          if (x.get('filialId').value == filial.filialId){
            index = i;
          }
        });

        this.filiaisForm
          .get([index])
          .patchValue({
            cidadeId: filial.cidadeId,
            uf: filial.estadoUF,
            // fornecedorId: filial.fornecedorId
          });

        this.filialChange$.next(this.filiaisForm.controls.map((control, index) => {
          return {uf: control.get('uf').value, index};
        }));
      });
  }

  listenEstados(){
    this.apiEstadoService.getOnlyStates().pipe(take(1)).subscribe(estados => this.estados = estados)
  }

  listenTributacaoCst():void{
    this.apiTributacaoCst.getAll().pipe(take(1)).subscribe(r => this.tributacaoCST = r);
  }

  listenTributacaoCSTImposto() {

    this.tributacaoCSTIImposto$.pipe(take(1)).subscribe(impostos => {
      this.tributacaoCSTIImposto = impostos
      this.tributacaoCSTIpi = this.tributacaoCSTIImposto.filter(imposto => imposto.tipoImposto == 0);
      this.tributacaoCSTPisCofins = this.tributacaoCSTIImposto.filter(imposto => imposto.tipoImposto == 1);
    });
  }
  getTributacaoForm(filialIndex: number) {
    return this.filiaisForm.controls[filialIndex].get('dadosTributariosList') as FormArray;
  }

  getExcecaoForm(filialIndex: number) {
    return this.filiaisForm.controls[filialIndex].get('excecaoList') as FormArray;
  }

  getDadosTributariosForm(filialIndex: number) {
    return this.filiaisForm.controls[filialIndex].get('dadosTributariosList') as FormArray;
  }

  getGroups(tipoId?: string) {
    return tipoId
      ? this.apiGrupo.getGrupoByExcecaoWithCache(tipoId)
      : of<Grupo[]>([]);
  }

  // #endregion

  // #endregion
}
