import { Component, OnInit, Input } from '@angular/core';
import { ProductSpecificDataService } from '../../services/product-specific-data.service';
import { Observable, of } from 'rxjs';
import { startWith, switchMap, catchError, map, tap, takeUntil } from 'rxjs/operators';
import { FormControl, AbstractControl } from '@angular/forms';
import { ApiCampoDinamicoService } from 'src/app/services/api/dados-especificos/api-campo-dinamico.service';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { EnumVariacoesDadosEspecificos } from 'src/app/models/api/dados-especificos/enum-variacoes-dados-especificos.enum';
import { DynamicFormComponent } from 'src/app/lib/dynamic-form/dynamic-form.component';
import { DynamicField } from 'src/app/lib/dynamic-form/models/dynamic-field.interface';
import { ProductSpecificDataSubnivelService } from '../../services/product-specific-data-subnivel.service';

@Component({
  selector: 'c-dynamic-data-section',
  templateUrl: './dynamic-data-section.component.html',
  styleUrls: ['./dynamic-data-section.component.scss']
})
export class DynamicDataSectionComponent implements OnInit {

  @Input() options;

  constructor(
    public specificDataService: ProductSpecificDataService,
    public apiCampoDinamico: ApiCampoDinamicoService,
    public subnivelService: ProductSpecificDataSubnivelService,
  ) { }

  ngOnInit() {
    // this.subnivelService.options$.subscribe( options => this.options = options );
  }

  removeOnVariation = (df: DynamicFormComponent, field: DynamicField) => {
    const variacaoControl = this.specificDataService.form.get('variacoes');
    const variacao = variacaoControl.value;

    if (
      variacao === EnumVariacoesDadosEspecificos.Grade ||
      variacao === EnumVariacoesDadosEspecificos.Sortimento
    ) {
      field.hide = true;
      const fieldControl = df.form.get(field.id);
      if (fieldControl) { fieldControl.reset(' '); }
    } else {
      field.hide = false;
    }
  }

}
