import { SessionService } from "./../../../../services/session.service";
import { EnumElasticDashboardColumns } from "src/app/models/api/elastic/elastic-dashboard-columns.enum";
import { QueryFilter } from "src/app/models/api/query/query-filter.interface";
import { BehaviorSubject } from "rxjs";
import { ApiElasticDashboardService } from "src/app/services/api/elastic/api-elastic-dashboard.service";
import {
  EnumProductTableInReviewSubView,
  ProductTableInReview,
} from "./../product-list.component";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EnumTabStatus } from "src/app/models/api/produto/enum-tab-status.enum";
import { IPaginator } from "src/app/ui/paginator/paginator.component";
import { ProductTable } from "../product-list.component";
import { DashboardFornecedor } from "src/app/models/api/dashboard-fornecedor/dashboard-fornecedor";
import { IResponse } from "src/app/models/api/response.interface";
import { WorkflowProduto } from "src/app/models/api/workflow/workflow-produto.interface";
import { debounceTime, skip } from "rxjs/operators";
import { DashboardService } from "../../services/dashboard.service";
import { ApiUsuarioService } from "src/app/services/api/autenticacao/api-usuario.service";
import { Usuario } from "src/app/models/api/autenticacao/usuario";

@Component({
  selector: "c-product-list-table-in-review",
  templateUrl: "./product-list-table-in-review.component.html",
  styleUrls: ["./product-list-table-in-review.component.scss"],
})
export class ProductListTableInReviewComponent implements OnInit {
  integrationDataSource = [];
  @Output() onPaginate = new EventEmitter<{
    paginator: IPaginator;
    status: EnumProductTableInReviewSubView;
  }>();
  tabSelected: EnumProductTableInReviewSubView =
    EnumProductTableInReviewSubView.allInReview;
  EnumProductTableInReviewSubView = EnumProductTableInReviewSubView;
  defaultTableColumns: string[] = [
    "reference",
    "supplierCodSap",
    "supplierName",
    "description",
    "category",
    "quality",
    "createdAt",
    "createdAtCommercial",
    "registerResponsable",
    "commercialResponsable",
    "actions",
  ];
  inRevisionTabFilterPermission: string;
  tabs!: ProductTableInReview[];
  page$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0,
  });

  columnFilters$: BehaviorSubject<string[]>;

  isNotFornecedor = true;

  constructor(
    private elasticDashboardServ: ApiElasticDashboardService,
    private apiUsuarioService: ApiUsuarioService,
    private sessionService: SessionService,
    private dashboardService: DashboardService,
  ) {
    this.configureSearch();
  }

  async ngOnInit() {
    this.isNotFornecedor = this.sessionService.user.role !== 'fornecedor';
    this.createColumnFilters();
    this.createTabs();
    this.loadAllProductsData();
  }

  configureSearch() {
    this.dashboardService.search$.pipe(skip(1), debounceTime(800)).subscribe(() => {
      this.loadAllProductsData(true);
    });
  }

  createColumnFilters() {
    const columnValues: string[] = Object.keys(EnumElasticDashboardColumns).map(
      (key) => EnumElasticDashboardColumns[key]
    );

    this.columnFilters$ = new BehaviorSubject<string[]>(columnValues);
  }

  loadAllProductsData(isSearch: boolean = false) {
    for (const key of Object.keys(EnumProductTableInReviewSubView)) {
      const status = Number(key);
      // console.log({
      //   status,
      //   isNotFornecedor: this.isNotFornecedor,
      //   condicao2: this.isNotFornecedor && status === 0,
      //   condicaoTotal: this.isNotFornecedor || this.isNotFornecedor && status === 0
      // })
      if (!isNaN(status)) {
        if (this.isNotFornecedor || !this.isNotFornecedor && status === 0) {
          this.loadProductsData(status, isSearch);
        }
      }
    }
  }

  loadProductsData(status: EnumProductTableInReviewSubView, isSearch: boolean = false) {
    let api: any;
    const tab: ProductTableInReview = this.tabs.find(
      (elem) => elem.status == status
    );

    // Select api
    switch (status) {

      case EnumProductTableInReviewSubView.allInReview:
        api = this.elasticDashboardServ.getReviewTodos;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumProductTableInReviewSubView.integratedInReview:
        api = this.elasticDashboardServ.getReviewIntegrados;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumProductTableInReviewSubView.newInReview:
        api = this.elasticDashboardServ.getReviewNovos;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumProductTableInReviewSubView.commercialDraft:
        api = this.elasticDashboardServ.getReviewComercialRascunho;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumProductTableInReviewSubView.fiscalDataInReview:
        api = this.elasticDashboardServ.getReviewFiscal;
        api = api.bind(this.elasticDashboardServ);
        break;

      case EnumProductTableInReviewSubView.coastAndPriceInReview:
        api = this.elasticDashboardServ.getReviewCustoPreco;
        api = api.bind(this.elasticDashboardServ);
        break;

      default:
        break;
    }

    if (api) {
      const paginator = this.page$.value;

      api({
        query: this.dashboardService.search$.value,
        skip: isSearch ? 0 : (paginator.page - 1) * paginator.pageLength,
        take: paginator.pageLength,
        filteredColumns: this.columnFilters$.value,
        sortedColumns: tab ? tab.sort : undefined,
      } as QueryFilter).subscribe((res: any) => {
        this.createTabData(res, status);
      });
    }
  }

  private createTabData(
    data: IResponse<DashboardFornecedor[] | WorkflowProduto[]>,
    status: EnumProductTableInReviewSubView,
    subViewType?: EnumProductTableInReviewSubView
  ) {
    const tab = this.tabs.find((elem) => elem.status == status);

    if (tab) {
      tab.count = data.count || 0;
      tab.products = data.data || [];
      this.inRevisionTabFilterPermission =
        "rhcdm.dadosprodutos.listarIntegracoes";
    }
  }

  reload(paginator: IPaginator, status: EnumProductTableInReviewSubView) {
    this.page$.next(paginator);
    this.loadProductsData(status);
  }

  updateSort(
    data: { [x: string]: number },
    paginator: IPaginator,
    status: EnumProductTableInReviewSubView
  ) {
    const tab = this.tabs.find((elem) => {
      return elem.status == status;
    });

    tab.sort = data;
    this.reload(paginator, status);
  }

  private createTabs() {
    if (this.isNotFornecedor) {
      this.tabs = [
        {
          name: "in review",
          title: "Preenchimento Comercial",
          status: EnumProductTableInReviewSubView.commercialDraft,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
        {
          name: "in review",
          title: "Análise Cadastro",
          status: EnumProductTableInReviewSubView.newInReview,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
        {
          name: "in review",
          title: "Análise Fiscal",
          status: EnumProductTableInReviewSubView.fiscalDataInReview,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
        {
          name: "in review",
          title: "Análise Custo",
          status: EnumProductTableInReviewSubView.coastAndPriceInReview,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
        {
          name: "in review",
          title: "Análise Cadastro (Integrados)",
          status: EnumProductTableInReviewSubView.integratedInReview,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
        {
          name: "in review",
          title: "Todos",
          status: EnumProductTableInReviewSubView.allInReview,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
      ];
    } else {
      this.tabs = [
        {
          name: "in review",
          title: "Todos os produtos",
          status: EnumProductTableInReviewSubView.allInReview,
          subViews: [],
          permission: "rhcdm.dadosprodutos.listarEmRevisao",
          count: 0,
          products: [],
        },
      ];
    }
  }
}
