import { FormControl } from '@angular/forms';

const eanDunRegex = /^(\d{8}|\d{12,14})$/;
const eanSapRegex = /^(1{1}0{7})\d{6}$/

export function validateEanDun() {
  return (control: FormControl) => {
    const value: string = control.value;

    if (value == undefined || value.length == 0) {
      return null;
    }

    if (eanSapRegex.test(value)) {
      return null;
    }

    if (!eanDunRegex.test(value)) {
      return {
        eanDun: 'Deve possuir 8, 12, 13, ou 14 caracteres',
      };
    }

    const paddedValue = value.padStart(14, '0');
    const sum = paddedValue.split('')
                           .map( (pv, i) => (+pv) * ((i % 2 == 0) ? 3 : 1))
                           .reduce( (previous, current) => current + previous, 0 );
    // Valida EAN pelo método aritimético e pelo Range do EAN SAP
    if (!(sum % 10 == 0) && (parseInt(paddedValue) < 10000000000000 || parseInt(paddedValue) > 100000000099999999)) {
      return {
        eanDun: 'Código Inválido',
      };
    }
  };
}
