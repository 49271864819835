import { DynamicFieldEditorForm, DynamicFieldEditorFormValidation } from './form';
import { DynamicField, DynamicFieldValidator } from 'src/app/lib/dynamic-form/models/dynamic-field.interface';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { EnumCampoDinamicoType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-type.enum';
import { ValidacoesDinamicas } from 'src/app/models/api/dados-especificos/validacoes-dinamicas';
import { EnumCampoDinamicoValidationType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-validation-type.enum';
import { DynamicFieldEditorService } from './dynamic-field-editor.service';

export function convertCampoDinamicoToForm(campoDinamico: CampoDinamico): DynamicFieldEditorForm {
  const { dynamicFieldValidator: dfv, ...dynamicField } = campoDinamico;
  const dynamicFieldValidator: Partial<DynamicFieldEditorFormValidation> = {};

  if (dfv) {
    const requiredValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.Required );
    const emailValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.Email );
    const minLengthValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.MinLength );
    const maxLengthValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.MaxLength );
    const minValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.Min );
    const maxValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.Max );
    const patternValidation = dfv.find( v => v.type === EnumCampoDinamicoValidationType.Pattern );

    if ( requiredValidation ) {
      dynamicFieldValidator.required = true;
    }
    if ( emailValidation ) {
      dynamicFieldValidator.email = true;
    }
    if ( minLengthValidation ) {
      dynamicFieldValidator.minLength = true;
      dynamicFieldValidator.maxLengthValue = minLengthValidation.value;
    }
    if ( maxLengthValidation ) {
      dynamicFieldValidator.maxLength = true;
      dynamicFieldValidator.maxLengthValue = maxLengthValidation.value;
    }
    if ( minValidation ) {
      dynamicFieldValidator.min = true;
      dynamicFieldValidator.minValue = minValidation.value;
    }
    if ( maxValidation ) {
      dynamicFieldValidator.max = true;
      dynamicFieldValidator.maxValue = maxValidation.value;
    }
    if ( patternValidation ) {
      dynamicFieldValidator.pattern = true;
      dynamicFieldValidator.patternValue = patternValidation.pattern;
    }
  }

  return {
    dynamicFieldValidator,
    ...dynamicField,
  } as DynamicFieldEditorForm;
}

export function convertFormToCampoDinamico(form: DynamicFieldEditorForm): CampoDinamico {
  const { dynamicFieldValidator: dfv, min, max, ...dynamicField } = form;
  const dynamicFieldValidator: ValidacoesDinamicas[] = [];

  if (dynamicField.type == EnumCampoDinamicoType.Range) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Min,
      value: min,
      errorMessage: `Deve ser no minimo ${ min }!`
    } as ValidacoesDinamicas);

    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Max,
      value: max,
      errorMessage: `Deve ser no máximo ${ max }!`
    } as ValidacoesDinamicas);
  }

  if (dfv.required) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Required,
      errorMessage: 'Campo obrigatório!'
    } as ValidacoesDinamicas);
  }
  if (dfv.email) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Email,
      errorMessage: 'E-mail inválido!'
    } as ValidacoesDinamicas);
  }
  if (dfv.minLength && dfv.minLengthValue != undefined) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.MinLength,
      value: dfv.minLengthValue,
      errorMessage: `Deve ter ao menos ${ dfv.minLengthValue } caracteres!`
    } as ValidacoesDinamicas);
  }
  if (dfv.maxLength && dfv.maxLengthValue != undefined) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.MaxLength,
      value: dfv.maxLengthValue,
      errorMessage: `Deve ter no máximo ${ dfv.maxLengthValue } caracteres!`
    } as ValidacoesDinamicas);
  }
  if (dfv.min && dfv.minValue != undefined) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Min,
      value: dfv.minValue,
      errorMessage: `Deve ser no minimo ${ dfv.minValue }!`
    } as ValidacoesDinamicas);
  }
  if (dfv.max && dfv.maxValue != undefined) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Max,
      value: dfv.maxValue,
      errorMessage: `Deve ser no máximo ${ dfv.maxValue }!`
    } as ValidacoesDinamicas);
  }
  if (dfv.pattern && dfv.patternValue != undefined) {
    dynamicFieldValidator.push({
      type: EnumCampoDinamicoValidationType.Pattern,
      pattern: dfv.patternValue,
      errorMessage: 'Padrão inválido!'
    } as ValidacoesDinamicas);
  }

  return { dynamicFieldValidator, min, max, ...dynamicField };
}

export function convertFormToDynamicField(form: DynamicFieldEditorForm): DynamicField {
  const campoDinamico = convertFormToCampoDinamico(form);
  return ({
    ...campoDinamico,
    label: campoDinamico.label || '',
    type: campoDinamico.type || EnumCampoDinamicoType.Text,
    id: '' + campoDinamico.type,

    dynamicFieldValidator: campoDinamico.dynamicFieldValidator as any[] || [],
    options: campoDinamico.options as any[] || []
  });
}
