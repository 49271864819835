import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { Marca } from 'src/app/models/api/dados-basicos/marca';
import { IResponse } from 'src/app/models/api/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiMarcaLicensaService {

  private baseUrl = 'MarcaLicenca';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosBasicos)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.useLoading().get<IResponse<Marca[]>>('');
  }

  insert(data: Marca) {
    return this.controller.useLoading().post('', data);
  }

  update(data: Marca) {
    return this.controller.useLoading().put('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().delete(id);
  }

}
