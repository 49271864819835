import { Injectable } from '@angular/core';
import { Fornecedor } from 'src/app/models/api/autenticacao/fornecedor';
import { ApiFornecedorService } from 'src/app/services/api/autenticacao/api-fornecedor.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupplierResolverService implements Resolve<Partial<Fornecedor>> {

  constructor(
    private apiFornecedor: ApiFornecedorService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Partial<Fornecedor>> | Partial<Fornecedor> {
    const id = route.paramMap.get('id');
    if (id) {
      console.log('oi', id)
      return this.apiFornecedor.get(id);
    } else {
      return ({});
    }
  }

}
