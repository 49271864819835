import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHttpClientService } from '../../api-http-client.service';
import { CategoriaFornecedor } from 'src/app/models/api/comercial/categoria-fornecedor';
import { Classificacao } from 'src/app/models/api/comercial/classificacao';

@Injectable({
  providedIn: 'root'
})
export class ApiClassificacaoService {
  private baseUrl = 'Classificacao';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Classificacao[]>('');
  }
}
