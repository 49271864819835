import { Component, OnInit, Output, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { PdmService } from '../pdm.service';

@Component({
  selector: 'c-pdm-rule-editor',
  templateUrl: './pdm-rule-editor.component.html',
  styleUrls: ['./pdm-rule-editor.component.scss']
})
export class PdmRuleEditorComponent {
  @Input() rule: string;

  constructor(
    public pdmService: PdmService
  ) { }
}
