import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiDadosProdutosService } from 'src/app/services/api/dados-produto/api-dados-produtos.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { zip, of, throwError } from 'rxjs';
import { ApiDadosBasicosService } from 'src/app/services/api/dados-basicos/api-dados-basicos.service';
import { ApiDadosEspecificosService } from 'src/app/services/api/dados-especificos/api-dados-especificos.service';
import { Product } from '../models/product';
import { ApiMidiaService } from 'src/app/services/api/midia/api-midia.service';
import { ApiDadosComerciaisService } from 'src/app/services/api/comercial/api-dados-comerciais.service';
import { ApiHistoricoService } from 'src/app/services/api/historico/api-historico.service';
import { ApiWorkflowService } from 'src/app/services/api/workflow/api-workflow.service';
import { DadosSap, DadosVtex, Produto } from 'src/app/models/api/dados-produto/produto';
import { ApiDadosFiscaisService } from 'src/app/services/api/dados-fiscais/api-dados-fiscais.service';
import { ApiCustoPrecoService } from 'src/app/services/api/custo-preco/api-custo-preco.service';

@Injectable()
export class ProductResolverService implements Resolve<Product> {

  constructor(
    private apiDadosProduto: ApiDadosProdutosService,
    private apiDadosBasicos: ApiDadosBasicosService,
    private apiDadosEspecificos: ApiDadosEspecificosService,
    private apiMidia: ApiMidiaService,
    private apiDadosFiscais: ApiDadosFiscaisService,
    private apiCustoPrecoService: ApiCustoPrecoService,
    private apiDadosComerciais: ApiDadosComerciaisService,
    private apiHistorico: ApiHistoricoService,
    private apiWorkflow: ApiWorkflowService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product> {
    const id = route.paramMap.get('id');

    if (id) {
      return this.apiDadosProduto.get(id).pipe(
        switchMap( data =>
          zip(
            // caso o id não exista sera retornado undefined
            this.apiDadosBasicos.get(data.id),
            this.apiDadosEspecificos.get(data.id),
            this.apiMidia.get(data.id),
            this.apiDadosFiscais.get(data.id), // this.apiDadosFiscais.get(data.id),
            this.apiCustoPrecoService.get(data.id),
            this.apiDadosComerciais.get(data.id).pipe(
              catchError( error => error.status == 403 || error.status == 400 ? of(null) : throwError(error)
            )),
            this.apiHistorico.get(data.id),
            this.apiWorkflow.getById(data.id)
          ).pipe(
            map(tabs => ({
              id: data.id,
              status: data.statusProduto,
              header: { ...data.header,
                codigoSap: this.getCodSap(data),
                idVtex: this.getIdVtex(data),
                sku: this.getSku(data),
                qualidadeCadastro: data.qualidadeCadastro
              },
              sortimento: this.getSortimentos(data),
              grade: this.getGrades(data),
              dadosBasicos: tabs[0],
              dadosEspecificos: tabs[1],
              midia: tabs[2],
              dadosFiscais: tabs[3],
              custoPreco: tabs[4],
              dadosComerciais: tabs[5],
              historico: tabs[6],
              workflow: tabs[7],
            } as Product)
          )
        )),
      );
    } else {
      return this.apiDadosProduto.createProductId().pipe( map( produto => {
        return { id: produto.id, header: produto.header };
      }));
    }
  }

  private getCodSap(produto: Produto): string {
    const aux = produto.dadosSap && produto.dadosSap.find( i => !i.idGrade );
    return aux && aux.codigoSap;
  }

  private getIdVtex(produto: Produto): string {
    const aux = produto.dadosVtex && produto.dadosVtex.find( i => !i.idSortimento );
    return aux && aux.idVtex;
  }
  private getSku(produto: Produto): string {
    const aux = produto.dadosVtex && produto.dadosVtex.find( i => !i.idSortimento );
    return aux && aux.skus && aux.skus[0] && aux.skus[0].idVtex;
  }

  private getSortimentos(produto: Produto) {
    const codigoSap = this.getCodSap(produto);
    const items = produto.dadosVtex.filter( i => !!i.idSortimento ).map( i => ({
      id: i.idSortimento,
      idVtex: i.idVtex,
      sku: i.skus && i.skus[0] && i.skus[0].idVtex,
      codigoSap,
    })).reduce( (o, c) => {
      o[c.id] = c;
      return o;
    }, {});
    return items || [];
  }

  private getGrades(produto: Produto) {
    const aux = produto.dadosVtex && produto.dadosVtex.find( i => !i.idSortimento );
    const codSapGrades = produto.dadosSap.filter( i => i.idGrade );
    return codSapGrades.map( i => {
      const sku = aux && aux.skus.find( s => s.idGrade == i.idGrade );
      return {
        id: i.idGrade,
        idVtex: aux && aux.idVtex,
        codigoSap: i.codigoSap,
        sku: sku && sku.idVtex,
      };
    }).reduce( (o, c) => {
      o[c.id] = c;
      return o;
    }, {}) || [];
  }
}
