import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';

import { Grupo } from 'src/app/models/api/comercial/grupo';
import { ApiGrupoService } from 'src/app/services/api/comercial/api-grupo-service';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';

@Component({
  selector: 'c-commercial-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class CommercialGroupComponent implements OnInit {
  groupData: GroupEditable[];
  groupPaginatedData: GroupEditable[];
  groupFilteredData: GroupEditable[];
  columns: string[] = ['name', 'active', 'actions'];
  form: FormGroup;
  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });
  search$ = new BehaviorSubject('');
  constructor(
    private apiGrupo: ApiGrupoService,
    private fb: FormBuilder,
  ) {
    this.createForm();
    this.listenPaginator();
    this.listenSearch();
  }

  ngOnInit() {
    this.loadGroupData();
  }

  createForm() {
    this.form = this.fb.group({
      descricao: [null],
      ativo: [null]
    });
  }

  loadGroupData() {
    this.apiGrupo.getAllAtivosInativos().subscribe(res => {
      this.groupData = res.data as GroupEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: GroupEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = true;
  }

  cancelEdit(data: GroupEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = false;
  }

  async save(data: GroupEditable) {
    if (!data) {
      const group: GroupEditable = this.form.value;
      group.descricao = group.descricao;
      group.ativo = true;

      await this.apiGrupo.insert(group).toPromise();
      this.form.reset();
      this.loadGroupData();
    } else {
      const description = data.newDescription;

      await this.apiGrupo.update({
        id: data.id, descricao: description, ativo: data.ativo
      }).toPromise();
      data.descricao = description;
      data.isEditActive = false;
    }
  }

  async changeEnableDisable(data: GroupEditable) {
    try {
      await this.apiGrupo.delete(data.id).toPromise();
    } catch {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.groupFilteredData = this.groupData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          const group = regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          return group;
        });
      } else {
        this.groupFilteredData = undefined;
      }
    });
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.groupData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.groupPaginatedData = this.groupData.slice(startAt, startAt + data.pageLength);
    }
  }

  get canCreate() {
    return this.descricao.value;
  }
  get descricao() { return this.form.get('descricao'); }
}

interface GroupEditable extends Grupo {
  isEditActive: boolean;
  newDescription: string;
}