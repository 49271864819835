import { Component, Input, ElementRef, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorGroupMixin } from 'src/app/shared/classes/control-value-accessor-group-mixin.class';

@Component({
  selector: 'c-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true
    }
  ]
})
export class CheckboxGroupComponent extends ControlValueAccessorGroupMixin {

  @Input() label: string;

  constructor(
    elementRef: ElementRef,
    injector: Injector
    ) {
    super(elementRef, injector);
    this.arrayMode = true;
    this.replicateValidators = false;
  }

}
