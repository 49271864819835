import { Injectable } from '@angular/core';
import { ModalService, ModalConfig } from './modal.service';
import { tap } from 'rxjs/operators';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';

@Injectable()
export class AlertDialogService {

  constructor(
    private modalService: ModalService
  ) { }

  alert(message: string, modalConfig: ModalConfig<any, AlertDialogComponent> = {}) {
    const ref = this.modalService.open(AlertDialogComponent, {
      initialState: {
        type: 'dialog'
      },
      contentInitialState: {
        message
      },
      ...modalConfig
    });

    return ref.contentComponentRef.instance.confirm.toPromise();
  }

}
