import { Component, OnInit } from '@angular/core';
import { ProductBasicDataService } from '../services/product-basic-data.service';
import { ProductService } from '../services/product.service';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { combineLatest, merge } from 'rxjs';

@Component({
  selector: 'c-product-basic-data',
  templateUrl: './product-basic-data.component.html',
  styleUrls: ['./product-basic-data.component.scss']
})
export class ProductBasicDataComponent implements OnInit {

  enumAba = EnumAba;

  constructor(
    public productBasicDataService: ProductBasicDataService,
    public productService: ProductService
  ) { }

  ngOnInit() {
    this.productBasicDataService.form.get("foraDaEmbalagem.pesoBruto").valueChanges.subscribe(v => {
      this.productBasicDataService.form.get("embalagemUnitaria.pesoLiquido").setValue(v);
    });
    const pesoBrutoUnitarioControl = this.productBasicDataService.form.get('embalagemUnitaria.pesoBruto')
    const embalagemMultiplaCaixaControl = this.productBasicDataService.form.get('embalagemCaixa.embalagemMultipla')
    merge(pesoBrutoUnitarioControl.valueChanges, embalagemMultiplaCaixaControl.valueChanges)
        .subscribe((v) => {
          const pesoBruto = pesoBrutoUnitarioControl.value;
          const unidade = embalagemMultiplaCaixaControl.value;
          if (pesoBruto && unidade){
            this.productBasicDataService.form.get("embalagemCaixa.pesoLiquido").setValue(parseFloat((pesoBruto * unidade).toFixed(3)));
          }
          else{
            this.productBasicDataService.form.get("embalagemCaixa.pesoLiquido").setValue(null);
          }
    })
  }
}
