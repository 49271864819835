import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { IResponse } from 'src/app/models/api/response.interface';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCategoriaService {

  private baseUrl = 'Categoria';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  getByDepartamentoId(departamentoId: string) {
    if (!departamentoId) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().useLoading().get<Categoria>(departamentoId);
  }

  getAll() {
    return this.controller.useLoading().get<IResponse<Categoria[]>>('');
  }

  getAllAtivosInativos() {
    return this.controller.useLoading().get<IResponse<Categoria[]>>('/BuscarAtivosInativos');
  }

  insert(data: Categoria) {
    return this.controller.useLoading().post('', data);
  }

  update(data: Categoria) {
    return this.controller.useLoading().put('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().delete(id);
  }
}
