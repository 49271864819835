import { Component, OnInit } from '@angular/core';
import { PdmService } from '../pdm.service';

@Component({
  selector: 'c-pdm-header',
  templateUrl: './pdm-header.component.html',
  styleUrls: ['./pdm-header.component.scss']
})
export class PdmHeaderComponent implements OnInit {

  constructor(
    public pdmService: PdmService
  ) { }

  ngOnInit() {
  }

}
