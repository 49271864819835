import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupplierComponent } from './supplier.component';
import { SupplierResolverService } from './services/supplier-resolver.service';
import { CanDeactivateGuard } from 'src/app/services/guards/can-deactivate.guard';
import { AuthGuard } from 'src/app/services/guards/auth.guard';


const routes: Routes = [
  {
    path: '', redirectTo: '/dashboard', pathMatch: 'full'
  },
  {
    path: 'new',
    component: SupplierComponent,
    resolve: {
      supplier: SupplierResolverService
    },
    canDeactivate: [
      CanDeactivateGuard
    ],
    canActivate: [AuthGuard],
    data: { permission: 'rhcdm.fornecedor.criar' }
  },
  {
    path: ':id',
    component: SupplierComponent,
    resolve: {
      supplier: SupplierResolverService
    },
    canDeactivate: [
      CanDeactivateGuard
    ],
    canActivate: [AuthGuard],
  
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierRoutingModule { }
