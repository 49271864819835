import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast.component';
import { ToastService } from './toast.service';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [ToastComponent],
  providers: [
    ToastService
  ],
  imports: [
    CommonModule,
    IconModule,
  ],
  exports: [
    ToastComponent
  ]
})
export class ToastModule { }
