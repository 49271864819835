import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { HttpParams } from '@angular/common/http';
import { IResponse } from 'src/app/models/api/response.interface';
import { DadosBasicos } from 'src/app/models/api/dados-basicos/dados-basicos';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiDadosBasicosService {

  private baseUrl = 'DadosBasicos';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosBasicos)
      .useBaseUrl(this.baseUrl);
  }

  get(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().useLoading().get<DadosBasicos>(id);
  }

  getAll() {
    return this.controller.get<IResponse<DadosBasicos[]>>('');
  }

  getWithFilter(filter: { query?: string, skip?: number, take?: number }) {
    const params = new HttpParams()
      .set('query', filter.query)
      .set('skip', '' + filter.skip)
      .set('take', '' + filter.take);

    return this.controller.get<IResponse<DadosBasicos[]>>('BuscaComFiltos', { params });
  }

  validateEan(ean: string, productId: string) {
    const params = new HttpParams()
      .set('id', productId)
      .set('ean', ean);

    return this.controller.unwrapMessage().get<string>('ValidaEan', { params });
  }

  validateDun(dun: string, productId: string) {
    const params = new HttpParams()
      .set('id', productId)
      .set('dun', dun);

    return this.controller.unwrapMessage().get<string>('ValidateDun', { params });
  }

  validateGS1(value: string) {
    const params = new HttpParams()
      .set('valor', value);

    return this.controller.unwrap().get<string>('ValidaGS1', { params });
  }

  insertOrUpdate(data: DadosBasicos) {
    return this.controller.unwrap().useLoading().post<DadosBasicos>('', data);
  }

  validate(data: DadosBasicos) {
    return this.controller.unwrap().useLoading().post<DadosBasicos>('ValidarCadastro', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.delete(id);
  }

  saveDraft(draft: DadosBasicos) {
    return this.controller.unwrap().useLoading().post<DadosBasicos>('draft', draft);
  }

}
