import { Component, OnInit } from '@angular/core';
import { XmlService } from './services/xml.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { CanComponentDeactivate } from 'src/app/services/guards/can-deactivate.guard';
import { TagItem } from 'src/app/models/api/xml/tag-item';

@Component({
  selector: 'c-xml',
  templateUrl: './xml.component.html',
  styleUrls: ['./xml.component.scss'],
  providers: [
    XmlService
  ]
})
export class XmlComponent implements OnInit, CanComponentDeactivate {

  private MENOR_PRECO_DO_PRODUTO: string = 'Menor Preço do Produto';

  constructor(
    public xmlService: XmlService,
    public confirmDialogService: ConfirmDialogService
  ) { }

  canDeactivate() {
    return this.xmlService.readonly ||
      this.xmlService.form.pristine ||
      this.confirmDialogService.confirm('Tem certeza que deseja sair sem salvar?');
  }

  ngOnInit() {
  }

  isArgumentField(elem: any){
    let selectedTag: TagItem = elem.value;

    if(selectedTag == null)
      return false;

    return selectedTag.nomeDescritivo == this.MENOR_PRECO_DO_PRODUTO;
  }

  getArgumentLabel(elem: any){
    let selectedTag: TagItem = elem.value;

    if(selectedTag == null)
      return "";

    if(selectedTag.nomeDescritivo == this.MENOR_PRECO_DO_PRODUTO)
      return  "Desconto";
  }

  getArgumentInputType(elem: any){
    let selectedTag: TagItem = elem.value;

    if(selectedTag == null)
      return "";

    if(selectedTag.nomeDescritivo == this.MENOR_PRECO_DO_PRODUTO)
      return  "number";
  }

}
