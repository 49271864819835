import { Component, OnInit, Input, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Component({
  selector: 'c-dynamic-tab-item',
  templateUrl: './dynamic-tab-item.component.html',
  styleUrls: ['./dynamic-tab-item.component.scss']
})
export class DynamicTabItemComponent implements OnInit {

  @Input() removeAble = true;
  @Input() confirmRemoveMessage = 'Tem certeza que deseja remover este item?';

  @Input()
  active: boolean;

  @Output() itemRemoved = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
