export enum EnumTabStatus {
  draft = 0,
  review = 1,
  inconsistency = 2,
  registered = 3,
  inactive = 4,
  integration = 5,
  inIntegration = 6,
  massIntegration = 7,
  responsable = 8
}
