import { FormGroup, FormBuilder } from '@angular/forms';
import { CustoPreco } from 'src/app/models/api/custo-preco/custo-preco.interface';
import { DadosBasicos } from 'src/app/models/api/dados-basicos/dados-basicos';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { DadosEspecificos } from 'src/app/models/api/dados-especificos/dados-especificos';
import { DadosFiscais } from 'src/app/models/api/dados-fiscais/dados-fiscais';
import { Midia } from 'src/app/models/api/dados-midia/midia.interface';

export abstract class ProductBaseEntity<T = any> {

  productId: string;
  form: FormGroup;
  status: EnumStatus;
  protected canSaveFinished: boolean;
  protected canSaveReview: boolean;
  protected canSaveRepproved: boolean;
  protected canApproveOrReprove: boolean;

  constructor(
    public fb: FormBuilder
  ) { }

  protected initForm() {
    this.form = this.createForm();
  }

  updateStatus(status: EnumStatus) {
    this.status = status;
  }

  setValue(productId: string, status: EnumStatus, value: T) {
    this.productId = productId;
    this.status = status;
    this.form.reset();
    this.form.patchValue(value);
  }

  get canSubmitForReview() {
    return this.form.valid && (
      ([EnumStatus.Draft, EnumStatus.Reprovado, EnumStatus.Finalizado].includes(this.status)) ||
      (this.status == EnumStatus.Revisao && this.canSaveReview)
    );
  }

  canSubmitBasicDataForReview(dadosBasicos: DadosBasicos) {
    // => Após aba Dados Basicos entrar em Finalizada não deve mais voltar para revisão através do ENVIAR CADASTRO!
    if (dadosBasicos.status == EnumStatus.Finalizado)
      return false;
    else
      return true;
  }

  canSubmitSpecificDataForReview(dadosEspecificos: DadosEspecificos) {
    // => Após aba Dados Especificos entrar em Finalizada não deve mais voltar para revisão através do ENVIAR CADASTRO!
    if (dadosEspecificos.status == EnumStatus.Finalizado)
      return false;
    else
      return true;
  }

  canSubmitMediaDataForReview(midia: Midia) {
    // => Após aba Media entrar em Finalizada não deve mais voltar para revisão através do ENVIAR CADASTRO!
    if (midia.status == EnumStatus.Finalizado)
      return false;
    else
      return true;
  }

  canSubmitTaxDataForReview(dadosFiscais: DadosFiscais) {
    // => Após aba Fiscal entrar em Finalizada não deve mais voltar para revisão através do ENVIAR CADASTRO!
    if (dadosFiscais.status == EnumStatus.Finalizado)
      return false;
    else
      return true;
  }

  canSubmitCoastPriceDataForReview(custoPreco: CustoPreco) {
    // => Após aba Custo Preço entrar em Finalizada não deve mais voltar para revisão através do ENVIAR CADASTRO!
    if (custoPreco.status == EnumStatus.Finalizado)
      return false;
    else 
      return true;
  }

  get canSave() {
    // é draft?
    if (this.status === EnumStatus.Draft) {
      return true;
    }

    // esta em revisão e pode salvar para revisão?
    if (this.status === EnumStatus.Revisao && this.canSaveReview) {
      return true;
    }

    // esta finalizado e pode salvar quando finalizado?
    if (this.status === EnumStatus.Finalizado && this.canSaveFinished) {
      return true;
    }
    // esta reprovado e pode salvar quando reprovado?
    if (this.status === EnumStatus.Reprovado && this.canSaveRepproved) {
      return true;
    }

    return false;
  }

  get isValid() {
    return this.form.valid;
  }

  abstract createForm(): FormGroup;
}
