import { Component, OnInit } from '@angular/core';
import { ApiGrupoPermissaoService } from 'src/app/services/api/autenticacao/api-grupo-permissao.service';
import { GrupoPermissao } from 'src/app/models/api/autenticacao/grupo-permissao.interface';
import { RouteDataService } from 'src/app/services/route-data.service';

@Component({
  selector: 'c-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

  roleData: GrupoPermissao[];
  columns: string[] = ['name', 'actions'];

  constructor(
    private apiGrupoPermissaoService: ApiGrupoPermissaoService,
    private routeData: RouteDataService
  ) { }

  ngOnInit() {
    this.loadRoleData();
  }

  loadRoleData() {
    this.apiGrupoPermissaoService.get().subscribe(res => {
      this.roleData = res;
    });
  }

  edit(data: GrupoPermissao) {
    this.routeData.data = data;
  }

}
