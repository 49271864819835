import { Component, OnInit } from '@angular/core';
import { ProductSpecificDataService } from '../../services/product-specific-data.service';
import { ProductSpecificDataSubnivelService } from '../../services/product-specific-data-subnivel.service';

@Component({
  selector: 'c-descriptions-section',
  templateUrl: './descriptions-section.component.html',
  styleUrls: ['./descriptions-section.component.scss']
})
export class DescriptionsSectionComponent implements OnInit {

  constructor(
    public specificDataService: ProductSpecificDataService,
  ) { }

  ngOnInit() {
  }

}
