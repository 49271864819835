import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { XmlComponent } from './xml.component';
import { CanDeactivateGuard } from 'src/app/services/guards/can-deactivate.guard';
import { XmlResolverService } from './services/xml-resolver.service';
import { AuthGuard } from 'src/app/services/guards/auth.guard';

import { SharedModule } from 'src/app/shared/shared.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { RadioModule } from 'src/app/ui/radio/radio.module';
import { RangeModule } from 'src/app/ui/range/range.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { ModalModule } from 'src/app/ui/modal/modal.module';

const routes: Routes = [
  {
    path: 'new',
    component: XmlComponent,
    resolve: {
      xml: XmlResolverService
    },
    canDeactivate: [
      CanDeactivateGuard
    ]
  },
  {
    path: ':id',
    component: XmlComponent,
    resolve: {
      xml: XmlResolverService
    },
    canDeactivate: [
      CanDeactivateGuard
    ]
  }
];

@NgModule({
  declarations: [XmlComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    InputModule,
    SelectModule,
    CheckboxModule,
    RadioModule,
    RangeModule,
    CardModule,
    IconModule,
    ModalModule,
  ]
})
export class XmlModule { }
