import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { FormControl, ControlContainer } from '@angular/forms';
import { startWith, switchMap, map, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { ProductService } from '../services/product.service';
import { ProductTaxDataService } from '../services/product-tax-data.service';
import { TributacaoCST } from 'src/app/models/api/dados-fiscais/tributacao-cst';

@Directive({
  selector: '[cNFGNREPorTributacao]',
  exportAs: 'cNFGNREPorTributacao'
})
export class NFGNREPorTributacaoDirective implements OnDestroy, OnInit {

  @Input() tributacaoCSTControlName = 'tributacaoCstId';
  @Input() destaqueNFControlName = 'destaqueNFId';

  private destroy$ = new Subject();
  tributacaoCST: FormControl;
  destaqueNF: FormControl;
  nfgnre$: Observable<any[]>;

  constructor(
    public fg: ControlContainer,
    private taxDataService: ProductTaxDataService,
  ) { }

  ngOnInit() {
    this.tributacaoCST = this.fg.control.get(this.tributacaoCSTControlName) as FormControl;
    this.destaqueNF = this.fg.control.get(this.destaqueNFControlName) as FormControl;

    this.tributacaoCST.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe( tcst => {
      if (tcst) {
        this.destaqueNF.reset(null);
      };
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
