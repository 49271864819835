import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { LocalStorage } from 'src/app/shared/decorators/local-storage.decorator';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingService, LoadingName } from 'src/app/services/loading.service';
import { ModalService } from 'src/app/ui/modal/modal.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ApiDadosBasicosService } from 'src/app/services/api/dados-basicos/api-dados-basicos.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'c-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {

  @LocalStorage('user') user: any;
  test: any = 2;

  radioGroupControl = new FormControl({ value: 3 });
  tabs = [
    'rascunhos',
    'dados específicos',
    'mídia',
    'dados fiscais',
    'custo e preço',
    'histórico',
  ];
  selectControl = new FormControl([0, 2, 1, 6, 4], [Validators.required]);
  textAreaControl = new FormControl('alalalalal');
  checkboxGroupControl = new FormControl(null);
  inputGroupControl = new FormControl(null, [Validators.required]);
  // inputGroupControl = new FormControl({ altura: 10, largura: 20, profundidade: 30 }, [Validators.required]);
  rangeControl = new FormControl(null, Validators.required);
  imageUploader = new FormControl(null);
  videoUploader = new FormControl(null, [Validators.required]);
  compositionInput = new FormControl(null, Validators.required);
  checkboxInput = new FormControl(null);

  form: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.email]]
  });

  superArray = Array.from({ length: 1000 }).fill('');

  // Table data
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = [
    { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
    { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
    { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
    { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
    { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
    { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
    { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
    { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
    { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
    { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
  ];

  referenceForm = this.fb.group({
    radioGroupControl: this.radioGroupControl,
    selectControl: this.selectControl,
    textAreaControl: this.textAreaControl,
    checkboxGroupControl: this.checkboxGroupControl,
    inputGroupControl: this.inputGroupControl,
    rangeControl: this.rangeControl,
    imageUploader: this.imageUploader,
    videoUploader: this.videoUploader,
    compositionInput: this.compositionInput,
    form: this.form,
    checkboxInput: this.checkboxInput,
  });

  readonly = false;

  constructor(
    private loadingServ: LoadingService,
    private modalService: ModalService,
    private confirmDialogService: ConfirmDialogService,
    private vcr: ViewContainerRef,
    private toastService: ToastService,
    private fb: FormBuilder,
    private adb: ApiDadosBasicosService
  ) {
    this.loadingServ.setTimeToDisincrease(10);
    // this.referenceForm.disable();
  }

  toggleEnabled() {
    if (this.referenceForm.enabled) {
      this.referenceForm.disable();
    } else {
      this.referenceForm.enable();
    }
  }

  toggleReadonly() {
    this.readonly = !this.readonly;
  }

  ngOnInit() {
    // this.videoUploader.valueChanges.subscribe(res => console.log(res));
    // this.videoUploader.setValue([
    //   'https://www.youtube.com/embed/S2ILS8c8yYo',
    //   'https://www.youtube.com/embed/7v8rpVkHETc',
    //   'https://www.youtube.com/embed/eW3_PdaFTnM',
    //   'https://www.youtube.com/embed/xMYvG5lDjIQ',
    // ]);

    // this.imageUploader.valueChanges.subscribe(res => console.log(res));
    // this.imageUploader.setValue([
    //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf80NS-3wrzh7TWYnGr5OwFDUFMwy3pkofGbaiZwN71jGjbtWy&s',
    //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJHqzEYG2pHjrK8oN0GdqdNl0jb0ai1005z_NA0mfp_QALwx4u&s'
    // ]);

    // this.rangeControl.valueChanges.subscribe(res => console.log(res));
    // this.rangeControl.disable();
    // this.rangeControl.markAsDirty();
    // setTimeout(() => {
    //   this.rangeControl.setValue({ start: 1, end: 3 });
    // }, 3000);

    // this.radioGroupControl.valueChanges.subscribe(res => console.log(res));
    // this.radioGroupControl.disable();

    this.selectControl.valueChanges.subscribe(res => console.log(res));
    // this.selectControl.disable();

    // this.textAreaControl.valueChanges.subscribe(res => console.log(res));
    // this.textAreaControl.disable();

    // this.form.valueChanges.subscribe(res => console.log(res));
    // this.form.disable();

    // this.checkboxGroupControl.valueChanges.subscribe(res => console.log(res));
    // setTimeout(() => {
    //   this.checkboxGroupControl.setValue(['rihappy', 'marketplace']);
    // }, 4000)
    // this.checkboxGroupControl.disable();

    // this.inputGroupControl.valueChanges.subscribe(res => console.log(res, this.inputGroupControl.touched));
    // setTimeout(() => {
    //   this.inputGroupControl.markAsTouched();
    // }, 4000);
    // this.inputGroupControl.markAsTouched();
    // this.inputGroupControl.disable();

    // this.toastService.toast('toast teste asasasas');
    // this.toastService.toast('toast teste');
    // this.toastService.toast('toast teste asasa');
    // this.toastService.toast('toast teste');
    // this.toastService.toast('toast teste sdjasldkas');

    // this.adb.getAll().subscribe(console.log);
  }

  showLoading(name: LoadingName) {
    this.loadingServ.show(name);
  }

  openModal() {
    this.confirmDialogService.confirm('tem certeza?');
    // const modal = this.modalService.open('teste teste teste teste teste teste te testetes testetet testete tetestet', { initialState: {
    //   // hideOverlay: true,
    //   type: 'dialog',
    //   style: { color: 'blue' },
    //   class: 'lalal'
    // }});
  }

}
