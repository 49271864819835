import { NgModule, ModuleWithProviders, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreYouSureDirective } from './are-you-sure.directive';
import { AreYouSureDialog } from './are-you-sure-dialog.class';
import { DefaultAreYouSureDialog } from './default-are-you-sure-dialog.class';

@NgModule({
  declarations: [
    AreYouSureDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AreYouSureDirective
  ],
})
export class AreYouSureModule {

  static forRoot(config?: Type<AreYouSureDialog>): ModuleWithProviders<any> {
    return {
      ngModule: AreYouSureModule,
      providers: [
        {
          provide: AreYouSureDialog,
          useClass: config || DefaultAreYouSureDialog
        }
      ]
    };
  }

  static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: AreYouSureModule,
    };
  }
}
