import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { take } from "rxjs/operators";

import { ApiHttpClientService } from '../../api-http-client.service';
import { IResponse } from 'src/app/models/api/response.interface';
import { Imagem, Midia, MidiaGrupo } from 'src/app/models/api/dados-midia/midia.interface';
import { environment } from 'src/environments/environment';
import { SessionService } from "../../session.service";

@Injectable({
  providedIn: 'root'
})
export class ApiMidiaService {

  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
    private httpClient: HttpClient,
    private sessionService: SessionService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.midia);
  }

  get(id: string) {
    return this.controller.unwrap().useLoading().get<Midia>(`Midia/produto/${id}`);
  }

  insertOrUpdate(data: Midia) {
    return this.controller.unwrap().useLoading().post<Midia>('Midia', data);
  }

  validate(data: Midia) {
    return this.controller.unwrap().useLoading().post<Midia>('Midia/ValidarCadastro', data);
  }

  saveDraft(data: Midia) {
    return this.controller.unwrap().useLoading().post<Midia>('Midia/draft', data);
  }

  uploadImage(productId: string, data: File) {
    const formData = new FormData();
    formData.append('file', data);

    return this.controller
      .useLoading()
      .unwrap()
      .post<IResponse<Imagem>>(`Imagem/UploadImagem/${productId}`, formData);
  }

  uploadEspecialImage(productId: string, data: File) {
    const formData = new FormData();
    formData.append('file', data);

    return this.controller
      .useLoading()
      .unwrap()
      .post<IResponse<Imagem>>(`Imagem/UploadImagemEspecial/${productId}`, formData);
  }

  uploadImageAltaResolucao(productId: string, data: File) {
    const formData = new FormData();
    formData.append('file', data);

    return this.controller
      .useLoading()
      .unwrap()
      .post<IResponse<Imagem>>(`Imagem/UploadImagemAltaResolucao/${productId}`, formData);
  }

  getAll(search: string|null): Observable<MidiaGrupo>{
    return this.controller
               .useLoading()
               .unwrap()
               .get<MidiaGrupo>(`Midia/BuscarMidiaPorDadosSAPVTEX/${search}`)
               .pipe(take(1));
  }

  downloadImage(id: string, format: string): Observable<Blob>{
    return this.httpClient
               .get(
                 `${environment.api}/${environment.microservice.midia}/Midia/DownloadImagem/${id}/${format}`,
                 {
                   responseType: 'blob',
                   headers: this.sessionService.authorizationHeader()
                 }
               )
               .pipe(take(1));
  }

  downloadAllImages(ids: string[], format: string): Observable<Blob>{
    return this.httpClient
               .post(
                 `${environment.api}/${environment.microservice.midia}/Midia/DownloadImagem/${format}`,
                 ids,
                 {
                   responseType: 'blob',
                   headers: this.sessionService.authorizationHeader()
                 }
               );
  }
}
