import { Directive, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[controlRef]',
  exportAs: 'controlRef'
})
export class ControlRefDirective {

  constructor(
    @Optional() public controlRef: NgControl
  ) { }

  get changes() {
    return this.controlRef && this.controlRef.valueChanges;
  }

}
