import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Integracao } from 'src/app/models/api/workflow/integracao.interface';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';
import { IntegracaoQueryFilter } from 'src/app/models/api/workflow/integracao-query-filter.interface';
import { IntegracaoEmMassaList, IntegracaoEmMassa } from 'src/app/models/api/workflow/integracao-em-massa.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiIntegracaoService {

  private baseUrl = 'Integracao';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.workflow)
      .useBaseUrl(this.baseUrl);
  }

  integrateManyProducts(data: IntegracaoEmMassaList[]) {
    const integrationData: IntegracaoEmMassa = {
      integracoesList: data
    };

    return this.controller.useLoading().post<any>('/IntegrarEmMassa', integrationData);
  }

  getInIntegrations(query?: IntegracaoQueryFilter) {
    const { skip, take, integrarVTEX, integrarSAP, integrarCEVA } = query;
    return this.controller.useLoading().post<any>('BuscarComFiltro', {
      query: query.query,
      skip,
      take,
      integrarVTEX: integrarVTEX || true,
      integrarSAP: integrarSAP || true,
      integrarCEVA: integrarCEVA || true
    });
  }

  integrateSap(productId: string) {
    const data: Integracao = {
      produtoId: productId,
      integrarSap: true,
      integrarVTEX: false,
      integrarCeva: false
    };

    return this.controller.useLoading().post<any>('', data);
  }

  integrateVtex(productId: string) {
    const data: Integracao = {
      produtoId: productId,
      integrarSap: false,
      integrarVTEX: true,
      integrarCeva: false
    };

    return this.controller.useLoading().post<any>('', data);
  }

  integrateCeva(productId: string) {
    const data: Integracao = {
      produtoId: productId,
      integrarSap: false,
      integrarVTEX: false,
      integrarCeva: true
    };

    return this.controller.useLoading().post<any>('', data);
  }

  integrateAll(productId: string) {
    const data: Integracao = {
      produtoId: productId,
      integrarSap: true,
      integrarVTEX: true,
      integrarCeva: true
    };

    return this.controller.useLoading().post<any>('', data);
  }

  approveSapIntegration(productId: string, vtexStatus: boolean) {
    const data: Partial<WorkflowProduto> = {
      id: productId,
      bloquearIntegracaoSap: false,
      bloquearIntegracaoVTEX: vtexStatus
    };

    return this.controller.useLoading().post<any>('bloquear', data);
  }

  reproveSapIntegration(productId: string, vtexStatus: boolean) {
    const data: Partial<WorkflowProduto> = {
      id: productId,
      bloquearIntegracaoSap: true,
      bloquearIntegracaoVTEX: vtexStatus
    };

    return this.controller.useLoading().post<any>('bloquear', data);
  }

  approveVtexIntegration(productId: string, sapStatus: boolean) {
    const data: Partial<WorkflowProduto> = {
      id: productId,
      bloquearIntegracaoSap: sapStatus,
      bloquearIntegracaoVTEX: false
    };

    return this.controller.useLoading().post<any>('bloquear', data);
  }

  reproveVtexIntegration(productId: string, sapStatus: boolean) {
    const data: Partial<WorkflowProduto> = {
      id: productId,
      bloquearIntegracaoSap: sapStatus,
      bloquearIntegracaoVTEX: true
    };

    return this.controller.useLoading().post<any>('bloquear', data);
  }

}
