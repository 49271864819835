import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoriaGlobal } from 'src/app/models/api/dados-especificos/categoria-global.interface';
import { globalCategories } from 'src/app/shared/data/global-categories';

@Injectable({
  providedIn: 'root'
})
export class ApiCategoriaGlobalService {

  constructor() {
  }

  getAll() {
    return new Observable<CategoriaGlobal[]>((subscribe => {
      subscribe.next(globalCategories);
    }));
  }

}
