import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SessionService } from 'src/app/services/session.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'c-core-navbar',
  templateUrl: './core-navbar.component.html',
  styleUrls: ['./core-navbar.component.scss']
})
export class CoreNavbarComponent implements OnInit {

  isShowMenu: boolean;

  constructor(
    private authServ: AuthService,
    public sessionServ: SessionService,
    public notificationService: NotificationService,
    private readonly router: Router
  ) { }

  ngOnInit() {
  }

  showMenu(e: Event) {
    e.stopPropagation();
    this.isShowMenu = !this.isShowMenu;
  }

  closeMenu() {
    this.isShowMenu = false;
  }

  logout() {
    this.authServ.logout();
  }

  logoutForgot() {
    this.authServ.logout();
    this.router.navigate(['/login'], {queryParams: {forgot:true}})
  }

  formatNameOrEmail(value: string) {
    const separatedValue = value ? value.split(' ') : [];
    if (separatedValue.length > 0) {
      return separatedValue[0];
    }
    return value;
  }

  toggleNotification(e: Event) {
    e.stopPropagation();
    this.notificationService.toggle();
  }

}
