import { FormControl } from '@angular/forms';
import { MediaFormMedias } from '../models/media-form';

export function imageRequired(control: FormControl) {
  if (control) {
    if (control.value) {
      // Try find a media without image or undefined
      let existMediaWithouImage: boolean;
      control.value.forEach((elem: MediaFormMedias) => {
        if (!(elem && elem.imagens && elem.imagens.length > 0)) {
          existMediaWithouImage = true;
        }
      });

      if (existMediaWithouImage) {
        return { required: true };
      }

      return null;
    }

    return { required: true };
  }

  return null;
}
