import { Component, OnInit, Input } from '@angular/core';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';

@Component({
  selector: 'c-general-integration-info',
  templateUrl: './general-integration-info.component.html',
  styleUrls: ['./general-integration-info.component.scss']
})
export class GeneralIntegrationInfoComponent implements OnInit {

  @Input() data: WorkflowProduto;

  constructor() { }

  ngOnInit() {
  }

  getApprobationStatusName(value: boolean) {
    if (value != undefined) {
      return value ? 'product.approbation status.approved' : 'product.approbation status.reproved';
    } else {
      return '-';
    }
  }

}
