import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'c-license-group',
  templateUrl: './license-group.component.html',
  styleUrls: ['./license-group.component.scss']
})
export class LicenseGroupComponent implements OnInit {
  tabs!: LicenseTable[];
  constructor() {
    this.createTabs();
  }

  ngOnInit() { }

  private createTabs() {
    this.tabs = [
      { name: "licença", permission: "rhcdm.marcalicenca.listar" },
      { name: "licenciador", permission: "rhcdm.marcalicenca.listar" },
      { name: "grupo", permission: "rhcdm.marcalicenca.listar" },
      { name: "linha", permission: "rhcdm.marcalicenca.listar" },
      { name: "hierarquia", permission: "rhcdm.marcalicenca.listar" },
    ];
  }
}

export interface LicenseTable {
  name: string;
  permission: string;
}