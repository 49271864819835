import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingLoginComponent } from './loading-login/loading-login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoadingWaveComponent } from './loading-wave/loading-wave.component';

@NgModule({
  declarations: [
  LoadingComponent,
  LoadingLoginComponent,
  LoadingWaveComponent
],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    LoadingComponent
  ]
})
export class LoadingModule { }
