import { Injectable } from '@angular/core';
import { Storage } from '../shared/decorators/local-storage.decorator';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISession } from 'src/app/models/session/session.interface';
import { IUser } from 'src/app/models/user/user.interface';
import { pluck, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  @Storage('session')
  // tslint:disable-next-line: variable-name
  private _session: ISession;

  // tslint:disable-next-line: variable-name
  private _session$: BehaviorSubject<ISession>;
  session$: Observable<ISession>;
  user$: Observable<IUser>;

  constructor(
  ) {
    this._session$ = new BehaviorSubject(this._session);
    this.session$ = this._session$.asObservable();
    this.user$ = this.session$.pipe(switchMap((value) => value ? of(value).pipe(pluck('user')) : of(null)));
  }

  setSession(session: ISession) {
    this._session = session;
    this._session$.next(session);
  }

  reset() {
    this._session = null;
    this._session$.next(null);
  }

  getSession() {
    return this._session;
  }

  authorizationHeader(): { Authorization: string }{
    return { Authorization: `Bearer ${this.getSession().accessToken}` };
  }

  get user() {
    if (this._session) {
      return this._session.user;
    }
  }
}
