import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from "@angular/cdk/overlay";

import { ClickOutsideDirective } from './click-outside.directive';
import { IsAllowedDirective } from './is-allowed.directive';
import { UploadDirective } from './upload.directive';
import { DownloadDirective } from './download.directive';
import { AreYouSureModule } from './are-you-sure/are-you-sure.module';
import { MouseupOutsideDirective } from './mouseup-outside.directive';
import { AutoFocusDirective } from './autofocus.directive';
import { ReadOnlyDirective } from './readonly.directive';
import { AnimatedIfDirective } from './animated-if.directive';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ModalModule } from 'src/app/ui/modal/modal.module';
import { MapControlDirective } from './map-control.directive';
import { ControlRefDirective } from './control-ref.directive';
import { OpenImageDirective } from './open-image.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    IsAllowedDirective,
    UploadDirective,
    DownloadDirective,
    MouseupOutsideDirective,
    AutoFocusDirective,
    ReadOnlyDirective,
    AnimatedIfDirective,
    MapControlDirective,
    ControlRefDirective,
    OpenImageDirective
  ],
  imports: [
    CommonModule,
    ModalModule,
    OverlayModule,
    AreYouSureModule.forRoot(ConfirmDialogService)
  ],
  exports: [
    ClickOutsideDirective,
    IsAllowedDirective,
    UploadDirective,
    DownloadDirective,
    AreYouSureModule,
    MouseupOutsideDirective,
    AutoFocusDirective,
    ReadOnlyDirective,
    AnimatedIfDirective,
    MapControlDirective,
    ControlRefDirective,
    OpenImageDirective
  ]
})
export class DirectivesModule { }
