import { Component, OnInit } from '@angular/core';
import { ApiMarcaLicensaService } from 'src/app/services/api/dados-basicos/api-marca-licensa.service';
import { Marca } from 'src/app/models/api/dados-basicos/marca';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject } from 'rxjs';
import { skip, debounceTime } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'c-brand-and-license',
  templateUrl: './brand-and-license.component.html',
  styleUrls: ['./brand-and-license.component.scss']
})
export class BrandAndLicenseComponent implements OnInit {

  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });

  brandAndLicenseData: MarcaLicensaEditable[];
  brandAndLicensePaginatedData: MarcaLicensaEditable[];
  brandAndLicenseFilteredData: MarcaLicensaEditable[];
  columns: string[] = ['name', 'actions'];
  search$ = new BehaviorSubject('');
  newBrand = new FormControl(undefined);

  constructor(
    private apiMarcaLicensaService: ApiMarcaLicensaService
  ) {
    this.listenPaginator();
    this.listenSearch();
  }

  ngOnInit() {
    this.loadBrandAndLicenseData();
  }

  loadBrandAndLicenseData() {
    this.apiMarcaLicensaService.getAll().subscribe(res => {
      this.brandAndLicenseData = res.data as MarcaLicensaEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: MarcaLicensaEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = true;
  }

  cancelEdit(data: MarcaLicensaEditable) {
    data.newDescription = data.descricao;
    data.isEditActive = false;
  }

  save(data: MarcaLicensaEditable) {
    if (!data) {
      const description = this.newBrand.value.toUpperCase();
      this.apiMarcaLicensaService.insert({ descricao: description }).subscribe(res => {
        this.newBrand.reset();
        this.loadBrandAndLicenseData();
      });
    } else {
      const description = data.newDescription.toUpperCase();
      this.apiMarcaLicensaService.update({ id: data.id, descricao: description }).subscribe(res => {
        data.descricao = data.newDescription;
        data.isEditActive = false;
      });
    }
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.brandAndLicenseData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.brandAndLicensePaginatedData = this.brandAndLicenseData.slice(startAt, startAt + data.pageLength);
    }
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.brandAndLicenseFilteredData = this.brandAndLicenseData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          return regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        });
      } else {
        this.brandAndLicenseFilteredData = undefined;
      }
    });
  }

}

interface MarcaLicensaEditable extends Marca {
  isEditActive: boolean;
  newDescription: string;
}
