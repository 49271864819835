import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiAutenticacaoService } from 'src/app/services/api/autenticacao/api-autenticacao.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ApiUsuarioService } from 'src/app/services/api/autenticacao/api-usuario.service';
import { LocalStorage } from 'src/app/shared/decorators/local-storage.decorator';
import { ModalTermsComponent } from 'src/app/core/modal-terms/modal-terms.component';
import { ModalService } from 'src/app/ui/modal/modal.service';
import { ToastrService } from 'ngx-toastr';
import { ToastType } from 'src/app/ui/toast/toast.model';

@Component({
  selector: 'c-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @LocalStorage() keepConnected: boolean;

  form: FormGroup;
  resetPasswordForm: FormGroup;
  isForgetPasswordVisible: boolean;
  //termsOfUse = true
  privacyPolicie = true

  constructor(
    private fb: FormBuilder,
    private autenticationServ: ApiAutenticacaoService,
    private apiUsuarioService: ApiUsuarioService,
    private toastService: ToastService,
    private router: Router,
    private modalService: ModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.createForm();
    this.createResetPasswordForm();

    if (this.keepConnected == null) {
      this.keepConnected = true;
    }
  }

  ngOnInit() {
    this.route.snapshot.queryParams['forgot']? this.isForgetPasswordVisible = true: this.isForgetPasswordVisible = false
  }

  createForm() {
    this.form = this.fb.group({
      login: [null, [Validators.required]],
      pass: [null, [Validators.required]]
    });
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      login: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]]
    });
  }

  login() {
    const data = this.form.value;
    if(this.form.valid && this.privacyPolicie){
      this.autenticationServ.auth(data.login, data.pass).subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });
    }else{
      this.form.markAllAsTouched()
       //if(!this.termsOfUse) this.toastService.toast('Necessário aceitar os Termos de Uso!', ToastType.error);
       if(!this.privacyPolicie) this.toastService.toast('Necessário aceitar as Políticas de Privacidade!', ToastType.error);
      if(this.form.invalid)this.toastService.toast('Preencha os campos inválidos!', ToastType.error);
    }
  }

  showForgetPasswordForm() {
    this.isForgetPasswordVisible = true;
  }

  showLoginForm() {
    this.isForgetPasswordVisible = false;
  }

  sendResetPasswordCode() {
    const formValue = this.resetPasswordForm.value;
    if(this.resetPasswordForm.valid){
      this.apiUsuarioService.forgetPassword(formValue.login, formValue.email).subscribe(res => {
        this.showLoginForm();
        this.toastService.toast(`O código foi enviado para ${formValue.email}`);
        this.resetPasswordForm.reset();
      });
    }else{
      this.resetPasswordForm.markAllAsTouched()
      this.toastService.toast({description: 'Preencha os campos corretamente', type: ToastType.error});
    }
  }
  
  openDialog(type:string):void{
    this.modalService.open(ModalTermsComponent, {contentInitialState: {
      type
    }} );
  }

}
