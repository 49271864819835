import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Exercicio } from 'src/app/models/api/comercial/exercicio';

@Injectable({
  providedIn: 'root'
})
export class ApiExercicioService {
  private baseUrl = 'Exercicio';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Exercicio[]>('');
  }
}
