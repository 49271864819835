export interface DynamicField {
  id: string;
  label: string;
  type: any;

  initialValue?: any;
  dynamicFieldValidator?: DynamicFieldValidator[];
  // updateOn?: 'change' | 'blur'; // remover?

  layout?: DynamicFieldLayout;
  options?: FormFieldOption[];
  typeAttribute?: string;

  // configuraçãoes extras
  [key: string]: any;
  prefix?: string;
  sufix?: string;
  iconName?: string;
  mask?: string;
  rules?: string[];
  min?: number;
  max?: number;

  hide?: boolean;
}

export interface DynamicFieldValidator {
  type: DynamicFieldValidationType;
  pattern?: string;
  value?: number;
  errorMessage: string;
}

export enum DynamicFieldValidationType {
  Pattern = 0,
  Required = 1,
  Min = 2,
  MinLength = 3,
  Max = 4,
  MaxLength = 5,
  Email = 6,
  // JsonScheme = 7,
}

export interface DynamicFieldLayout {
  size?: number;
  highlight?: boolean;

  /** usar com cautela */
  // styles?: {[key: string]: string | number };
  /** usar com cautela */
  // classes?: string[];
}

export interface FormFieldOption {
  optionId: string;
  description: string;
}

