import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { IResponse } from 'src/app/models/api/response.interface';
import { DadosComerciais } from 'src/app/models/api/comercial/dados-comerciais';

@Injectable({
  providedIn: 'root'
})
export class ApiDadosComerciaisService {

  private baseUrl = 'DadosComerciais';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.comercial)
      .useBaseUrl(this.baseUrl);
  }

  get(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.unwrap().get<DadosComerciais>(id);
  }

  // getAll() {
  //   return this.controller.get<IResponse<DadosComerciais[]>>('');
  // }

  insertOrUpdate(data: DadosComerciais) {
    return this.controller.unwrap().post<DadosComerciais>('', data);
  }

  // delete(id: string) {
  //   if (!id) { throw new Error('Invalid Argument'); }
  //   return this.controller.delete(id);
  // }

  saveDraft(draft: Partial<DadosComerciais>) {
    return this.controller.unwrap().post<DadosComerciais>('draft', draft);
  }

  getTemplateOmni() {
    return this.controller.useLoading().get('DownloadPlanilhaTemplateOmni', { responseType: 'blob' });
  }

  importSpreadSheetOmni(res: object) {
    return this.controller.unwrap().useLoading().post('ImportarOmni', res);
  }

  submitCommercialForReview(data: DadosComerciais) {
    return this.controller.unwrap().post<DadosComerciais>('EnviarParaRevisao', data);
  }

}
