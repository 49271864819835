import { FormArray, FormGroup } from "@angular/forms";

export const removeValidators = (control: any) => {
  if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach(key => {
      const childControl = control.controls[key];
      removeValidators(childControl);
      childControl.clearValidators();
    });
  } else if (control instanceof FormArray) {
    control.controls.forEach(childControl => {
      removeValidators(childControl);
      childControl.clearValidators();
    });
  } else {
    control.clearValidators();
  }
  control.updateValueAndValidity();
}