import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { skip, debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiDepartamentoService } from 'src/app/services/api/dados-especificos/api-departamento.service';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { ApiCategoriaService } from 'src/app/services/api/dados-especificos/api-categoria.service';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { DashboardService } from '../services/dashboard.service';
import { CategoriaGlobal } from 'src/app/models/api/dados-especificos/categoria-global.interface';
import { ApiCategoriaGlobalService } from 'src/app/services/api/dados-especificos/api-global-category.service';
import { FormDinamicoCampo } from 'src/app/models/api/dados-especificos/form-dinamico';

@Component({
  selector: 'c-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

  paginator$ = new BehaviorSubject<IPaginator>({
    isLastPage: false,
    page: 1,
    pageLength: 25,
    total: 0
  });

  categoryData: CategoryEditable[];
  categoryPaginatedData: CategoryEditable[];
  categoryFilteredData: CategoryEditable[];
  columns: string[] = ['name', 'department', 'categoryId', 'globalCategory', 'active', 'actions'];
  search$ = new BehaviorSubject('');
  departmentsData: Departamento[];
  form: FormGroup;
  departmentChange$: Subscription;
  globalCategoriesData: CategoriaGlobal[];
  listStock: string[] = [];

  constructor(
    private apiCategoriaService: ApiCategoriaService,
    private apiDepartamentoService: ApiDepartamentoService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private apiGlobalCategoryService: ApiCategoriaGlobalService
  ) {
    this.getStock()
    this.createForm();
    this.listenPaginator();
    this.listenSearch();
  }
  getStock() {
    this.listStock = ["SPECIFICATION", "LIST", "RADIO", "COMBO"];
  }

  async ngOnInit() {
    await this.loadDepartmentsData();
    this.loadCategoryData();
    this.loadGlobalCategories();

    // Listen department changes
    this.departmentChange$ = this.dashboardService.departmentChange$.subscribe(() => {
      this.loadDepartmentsData();
    });
  }

  ngOnDestroy() {
    if (this.departmentChange$) {
      this.departmentChange$.unsubscribe();
    }
  }

  createForm() {
    this.form = this.fb.group({
      descricao: [null],
      metaTag: [null],
      stockKeepingUnitSelectionMode: [null],
      departamentoId: [null],
      globalCategoryId: [null],
      cloneFrom: [null],
      categoryId: 0
    });
  }

  loadGlobalCategories() {
    this.apiGlobalCategoryService.getAll().subscribe(res => {
      this.globalCategoriesData = res;
    });
  }

  async loadDepartmentsData() {
    const res = await this.apiDepartamentoService.getAllAtivosInativos().toPromise();
    this.departmentsData = res.data;
  }

  loadCategoryData() {
    this.apiCategoriaService.getAllAtivosInativos().subscribe(res => {
      this.categoryData = res.data as CategoryEditable[];
      this.paginate(this.paginator$.value);
    });
  }

  edit(data: CategoryEditable) {
    data.newDescription = data.descricao;
    data.newDepartmentId = data.departamentoId;
    data.newGlobalCategoryId = data.globalCategoryId;
    data.isEditActive = true;
  }

  cancelEdit(data: CategoryEditable) {
    data.newDescription = data.descricao;
    data.newDepartmentId = data.departamentoId;
    data.newGlobalCategoryId = data.globalCategoryId;
    data.isEditActive = false;
  }

  async save(data: CategoryEditable) {
    if (!data) {
      const category: Categoria = this.form.value;
      if (category.categoryId == null){
        category.categoryId = 0;
      }
      const { cloneFrom } = this.form.value;
      category.descricao = category.descricao.toUpperCase();
      category.camposList = this.getCamposList(cloneFrom);

      await this.apiCategoriaService.insert(category).toPromise();
      this.form.reset();
      this.loadCategoryData();
    } else {
      const description = data.newDescription.toUpperCase();
      const departmentId = data.newDepartmentId;
      const globalCategoryId = data.newGlobalCategoryId;
      const camposList = data.camposList;

      await this.apiCategoriaService.update({
        id: data.id, descricao: description, departamentoId: departmentId, globalCategoryId, camposList
      }).toPromise();
      data.descricao = description;
      data.departamentoId = departmentId;
      data.isEditActive = false;
    }

    this.dashboardService.categoryChange$.next();
  }

  async changeEnableDisable(data: CategoryEditable) {
    try {
      await this.apiCategoriaService.delete(data.id).toPromise();
    } catch {
      setTimeout(() => {
        window.location.reload();
      }, 4000);

    }
    this.dashboardService.categoryChange$.next();
  }

  private listenPaginator() {
    this.paginator$.subscribe(res => {
      this.paginate(res);
    });
  }

  private paginate(data: IPaginator) {
    if (this.categoryData) {
      const startAt = (data.page - 1) * data.pageLength;
      this.categoryPaginatedData = this.categoryData.slice(startAt, startAt + data.pageLength);
    }
  }

  getCamposList(categoryId: string): FormDinamicoCampo[] {
    let categories = this.categoryData.filter(
      c => c.id === categoryId);

    return categories == null || categories.length == 0 ? [] : categories[0].camposList;
  }

  private listenSearch() {
    this.search$.pipe(skip(1), debounceTime(800)).subscribe((res) => {
      if (res) {
        this.categoryFilteredData = this.categoryData.filter(elem => {
          const regex = new RegExp(res.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 'i');
          const category = regex.test(elem.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          const departamento = regex.test(this.departmentIdToDescription(elem.departamentoId.normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
          const categoryId = regex.test(String(elem.categoryId));
          return category || departamento || categoryId;
        });
      } else {
        this.categoryFilteredData = undefined;
      }
    });
  }

  departmentIdToDescription(id: string) {
    const department = this.departmentsData.find(elem => elem.id == id);

    if (department) {
      return department.descricao;
    }
  }

  globalCategoryIdToDescription(id: string) {
    const globalCategory = this.globalCategoriesData.find(elem => elem.id == id);

    if (globalCategory) {
      return globalCategory.descricao;
    }
  }

  get canCreate() {
    return this.descricao.value && this.departamentoId.value && this.globalCategoryId.value;
  }

  // Form gets
  get descricao() { return this.form.get('descricao'); }
  get departamentoId() { return this.form.get('departamentoId'); }
  get globalCategoryId() { return this.form.get('globalCategoryId'); }
}

interface CategoryEditable extends Categoria {
  isEditActive: boolean;
  newDescription: string;
  newDepartmentId: string;
  newGlobalCategoryId: string;
}
