import { Component, OnInit, Input } from '@angular/core';
import { WorkflowProduto } from 'src/app/models/api/workflow/workflow-produto.interface';
import { EnumSistemaIntegracaoStatus } from 'src/app/models/api/workflow/enum-sistema-integracao-status.enum';

@Component({
  selector: 'c-product-list-table-integration',
  templateUrl: './product-list-table-integration.component.html',
  styleUrls: ['./product-list-table-integration.component.scss']
})
export class ProductListTableIntegrationComponent implements OnInit {

  @Input() dataSource: WorkflowProduto[];
  @Input() columns: string[];

  constructor(
  ) {
  }

  ngOnInit() {
  }

  getIntegrationStatusName(value: EnumSistemaIntegracaoStatus) {
    switch (value) {
      case EnumSistemaIntegracaoStatus.NaoIntegrado:
        return 'not integrated';

      case EnumSistemaIntegracaoStatus.Integrado:
        return 'integrated';

      case EnumSistemaIntegracaoStatus.ErroAoIntegrar:
        return 'error in integration';

      case EnumSistemaIntegracaoStatus.PrecisaSerAtualizado:
        return 'need update';
    }
  }

}
