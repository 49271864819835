import { Injectable } from "@angular/core";
import { ApiHttpClientService } from "../../api-http-client.service";
import { environment } from "src/environments/environment";
import { TributacaoCST } from "src/app/models/api/dados-fiscais/tributacao-cst";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiTributacaoCSTService {
  private baseUrl = "TributacaoCST";
  private baseUrlImposto = "TributacaoCSTImposto";
  private controller: ApiHttpClientService;
  private controllerImposto: ApiHttpClientService;

  constructor(private api: ApiHttpClientService) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosFiscais)
      .useBaseUrl(this.baseUrl);
    
    this.controllerImposto = this.api
      .useBaseUrl(environment.microservice.dadosFiscais)
      .useBaseUrl(this.baseUrlImposto);

  }

  getAll() {
    return this.controller.unwrap().get<TributacaoCST[]>('');
  }

  getAllImposto() {
    return this.controllerImposto.unwrap().get<TributacaoCST[]>('');
  }
}
