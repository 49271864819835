import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Grupo } from 'src/app/models/api/dados-fiscais/grupo';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiGrupoService {

  private baseUrl = 'Grupo';
  private controller: ApiHttpClientService;
  private getAllCache$: Observable<Grupo[]>;


  constructor(
    private api: ApiHttpClientService,
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosFiscais)
      .useBaseUrl(this.baseUrl);
  }

  getAll() {
    return this.controller.unwrap().get<Grupo[]>('');
  }

  getGrupoByExcecao(idExcecao: string) {
    const params = new HttpParams().append('excecao', idExcecao);
    return this.controller.unwrap().get<Grupo[]>('', { params });
  }

  getAllWithCache() {
    if (!this.getAllCache$) {
      this.getAllCache$ = this.getAll().pipe(
        shareReplay(1)
      );
    }

    return this.getAllCache$;
  }

  getGrupoByExcecaoWithCache(idExcecao: string) {
    return this.getAllWithCache().pipe(
      map( grupos => grupos.filter( g => g.validoPara.includes(idExcecao)))
    );
  }
}
