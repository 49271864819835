import { Component, OnInit } from '@angular/core';
import { ProductHistoricService } from '../services/product-historic.service';

@Component({
  selector: 'c-product-historic',
  templateUrl: './product-historic.component.html',
  styleUrls: ['./product-historic.component.scss']
})
export class ProductHistoricComponent implements OnInit {

  constructor(
    public productHistoricServ: ProductHistoricService
  ) { }

  ngOnInit() {
  }

}
