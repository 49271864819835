import { Injectable } from "@angular/core";
import { ApiHttpClientService } from "../../api-http-client.service";
import { environment } from "src/environments/environment";
import { CustoPrecoEstadoAliquota } from "src/app/models/api/custo-preco/custo-preco.interface";
import { Observable, of } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiCustoPrecoEstadoService {
  private baseUrl = "Estado";
  private controller: ApiHttpClientService;

  constructor(private api: ApiHttpClientService) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.custoPreco)
      .useBaseUrl(this.baseUrl);
  }

  getAliquotasByState(id: string, isForeign: boolean, fornecedorId: string): any {
    if (!id) {
      throw new Error("Invalid Argument");
    }
    
    const params = new HttpParams()
    .set('uf', id)
    .set('estrangeiro', isForeign.toString())
    .set('fornecedorId', fornecedorId);

    return this.controller.unwrap().get<CustoPrecoEstadoAliquota[]>('UF', {params});
  }
}
