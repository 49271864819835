import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ContentChildren,
  QueryList,
  Injector,
  DoCheck,
  OnDestroy,
  NgZone
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, NgControl } from '@angular/forms';
import { DynamicField } from './models/dynamic-field.interface';
import { FieldControlService } from './field-control.service';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { DynamicFormConfig } from './models/dyanmic-form.interface';
import { Subject } from 'rxjs';

type Data = object;
type OnChangeFn = (data: Data) => any;
type OnTouchedFn = () => any;

@Component({
  selector: 'c-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [
    FieldControlService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DynamicFormComponent),
      multi: true,
    }
  ]
})
export class DynamicFormComponent implements OnInit, DoCheck, ControlValueAccessor, Validator, OnDestroy {
  destroy$ = new Subject();

  @ContentChildren(DynamicFieldDirective) dynamicFields: QueryList<DynamicFieldDirective>;

  @Input() rules: { [rule: string]: ((df: DynamicFormComponent, field: DynamicField) => any) } = {};

  private fieldsByRule: [string, DynamicField][] = [];

  // tslint:disable-next-line: variable-name
  _config: DynamicFormConfig;
  @Input() set config(config: DynamicFormConfig) {
    this._config = [];
    // tslint:disable-next-line: forin
    for (const control in this.form.controls) {
      this.form.removeControl(control);
    }
    this.form.reset();
    if (config) {
      for (const c of this.fcs.dynamicFormToControls(config)) {
        this.form.registerControl(c.name, c.control);
      }
    }
    if (this.value) {
      this.form.patchValue(this.value, { emitEvent: false });
      this._config = config;
    } else {
      this._config = config;
      this.resetForm({ emitEvent: false });
    }
    this.form.updateValueAndValidity();

    this.fieldsByRule = [];
    this.fieldsByRule = this.ngZone.runOutsideAngular(() => {
      const fieldsByRule: [string, DynamicField][] = [];
      if (this._config) {
        this._config.forEach(c => {
          if (c.rules) {
            c.rules.forEach(r => {
              if (this.rules[r]) {
                fieldsByRule.push([r, c]);
              }
            });
          }
        });
      }

      return fieldsByRule;
    });


  }

  @Input() highlightClass = 'highlight';
  form: FormGroup;
  private value: any;
  // private onChange: OnChangeFn;
  private onTouched: OnTouchedFn;


  private ngControl: NgControl;
  private touched = false;

  constructor(
    private injector: Injector,
    private fcs: FieldControlService,
    private ngZone: NgZone,
  ) {
    // console.log('constructor');
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.form.statusChanges.pipe(startWith(this.form.status), distinctUntilChanged()).subscribe(console.log);
    // tslint:disable-next-line: deprecation
    this.ngControl = this.injector.get(NgControl);

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngDoCheck() {
    this.applyRules();

    if (this.ngControl && this.touched !== this.ngControl.touched) {
      this.touched = this.ngControl.touched;
      if (this.touched) {
        this.form.markAllAsTouched();
      }
    }
  }

  writeValue(data: Data) {
    // console.log('writing value', data);
    if (data) {
      this.value = data;
      this.form.patchValue(data, { emitEvent: false });
    } else {
      this.value = null;
      this.resetForm();
    }
  }

  resetForm(options?: { emitEvent: boolean }) {
    if (this._config) {
      for (const field of this._config) {
        this.form.get(field.id).reset(field.initialValue, options);
      }
    } else {
      this.form.reset();
    }
  }

  registerOnChange(fn: OnChangeFn) {
    // console.log('registerOnChange');
    fn(this.form.value);
    // this.form.valueChanges.subscribe(value => console.log('new value', value));
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: OnTouchedFn) {
    // console.log('registerOnTouched');
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate() {
    return this.form && this.form.invalid ? {
      dynamicForm: this.form.errors
    } : null;
  }

  applyRules() {
    this.fieldsByRule.forEach(([rule, field]) => {
      if (this.rules[rule]) { this.rules[rule](this, field); };
    });
  }

}
