import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicFieldEditorRoutingModule } from './dynamic-field-editor-routing.module';
import { DynamicFieldEditorComponent } from './dynamic-field-editor.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { RadioModule } from 'src/app/ui/radio/radio.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { ModalModule } from 'src/app/ui/modal/modal.module';
import { CollapseModule } from 'src/app/ui/collapse/collapse.module';
import { TabModule } from 'src/app/ui/tab/tab.module';
import { RangeModule } from 'src/app/ui/range/range.module';
import { DynamicFormModule } from 'src/app/lib/dynamic-form/dynamic-form.module';
import { EmptyPatternModule } from 'src/app/ui/empty-pattern/empty-pattern.module';
import { CompositionInputModule } from 'src/app/ui/composition-input/composition-input.module';

@NgModule({
  declarations: [DynamicFieldEditorComponent],
  imports: [
    CommonModule,
    DynamicFieldEditorRoutingModule,
    SharedModule,
    InputModule,
    SelectModule,
    CheckboxModule,
    RadioModule,
    CardModule,
    IconModule,
    ModalModule,
    CollapseModule,
    TabModule,
    RangeModule,
    DynamicFormModule,
    EmptyPatternModule,
    CompositionInputModule,
  ]
})
export class DynamicFieldEditorModule { }
