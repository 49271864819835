import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductService } from '../services/product.service';
import { ProductMediaService } from '../services/product-media.service';

@Component({
  selector: 'c-product-media',
  templateUrl: './product-media.component.html',
  styleUrls: ['./product-media.component.scss']
})
export class ProductMediaComponent implements OnInit {

  form: FormGroup;

  constructor(
    public productServ: ProductService,
    public productMediaServ: ProductMediaService
  ) {

  }

  ngOnInit() {
  }

}
