import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { trigger, transition, style, group, query, animateChild, animate } from '@angular/animations';

@Component({
  selector: 'c-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('icon', [
      transition('void => *', [
        style({ opacity: 0 }),
        group([
          animate(150, style({ opacity: 1 }))
        ]),
      ]),
      transition('* => void', [
        style({ opacity: 1 }),
        group([
          animate(100, style({ opacity: 0 }))
        ]),
      ])
    ]),
    trigger('dialog', [
      transition('void => *', [
        style({ opacity: 0 }),
        group([
          animate(150, style({ opacity: 1 }))
        ]),
      ]),
      // transition('* => void', [
      //   style({ opacity: 1 }),
      //   group([
      //     animate(100, style({ opacity: 0 }))
      //   ]),
      // ])
    ]),
  ]
})
export class InputErrorComponent implements OnInit {

  @Input() showDialog = false;
  @Input() showIcon = true;
  @Input() errors: {
    type: string;
    params: any;
    literal: boolean;
  }[];

  constructor() { }

  ngOnInit() {
  }

}
