import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiAutenticacaoService } from 'src/app/services/api/autenticacao/api-autenticacao.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ApiUsuarioService } from 'src/app/services/api/autenticacao/api-usuario.service';
import { LocalStorage } from 'src/app/shared/decorators/local-storage.decorator';

@Component({
  selector: 'c-login-supplier',
  templateUrl: './login-supplier.component.html',
  styleUrls: ['./login-supplier.component.scss']
})
export class LoginSupplierComponent implements OnInit {

  @LocalStorage() keepConnected: boolean;

  form: FormGroup;
  resetPasswordForm: FormGroup;
  isForgetPasswordVisible: boolean;

  constructor(
    private fb: FormBuilder,
    private autenticationServ: ApiAutenticacaoService,
    private apiUsuarioService: ApiUsuarioService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.createForm();
    this.createResetPasswordForm();

    if (this.keepConnected == null) {
      this.keepConnected = true;
    }
  }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      login: [null, [Validators.required]],
      pass: [null, [Validators.required]]
    });
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      login: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]]
    });
  }

  login() {
    const data = this.form.value;
    this.autenticationServ.auth(data.login, data.pass).subscribe(() => {
      this.router.navigateByUrl('/dashboard');
    });
  }

  showForgetPasswordForm() {
    this.isForgetPasswordVisible = true;
  }

  showLoginForm() {
    this.isForgetPasswordVisible = false;
  }

  sendResetPasswordCode() {
    const formValue = this.resetPasswordForm.value;
    this.apiUsuarioService.forgetPassword(formValue.login, formValue.email).subscribe(res => {
      this.showLoginForm();
      this.toastService.toast(`O código foi enviado para ${formValue.email}`);
      this.resetPasswordForm.reset();
    });
  }

}
