import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";

import { saveAs } from "file-saver";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Imagem } from "../../../models/api/dados-midia/midia.interface";
import { ApiMidiaService } from "../../../services/api/midia/api-midia.service";

@Component({
    selector: 'c-medias',
    templateUrl: './medias.component.html',
    styleUrls: ['./medias.component.scss']
})
export class MediasComponent implements OnDestroy, OnInit{
    readonly MAX_LENGTH = 50;

    columns = ['date', 'actions', 'download-all'];
    list: { images: Imagem[], title: string, notFound: string, format: string }[] = [];
    error = false;
    disabledForm = true;
    form = this.formBuilder.group({ search: [null, [Validators.required, Validators.maxLength(this.MAX_LENGTH)]] });

    private stillAlive$ = new Subject();

    constructor(
        private formBuilder: FormBuilder,
        private service: ApiMidiaService,
        private datePipe: DatePipe,
    ){}

    ngOnInit(): void{
        this.watchFormChanges();
    }

    search(): void{
        this.service
            .getAll(this.form.value.search)
            .subscribe(
                response => {
                    this.error = false;
                    this.list = [
                        {
                            title: 'Imagens',
                            notFound: 'Imagens não encontradas',
                            images: response.imagens,
                            format: 'jpeg'
                        },
                        {
                            title: 'Imagens em alta resolução',
                            notFound: 'Imagens em alta resolução não encontradas',
                            images: response.imagemAltaResolucao,
                            format: 'png'
                        },
                        {
                            title: 'Imagens especiais',
                            notFound: 'Imagens especiais não encontradas',
                            images: response.imagemEspecial,
                            format: 'jpeg'
                        }
                    ];
                },
                () => {
                    this.error = true;
                    this.list = [];
                }
            );
    }

    download(image: Imagem, format: string): void{
        const { id } = image;

        this.service.downloadImage(id, format).subscribe(response => saveAs(response, id));
    }

    downloadAll(list: Imagem[], format: string): void{
        const ids = list.map(image => image.id);

        this.service
            .downloadAllImages(ids, format)
            .subscribe(response => saveAs(response, `grupo-de-imagens-${this.datePipe.transform(new Date(), 'dd-MM-yyyy-HH-mm-ss')}`));
    }

    ngOnDestroy(): void{
        this.stillAlive$.next();
        this.stillAlive$.complete();
    }

    private watchFormChanges(): void{
        this.form
            .valueChanges
            .pipe(takeUntil(this.stillAlive$))
            .subscribe(() => this.disabledForm = this.form.invalid || this.form.pristine);
    }
}
