import { Component, OnInit } from '@angular/core';
import { ApiLogService  as ApiLogServiceBasicData } from 'src/app/services/api/dados-basicos/api-log.service';
import { ApiLogService  as ApiLogServiceSpecificData } from 'src/app/services/api/dados-especificos/api-log.service';
import { ApiLogService  as ApiLogServiceMedia } from 'src/app/services/api/midia/api-log.service';
import { ApiLogService  as ApiLogServiceTax } from 'src/app/services/api/dados-fiscais/api-log.service';
import { ApiLogService  as ApiLogServiceCostAndPrice } from 'src/app/services/api/custo-preco/api-log.service';
import { ApiLogService  as ApiLogServiceCommercial } from 'src/app/services/api/comercial/api-log.service';
import { Observable, of, combineLatest, Subject, ReplaySubject, EMPTY } from 'rxjs';
import { Logs, Log } from 'src/app/models/api/logs';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { FormControl } from '@angular/forms';
import { startWith, switchMap, map, debounceTime, shareReplay, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'c-product-logs-modal',
  templateUrl: './product-logs-modal.component.html',
  styleUrls: ['./product-logs-modal.component.scss']
})
export class ProductLogsModalComponent implements OnInit {
  EnumStatus = EnumStatus;
  EnumAba = EnumAba;

  readonly productId: string;
  readonly destroy: () => void;

  tabsFilter = new FormControl(EnumAba.dadosBasicos);
  tabsFilter$ = this.tabsFilter.valueChanges.pipe(startWith(this.tabsFilter.value), shareReplay(1));

  changesFilter = new FormControl('');
  changesFilter$ = this.changesFilter.valueChanges.pipe(startWith(this.changesFilter.value), shareReplay(1));

  logs$: Observable<Log[]>;

  selectedItemIndex?: number;
  selectedItem$ = new ReplaySubject<Log>(1);

  changes$ = combineLatest([
    this.changesFilter$,
    this.selectedItem$
  ]).pipe(
    map( ([filter, selectedItem]) => {
      const test = (value: string) => (new RegExp(filter.normalize('NFD')
                                                        .replace(/[\u0300-\u036f]/g, '')
                                                        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''), 'i'))
        .test(value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

      return selectedItem ?
        !!filter ?
          selectedItem.changes.filter( c => test(c.nomeCampo + c.novoValor + c.valorAnterior)).sort(
            (a, b) => a.nomeCampo > b.nomeCampo ? 1 : a.nomeCampo < b.nomeCampo ? -1 : 0
          ) :
          selectedItem.changes.sort(
            (a, b) => a.nomeCampo > b.nomeCampo ? 1 : a.nomeCampo < b.nomeCampo ? -1 : 0
          ) :
        [];
    })
  );

  constructor(
    private apiLogServiceBasicData: ApiLogServiceBasicData,
    private apiLogServiceSpecificData: ApiLogServiceSpecificData,
    private apiLogServiceMedia: ApiLogServiceMedia,
    private apiLogServiceTax: ApiLogServiceTax,
    private apiLogServiceCostAndPrice: ApiLogServiceCostAndPrice,
    private apiLogServiceCommercial: ApiLogServiceCommercial,
  ) { }

  ngOnInit() {
    this.logs$ = this.tabsFilter$.pipe(
      switchMap(tab => {
        switch (tab) {
          case EnumAba.dadosBasicos:
            return this.apiLogServiceBasicData.getByProduct(this.productId).pipe( catchError( err => of([]) ));
          case EnumAba.dadosEspecificos:
            return this.apiLogServiceSpecificData.getByProduct(this.productId).pipe( catchError( err => of([]) ));
          case EnumAba.midia:
            return this.apiLogServiceMedia.getByProduct(this.productId).pipe( catchError( err => of([]) ));
          case EnumAba.dadosFiscais:
            return this.apiLogServiceTax.getByProduct(this.productId).pipe( catchError( err => of([]) ));
          case EnumAba.custo:
            return this.apiLogServiceCostAndPrice.getByProduct(this.productId).pipe( catchError( err => of([]) ));
          case EnumAba.comercial:
            return this.apiLogServiceCommercial.getByProduct(this.productId).pipe( catchError( err => of([]) ));
          default:
            return of([]);
        }
      }),
      tap( data => {
        if (data && data.length >= 0) {
          this.selectItem(data[0]);
        }
      })
    );
  }

  selectItem(log: Log) {
    this.selectedItem$.next(log);
  }

}
