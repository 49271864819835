import { RegraPDM } from './regra-pdm';

export interface PDM {
  id: string;
  departamentoId: string;
  categoriaId?:	string;
  subnivelId?: string;
  tipo: number;
  regraDescricaoLonga: RegraPDM;
  regraDescricaoEstendida: RegraPDM;
  regraTituloDePublicacao?: RegraPDM;
  regraDescricaoEcommerce?: RegraPDM;

}

export enum PDMTipo {
  departamento = 0,
  categoria = 1,
  subnivel = 2
}
