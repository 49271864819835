import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RadioComponent } from './radio/radio.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [
    RadioGroupComponent,
    RadioComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    InputErrorModule,
  ],
  exports: [
    RadioGroupComponent,
    RadioComponent
  ]
})
export class RadioModule { }
