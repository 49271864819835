import { Storage } from './local-storage.decorator';
import { environment } from 'src/environments/environment';
import { ISession } from 'src/app/models/session/session.interface';

class IsAllowedDecorator {

  @Storage('session') session: ISession;

  /**
   * Descorator to check if user has a specific permission
   * @param permissionName permission name to check if a user has a permission
   */
  public isAllowed(permissionName: string) {
    return (
      target: any, // The prototype of the class
      key: string // The name of the property
    ) => {
      // get and set methods
      Object.defineProperty(target, key, {
        get: () => {
          if (this.session) {
            const user = this.session.user;
            const userPermissions = user && user.permissions ? user.permissions : [];
            return !!userPermissions.find(elem => elem === permissionName || elem === `${environment.permissionPrefix}.*`);
          } else {
            return false;
          }
        }
      });
    };
  }

}

const isAllowedDecorator = new IsAllowedDecorator();

export function IsAllowed(permissionName: string) {
  return isAllowedDecorator.isAllowed(permissionName);
}


