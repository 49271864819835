import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from '../services/product.service';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { EnumStatus } from 'src/app/models/api/dados-basicos/enum-status.enum';

@Component({
  selector: 'c-product-approval',
  templateUrl: './product-approval.component.html',
  styleUrls: ['./product-approval.component.scss']
})
export class ProductApprovalComponent implements OnInit {

  @Input() tab: EnumAba;

  reason: string;
  EnumStatus = EnumStatus;

  constructor(
    public productServ: ProductService
  ) { }

  ngOnInit() {

  }

  approve() {
    this.productServ.approveOrReprove(this.reason, this.tab, true);
  }

  reprove() {
    this.productServ.approveOrReprove(this.reason, this.tab, false);
  }



}
