import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { Usuario } from 'src/app/models/api/autenticacao/usuario';
import { AlterarSenha } from 'src/app/models/api/autenticacao/alterar-senha.interface';
import { QueryFilter } from 'src/app/models/api/query/query-filter.interface';
import { IResponse } from 'src/app/models/api/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiUsuarioService {

  private baseUrl = 'Usuario';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.autenticacao)
      .useBaseUrl(this.baseUrl);
  }

  getAll(query?: QueryFilter) {
    const filtro = query ? query.query : '';
    delete query.query;

    return this.controller
      .useLoading()
      .post<IResponse<Usuario[]>>('busca', {
        filtro,
        skip: 0,
        take: 0,
        filtrosPesquisados: [
          0,
          1
        ],
        ...query
      });
  }

  getById(id: string) {
    return this.controller
      .useLoading()
      .unwrap()
      .get<Usuario>(id);
  }

  getByLogin(login: string) {
    return this.controller
      .useLoading()
      .unwrap()
      .get<Usuario>(`busca/${login}`);
  }

  insert(data: Usuario) {
    return this.controller.unwrap().post<Usuario>('', data);
  }

  update(data: Usuario) {
    return this.controller.unwrap().put<unknown>('', data);
  }

  changePassword(data: AlterarSenha) {
    return this.controller.useLoading().put<unknown>('alterarSenha', data);
  }

  changePasswordWithCode(data: AlterarSenha, code: string) {
    return this.controller.useLoading().put<unknown>('redefinirSenha', { ...data, codigo: code });
  }

  forgetPassword(login: string, email: string) {
    return this.controller.useLoading().post<unknown>('esqueciSenha', { login, email });
  }

}
