import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { EnumCampoDinamicoValidationType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-validation-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ApiCampoDinamicoService {

  private baseUrl = 'CampoDinamico';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.dadosEspecificos)
      .useBaseUrl(this.baseUrl);
  }

  get() {
    return this.controller.useLoading().unwrap().get<CampoDinamico[]>('');
  }

  getById(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().unwrap().get<CampoDinamico>(`buscaPorId/${id}`).pipe(
      map( data => this.createMinMaxCampoDinamico(data))
    );
  }

  getBySubnivel(subnivelId: string) {
    if (!subnivelId) { throw new Error('Invalid Argument'); }
    return this.controller.useLoading().unwrap().get<CampoDinamico[]>(subnivelId).pipe(
      map(data => {
        for (const d of data) {
          // altera por referencia
          this.createMinMaxCampoDinamico(d);
        }

        return data;
      })
    );
  }

  insert(data: CampoDinamico) {
    return this.controller.useLoading().unwrap().post<CampoDinamico>('', data);
  }

  update(data: CampoDinamico) {
    return this.controller.useLoading().unwrap().put<CampoDinamico>('', data);
  }

  // delete(id: string) {
  //   if (!id) { throw new Error('Invalid Argument'); }
  //   return this.controller.useLoading().delete(id);
  // }

  /**
   * @param data campo dinamico passado por referencia para alteração
   * @description este processo de conversão é realido neste service somente quando o dado vem do backend
   * no momento de envio ao backend deve ser enviado sem estes dados (min e max) e usar as validações ao invez disso)
   */
  private createMinMaxCampoDinamico(data: CampoDinamico) {
    if (data) {
      for (const v of data.dynamicFieldValidator) {
        if (v.type === EnumCampoDinamicoValidationType.Min) {
          // tslint:disable-next-line: no-string-literal
          data['min'] = v.value;
        }
        if (v.type === EnumCampoDinamicoValidationType.Max) {
          // tslint:disable-next-line: no-string-literal
          data['max'] = v.value;
        }
      }
    }

    return data;
  }
}
