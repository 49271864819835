import { Component, ElementRef, forwardRef, Input, OnInit, Injector, Optional } from '@angular/core';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioComponent } from '../radio/radio.component';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';

@Component({
  selector: 'c-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ]
})
export class RadioGroupComponent extends ControlValueAccessorMixin implements OnInit {

  @Input() label: string;

  selectedRadio: RadioComponent;

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  ngOnInit() {

  }

}
