import { Component, OnInit, Input } from '@angular/core';
import { animate, trigger, transition, style, group } from '@angular/animations';

@Component({
  selector: 'c-input-info',
  templateUrl: './input-info.component.html',
  styleUrls: ['./input-info.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ opacity: 0 }),
        group([
          animate(150, style({ opacity: 1 }))
        ]),
      ]),
      // transition('* => void', [
      //   style({ opacity: 1 }),
      //   group([
      //     animate(100, style({ opacity: 0 }))
      //   ]),
      // ])
    ]),
  ]
})
export class InputInfoComponent implements OnInit {
  @Input() showDialog = false;

  constructor() { }

  ngOnInit() {
  }

}
