import { Component, OnInit, Input, HostBinding, Renderer2, ElementRef, HostListener } from '@angular/core';
import { trigger, transition, style, animate, state, group, query, animateChild } from '@angular/animations';

@Component({
  selector: 'c-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('modal', [
      transition('void => *', [
        style({ opacity: 0 }),
        group([
          query('@content', animateChild()),
          animate(150, style({ opacity: 1 }))
        ]),
      ]),
      transition('* => void', [
        group([
          query('@content', animateChild()),
          animate(100, style({ opacity: 0 }))
        ]),
      ])
    ]),
    trigger('content', [
      transition('void => *', [
        style({ transform: 'translateY(10%)' }),
        animate('200ms ease-out', style({
          transform: 'translateY(0%)',
        }))
      ]),
      transition('* => void', [
        style({ transform: 'translateY(0%)' }),
        animate('200ms ease-in', style({
          transform: 'translateY(20%)',
        }))
      ]),
    ])
  ],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[@modal]': 'true'
  }
})
export class ModalComponent implements OnInit {
  @HostBinding('attr.type')
  @Input() type?: 'fullscreen' | 'dialog' | 'large';

  @HostBinding('attr.hideOverlay')
  @Input() hideOverlay?: boolean;

  @HostBinding('attr.hideOverlay')
  @Input() hideCloseButton?: boolean;

  // custom styles
  @HostBinding('attr.class')
  @Input() class?: string;
  @Input() contentClass?: string;
  @Input() overlayClass?: string;
  set style(s: {[p: string]: any}) {
    for (const p in s) {
      if (s.hasOwnProperty(p)) {
        this.renderer.setStyle(this.elementRef.nativeElement, p, s[p]);
      }
    }
  }
  @Input() contentStyle?: string;
  @Input() overlayStyle?: string;

  readonly destroy: () => void;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
  }

  @HostListener('document:keyup.esc')
  close() {
    this.destroy();
  }

}
