import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { HttpParams } from '@angular/common/http';
import { IResponse } from 'src/app/models/api/response.interface';
import { UnidadeFaturamento } from 'src/app/models/api/dados-basicos/unidade-faturamento';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUnidadeFaturamentoService {

  private baseUrl = 'UnidadeFaturamento';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
    .useBaseUrl(environment.microservice.dadosBasicos)
    .useBaseUrl(this.baseUrl);
  }

  // get(id: string) {
  //   if (!id) { throw new Error('Invalid Argument'); }
  //   return this.controller.unwrap().get<UnidadeFaturamento>(id);
  // }

  getAll() {
    return this.controller.get<IResponse<UnidadeFaturamento[]>>('');
  }

  insert(data: UnidadeFaturamento) {
    return this.controller.post('', data);
  }

  update(data: UnidadeFaturamento) {
    return this.controller.put('', data);
  }

  delete(id: string) {
    if (!id) { throw new Error('Invalid Argument'); }
    return this.controller.delete(id);
  }

}
