import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiAutenticacaoService } from 'src/app/services/api/autenticacao/api-autenticacao.service';

interface DialogData {
  type: string
}
@Component({
  selector: 'c-modal-terms',
  templateUrl: './modal-terms.component.html',
  styleUrls: ['./modal-terms.component.scss']
})
export class ModalTermsComponent implements OnInit {

  @Input() type:string
  textTerms = ''

  constructor(
    private apiAutenticacaoService: ApiAutenticacaoService
  ) { }

  ngOnInit() {
    // this.type == 'terms' ? this.getTerms() : this.getPrivacy()
  }

  getTerms():void{
    this.apiAutenticacaoService.getTerms().subscribe(r =>{
      this.textTerms = r.text
    })
  }

  getPrivacy():void{
    this.apiAutenticacaoService.getPrivacy().subscribe(r =>{
      this.textTerms = r.text
    })
  }
}
