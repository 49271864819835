import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSortDirective } from './table-sort.directive';
import { TableSortItemComponent } from './table-sort-item/table-sort-item.component';
import { IconModule } from '../icon/icon.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    TableSortDirective,
    TableSortItemComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    SharedModule
  ],
  exports: [
    TableSortDirective,
    TableSortItemComponent
  ]
})
export class TableSortModule { }
