import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { ProductBasicDataService } from './product-basic-data.service';
import { ProductSpecificDataService } from './product-specific-data.service';
import { startWith, map } from 'rxjs/operators';
import { Marca } from 'src/app/models/api/dados-basicos/marca';
import { UnidadeFaturamento } from 'src/app/models/api/dados-basicos/unidade-faturamento';
import { Subnivel } from 'src/app/models/api/dados-especificos/subnivel';
import { Categoria } from 'src/app/models/api/dados-especificos/categoria';
import { Departamento } from 'src/app/models/api/dados-especificos/departamento';
import { RegraPDM, CampoRegra } from 'src/app/models/api/dados-especificos/regra-pdm';
import { CampoDinamico } from 'src/app/models/api/dados-especificos/campo-dinamico';
import { EnumCampoDinamicoType } from 'src/app/models/api/dados-especificos/enum-campo-dinamico-type.enum';

@Injectable()
export class PdmDescriptionBuilderService {

  pdmMapping$: Observable<{ id?: string; descricao?: string }[]>;

  constructor(
    private basicDataService: ProductBasicDataService,
    private specificDataService: ProductSpecificDataService,
  ) {

    this.pdmMapping$ = combineLatest([
      this.basicDataService.licenca$.pipe(startWith<Marca[]>([])),
      this.basicDataService.unidadeFaturamento$.pipe(startWith<UnidadeFaturamento[]>([])),
      this.specificDataService.subniveis$.pipe(startWith<Subnivel[]>([])),
      this.specificDataService.categorias$.pipe(startWith<Categoria[]>([])),
      this.specificDataService.departamentos$.pipe(startWith<Departamento[]>([])),
    ]).pipe(
      map((datas) => datas.flat())
    );
  }

  getStringForPDMRule(rule: RegraPDM, camposDinamicos: CampoDinamico[], sortimentoGrade: string = '', indexSortimentoGrade: number = 0) {
    const campos: Observable<string>[] = rule.camposList.map(c => this.getStringForPDMRuleField(c, camposDinamicos, sortimentoGrade, indexSortimentoGrade));
    const string$ = combineLatest(campos).pipe(
      map((c: string[]) => c.filter(s => !!s).join(rule.concatenaCom))
    );

    return string$;
  }

  getStringForPDMRuleField(field: CampoRegra, camposDinamicos: CampoDinamico[], sortimentoGrade: string, indexSortimentoGrade: number) {
    let value$: Observable<string>;

    // campo dinamico dos dados especificos
    if (field.campoDinamico && !field.pertenceADadosBasicos) {

      const campoDinamicoRange = camposDinamicos && (camposDinamicos
        .find(cd => cd.id == field.campoDinamicoId) ? camposDinamicos
          .find(cd => cd.id == field.campoDinamicoId).type : undefined) === EnumCampoDinamicoType.Range || null;

      const campoDinamicoMultipleSelect = camposDinamicos && (camposDinamicos
        .find(cd => cd.id == field.campoDinamicoId) ? camposDinamicos
          .find(cd => cd.id == field.campoDinamicoId).type : undefined) === EnumCampoDinamicoType.MultipleSelect || null;

      const dynamicForm = this.specificDataService.form.get('objetoTemplateSubnivel');

      /* a pedido do cliente foi criado novos campos de descrição para cada sortimento/grade e 
        caso a regra do pdm contenha os campos de modelo, cor, referencia e tamanho,
        deverá ser usado os valores dos campos do sortimento/grade */

      if (sortimentoGrade.length > 0 && 
        (field.campoDinamicoId == "5df4071ff289080001fb864c" || 
        field.campoDinamicoId == "5e0df9c4a0611125dc24f7df" ||
        field.campoDinamicoId == "5df3fa15f289080001fb862d")) {
        let texto;
        let value;
        if (sortimentoGrade == "sortimento") {
          value = this.specificDataService.sortimentoListForm.value
        } else {
          value = this.specificDataService.gradeListForm.value
        }
        switch (field.campoDinamicoId) {
          // id do campo dinamico cor
          case "5df4071ff289080001fb864c":
            const idCor = value[indexSortimentoGrade] ? value[indexSortimentoGrade].cor : null;
            if (idCor) {
              this.specificDataService.corGrade$.subscribe(cores =>{
                let cor = cores.filter(cor => cor.valCaract == idCor);
                texto = cor.length ? cor[0].cor : '';
              });
            } else {
              texto = ''; 
            }
            break;

          // id do campo dinamico Tamanho
          case "5e0df9c4a0611125dc24f7df":
            const idTamanho = value[indexSortimentoGrade] ? value[indexSortimentoGrade].tamanho : null;
            if (idTamanho) {
              this.specificDataService.tamanhoGrade$.subscribe(tamanhos =>{
                let tamanho = tamanhos.filter(tamanho => tamanho.valCaract == idTamanho);
                texto = tamanho.length ? tamanho[0].tamanho : '';
              });
            } else {
              texto = ''; 
            }
            break;

          // id do campo dinamico modelo
          case "5df3fa15f289080001fb862d":
            if (sortimentoGrade == "sortimento") {
              texto = value[indexSortimentoGrade].itemSortido;
            } else {
              texto = value[indexSortimentoGrade].nomeDoProduto;
            }
            break;
        
          default:
            texto = ""
            break;
        }

        value$ = of(texto);
      } else {
        value$ = dynamicForm.valueChanges.pipe(
          startWith(dynamicForm.value),
          map(value => {
            if (!value) { return ''; }
  
            if (sortimentoGrade) {
              
            }
  
            if (!field.usaId && campoDinamicoRange) {
              const valor = value[field.campoDinamicoId];
  
              if (valor) {
                if (valor.start === 0 && valor.end === 0) { return null; }
                return `${field.nomeCampo}: de ${valor.start} até ${valor.end}`;
              }
            }
  
            if (field.usaId && campoDinamicoMultipleSelect) {
              const campoDinamicoMultipleArray = [];
              const ids = value[field.campoDinamicoId];
  
              const cdConfig = camposDinamicos && camposDinamicos.find(cd => cd.id == field.campoDinamicoId);
  
              if (ids) {
                ids.forEach((id: string) => campoDinamicoMultipleArray.push(cdConfig.options.find(opt => opt.optionId == id).description));
  
                return campoDinamicoMultipleArray.join(', ');
              }
            }
  
            if (field.usaId) {
              const id = value[field.campoDinamicoId];
              if (!id) { return ''; }
              const cdConfig = camposDinamicos && camposDinamicos.find(cd => cd.id == field.campoDinamicoId);
              const cdOption = cdConfig && cdConfig.options ? cdConfig.options.find(o => o.optionId == id) : null;
              return cdOption ? cdOption.description : '';
            } else {
              return value[field.campoDinamicoId];
            }
  
          })
        );
      }

      // campo dos dados especificos que não é dinamico
    } else if (!field.campoDinamico && !field.pertenceADadosBasicos) {

      const control = this.specificDataService.form.get(field.nomeCampo);

      if (field.usaId) {
        const id$: Observable<string> = control ? control.valueChanges.pipe(startWith(control.value)) : of(null);

        value$ = combineLatest(
          this.pdmMapping$,
          id$,
        ).pipe(
          map(([pdmMapping, id]) => {
            if (!id) {
              return '';
            }

            const item = pdmMapping.find(p => p.id == id);
            return item ? item.descricao : '';
          })
        );

      } else {
        value$ = control ? control.valueChanges.pipe(startWith(control.value)) : of('');
      }

      // campo dos dados basicos
    } else if (!field.campoDinamico && field.pertenceADadosBasicos) {
      if (sortimentoGrade.length > 0 && field.nomeCampo == "referencia") {
        let texto = '';
        let value;
        value = sortimentoGrade == "sortimento" ?
          this.specificDataService.sortimentoListForm.value :
          this.specificDataService.gradeListForm.value;

        texto = value[indexSortimentoGrade].referencia || "";
        
        value$ = of(texto);
      } else {
        const control = this.basicDataService.form.get(field.nomeCampo);
  
        if (field.usaId) {
          const id$: Observable<string> = control ? control.valueChanges.pipe(startWith(control.value)) : of(null);
  
          value$ = combineLatest(
            this.pdmMapping$,
            id$,
          ).pipe(
            map(([pdmMapping, id]) => {
              if (!id) {
                return '';
              }
  
              const item = pdmMapping.find(p => p.id == id);
              return item ? item.descricao : '';
            })
          );
  
        } else {
          value$ = control ? control.valueChanges.pipe(startWith(control.value)) : of('');
        }
      }
    } else {
      value$ = of('falhou na regra' + field.nomeCampo);
    }

    return value$;
  }

}

