export enum EnumStatus {
  /**
   * Quando o item esta sendo salvado pelo auto-save
   * Aba: Novo
   */
  Draft = 0,
  /**
   * Quando o item foi validado e salvo, aguradando ser revisado pelo funcionário
   * Aba: Em Revisão
   */
  Revisao = 1,
  /**
   * Quando o item foi validado pelo funcionário e foi reprovado
   * Aba: Inconsistências
   */
  Reprovado = 2,
  /**
   * QUando o funcionário validou o item e ele foi enviado para os agregadores
   * Aba: Cadastrados
   */
  Finalizado = 3,
  /**
   * Item inativado
   * Aba: Inativo
   */
  Inativo = 4,
}
