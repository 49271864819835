import { CategoriaGlobal } from 'src/app/models/api/dados-especificos/categoria-global.interface';

export const globalCategories: CategoriaGlobal[] = [
  {
    id: '772',
    descricao: 'Adultos'
  },
  {
    id: '412',
    descricao: 'Alimentos, bebidas e tabaco'
  },
  {
    id: '1',
    descricao: 'Animais e suprimentos para animais de estimação'
  },
  {
    id: '8',
    descricao: 'Artes e entretenimento'
  },
  {
    id: '988',
    descricao: 'Artigos esportivos'
  },
  {
    id: '1239',
    descricao: 'Brinquedos e jogos'
  },
  {
    id: '536',
    descricao: 'Casa e jardim'
  },
  {
    id: '111',
    descricao: 'Comercial e industrial'
  },
  {
    id: '141',
    descricao: 'Câmeras e óptica'
  },
  {
    id: '222',
    descricao: 'Eletrônicos'
  },
  {
    id: '632',
    descricao: 'Ferragens'
  },
  {
    id: '537',
    descricao: 'Infantil'
  },
  {
    id: '5181',
    descricao: 'Malas e bolsas'
  },
  {
    id: '922',
    descricao: 'Materiais de escritório'
  },
  {
    id: '5605',
    descricao: 'Material para cerimônias e eventos religiosos'
  },
  {
    id: '783',
    descricao: 'Mídia'
  },
  {
    id: '436',
    descricao: 'Móveis'
  },
  {
    id: '469',
    descricao: 'Saúde e beleza'
  },
  {
    id: '2092',
    descricao: 'Software'
  },
  {
    id: '166',
    descricao: 'Vestuário e acessórios'
  },
  {
    id: '888',
    descricao: 'Veículos e peças'
  }
];
