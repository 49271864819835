import { Injectable } from "@angular/core";
import { ProductBaseEntity } from "./product-base-entity.class";
import { CanSubmitForReview } from "./interfaces";
import { IsAllowed } from "src/app/shared/decorators/is-allowed.decorator";
import { FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { EnumStatus } from "src/app/models/api/dados-basicos/enum-status.enum";
import { CostAndPriceForm } from "../models/cost-and-price-form";
import { ProductTaxDataService } from "./product-tax-data.service";
import { removeValidators } from "src/app/shared/utils/remove-validators-form";

import {
  CustoPrecoEstadoAliquota,
  CustoPrecoEstadoOrigem,
  CustoPrecoEstadoRegiao,
  CustoPrecoPorEstadoValor,
} from "src/app/models/api/custo-preco/custo-preco.interface";
import { ApiCustoPrecoEstadoService } from "src/app/services/api/custo-preco/api-custo-preco-estado.service";
import { distinctUntilChanged } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { of, Subject, BehaviorSubject, ReplaySubject, Observable } from "rxjs";

@Injectable()
export class ProductCostAndPriceService
  extends ProductBaseEntity
  implements CanSubmitForReview
{
  @IsAllowed("rhcdm.custopreco.editarFinalizado") canSaveFinished: boolean;
  @IsAllowed("rhcdm.custopreco.editarRevisao") canSaveReview: boolean;
  @IsAllowed("rhcdm.custopreco.editarReprovado") canSaveRepproved: boolean;
  @IsAllowed("rhcdm.custopreco.aprovar") canApproveOrReprove: boolean;

  isCustoPrecoIntegrado$ = new BehaviorSubject<boolean>(false);

  fornecedorId: string;

  constructor(
    fb: FormBuilder,
    private taxDataService: ProductTaxDataService,
    private apiCustoPrecoEstadoService: ApiCustoPrecoEstadoService,

  ) {
    super(fb);
    super.initForm();
    this.listenTaxDataFiliais();

  }

  setValue(productId: string, status: EnumStatus, value: CostAndPriceForm) {
    super.setValue(productId, status, value);
    this.createEstadoOrigemList(value.estadoOrigemList); 
    if (this.isCustoPrecoIntegrado$.getValue()){
      removeValidators(this.form)
    }
  }

  createForm() {
    return this.fb.group({
      codigoRihappy: [undefined, Validators.required],
      precoSugerido: [undefined, Validators.required],
      custoBrasil: [undefined, Validators.required],
      estadoOrigemList: new FormArray([]),
    });
  }

  replicate() {
    const custoBrasil = this.form.get('custoBrasil').value;

    this.estadoOrigemList.controls.forEach(estadoControl => 
      (estadoControl.get('estadoPorAliquotaList') as FormArray).controls.forEach(aliquotaControl => 
        (aliquotaControl.get('regiaoEstadoSemDescontoList') as FormArray).controls.forEach(regiaoControl => 
          (regiaoControl.get('valorPorEstadoList') as FormArray).controls.forEach(valorControl => {
            valorControl.get('valor').patchValue(custoBrasil);
          })
        )
      )
    );
  }  

  private createEstadoOrigemList(data: CustoPrecoEstadoOrigem[]){
    this.estadoOrigemList.clear();

    if (data) {
      data.forEach((elem) => {
        this.createEstadoOrigemItem(elem);
      });      
    } 
  }

  private createEstadoOrigemItem(data: CustoPrecoEstadoOrigem) {
    const form = this.fb.group({
      uf: [data.uf, Validators.required],
      estadoPorAliquotaList: this.createEstadoPorAliquotaList(
        data.estadoPorAliquotaList
      ),
    });

    this.estadoOrigemList.push(form);
  }

  private createEstadoPorAliquotaList(data: CustoPrecoEstadoAliquota[]) {
    const formArray = this.fb.array([]);

    if (data) {
      data.forEach((elem) => {
        const form = this.fb.group({
          aliquota: [elem.aliquota, Validators.required],
          regiaoEstadoSemDescontoList: this.createRegiaoEstadoDescontoList(
            elem.regiaoEstadoSemDescontoList
          ),
          regiaoEstadoComDescontoList: this.createRegiaoEstadoDescontoList(
            elem.regiaoEstadoComDescontoList
          ),
        });

        formArray.push(form);
      });
    }

    return formArray;
  }

  private createRegiaoEstadoDescontoList(data: CustoPrecoEstadoRegiao[]) {
    const formArray = this.fb.array([]);

    if (data) {
      data.forEach((elem) => {
        const form = this.fb.group({
          regiao: [elem.regiao, Validators.required],
          valorPorEstadoList: this.createValorPorEstadoList(
            elem.valorPorEstadoList
          ),
        });

        formArray.push(form);
      });
    }

    return formArray;
  }

  private createValorPorEstadoList(data: CustoPrecoPorEstadoValor[]) {
    const formArray = this.fb.array([]);

    if (data) {
      data.forEach((elem) => {
        const form = this.fb.group({
          uf: [elem.uf, Validators.required],
          valor: [elem.valor, [Validators.required, Validators.min(0)]],
        });

        formArray.push(form);
      });
    }

    return formArray;
  }

  private listenTaxDataFiliais() {
    
    // Listen to filiais and to the fiscal origin
    combineLatest(this.taxDataService.filialChange$, 
      this.taxDataService.isSelectedCstForeigner$.pipe(distinctUntilChanged()))
      .subscribe(async ([filiais, isForeigner]) => {
        const custoPrecoEstadoOrigemList: CustoPrecoEstadoOrigem[] = [];

        for (const filial of filiais.sort(x => x.index)) {
          const aliquotasData = await this.apiCustoPrecoEstadoService
            .getAliquotasByState(filial.uf, isForeigner, this.fornecedorId)
            .toPromise() as CustoPrecoEstadoAliquota[];

          const custoPrecoEstadoOrigem: CustoPrecoEstadoOrigem = {
            uf: filial.uf,
            estadoPorAliquotaList: aliquotasData
          };

          const filialCustoPreco = (this.estadoOrigemList.getRawValue() as CustoPrecoEstadoOrigem[])[filial.index];
          if (filialCustoPreco && filial.uf == filialCustoPreco.uf) {           
            custoPrecoEstadoOrigem.estadoPorAliquotaList = this.getCustoPrecoEstadoListWithPreviousValues(
                filialCustoPreco.estadoPorAliquotaList, aliquotasData);
          }

          custoPrecoEstadoOrigemList.push(custoPrecoEstadoOrigem);
        }     
        
        this.createEstadoOrigemList(custoPrecoEstadoOrigemList);
      });
  }

  private getCustoPrecoEstadoListWithPreviousValues(formEstados: CustoPrecoEstadoAliquota[], 
    newEstados: CustoPrecoEstadoAliquota[]){
      const regioesSemDesconto: CustoPrecoEstadoRegiao[] = [];
      const regioesComDesconto: CustoPrecoEstadoRegiao[] = [];

      formEstados.forEach(formEstado => {
        this.fillUniqueCustoPrecoEstadoRegiaoList(formEstado.regiaoEstadoSemDescontoList, regioesSemDesconto);
        this.fillUniqueCustoPrecoEstadoRegiaoList(formEstado.regiaoEstadoComDescontoList, regioesComDesconto);
      });

      newEstados.forEach(estadoAliquota => {
        this.fillValorOfRegiaoEstado(estadoAliquota.regiaoEstadoSemDescontoList, regioesSemDesconto);
        this.fillValorOfRegiaoEstado(estadoAliquota.regiaoEstadoComDescontoList, regioesComDesconto);
      });

    return newEstados;
  }

  private fillUniqueCustoPrecoEstadoRegiaoList(list: CustoPrecoEstadoRegiao[], 
    uniqueRegionsList: CustoPrecoEstadoRegiao[]){

    list.forEach(formRegiao => {
      const regiaoExistente = uniqueRegionsList.find(x => x.regiao == formRegiao.regiao);

      if(regiaoExistente){
        regiaoExistente.valorPorEstadoList.push(...formRegiao.valorPorEstadoList);
      }else{
        uniqueRegionsList.push(formRegiao);
      }
    });
  }

  private fillValorOfRegiaoEstado(regioesOrigin: CustoPrecoEstadoRegiao[], regioesDestiny: CustoPrecoEstadoRegiao[]) {
    if (regioesOrigin && regioesOrigin.length > 0 && regioesDestiny && regioesDestiny.length > 0){
      regioesOrigin.forEach(estadoRegiao => {
        const regiaoDestino = regioesDestiny.find(x => x.regiao === estadoRegiao.regiao);

        if(estadoRegiao && estadoRegiao.valorPorEstadoList && estadoRegiao.valorPorEstadoList.length > 0){
          estadoRegiao.valorPorEstadoList.forEach(estadoValor => {
            const value = regiaoDestino.valorPorEstadoList.find(x => x.uf === estadoValor.uf).valor;
            estadoValor.valor = value ? value : estadoValor.valor;
          })
        }
      });
    }
  }


  get estadoOrigemList() {
    return this.form.get("estadoOrigemList") as FormArray;
  }

  get codigoSapFornecedor() {
    return this.form.get("codigoRihappy") as FormControl;
  }
}
