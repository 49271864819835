import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSpecificDataService } from '../../services/product-specific-data.service';
import { EnumVariacoesDadosEspecificos } from 'src/app/models/api/dados-especificos/enum-variacoes-dados-especificos.enum';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, take, takeUntil, } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ProductSpecificDataSubnivelService } from '../../services/product-specific-data-subnivel.service';
import { IsAllowed } from 'src/app/shared/decorators/is-allowed.decorator';

@Component({
  selector: 'c-variations-section',
  templateUrl: './variations-section.component.html',
  styleUrls: ['./variations-section.component.scss']
})
export class VariationsSectionComponent implements OnInit, OnDestroy {
  environment = environment;
  EnumVariacoesDadosEspecificos = EnumVariacoesDadosEspecificos;
  variacao$: Observable<EnumVariacoesDadosEspecificos>;
  variacaoControl: FormControl;
  destroy$ = new Subject();
  isDisabledVariation: boolean = false;
  showGradeVariation: boolean = true;

  @IsAllowed('rhcdm.dadosespecificos.excluirVariacao') canRemoveVariation: boolean;
  
  constructor(
    public specificDataService: ProductSpecificDataService,
    public specificDataSubnivelService: ProductSpecificDataSubnivelService,
    private route: ActivatedRoute
  ) {
    this.variacaoControl = this.specificDataService.form.get('variacoes') as FormControl;

    this.variacao$ = combineLatest([this.variacaoControl.valueChanges.pipe(startWith(this.variacaoControl.value)), 
      this.specificDataService.departamentos$.pipe(map(departamentos => {
      return !!departamentos.find(departamento => 
        departamento.id == this.specificDataSubnivelService.departamentoControl.value && 
        departamento.descricao == 'MODA E ACESSÓRIOS');
    }))]).pipe(takeUntil(this.destroy$), distinctUntilChanged(), switchMap(([v, isDepartamentoModa]) => {
      this.showGradeVariation = isDepartamentoModa;
      let variation = v;
      if (variation === EnumVariacoesDadosEspecificos.Grade && !this.showGradeVariation){
        variation = EnumVariacoesDadosEspecificos.Nenhum;
        this.variacaoControl.setValue(EnumVariacoesDadosEspecificos.Nenhum, {emitEvent: false});  
      }

      return of(variation);
    }));
  }

  ngOnInit() {

    var variacaoNoBanco = this.variacaoControl.value;

    this.variacao$.subscribe(v => {
      if (v === EnumVariacoesDadosEspecificos.Sortimento) {
        this.specificDataService.gradeListForm.clear();
        this.specificDataService.enableDadosDoEcommerce();

        if (this.specificDataService.sortimentoListForm.length == 0) {
          this.specificDataService.addSortimento();
        }

        this.specificDataService.canUpdatePDM && this.specificDataSubnivelService.pdmSortimentoGrade();
        this.specificDataService.canUpdatePDM = true;
      } else if (v === EnumVariacoesDadosEspecificos.Grade) {
        this.specificDataService.sortimentoListForm.clear();
        this.specificDataService.enableDadosDoEcommerceGrade();

        if (this.specificDataService.gradeListForm.length == 0) {
          this.specificDataService.addVariacao();
        }
        this.specificDataService.canUpdatePDM && this.specificDataSubnivelService.pdmSortimentoGrade("grade");
        this.specificDataService.canUpdatePDM = true;
      } else if (v === EnumVariacoesDadosEspecificos.Nenhum) {
        this.specificDataService.sortimentoListForm.clear();
        this.specificDataService.gradeListForm.clear();
        this.specificDataService.enableDadosDoEcommerce();
      }

      this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.isDisabledVariation = ((data.product.header.codigoSap != undefined || data.product.header.idVtex != undefined) && (v === EnumVariacoesDadosEspecificos.Sortimento || v === EnumVariacoesDadosEspecificos.Grade) && variacaoNoBanco !== EnumVariacoesDadosEspecificos.Nenhum);
      });

    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  isRemovableView(numeroVariacao: number, variacaoIntegrada: boolean) {
    const isNotIntegrated = (numeroVariacao > 1 && variacaoIntegrada)
    
    // O botão será visível se o usuário tiver permissão OU se o sortimento não estiver integrado
    return this.canRemoveVariation || isNotIntegrated;
  }
}
