import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReferenceComponent } from './reference.component';
import { DynamicFormTestComponent } from './dynamic-form-test/dynamic-form-test.component';

const routes: Routes = [
  { path: '', component: ReferenceComponent },
  { path: 'dynamic-forms', component: DynamicFormTestComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReferenceRoutingModule { }
