import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductCommercialDataService } from '../services/product-commercial-data.service';
import { ProductService } from '../services/product.service';
import { EnumAba } from 'src/app/models/api/produto/aba.enum';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { TipoMaterial } from 'src/app/models/api/comercial/tipo-material';
import { ProductSpecificDataService } from '../services/product-specific-data.service';
import { ProductSpecificDataSubnivelService } from '../services/product-specific-data-subnivel.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'c-product-commercial-data',
  templateUrl: './product-commercial-data.component.html',
  styleUrls: ['./product-commercial-data.component.scss']
})
export class ProductCommercialDataComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  enumAba = EnumAba;

  tipoMaterial$: Observable<TipoMaterial[]>;

  constructor(
    private productSpecificData: ProductSpecificDataService,
    private productSpecificDataSubnivel: ProductSpecificDataSubnivelService,
    public productCommercialData: ProductCommercialDataService,
    public productService: ProductService,
    ) {
      this.tipoMaterial$ = combineLatest([
        this.productCommercialData.tipoMaterial$,
        this.productSpecificData.departamentos$.pipe(map(departamentos => {
          return !!departamentos.find(departamento => 
            departamento.id == this.productSpecificDataSubnivel.departamentoControl.value && 
            departamento.descricao == 'MODA E ACESSÓRIOS');}))
      ]).pipe(takeUntil(this.destroy$), switchMap(([materials, isDepartamentoModa]) => {
        const control = this.productCommercialData.form.get('tipoMaterialId');
        const isSelectedMaterialMode = 
          !!materials.find(material => material.id == control.value && material.descricao == "Mode");
        
        if((isDepartamentoModa && !isSelectedMaterialMode) || (!isDepartamentoModa && isSelectedMaterialMode)){
          control.setValue(null);
        }

        return of(materials.filter(material => (!isDepartamentoModa && material.descricao != "Mode") || 
          (isDepartamentoModa && material.descricao == "Mode") ));
      }));;
     }

    ngOnInit() {
    }

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    }
    onKeyDown(event: KeyboardEvent) {
      if (event.key === ' ') {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value += '\n';
        inputElement.dispatchEvent(new Event('input'));
        event.preventDefault();
      }
    }
}
