import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  /**
   * Subscribe toggle$ to listen notification show or hide events
   */
  toggle$ = new BehaviorSubject(false);
  notifications: any[];

  /**
   * Call toggle to show or hide notification menu
   */
  toggle() {
    this.toggle$.next(!this.toggle$.value);
  }

  get hasNewNotifications() {
    return false;
  }

}
