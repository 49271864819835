import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleComponent } from './role.component';
import { AuthGuard } from 'src/app/services/guards/auth.guard';

const routes: Routes = [
  {
    path: '', component: RoleComponent,
    canActivate: [AuthGuard],
    data: { permission: 'rhcdm.papeis.criar' }
  },
  {
    path: ':id', component: RoleComponent,
    canActivate: [AuthGuard],
    data: { permission: 'rhcdm.papeis.editar' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }
