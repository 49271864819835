import { IResponse } from './../../../models/api/response.interface';
import { LogImport } from './../../../models/api/log-import';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApiHttpClientService } from '../../api-http-client.service';
import { PlanilhaTemplate } from 'src/app/models/api/importador/planilha-template.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiImportadorService {

  private baseUrl = 'Importador';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.importador)
      .useBaseUrl(this.baseUrl);
  }

  validateSpreadSheet(res: object) {
    return this.controller.unwrap().useLoading().post('ValidarPlanilha', res);
  }

  importSpreadSheetCreate(res: object) {
    return this.controller.unwrap().useLoading().post('ImportarPlanilha', res);
  }

  importSpreadSheetUpdate(res: object) {
    return this.controller.unwrap().useLoading().post('ImportarPlanilhaAtualizar', res);
  }

  importSpreadSheetCreateDadosComerciais(res: object) {
    return this.controller.unwrap().useLoading().post('SalvarPlanilha/DadosComerciais', res);
  }

  importSpreadSheetUpdateDadosComerciais(res: object) {
    return this.controller.unwrap().useLoading().post('AtualizarPlanilha/DadosComerciais', res);
  }

  saveSpreadSheet(file: object) {
  return this.controller.unwrap().useLoading().post<PlanilhaTemplate>('SalvarPlanilha', file);
  }

  exportProduct() {
    return this.controller.useLoading().get('DownloadPlanilhaTemplate', { responseType: 'blob' });
  }

  getLogImport() {
    return this.controller.useLoading().get<IResponse<LogImport[]>>('LogImportacao');
  }
}
