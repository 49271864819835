import { FormControl } from '@angular/forms';
import { isFunction } from 'src/app/shared/utils/is-function';

export function validatePassword(secondControl: (() => FormControl) | FormControl) {
  return (control: FormControl) => {
    const controlToCompare = isFunction<FormControl>(secondControl) ? secondControl() : secondControl;

    if (controlToCompare) {
      if (control.value != controlToCompare.value) {
        return {
          passwordNotSame: true
        };
      }
    }

    return null;
  };
}
